<template>
  <div class="container">
    <div class="form-group row mb-3">
      <h4 class="mx-2 mb-2">Manage Pictures</h4>
      <h5>Upload Images for <span style="color:#4B49AC">{{ excursionInfo.informationName }} </span></h5>
      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-secondary me-2" @click="goBack">
          <font-awesome-icon :icon="['fas', 'fa-arrow-left']" class="me-2" /> Back to New Information
        </button>
      </div>
    </div>

    <div class="form-group row mb-3">
      <label for="uploadImage" class="col-sm-3 col-form-label">Select Image <span style="color:red !important">*</span></label>
      <div class="col-sm-6">
        <input
          type="file"
          class="form-control"
          id="uploadImage"
          @change="handleFileUpload"          
          accept="image/jpeg, image/png"/>
      </div>
    </div>
    <div class="form-group row mb-3">
      <label class="col-sm-3 col-form-label">Newinformation Image Show In:</label>
      <div class="col-sm-6 d-flex gap-3 align-items-center">
        <div v-for="option in availableOptions" :key="option" class="form-check-inline">
          <input type="checkbox" :id="option" :value="option" v-model="selectedOptions" class="me-2" />
          <label :for="option">{{ option }}</label>
        </div>
      </div>
    </div>
    <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div> 
    <div class="form-actions d-flex justify-content-end mb-4">
      <button type="button" class="btn btn-success me-2" @click="saveImage" :disabled="!selectedFile">
        <font-awesome-icon :icon="['fas', 'floppy-disk']" /> Upload & Save
      </button>
      <button type="button" class="btn btn-danger" @click="cancelUpload">
        <font-awesome-icon :icon="['fas', 'xmark']" /> Cancel
      </button>
    </div>

    <div v-if="validImages.length > 0">
      <h5>Uploaded Images List</h5>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Sl.No</th>
            <th>Image Preview</th>
            <th>Picture Show In</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(image, index) in validImages" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <img :src="image.filePath" class="table-image" alt="Image Preview" />
            </td>
            <td>
              <span v-if="image.DisplayIn && image.DisplayIn.length">
                {{ image.DisplayIn.join(', ') }}
              </span>
              <span v-else></span>
            </td>
            <td>
              <button class="btn-sm faq-button" @click="confirmDeleteImage(index)"><font-awesome-icon :icon="['fas', 'fa-trash']" class="delete" /><span class="tooltip">Delete</span></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
  <h5>Uploaded Images List</h5>
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>SI.No</th>
        <th>Image</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="3" class="text-center">No Images Uploaded</td>
      </tr>
    </tbody>
  </table>
</div>
    <DeleteModal
  :visible="showDeleteModal"
  @confirmDelete="deleteImage"
  @cancelDelete="showDeleteModal = false"
  />  
  </div>
</template>

<script>
import axios from 'axios';
import { ref,reactive,onMounted, computed } from 'vue';
import { useRoute, useRouter} from 'vue-router';
import { API_CONFIG } from '@/apiConfig';
import DeleteModal from './DeleteModal.vue';

export default {
  components: {
        DeleteModal,
        },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const excursionInfo = reactive({ images:[ { filePath :'', DisplayIn:[] } ] });
    const excursionId = route.params.excursionId;
    const informationName = ref(''); 
    const selectedFile = ref(null);
    const uploadedImages = ref([]);
    const excursionType = reactive({ value: 'information' });
    const errorMessage = ref(null);
    const availableOptions = ref(["Quotation Itinerary", "Final Itinerary", "Website"]);
    const selectedOptions = ref([]);
    const showDeleteModal = ref(false);
    const imageToDelete = ref(null);
    // Handle image selection
    const handleFileUpload = (event) => {
      errorMessage.value = null; // Reset error message
      const file = event.target.files[0];
      const validTypes = ['image/jpeg', 'image/png'];
      const maxSize = 2 * 1024 * 1024; // 2MB limit

      if (!validTypes.includes(file.type)) {
        alert('Only JPEG and PNG files are allowed.');
        event.target.value = ''; // Clear the input
        return;
      }

      if (file.size > maxSize) {
        alert('File size should not exceed 2MB.');
        event.target.value = ''; 
        return;
      }
      selectedFile.value = file;
    };

    const validImages = computed(() => {
      return excursionInfo.images.filter(image => image.filePath);
    });

    // Upload and save image
    const saveImage = async () => {
      if (!selectedFile.value) {
        alert('Please select an image to save.');
        return;
      }      
      const imageData = new FormData();
      imageData.append('images', selectedFile.value); // Append file
      try {
        const response = await axios.post(API_CONFIG.getEndpoint('uploadImages') + `/`+excursionType.value, imageData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log("S3 bucket path:", response.data);
        excursionInfo.images = [
          ...(excursionInfo.images || []), 
          ...response.data.map(filePath => ({ filePath,
             DisplayIn: [...selectedOptions.value]
           }))
        ];   
        console.log("excursionInfo.images ::" , excursionInfo.images);        
        // Save the excursion with updated images
        await saveExcursion(excursionInfo);
        
        // Append uploaded images to UI
        response.data.forEach(filePath => {
          uploadedImages.value.push({ url: filePath });
        });        

      } catch (error) {
        console.log("Error uploading images", error);
      }
      selectedFile.value = null;
      document.getElementById('uploadImage').value = ''; // Reset file input
    };
    const saveExcursion = async (excursionInfo) => {
      try {                  
        const response = await axios.post(
          API_CONFIG.getEndpoint('createExcursion') + `/${excursionInfo.id}`
          +`?excursionType=${excursionType.value}`
          +`&code=${excursionInfo.destinationCityId}`
          + `&destinationId=${excursionInfo.destinationId}`
          + `&cityId=${excursionInfo.cityId}`,  
          excursionInfo );
        Object.assign(excursionInfo, response.data);
        console.log("saveExcursion:", response.data);

      } catch (error) {
        console.log("Save excursion failed:", error);
        errorMessage.value = error;
      }
    };

    // Cancel file upload
    const cancelUpload = () => {
      selectedFile.value = null;
      document.getElementById('uploadImage').value = ''; // Reset file input
    };
    const confirmDeleteImage = (index) => {
  imageToDelete.value = index;
  showDeleteModal.value = true;
};

const deleteImage = async () => {
  if (imageToDelete.value !== null) {
    try {
      excursionInfo.images.splice(imageToDelete.value, 1);    
      await saveExcursion(excursionInfo);

      showDeleteModal.value = false;
      imageToDelete.value = null;

      console.log("Image deleted successfully");
    } catch (error) {
      console.log("Error deleting image:", error);
    }
  }
};

    // Navigate back
    const goBack = () => {
      router.push({ name: 'information'});
    };
    // Fetch excursion details
    const fetchExcursionById = async () => {      
      try {
        const response = await axios.get(API_CONFIG.getEndpoint('fetchExcursions') + '/' + excursionId);          
        Object.assign(excursionInfo, response.data);
        if (excursionInfo.images && excursionInfo.images.length) {
          uploadedImages.value = excursionInfo.images.map(image => ({ url: image.filePath }));
        }
        console.log("fetchExcursionById:", excursionInfo);          
      } catch (error) {
        console.log("Error fetching excursion:", error);
      }
    };

    onMounted ( () => {        
        fetchExcursionById();                
    });

    return {
      informationName,
      selectedFile,
      uploadedImages,
      handleFileUpload,
      saveImage,
      cancelUpload,
      deleteImage,
      goBack,
      fetchExcursionById,
      excursionInfo,
      saveExcursion,
      errorMessage,
      availableOptions,
      selectedOptions,
      imageToDelete,
      confirmDeleteImage,
      showDeleteModal,
      validImages
    };
  },
};
</script>

<style scoped>
.table-image {
  width: 80px;
  height: 50px;
  border-radius: 5px;
}

label{
  margin: 0px;
}
</style>
