<template>
  <div class="nested-options">
    <div v-for="(level, index) in levels" :key="index" class="level">
      <label class="level-label">{{ level.label }}</label>
      <div v-for="option in level.options" :key="option.name" class="option-item" v-show="option.name">
        <input type="checkbox" :value="option.name" v-model="selectedOptions[index]" @change="onSelectionChange(index)">
        <label>{{ option.name }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NestedOptions',
  props: {
    data: {
      type: Object,
      required: true
    },
    selectedData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      levels: [],
      selectedOptions: [],
      localSelectedData: {}
    };
  },
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        this.initializeLevels(newData);
        this.initializeSelectedOptions();
      }
    },
    selectedData: {
      immediate: true,
      handler(newSelectedData) {
        this.localSelectedData = { ...newSelectedData };
        this.initializeSelectedOptions();
      }
    }
  },
  methods: {
    initializeLevels(data) {
      this.levels = [];
      this.selectedOptions = [];
      if (data.continents) {
        this.levels.push(
            { label: 'Continent',
              options: data.continents.map(
                  continent => ({ name: continent.continentName,
                    children: continent.regions }))
            });
        this.selectedOptions.push([]);
      }
    },
    initializeSelectedOptions() {
      if (Object.keys(this.localSelectedData).length > 0) {
        this.levels.forEach((level, index) => {
          if (this.localSelectedData[level.label]) {
            this.selectedOptions[index] = this.localSelectedData[level.label].split(', ');
            this.onSelectionChange(index, true);
          }
        });
      }
    },
    onSelectionChange(levelIndex, initializing = false) {
      this.levels = this.levels.slice(0, levelIndex + 1);
      this.selectedOptions = this.selectedOptions.slice(0, levelIndex + 1);
      const selectedOptions = this.levels[levelIndex].options.filter(option => this.selectedOptions[levelIndex].includes(option.name));
      if (selectedOptions.length > 0) {
        const nextLevelOptions = selectedOptions.flatMap(option => option.children || []).map(child => (
            { name: Array.isArray(child.cityNames) ? child.cityNames.join(', ') : (child.regionName || child.countryName || child.stateName || child.cityNames), children: child.countries || child.states || child.cities }));
        if (nextLevelOptions.length > 0) {
          const nextLevelLabel = this.getNextLevelLabel(levelIndex + 1);
          this.levels.push({ label: nextLevelLabel, options: nextLevelOptions });
          this.selectedOptions.push([]);
          if (initializing && this.localSelectedData[nextLevelLabel]) {
            this.selectedOptions[levelIndex + 1] = this.localSelectedData[nextLevelLabel].split(', ');
            this.onSelectionChange(levelIndex + 1, true);
          }
        }
      }
      if (!initializing) {
        this.updateSelectedData();
      }
    },
    updateSelectedData() {
      this.localSelectedData = this.levels.reduce((acc, level, index) => {
        acc[level.label] = this.selectedOptions[index].join(', ');
        return acc;
      }, {});
      this.$emit('update:selectedData', this.localSelectedData);
    },
    getNextLevelLabel(levelIndex) {
      const labels = ['Region', 'Country', 'State', 'City'];
      return labels[levelIndex - 1] || '';
    },
    getCommaSeparatedNames(levelIndex) {
      return this.selectedOptions[levelIndex].join(', ');
    }
  }
};
</script>

<style scoped>
.nested-options {
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.level-label {
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
}

.option-item {
  margin-bottom: 5px;
}

.level {
  padding-left: 0;
}

input[type="checkbox"] {
  margin-right: 10px;
}

input[type="checkbox"]:hover {
  cursor: pointer;
}

label {
  cursor: pointer;
}

.output {
  margin-top: 20px;
}
</style>