
<template>
  <div class="container">
    <div class="card-header" v-if="showListView">
      <div class="d-flex justify-content-between align-items-center" >
        <h4>Manage Intercity Transfer </h4>   
        <div style="display: flex !important; gap: 20px !important">
          <div v-if="showListView">
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div> 
  <button type="button" class="btn1" @click="showForm('create')"><font-awesome-icon :icon="['fas', 'fa-plus']" class="me-2" />New Intercity Transfer </button>
        </div>        
      </div>
      </div>
      <div class="table-container"> 
      <div v-if="showListView">
         <!-- <h4> List of Intercity Transfer</h4> -->        
          <table class="table table-bordered mt-3 custom-table-width">
            <thead>
              <tr>
                  <th>SI.No</th>
                  <th>Destination Name</th>
                  <th>Start City </th>
                  <th>End City</th>
                  <th>TransferMode</th>
                  <th>groupSize</th>                  
                  <th>Partner</th>
                  <th>Actual Price</th>
                  <th>Company Markup In</th>
                  <th>Company Markup</th>
                  <th>Currency</th>
                  <th>Price</th>                  
                  <th>Status</th>
                  <th style="width: 150px;">Actions</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(excursion, index) in paginatedUsers" :key = "excursion.id">
                <tr v-for="(transportDetail, tIndex) in excursion.transportDetails" :key="tIndex">    
                  <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>              
                    <td>{{ excursion.destinationName }}</td>
                    <td>{{ excursion.cityName }}</td>
                    <td>{{ excursion.destinationCityName }}</td>
                    <td class="text-nowrap">{{ transportDetail.transferMode  }}</td>
                    <td>{{ transportDetail.groupSize  }}</td>                    
                    <td>{{ transportDetail.partner  }}</td>
                    <td>{{ transportDetail.actualPrice  }}</td>
                    <td>{{ transportDetail.companyMarkupType  }}</td>                    
                    <td>{{ transportDetail.companyMarkup  }}</td>
                    <td>{{ transportDetail.currency  }}</td>
                    <td>{{ transportDetail.finalPrice  }}</td>
                    <td>{{ excursion.status  }}</td>                
                    <td>
                        <div style="display: flex;">
                          <a href="#" @click="showForm('edit',excursion)" class="text-warning mr-3 faq-button" role="button"> <font-awesome-icon :icon="['fas', 'fa-edit']" class="edit" /> <span class="tooltip">Edit</span></a>
                          <a href="#" @click="confirmDeleteExcursion(excursion.id)" class="text-warning mr-3 faq-button" role="button"> <font-awesome-icon :icon="['fas', 'fa-trash']" class="delete" /><span class="tooltip">Delete</span></a>
                        </div>
                    </td>                            
                </tr>
              </template>
            </tbody>
          </table>
      </div>
      </div>
      <div v-if="!showListView">
        <div class="form-section">                      
          <!-- <h4>{{ formTitle }}</h4> -->
          <div class="form-group row mb-3">
            <div class="col-sm-1"></div>
            <label for="destination" class="col-sm-3 col-form-label">Select Destination <span style="color:red !important">*</span></label>                    
            <div class="col-sm-6">
                <select v-model="excursionInfo.destinationId"  @change="onChangeByDestination" class="form-control" id="destination" required>
                    <option disabled value="">--Please select a destination--</option>
                    <option v-for="destination in destinations.list" :key="destination.id" :value="destination.id">
                        {{ destination.destinationName }}
                    </option>
                </select>                
                <div class="text-danger" v-for="(error, index) in v$.excursionInfo.destinationId.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
          </div>

          <div class="form-group row mb-3">
            <div class="col-sm-1"></div>
            <label for="city" class="col-sm-3 col-form-label">Select Start City <span style="color:red !important">*</span></label>                    
            <div class="col-sm-6">
              <select v-model="excursionInfo.cityId" class="form-control" id="city">
                  <option disabled value="">-- Please select a city --</option>
                  <option v-for="city in cities.list" :key="city.id" :value="city.id">
                      {{ city.cityName }}
                  </option>
              </select>              
              <div class="text-danger" v-for="(error, index) in v$.excursionInfo.cityId.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>                    
          </div>

          <div class="form-group row mb-3">
            <div class="col-sm-1"></div>
            <label for="city" class="col-sm-3 col-form-label">Select Destination City <span style="color:red !important">*</span></label>                    
            <div class="col-sm-6">
              <select v-model="excursionInfo.destinationCityId" class="form-control" id="city">
                  <option disabled value="">-- Please select a city --</option>
                  <option v-for="city in cities.list" :key="city.id" :value="city.id">
                      {{ city.cityName }}
                  </option>
              </select>              
              <div class="text-danger" v-for="(error, index) in v$.excursionInfo.destinationCityId.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>                    
          </div>

        </div>
          <!-- <h3>Transfer Type</h3> -->
          
        <div v-for="(transportInfo, index) in excursionInfo.transportDetails" :key="index" class="mb-3">
          <div>            
            <h4> 
              <label for="" class="col-form-label">Transfer Type {{ transportInfo.transferMode }}</label>
              <!-- <a href="#" @click.prevent="toggleForm(index)" v-if="excursionInfo.transportDetails.length < 0">
                {{ formVisible[index] ? 'Hide' : 'Show' }} Transport Details {{ index + 1 }}
              </a> 
              <a href="#" @click.prevent="addMoreTransportDetails(index)" class="ml-3"> Add More </a>
              <a href="#" @click.prevent="removeTransportDetail(index)" class="ml-3 text-danger">Remove</a>             -->
            </h4>
          </div>

          <div v-if="formVisible[index] || excursionInfo.transportDetails.length === 1" >
            <div class="container border p-3">                       

              <div class="form-group row mb-3">                
                <label for="transferMode" class="col-sm-3 col-form-label">Transfer Mode <span style="color:red !important">*</span> </label>
                <div class="col-sm-3">
                  <input type="text" class="form-control" id="transferMode" v-model="transportInfo.transferMode" placeholder="Enter Unique Mode of transfer">
                </div>

                <label for="groupSize" class="col-sm-3 col-form-label">Group Size </label>
                <div class="col-sm-3">
                  <input type="text" class="form-control" id="groupSize" v-model="transportInfo.groupSize" placeholder="Enter group size">                    
                </div>                
              </div>              

              <div class="form-section">
                <h5>Price Details </h5>
                <hr/>
                <div class="form-group row mb-3">
                  <label for="partners" class="col-sm-3 col-form-label">Select partner </label>                    
                  <div class="col-sm-3">
                      <select v-model="transportInfo.partner"  class="form-control" id="partners" >
                          <option disabled value="">--Please select a partner--</option>
                          <option v-for="partner in partners.list " :key="partner.partnerCode" :value="partner.partnerCode">
                              {{ partner.partnerName }}
                          </option>
                      </select>
                  </div>                  
                  <label for="partners" class="col-sm-3 col-form-label">Select Currency </label>                    
                  <div class="col-sm-3">
                      <select v-model="transportInfo.currency"  class="form-control" id="partners">
                          <option disabled value="">--Select Currency--</option>
                          <option v-for="currency in currencies " :key="currency" :value="currency">
                                  {{ currency }}
                          </option>                      
                        </select>
                  </div>
                </div>

                <div class="form-group row mb-3">
                  <label for="actualPrice" class="col-sm-3 col-form-label">Actual Price </label>
                  <div class="col-sm-3">
                      <input type="number" step="0.01" min="0" class="form-control" id="actualPrice" v-model="transportInfo.actualPrice" placeholder="Enter actual price"  @input="limitDecimals(transportInfo, 'actualPrice')">
                  </div>                  
                  <label for="companyMarkupType" class="col-sm-3 col-form-label">Select Company Markup In </label>
                  <div class="col-sm-3">
                      <select v-model="transportInfo.companyMarkupType"  class="form-control" id="companyMarkupType" >
                          <option disabled value="">-- Please select a markup type --</option>
                          <option value="percentage">Percentage</option>
                          <option value="absolute">Absolute</option>
                      </select>  
                  </div>
                </div>

                <div class="form-group row mb-3">
                    <label for="companyMarkup" class="col-sm-3 col-form-label">Company Markup </label>
                    <div class="col-sm-3">
                        <input type="number" min="0" class="form-control" id="companyMarkup" v-model="transportInfo.companyMarkup" placeholder="Enter price" @input="limitDecimals(transportInfo, 'companyMarkup')">
                    </div>                
                    <label for="finalPrice" class="col-sm-3 col-form-label">Final Price </label>
                    <div class="col-sm-3">
                        <input type="number" step="0.01" class="form-control" id="finalPrice" :value="calculateFinalPrice(transportInfo)" readonly>
                    </div>
                </div>
              </div><!-- End of price details -->

              <div class="form-section">
                <h5>Meeting Details</h5>
                <hr/>
                <div class="form-group row mb-3">
                  <label for="finalPrice" class="col-sm-3 col-form-label">Departure Time </label>
                  <div class="col-sm-6">
                      <input type="text" v-model="transportInfo.departureTime" class="form-control" id="finalPrice">
                  </div>       
                </div>
                <div class="form-group row mb-3">
                  <label for="departureTimeDisplayIn" class="col-sm-3 col-form-label">Display In<span style="color:red !important">*</span></label>
                  <div class="col-sm-9 mt-2">
                    <div class="form-group-inline-flex mr-3 col-sm-3" v-for="showIn in showInOptionsDT" :key="showIn.key">
                      <input type="checkbox" v-model="transportInfo.departureTimeDisplayIn" :id="showIn.key" :value="showIn.value">
                      <label class="ml-3" :for="showIn.key"> {{ showIn.label }} </label>
                    </div>
                  </div>
                </div>

                <div class="form-group row mb-3">
                  <label for="finalPrice" class="col-sm-3 col-form-label">Name of Originating Point</label>
                  <div class="col-sm-6">
                      <input type="text" v-model="transportInfo.originatingPoint" class="form-control" id="finalPrice" >
                  </div>       
                </div>
                <div class="form-group row mb-3">
                  <label for="meeting-point-display-in" class="col-sm-3 col-form-label">Originating Point Display In<span style="color:red !important">*</span></label>
                  <div class="col-sm-9 mt-2">
                    <div class="form-group-inline-flex mr-3 col-sm-3" v-for="showIn in showInOptionsNOP" :key="showIn.key">
                      <input type="checkbox" v-model="transportInfo.originatingPointDisplayIn" :id="showIn.key" :value="showIn.value">
                      <label class="ml-3" :for="showIn.key"> {{ showIn.label }} </label>
                    </div>
                  </div>
                </div>                
                <div class="form-group row mb-3">
                  <label for="hotelLocation" class="col-sm-3 col-form-label">Originating Point Location</label>
                  <div class="col-sm-3">
                    <input type="number" step="0.000001" class="form-control" id="hotelLocation" v-model="excursionInfo.originatingPointLocationLatitude" placeholder="Latitude">
                    <div class="text-danger" v-for="(error, index) in v$.excursionInfo.originatingPointLocationLatitude.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <input type="number" step="0.000001" class="form-control" id="hotelLocation" v-model="excursionInfo.originatingPointLocationLongitude" placeholder="Longitude">
                    <div class="text-danger" v-for="(error, index) in v$.excursionInfo.originatingPointLocationLongitude.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>  
                  </div>
                </div><!-- end of orinating point-->

                <div class="form-group row mb-3">
                  <label for="local-partner-no" class="col-sm-3 col-form-label">Arrival Time </label>
                  <div class="col-sm-6">
                      <input type="text" v-model="transportInfo.arrivalTime" class="form-control" id="local-partner-no">
                  </div>       
                </div>
                <div class="form-group row mb-3">
                  <label for="local-partner-number-display-in" class="col-sm-3 col-form-label">Arrival Time Display In<span style="color:red !important">*</span></label>
                  <div class="col-sm-9 mt-2">
                    <div class="form-group-inline-flex mr-3 col-sm-3" v-for="showIn in showInOptionsAT" :key="showIn.key">
                      <input type="checkbox" v-model="transportInfo.arrivalTimeDisplayIn" :id="showIn.key" :value="showIn.value">
                      <label class="ml-3" :for="showIn.key"> {{ showIn.label }} </label>
                    </div>
                  </div>
                </div>

                <div class="form-group row mb-3">
                  <label for="finalPrice" class="col-sm-3 col-form-label">Name of Destination Point</label>
                  <div class="col-sm-6">
                      <input type="text" v-model="transportInfo.destinationPoint" class="form-control" id="finalPrice" >
                  </div>       
                </div>
                <div class="form-group row mb-3">
                  <label for="meeting-point-display-in" class="col-sm-3 col-form-label">Destination Point Display In<span style="color:red !important">*</span></label>
                  <div class="col-sm-9 mt-2">
                    <div class="form-group-inline-flex mr-3 col-sm-3" v-for="showIn in showInOptionsDP" :key="showIn.key">
                      <input type="checkbox" v-model="transportInfo.destinationPointDisplayIn" :id="showIn.key" :value="showIn.value">
                      <label class="ml-3" :for="showIn.key"> {{ showIn.label }} </label>
                    </div>
                  </div>
                </div>                
                <div class="form-group row mb-3">
                  <label for="hotelLocation" class="col-sm-3 col-form-label">Destination Point Location</label>
                  <div class="col-sm-3">
                    <input type="number" step="0.000001" class="form-control" id="hotelLocation" v-model="excursionInfo.destinationPointLocationLatitude" placeholder="Latitude">
                    <div class="text-danger" v-for="(error, index) in v$.excursionInfo.destinationPointLocationLatitude.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <input type="number" step="0.000001" class="form-control" id="hotelLocation" v-model="excursionInfo.destinationPointLocationLongitude" placeholder="Longitude">
                    <div class="text-danger" v-for="(error, index) in v$.excursionInfo.destinationPointLocationLongitude.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>  
                  </div>
                </div><!-- end of orinating point-->

              </div>  <!-- End of meeting details -->

              <div class="form-section">
                <div class="form-group row mb-3">
                  <label for="description" class="col-sm-3 col-form-label">Description </label>
                  <div class="col-sm-6">
                    <textarea type="text" class="form-control" id="description" v-model="transportInfo.description" placeholder="Enter description"></textarea>              
                  </div>
                </div>                          
              </div>
            </div>
          </div>
        </div> <!-- end of repeat -->
        <div class="form-group row mb-3 d-flex align-items-center g-2">
            <label for="status" class="col-sm-3 col-form-label">Status</label>                
            <div class="col-sm-2 d-flex align-items-center">
                <input type="radio" v-model="excursionInfo.status" class="form-check-input m-0 me-2" id="statusActive" value="Active">
                <label for="statusActive" class="form-check-label m-0">Active</label>
            </div>
            <div class="col-sm-2 d-flex align-items-center">
                <input type="radio" v-model="excursionInfo.status" class="form-check-input m-0 me-2" id="statusInactive" value="Inactive">
                <label for="statusInactive" class="form-check-label m-0">Inactive</label>
            </div>            
        </div>  
        <hr>
        <div class="form-actions">
          <button type="submit" class="btn btn-success" @click="addTransport" v-if="!isEdit" > <font-awesome-icon :icon="['fas', 'floppy-disk']" /> Save </button>
          <button type="submit" class="btn btn-success" @click="updateTransport" v-if="isEdit" > <font-awesome-icon :icon="['fas', 'file-pen']" /> Update </button>
          <button type="button" class="btn btn-light" @click="cancel"> <font-awesome-icon :icon="['fas', 'xmark']" /> Cancel</button>
        </div>
        <br>
      </div>     
      <DeleteModal
  :visible="showDeleteModal"
  @confirmDelete="deleteExcursion"
  @cancelDelete="showDeleteModal = false"
  />    
  <div class="d-flex justify-content-between mt-3" v-if="showListView">
      <div class="d-flex align-items-center mt-0">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredExcursions.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination mb-0" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>   
    </div>
  </div> <!-- end of container -->
</template>
<script>
    import axios from 'axios';
    import { reactive,  onMounted, ref, watch ,computed} from 'vue';
    import { API_CONFIG } from '@/apiConfig';
    import { required, numeric, minValue,between, helpers} from '@vuelidate/validators'
    import useVuelidate from '@vuelidate/core'
    import 'bootstrap-icons/font/bootstrap-icons.css'
    import DeleteModal from './DeleteModal.vue';
    import currencies from '@/data/currencies';

    // import { toRefs } from 'vue';
    // import { useRouter } from 'vue-router';
    
    export default {
      components: {
        DeleteModal,
        },

        setup() {
            
            const excursionType = ref("intercity");
            // const router = useRouter();
            const showDeleteModal = ref(false);
            const excursionToDelete = ref(null);
            const searchQuery = ref('');
            const currentPage = ref(1);
            const itemsPerPage = ref(10);
            
            const excursions =  reactive({ list: [] });
            const destinations =  reactive({ list: [] });            
            const cities = reactive( { list :[] });            
            //const airports = reactive({ list: [] });
            const transportDetails = reactive({ list: [] });
            const partners = reactive({ list: [] });
            const excursionInfo = reactive({
              destinationId : '',
              cityId : '',
              destinationCityId : '',
              status : 'Active',
              transportDetails : [{
                id : '',
                groupSize  : '',
                partner: '',
                currency : '',
                actualPrice  : '0',
                companyMarkupType : '',
                companyMarkup  : 0,
                finalPrice  : '0',
                transferMode : '',   // this should be unique
                departureTime : '',
                departureTimeDisplayIn : [],
                arrivalTime : '',
                arrivalTimeDisplayIn : [],
                originatingPoint: '',
                originatingPointDisplayIn : [],
                originatingPointLocationLatitude : "",
                originatingPointLocationLongitude : "",
                destinationPoint: '',
                destinationPointDisplayIn :[],
                destinationPointLocationLatitude : '',
                destinationPointLocationLongitude : '',
                description : '',
                status: 'Active'
              }]
            });

            const transportInfo = reactive({
              id : '',
              groupSize  : '',
              partner: '',
              currency : '',
              actualPrice  : '0',
              companyMarkupType : '',
              companyMarkup  : 0,
              finalPrice  : '0',
              transferMode : '',   // this should be unique
              departureTime : '',
              departureTimeDisplayIn : [],
              arrivalTime : '',
              arrivalTimeDisplayIn : [],
              originatingPoint: '',
              originatingPointDisplayIn : [],
              originatingPointLocationLatitude : "",
              originatingPointLocationLongitude : "",
              destinationPoint: '',
              destinationPointDisplayIn :[],
              destinationPointLocationLatitude : '',
              destinationPointLocationLongitude : '',
              description : '',
              status: 'Active'
            });

            const filteredExcursions = computed(() => {
  return excursions.list.filter((excursion) => {
    return (
      excursion.destinationName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      excursion.cityName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      excursion.destinationCityName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      excursion.transportDetails.some((transportDetail) =>
        transportDetail.transferMode.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        transportDetail.partner.toLowerCase().includes(searchQuery.value.toLowerCase()) 
      )
    );
  });
});

const getPageRange = () => {
  const range = [];
  const total = totalPages.value;
  const current = currentPage.value;
  const delta = 2;

  if (current > delta + 1) {
    range.push(1);
    if (current > delta + 2) range.push('...');
  }

  for (let i = Math.max(1, current - delta); i <= Math.min(total, current + delta); i++) {
    range.push(i);
  }

  if (current < total - delta - 1) {
    if (current < total - delta - 2) range.push('...');
    range.push(total);
  }

  return range;
};

const resetPagination = () => {
  currentPage.value = 1;
};

const goToPage = (page) => {
  const pageNum = Number(page);
  if (pageNum >= 1 && pageNum <= totalPages.value) {
    currentPage.value = pageNum;
  }
};

const paginatedUsers = computed(() => {
  const pageSize = Number(itemsPerPage.value) || 10;
  const current = Number(currentPage.value) || 1;
  
  const startIndex = (current - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  
  if (!filteredExcursions.value.length) return [];
  
  return filteredExcursions.value.slice(startIndex, endIndex);
});

const totalPages = computed(() => {
  const pageSize = Number(itemsPerPage.value) || 10;
  return Math.max(1, Math.ceil(filteredExcursions.value.length / pageSize));
});

watch(filteredExcursions, () => {
  if (currentPage.value > totalPages.value) {
    currentPage.value = totalPages.value || 1;
  }
});

watch(itemsPerPage, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    resetPagination();
  }
});

const clearSearch = () => {
  searchQuery.value = '';
};
            const generateRoomId = (destinationId, cityId, destinationCityId, transferMode) => {
              const tMode = transferMode ? transferMode.replace(/\s+/g, '') : '';              
              return `${ destinationId }_${ cityId }_${ destinationCityId }_${ tMode }`;
            };

            watch(
                [ () => excursionInfo.destinationId, () => excursionInfo.cityId, () => excursionInfo.destinationCityId, () => transportInfo.transferMode ], 
                () => { 
                  transportInfo.id = generateRoomId( excursionInfo.destinationId, excursionInfo.cityId, excursionInfo.destinationCityId, transportInfo.transferMode);
                  console.log("watch called ::: generateRoomId ==> transportInfo.transferMode"+ transportInfo.transferMode + ", id=> " + transportInfo.id );
                }, { immediate: true }
            );

            const maxValue = 5;
            const items = ref([ { 
              content: 'This is the content for Accordion Item #1' }, 
              { content: 'This is the content for Accordion Item #2' } 
            ]); 
            const activeIndex = ref(null); 
            const toggle = (index) => { activeIndex.value = activeIndex.value === index ? null : index; }; 
            const addItem = () => { 
              { if (items.value.length < maxValue)
                items.value.push({ content: `This is the content for Accordion Item #${items.value.length + 1}` }); 
                activeIndex.value = items.value.length - 1; 
              }
            };
            const removeItem = (index) => { 
              items.value.splice(index, 1); 
              if (activeIndex.value === index) { 
                activeIndex.value = null; 
              } else if (activeIndex.value > index) { 
                activeIndex.value -= 1; 
              } 
            };

            const showInOptionsDT = reactive([
              { key: 'quotation_dt', value: 'quotation', label: 'Quotation Itinerary' },
              { key: 'final_dt', value: 'final', label: 'Final  Itinerary' },
              { key: 'website_dt', value: 'website', label: 'Website' },
            ]);

          const showInOptionsNOP = reactive([
            { key: 'quotation_nop', value: 'quotation', label: 'Quotation Itinerary' },
            { key: 'final_nop', value: 'final', label: 'Final  Itinerary' },
            { key: 'website_nop', value: 'website', label: 'Website' },
          ]);

          const showInOptionsAT = reactive([
            { key: 'quotation_at', value: 'quotation', label: 'Quotation Itinerary' },
            { key: 'final_at', value: 'final', label: 'Final  Itinerary' },
            { key: 'website_at', value: 'website', label: 'Website' },
          ]);

          const showInOptionsDP = reactive([
            { key: 'quotation_dp', value: 'quotation', label: 'Quotation Itinerary' },
            { key: 'final_dp', value: 'final', label: 'Final  Itinerary' },
            { key: 'website_dp', value: 'website', label: 'Website' },
          ]);

            const showListView = ref(true);
            const isEdit = ref(false);
            const selectedIndex = ref(-1);
            const errorMessage = ref("");

            const rules = { 
              excursionInfo: {
                destinationId: { required }, 
                cityId: { required },
                destinationCityId : { required},
                //groupSize : { required },
                actualPrice : { numeric, minValue:minValue(0.0),
                  maxDecimalPlaces: helpers.withMessage(
            'Maximum 2 decimal places allowed',
            (value) => {
              if (!value) return true;
              const valueStr = value.toString();
              const decimalIndex = valueStr.indexOf('.');
              return decimalIndex === -1 || valueStr.length - decimalIndex <= 3;
            }
          )
                 },
                 companyMarkup: {
          numeric,
          minValue: minValue(0.0),
          maxDecimalPlaces: helpers.withMessage(
            'Maximum 2 decimal places allowed',
            (value) => {
              if (!value) return true;
              const valueStr = value.toString();
              const decimalIndex = valueStr.indexOf('.');
              return decimalIndex === -1 || valueStr.length - decimalIndex <= 3;
            }
          )
        } ,
                originatingPointLocationLatitude :  { between: between(-90, 90) },
                originatingPointLocationLongitude : { between: between(-180, 180) },
                destinationPointLocationLatitude :  { between: between(-90, 90) },
                destinationPointLocationLongitude : { between: between(-180, 180) },

              }
            };

            const v$ = useVuelidate(rules, { excursionInfo });
         
            // const currencies = ref (
            // [
            //     { value: 1, name: "AED", rate: 3.673 },
            //     { value: 2, name: "AFN", rate: 70.483864 },
            //     { value: 3, name: "ALL", rate: 94.101325 },
            //     { value: 4, name: "AMD", rate: 400.326463 },
            //     { value: 5, name: "ANG", rate: 1.804345 },
            //     { value: 6, name: "AOA", rate: 912 },
            //     { value: 7, name: "ARS", rate: 1030.200329 },
            //     { value: 8, name: "AUD", rate: 1.608752 },
            //     { value: 9, name: "AWG", rate: 1.8 },
            //     { value: 10, name: "AZN", rate: 1.7 },
            //     { value: 11, name: "BAM", rate: 1.875797 },
            //     { value: 12, name: "BBD", rate: 2 },
            //     { value: 13, name: "BDT", rate: 119.666241 },
            //     { value: 14, name: "BGN", rate: 1.875898 },
            //     { value: 15, name: "BHD", rate: 0.377452 },
            //     { value: 16, name: "BIF", rate: 2960.624136 },
            //     { value: 17, name: "BMD", rate: 1 },
            //     { value: 18, name: "BND", rate: 1.360284 },
            //     { value: 19, name: "BOB", rate: 6.917949 },
            //     { value: 20, name: "BRL", rate: 6.19575 },
            //     { value: 21, name: "BSD", rate: 1 },
            //     { value: 22, name: "BTC", rate: 0.000010551195 },
            //     { value: 23, name: "BTN", rate: 85.655778 },
            //     { value: 24, name: "BWP", rate: 13.925095 },
            //     { value: 25, name: "BYN", rate: 3.276459 },
            //     { value: 26, name: "BZD", rate: 2.011126 },
            //     { value: 27, name: "CAD", rate: 1.44155 },
            //     { value: 28, name: "CDF", rate: 2848.433737 },
            //     { value: 29, name: "CHF", rate: 0.901912 },
            //     { value: 30, name: "CLF", rate: 0.035968 },
            //     { value: 31, name: "CLP", rate: 992.480695 },
            //     { value: 32, name: "CNH", rate: 7.3006 },
            //     { value: 33, name: "CNY", rate: 7.2988 },
            //     { value: 34, name: "COP", rate: 4398.4079 },
            //     { value: 35, name: "CRC", rate: 507.936084 },
            //     { value: 36, name: "CUC", rate: 1 },
            //     { value: 37, name: "CUP", rate: 25.75 },
            //     { value: 38, name: "CVE", rate: 105.754547 },
            //     { value: 39, name: "CZK", rate: 24.188 },
            //     { value: 40, name: "DJF", rate: 178.286115 },
            //     { value: 41, name: "DKK", rate: 7.154 },
            //     { value: 42, name: "DOP", rate: 60.892915 },
            //     { value: 43, name: "DZD", rate: 135.55545 },
            //     { value: 44, name: "EGP", rate: 50.85791 },
            //     { value: 45, name: "ERN", rate: 15 },
            //     { value: 46, name: "ETB", rate: 127.756654 },
            //     { value: 47, name: "EUR", rate: 0.959325 },
            //     { value: 48, name: "FJD", rate: 2.3224 },
            //     { value: 49, name: "FKP", rate: 0.795197 },
            //     { value: 50, name: "GBP", rate: 0.795197 },
            //     { value: 51, name: "GEL", rate: 2.799991 },
            //     { value: 52, name: "GGP", rate: 0.795197 },
            //     { value: 53, name: "GHS", rate: 14.717307 },
            //     { value: 54, name: "GIP", rate: 0.795197 },
            //     { value: 55, name: "GMD", rate: 71.9954 },
            //     { value: 56, name: "GNF", rate: 8653.957027 },
            //     { value: 57, name: "GTQ", rate: 7.718794 },
            //     { value: 58, name: "GYD", rate: 209.370329 },
            //     { value: 59, name: "HKD", rate: 7.7617 },
            //     { value: 60, name: "HNL", rate: 25.438066 },
            //     { value: 61, name: "HRK", rate: 7.22598 },
            //     { value: 62, name: "HTG", rate: 130.906835 },
            //     { value: 63, name: "HUF", rate: 394.11 },
            //     { value: 64, name: "IDR", rate: 16185 },
            //     { value: 65, name: "ILS", rate: 3.684266 },
            //     { value: 66, name: "IMP", rate: 0.795197 },
            //     { value: 67, name: "INR", rate: 85.39575 },
            //     { value: 68, name: "IQD", rate: 1311.56349 },
            //     { value: 69, name: "IRR", rate: 42250 },
            //     { value: 70, name: "ISK", rate: 139.16462 },
            //     { value: 71, name: "JEP", rate: 0.795197 },
            //     { value: 72, name: "JMD", rate: 155.843283 },
            //     { value: 73, name: "JOD", rate: 0.7091 },
            //     { value: 74, name: "JPY", rate: 157.825 },
            //     { value: 75, name: "KES", rate: 129.650403 },
            //     { value: 76, name: "KGS", rate: 86.9994 },
            //     { value: 77, name: "KHR", rate: 4021.491937 },
            //     { value: 78, name: "KMF", rate: 471.824 },
            //     { value: 79, name: "KPW", rate: 900 },
            //     { value: 80, name: "KRW", rate: 1473.797724 },
            //     { value: 81, name: "KWD", rate: 0.308445 },
            //     { value: 82, name: "KYD", rate: 0.834316 },
            //     { value: 83, name: "KZT", rate: 524.068491 },
            //     { value: 84, name: "LAK", rate: 21884.539363 },
            //     { value: 85, name: "LBP", rate: 89661.111105 },
            //     { value: 86, name: "LKR", rate: 292.859536 },
            //     { value: 87, name: "LRD", rate: 182.218385 },
            //     { value: 88, name: "LSL", rate: 18.755383 },
            //     { value: 89, name: "LYD", rate: 4.923033 },
            //     { value: 90, name: "MAD", rate: 10.101472 },
            //     { value: 91, name: "MDL", rate: 18.461613 },
            //     { value: 92, name: "MGA", rate: 4696.722597 },
            //     { value: 93, name: "MKD", rate: 59.010694 },
            //     { value: 94, name: "MK", rate: 2098 },
            //     { value: 95, name: "MNT", rate: 3398 },
            //     { value: 96, name: "MOP", rate: 8.004412 },
            //     { value: 97, name: "MRO", rate: 357 },
            //     { value: 98, name: "MRU", rate: 39.937659 },
            //     { value: 99, name: "MUR", rate: 46.949997 },
            //     { value: 100, name: "MVR", rate: 15.4 },
            //     { value: 101, name: "MWK", rate: 1736.05821 },
            //     { value: 102, name: "MXN", rate: 20.33 },
            //     { value: 103, name: "MYR", rate: 4.4715 },
            //     { value: 104, name: "MZN", rate: 63.830001 },
            //     { value: 105, name: "NAD", rate: 18.755383 },
            //     { value: 106, name: "NGN", rate: 1550.283356 },
            //     { value: 107, name: "NIO", rate: 36.848416 },
            //     { value: 108, name: "NOK", rate: 11.3578 },
            //     { value: 109, name: "NPR", rate: 136.97173 },
            //     { value: 110, name: "NZD", rate: 1.775411 },
            //     { value: 111, name: "OMR", rate: 0.38501 },
            //     { value: 112, name: "PAB", rate: 1 },
            //     { value: 113, name: "PEN", rate: 3.746512 },
            //     { value: 114, name: "PGK", rate: 3.98854 },
            //     { value: 115, name: "PHP", rate: 57.918 },
            //     { value: 116, name: "PKR", rate: 278.705477 },
            //     { value: 117, name: "PLN", rate: 4.101568 },
            //     { value: 118, name: "PYG", rate: 7784.056779 },
            //     { value: 119, name: "QAR", rate: 3.64878 },
            //     { value: 120, name: "RON", rate: 4.7731 },
            //     { value: 121, name: "RSD", rate: 112.137345},
            //     { value: 122, name: "RUB", rate: 105.500406},
            //     { value: 123, name: "RWF", rate: 1381.183031},
            //     { value: 124, name: "SAR", rate: 3.7553},
            //     { value: 125, name: "SBD", rate: 8.38356},
            //     { value: 126, name: "SCR", rate: 13.9432},
            //     { value: 127, name: "SDG", rate: 601.5},
            //     { value: 128, name: "SEK", rate: 11.0062},
            //     { value: 129, name: "SGD", rate: 1.3588},
            //     { value: 130, name: "SHP", rate: 0.795197},
            //     { value: 131, name: "SLL", rate: 20969.5},
            //     { value: 132, name: "SOS", rate: 572.195879},
            //     { value: 133, name: "SRD", rate: 35.08},
            //     { value: 134, name: "SSP", rate: 130.26},
            //     { value: 135, name: "STD", rate: 22281.8},
            //     { value: 136, name: "STN", rate: 23.497818},
            //     { value: 137, name: "SVC", rate: 8.76037},
            //     { value: 138, name: "SYP", rate: 13002},
            //     { value: 139, name: "SZL", rate: 18.748382},
            //     { value: 140, name: "THB", rate: 34.069918},
            //     { value: 141, name: "TJS", rate: 10.937994},
            //     { value: 142, name: "TMT", rate: 3.51},
            //     { value: 143, name: "TND", rate: 3.195032},
            //     { value: 144, name: "TOP", rate: 2.39453},
            //     { value: 145, name: "TRY", rate: 35.139999},
            //     { value: 146, name: "TTD", rate: 6.803817},
            //     { value: 147, name: "TWD", rate: 32.8235},
            //     { value: 148, name: "TZS", rate: 2426.939935},
            //     { value: 149, name: "UAH", rate: 42.015248},
            //     { value: 150, name: "UGX", rate: 3672.380504},
            //     { value: 151, name: "USD", rate: 1},
            //     { value: 152, name: "UYU", rate: 43.865353},
            //     { value: 153, name: "UZS", rate: 12936.482705},
            //     { value: 154, name: "VEF", rate: 248487.642241},
            //     { value: 155, name: "VES", rate: 51.701112},
            //     { value: 156, name: "VND", rate: 25455.011984},
            //     { value: 157, name: "VUV", rate: 118.722},
            //     { value: 158, name: "WST", rate: 2.8},
            //     { value: 159, name: "XAF", rate: 629.275716},
            //     { value: 160, name: "XAG", rate: 0.03405713},
            //     { value: 161, name: "XAU", rate: 0.00038136},
            //     { value: 162, name: "XCD", rate: 2.70255},
            //     { value: 163, name: "XDR", rate: 0.767755},
            //     { value: 164, name: "XOF", rate: 629.275716},
            //     { value: 165, name: "XPD", rate: 0.00110064},
            //     { value: 166, name: "XPF", rate: 114.477881},
            //     { value: 167, name: "XPT", rate: 0.00108564},
            //     { value: 168, name: "YER", rate: 249.851663},
            //     { value: 169, name: "ZAR", rate: 18.6804},
            //     { value: 170, name: "ZMW", rate: 27.758117},
            //     { value: 171, name: "ZWL", rate: 322}
            // ]);


            const limitDecimals = (transportInfo, field) => {
  if (transportInfo[field] !== undefined && transportInfo[field] !== null) {
    const valueStr = transportInfo[field].toString();
    const decimalIndex = valueStr.indexOf('.');
    
    if (decimalIndex !== -1 && valueStr.length - decimalIndex > 3) {
      transportInfo[field] = parseFloat(valueStr.substring(0, decimalIndex + 3));
    }
  }
};
            const fetchExcursions = async () => {
              try {
                const response = await axios.get(API_CONFIG.getEndpoint('fetchExcursions') +'/type/' + excursionType.value);          
                excursions.list = response.data;
              }catch(error) {
                console.log("Error ::" , error);
              }
            };

            const fetchDestinations =  async () => {
              //loading = true;
              try {
                  const resp = await axios.get(API_CONFIG.getEndpoint('fetchDestinations'));                
                  destinations.list = resp.data;
              } catch (error) {
                  console.log('Error fetching destinations list', error);
                  //error = 'Failed to fetch destinations list. Please try again.';
              } finally {
                //loading = false;
              }
            };

            const fetchCitiesByDestination = async () => {        
              try {
                const response = await axios.get(API_CONFIG.getEndpoint('citiesByDestination')  + `/`+ excursionInfo.destinationId );
                cities.list =  response.data;
                if (cities.length === 0) {
                    errorMessage.value = "No cities available for the selected destination.";
                } else {
                    errorMessage.value = "";
                }
              } catch (error) {
                console.log("Error fetching cities list ", error);
                //error = "Failed to fetch cities list. Please try again !!";
              }finally {
                //loading = false;
              }
            };
            
            const fetchPartnersByDestination = async () => {                
                try {
                    const response = await axios.get(API_CONFIG.getEndpoint('searchPartners') + `/`  + excursionInfo.destinationId );
                    partners.list = response.data;
                } catch(error) {
                    console.error('Error fetching partners list', error);
                }
            };

            // const fetchAirportsByDestination = async() => {
            //     try {
            //         const resp = await axios.get(API_CONFIG.getEndpoint('searchAirports') + `/` + excursionInfo.destinationId );
            //         airports.list = resp.data;
            //     }catch (error) {
            //         console.error("Error fetching airports list",error);
            //         //this.error = "Failed to fetch airports list. Please try again";
            //     }
            // }

            const addTransport = async () => {
                saveTransportDetails();
            };

            const formVisible = ref([]);            

            const addMoreTransportDetails = ( index ) => {
              const newTransportDetail = reactive({                
                id : '',
                groupSize  : '',
                partner: '',                
                currency : '',                           
                actualPrice  : '0',
                companyMarkupType : '', 
                companyMarkup  : 0,
                finalPrice  : '0',                    
                transferMode : '',
                departureTime : '',
                departureTimeDisplayIn : [],                
                arrivalTime : '',
                arrivalTimeDisplayIn : [],
                originatingPoint: '',
                originatingPointDisplayIn : [],
                originatingPointLocationLatitude : "",
                originatingPointLocationLongitude : "",
                destinationPoint: '',
                destinationPointDisplayIn :[],
                destinationPointLocationLatitude : '',
                destinationPointLocationLongitude : '',                
                description : '',
                status: 'Active'
              });
              excursionInfo.transportDetails.splice(index + 1, 0, newTransportDetail);
              formVisible.value.splice(index + 1, 0, true); // Show the new form by default
            };
            
            const removeTransportDetail = (index) => { 
              if (excursionInfo.transportDetails.length > 1) {
                excursionInfo.transportDetails.splice(index, 1); 
                formVisible.value.splice(index, 1);
              }else{
                console.log("Remove from the list failed since to keep atleast one from the list.");
              }
            };

            const toggleForm = (index) => { 
              formVisible.value[index] = !formVisible.value[index]; 
            };

            const saveTransportDetails = async () => {
              
              v$.value.$touch(); 
              if (v$.value.$invalid) {
                showListView.value = false;
                return; 
              }

              try {

                excursionInfo.title = excursionInfo.destinationId + '-' + excursionInfo.cityId + '-' + excursionInfo.destinationCityId 
                + '-' +  excursionInfo.transportDetails[0].transferMode + '-' + excursionInfo.transportDetails[0].groupSize;

                  const response = await axios.post(API_CONFIG.getEndpoint('createExcursion') 
                    +`?excursionType=${ excursionType.value }`
                    +`&code=${ excursionInfo.destinationCityId }`
                    +`&destinationId=${ excursionInfo.destinationId }`
                    +`&cityId=${ excursionInfo.cityId }`
                    +`&title=${ excursionInfo.title}`,  excursionInfo );

                  Object.assign(excursionInfo, response.data);          
                  console.log("Excursion details",excursionInfo);
                  fetchExcursions();
                  showListView.value = true;
              }catch(error) {
                if (error.response && error.response.status === 409) { 
                  errorMessage.value = 'Duplicate entry detected. The excursion already exists.'; 
                  alert(errorMessage.value = 'Duplicate entry detected. The excursion already exists.');
                } else { 
                  errorMessage.value = 'An error occurred while saving the excursion details.'; 
                  alert(errorMessage.value = 'An error occurred while saving the excursion details.');
                } 
                console.log("Error while saving excursion details", error);
              }
            };

            const showForm = (action, excursion = null, idx) => {
              //alert("Selected idx=" + idx);
              selectedIndex.value = idx;
              if( action === 'create') {
                //formTitle =  'Add/Update Hotel Details';
                reset();
                fetchDestinations();
                showListView.value = false;
                isEdit.value = false;
              } else if (action === 'edit' && excursion ) {                    
                //load excursion object to excursionInfo obj  
                loadExcursionDetails(excursion);
                console.log("This is called => " + JSON.stringify(excursionInfo) );
                if (!('departureTimeDisplayIn' in excursionInfo.transportDetails[0])) {
                  excursionInfo.transportDetails[0]['departureTimeDisplayIn'] = []
                }
                if (!('arrivalTimeDisplayIn' in excursionInfo.transportDetails[0])) {
                  excursionInfo.transportDetails[0]['arrivalTimeDisplayIn'] = []
                }
                if (!('originatingPointDisplayIn' in excursionInfo.transportDetails[0])) {
                  excursionInfo.transportDetails[0]['originatingPointDisplayIn'] = []
                }
                if (!('destinationPointDisplayIn' in excursionInfo.transportDetails[0])) {
                  excursionInfo.transportDetails[0]['destinationPointDisplayIn'] = []
                }

                fetchDestinations();                    
                onChangeByDestination();
                showListView.value = false;
                isEdit.value = true;                    
              }
            };           

            const cancel = () => {
              reset();
              showListView.value  = true;
            };

            const reset = () => {                             
              Object.assign(excursionInfo, { 
                destinationId : '',
                cityId : '',
                destinationCityId : '',
                status : 'Active',
                transportDetails : [{
                  id : '',
                  groupSize  : '',
                  partner: '',
                  currency : '',
                  actualPrice  : '0',
                  companyMarkupType : '',
                  companyMarkup  : 0,
                  finalPrice  : '0',
                  transferMode : '',
                  departureTime : '',
                  departureTimeDisplayIn : [],
                  arrivalTime : '',
                  arrivalTimeDisplayIn : [],
                  originatingPoint: '',
                  originatingPointDisplayIn : [],
                  originatingPointLocationLatitude : "",
                  originatingPointLocationLongitude : "",
                  destinationPoint: '',
                  destinationPointDisplayIn :[],
                  destinationPointLocationLatitude : '',
                  destinationPointLocationLongitude : '',
                  description : '',
                  status: 'Active'
                }]
              });

              Object.assign(transportInfo, {
                id : '',
                groupSize  : '',
                partner: '',                
                currency : '',                           
                actualPrice  : '0',
                companyMarkupType : '', 
                companyMarkup  : 0,
                finalPrice  : '0',                
                transferMode : '',
                departureTime : '',
                departureTimeDisplayIn : [],
                arrivalTime : '',
                arrivalTimeDisplayIn : [],
                originatingPoint: '',
                originatingPointDisplayIn : [],
                originatingPointLocationLatitude : "",
                originatingPointLocationLongitude : "",
                destinationPoint: '',
                destinationPointDisplayIn :[],
                destinationPointLocationLatitude : '',
                destinationPointLocationLongitude : '',                
                description : '',
                status: 'Active'
              }); 

              formVisible.value = [];

            };

            const onChangeByDestination = () => {
              fetchCitiesByDestination();
              fetchPartnersByDestination();
              //fetchAirportsByDestination();
            }

            const calculateFinalPrice = ( transport ) => {              
              //console.log("calculateFinalPrice length ==> "  + transport.actualPrice  );
              let fPrice = transport.actualPrice;
              //console.log("calculateFinalPrice actualPrice of"  + fPrice );
              if( transport.companyMarkupType === 'percentage' ) {
                fPrice *= (1 + transport.companyMarkup / 100);
              } else if( transport.companyMarkupType === 'absolute' ) {
                fPrice += transport.companyMarkup;
              }
              transport.finalPrice = (Math.round(fPrice * 100) / 100).toFixed(2);
              //console.log("calculateFinalPrice exit.." + transport.finalPrice);
              return transport.finalPrice;
            };

            const confirmDeleteExcursion = (id) => {
      excursionToDelete.value = id;
      showDeleteModal.value = true;
    };

    const deleteExcursion = async () => {
      if (!excursionToDelete.value) return;
      try {
        await axios.delete(`${API_CONFIG.getEndpoint('fetchExcursions')}/${excursionToDelete.value}`);
        excursions.list = excursions.list.filter(excursion => excursion.id !== excursionToDelete.value);
        fetchExcursions();
      } catch (error) {
        console.error('Error deleting excursion:', error);
      } finally {
        showDeleteModal.value = false;
        excursionToDelete.value = null;
      }
    };

    const cancelDelete = () => {
      showDeleteModal.value = false;
      excursionToDelete.value = null;
    };


            const deleteTransport = ( excursion_id, index ) => {
                //alert(excursion_id + ',  ' + excursionInfo.transportDetails.length);
                //excursionInfo.id = excursion_id;
                if (index > -1 && index < excursionInfo.transportDetails.length) {
                    excursionInfo.transportDetails.splice(index, 1); //removed from rooms list
                    //alert("after romoving roomDetails size = " + excursionInfo.roomDetails.length );
                    //alert("before delete index ==> "+ index +" , length = " + excursionInfo.transportDetails.length) ;
                    //updateTransport();
                }
            };

            const loadExcursionDetails = (excursion) => { 
              // Copy top-level properties 
              Object.assign(excursionInfo, excursion); 
              // Deep copy transportDetails array               
              excursionInfo.transportDetails = excursion.transportDetails.map(detail => ({ ...detail }));
              formVisible.value = excursionInfo.transportDetails.map(() => true);
            };

            const updateTransport = async () => {
              v$.value.$touch();
              if (v$.value.$invalid) {
                alert('Form is invalid');
                showListView.value = false;
                return;
              }

              excursionInfo.title = excursionInfo.destinationId + '-' + excursionInfo.cityId + '-' + excursionInfo.destinationCityId 
                + '-' +  excursionInfo.transportDetails[0].transferMode + '-' + excursionInfo.transportDetails[0].groupSize;

              try {                  
                const response = await axios.post(API_CONFIG.getEndpoint('createExcursion') +`/${excursionInfo.id}`
                    +`?excursionType=${ excursionType.value }`
                    +`&code=${ excursionInfo.destinationCityId }`
                    +`&destinationId=${ excursionInfo.destinationId }`
                    +`&cityId=${ excursionInfo.cityId }`
                    +`&title=${ excursionInfo.title}`,  excursionInfo );
                
                Object.assign(excursionInfo, response.data);                 
                showListView.value = true;
                fetchExcursions();
                // this.$router.push({              
                //   path: `/rooms/${this.excursionInfo.id}`
                // });
              } catch(error) {
                console.log("Update hotels failed" + error);
              }
            };

            const getErrorMessage = (field, params) => { 
              switch (field) { 
                case 'actualPrice': 
                  if (params.required) return 'Actual Price is required'; 
                  if (params.minValue) return 'Actual Price must be greater than 0'; 
                  break; 
                case 'companyMarkup': 
                  if (params.minValue) return 'Markup must be greater than 0'; 
                  break; 
                } 
              return ''; 
            };

            onMounted ( () => {        
              fetchExcursions();
            });

            return {
                //...toRefs(transportInfo),
                v$,
                transportInfo,transportDetails,excursionInfo,
                destinations,partners,excursions, //airports,
                fetchDestinations, fetchPartnersByDestination,
                currencies,
                fetchExcursions,deleteTransport,updateTransport,
                showListView,                
                cities,
                showForm,formVisible,addMoreTransportDetails,toggleForm,removeTransportDetail,
                reset,
                errorMessage,
                isEdit,
                cancel,
                onChangeByDestination,
                showInOptionsDT, showInOptionsNOP, showInOptionsAT, showInOptionsDP,
              addTransport,saveTransportDetails,
                items,
                activeIndex,
                toggle,
                addItem,
                removeItem,
                maxValue, filteredExcursions, searchQuery, clearSearch,
                calculateFinalPrice,getErrorMessage, loadExcursionDetails,generateRoomId,deleteExcursion, cancelDelete, confirmDeleteExcursion,showDeleteModal,
                paginatedUsers, goToPage, resetPagination, totalPages, getPageRange, currentPage, itemsPerPage, limitDecimals
            };
        }
    };
</script>

<style scoped> 
  /* @import 'bootstrap/dist/css/bootstrap.css'; */

  .custom-table-width { width: 100%; /* Set desired width (e.g., 100% for full width, 80% for 80% width, etc.) */ }

  .ml-3  {
    margin-left: 0.5rem !important;        
  }
  .mr-3 {        
      margin-right: 1rem !important;        
  }
  .container {
      margin-top: 20px;
  }   
  h3 {
      margin-bottom: 20px;
  }
  
  .form-section {
    margin-bottom: 20px;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-group label {
    display: block;
  }

  label{
    margin: 0px;
  }

  .form-group input,
  .form-group select {
    /* width: 100%; */
    padding: 8px;
    box-sizing: border-box;
  }
  .form-group-inline-flex {
    display: inline-flex;
  }
  .form-actions {
    display: flex;
    justify-content: flex-end;
  }
  .btn {
    padding: 10px 20px;
    margin-left: 10px;
  }
  .text-danger {
    color: red;
    font-size: 0.875em;
  }


.btn1{
  color: #ffffff;
  background-color: #7978E9;
  border-radius:6px;
}
.btn1:hover{
  background-color: #4B49AC;
}
</style>