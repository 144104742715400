const host = 'http://139.59.87.111';

const endpoints = {
  fetchPackageTypes: '/api/package-types',
  fetchPackages: '/api/packages',
  fetchPackagesVersions: '/api/packages/versions',
  updatePackages: '/api/packages',
  createPackages: '/api/packages',
  clonePackages: '/api/packages/clone',
  fetchTemplates: '/api/packages/templates',
  fetchStitchAblePackages: '/api/packages/stitch-able',
  createUser: '/api/user',
  fetchUsers: '/api/users',
  updateUser: '/api/user',
  deleteUser: '/api/user',
  signIn: '/api/auth/signin',
  saveTransfer: 'api/saveTransfer',
  getTransfer: 'api/getTransfer',
  updateTransfer: 'api/updateTransfer',
  fetchPartners : '/api/partners',
  searchPartners : '/api/partners/destination',
  createPartners: '/api/partners',
  fetchDestinations: '/api/destinations',
  createDestination: '/api/destinations',
  fetchCities: '/api/cities',
  createCities: '/api/cities',
  citiesByDestination: '/api/cities/destination',
  destinationTreeMap : '/api/cities/destination-tree-map',
  fetchAirports : '/api/airports',
  createAirports : '/api/airports',
  searchAirports : '/api/airports/destination',
  createExcursion : '/api/excursions',
  fetchExcursions : '/api/excursions',
  uploadImages : '/api/uploadImages',
  uploadVoucher: '/api/uploadFile',
  saveVouchers: '/api/packages',
  fetchInstallmentSchedule: '/api/installment-schedule',
  saveInstallmentSchedule: '/api/installment-schedule/update',
  websitePackages: '/api/packages/website',
  fetchPackageType: '/api/package-extras/package-type', 
  fetchAllActivePackageTypes: '/api/package-extras/package-type/active', 
  createPackageType: '/api/package-extras/package-type',    
  updatePackageType: '/api/package-extras/package-type',  
  fetchPackageTheme: '/api/package-extras/package-theme', 
  fetchAllActivePackageThemes: '/api/package-extras/package-theme/active', 
  createPackageThemes: '/api/package-extras/package-theme',    
  updatePackageTheme: '/api/package-extras/package-theme',    
  createTestimonial: '/api/testimonials',
  fetchTestimonials:'/api/testimonials',
  updateTestimonial: '/api/testimonials',
  fetchCoupons: '/api/coupons',
  createCoupons: '/api/coupons',
  updateCoupons: '/api/coupons',
  fetchWebCards: '/api/web-cards',
  createWebCards: '/api/web-cards',
  updateWebCards: '/api/web-cards',
  deletePackageType: '/api/package-extras/package-type',
  deletePackageTheme: '/api/package-extras/package-theme',
  createLeads: '/api/travel-requests',
  updateLeads: '/api/travel-requests',
  fetchLeads: '/api/travel-requests',
  deleteLeads: '/api/travel-requests',
  createFAQ: '/api/faqs',
  updateFAQ: '/api/faqs',
  fetchFAQ: '/api/faqs',
  deleteFAQ: '/api/faqs',
  fetchWebImage: '/api/web-images',
  createWebImage: '/api/web-images',
  updateWebImage: '/api/web-images',
  deleteWebImage: '/api/web-images',
  deleteTestimonial: '/api/testimonials',
  fetchExchangeRates: '/api/packages/exchange-rates',
  fetchPopularDestination: '/api/cities/popular-destinations',
  updatePopularDestination : '/api/cities/popular-destinations',
  createPopularDestination:  '/api/cities/popular-destinations',
  fetchPopularDestinationById: '/api/cities/popular-destinations',
  fetchBookings: '/api/booking',
  fetchInstallment: '/api/booking',
  updateInstallmentPayment: '/api/booking'
};

export const API_CONFIG = {
  getEndpoint(key) {
    return `${host}${endpoints[key]}`;
  },
};
