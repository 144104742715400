<template>
  <div class="container">
    <div class="card-header" v-if="!isFormVisible">
      <div class="d-flex justify-content-between align-items-center">
        <h4>Manage Web Image</h4>
        <div style="display: flex !important; gap: 20px !important">
          <div v-if="!isFormVisible">
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div> 
  <button class="btn" @click="showForm('create')">
          <font-awesome-icon :icon="['fas', 'plus']" class="me-2" /> New Web
          Image
        </button>
        </div>
      </div>
    </div>

    <div class="button-container d-flex mt-4" v-if="!isFormVisible" style="gap: 20px">
      <button class="btn" @click="filterImages('ALL')">All Types</button>
      <button class="btn" @click="filterImages('HOME')">Home Banner</button>
      <button class="btn" @click="filterImages('STATIC')">Static Banner</button>
      <button class="btn" @click="filterImages('PARTNER')">Partner</button>
      <button class="btn" @click="filterImages('PAGE')">Page Management</button>
    </div>

    <div v-if="loading">Loading...</div>
    <div v-if="error" class="text-danger">{{ error }}</div>

    <div class="table-container" v-if="!isFormVisible">
      <table
        class="table table-bordered table-hover table-responsive"
        style="table-layout: auto"
      >
        <thead>
          <tr>
            <th>SI.No</th>
            <th>Image Name</th>
            <th>Image Type</th>
            <th>Image</th>
            <th>Web Link</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pkg, index) in paginatedUsers" :key="index">
            <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td> 
            <td>{{ pkg.imageText }}</td>
            <td>{{ pkg.imageType }}</td>
            <td>
              <img
                :src="pkg.imageLink || pkg.imageFile"
                alt="Website Image"
                width="100"
              />
            </td>

            <td>{{ pkg.webLink }}</td>
            <td>{{ pkg.status }}</td>
            <td>
              <div style="display: flex">
                <a
                  href="#"
                  class="text-success mr-3 faq-button"
                  role="button"
                  @click="showForm('edit', pkg)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'edit']"
                    class="edit"
                  />
                  <span class="tooltip">Edit</span>
                </a>

                <a
                  href="#"
                  class="text-success mr-3 faq-button"
                  role="button"
                  @click="deleteWebCardDialog(pkg)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'fa-trash']"
                    class="delete"
                  />
                  <span class="tooltip">Delete</span>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="isFormVisible">
      <h5>{{ formTitle }}</h5>
      <hr />
      <form @submit.prevent="submitForm">
        <div class="form-group row mb-3">
          <label for="title" class="col-sm-3 col-form-label"
            >Image Name <span class="text-danger">*</span></label
          >
          <div class="col-sm-6">
            <input
              type="text"
              v-model="form.imageText"
              class="form-control"
              id="imageText"
              required
              placeholder="Enter Name"
            />
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="imageType" class="col-sm-3 col-form-label">
            Image Type <span class="text-danger">*</span></label
          >
          <div class="col-sm-6">
            <select v-model="form.imageType" id="imageType" class="form-select">
              <option value="">Select an option</option>
              <option value="PAGE">Page Management</option>
              <option value="HOME">Home Banner</option>
              <option value="STATIC">Static Banner</option>
              <option value="PARTNER">Partner</option>
            </select>
          </div>
        </div>
        <div class="form-group row mb-3">
          <label class="col-sm-3">
            Image
            <span v-if="!form.imageFile && !form.imageLink" class="text-danger"
              >*</span
            >
          </label>
          <div class="col-sm-6">
            <div v-if="form.imageLink">
              <img
                :src="form.imageLink"
                alt="WebCards Image"
                style="width: 300px; margin-bottom: 10px"
              />
            </div>
            <input
              type="file"
              @change="handleFileUpload('imageFile', $event)"
              class="form-control"
              id="imageFile"
              :required="!form.imageLink"
            />
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="webLink" class="col-sm-3 col-form-label"
            >Web Link <span class="text-danger">*</span></label
          >
          <div class="col-sm-6">
            <input
              type="url"
              v-model="form.webLink"
              class="form-control"
              id="webLink"
              required
              placeholder="Enter Web Link"
            />
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="status" class="col-sm-3 col-form-label">Status</label>
          <div class="col-sm-6 d-flex align-items-center">
            <div class="form-check me-3">
              <input
                type="radio"
                v-model="form.status"
                class="form-check-input"
                id="statusActive"
                value="ACTIVE"
              />
              <label for="statusActive" class="form-check-label">Active</label>
            </div>
            <div class="form-check">
              <input
                type="radio"
                v-model="form.status"
                class="form-check-input"
                id="statusInactive"
                value="INACTIVE"
              />
              <label for="statusInactive" class="form-check-label"
                >Inactive</label
              >
            </div>
          </div>
        </div>

        <hr />
        <div class="d-flex justify-content-end">
          <button type="submit" class="btn btn-success me-2">
            <font-awesome-icon :icon="['fas', 'save']" class="me-2" /> Submit
          </button>
          <button type="button" class="btn btn-light" @click="cancelForm">
            <font-awesome-icon :icon="['fas', 'times']" /> Cancel
          </button>
        </div>
      </form>
    </div>
    <DeleteModal
      :visible="showDeleteModal"
      @confirmDelete="deleteWebImage"
      @cancelDelete="showDeleteModal = false"
    />
    <div class="d-flex justify-content-between mt-3"  v-if="!isFormVisible">
      <div class="d-flex align-items-center mt-0">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredWebImages.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination mb-0" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>    
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_CONFIG } from "@/apiConfig";
import DeleteModal from "./DeleteModal.vue";

export default {
  components: {
    DeleteModal,
  },
  data() {
    return {
      selectedImageType: "ALL",
      webImages: [],
      isFormVisible: false,
      searchQuery: "",
      form: {
        imageText: "",
        imageType: "",
        imageFile: null,
        webLink: "",
        imageLink: "",
        status: "ACTIVE",
      },
      loading: false,
      error: null,
      showDeleteModal: false,
      webImageToDelete: null,
      currentPage: 1, 
      itemsPerPage: 10, 
    };
  },
  computed: {
    filteredWebImages() {
      let filteredImages = this.selectedImageType === "ALL"
        ? this.webImages
        : this.webImages.filter((pkg) => pkg.imageType === this.selectedImageType);

      if (this.searchQuery.trim()) {
        filteredImages = filteredImages.filter(pkg =>
          pkg.imageText.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          pkg.webLink.toLowerCase().includes(this.searchQuery.toLowerCase()) 
        );
      }

      return filteredImages;
    },
    paginatedUsers() {
    const pageSize = Number(this.itemsPerPage) || 10;
    const currentPage = Number(this.currentPage) || 1;
    
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    
    if (!this.filteredWebImages.length) return [];
    
    return this.filteredWebImages.slice(startIndex, endIndex);
  },
  
  totalPages() {
    const pageSize = Number(this.itemsPerPage) || 10;
    return Math.max(1, Math.ceil(this.filteredWebImages.length / pageSize));
  },
  },
  watch: {
  itemsPerPage(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.resetPagination();
    }
  },
  searchQuery() {
    this.resetPagination();
  },
  filteredWebImages: {
    handler() {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages || 1;
      }
    },
    immediate: true
  }
},
  methods: {
    clearSearch() {
      this.searchQuery = "";
    },
    
    handleFileUpload(field, event) {
      const file = event.target.files[0];
      if (!file) {
         return;
         }
      const validTypes = ["image/jpeg", "image/png"];
      const maxSize = 2 * 1024 * 1024;

      if (!validTypes.includes(file.type)) {
        alert("Only JPEG and PNG files are allowed.");
        event.target.value = "";
        return;
      }

      if (file.size > maxSize) {
        alert("File size should not exceed 2MB.");
        event.target.value = "";
        return;
      }

      this.form[field] = file;
      this.form.imageLink = URL.createObjectURL(file);
    },

    showForm(action, pkg = null) {
      this.formTitle =
        action === "create" ? "Create Web Image" : "Edit Web Image";
      if (action === "create") {
        this.resetForm();
      } else if (action === "edit" && pkg) {
        this.form = { ...pkg, imageFile: null, imageLink: pkg.imageLink || "" };
      }
      this.isFormVisible = true;
    },

    cancelForm() {
      this.isFormVisible = false;
      this.resetForm();
    },

    async submitForm() {
      if (this.form.id) {
        await this.updateWebImage();
      } else {
        await this.createWebImage();
      }
    },
    resetForm() {
      this.form = {
        id: null,
        imageText: "",
        imageType: "",
        imageFile: null,
        webLink: "",
        status: "ACTIVE",
      };
    },

    async fetchWebImage() {
      this.loading = true;
      try {
        const response = await axios.get(
          API_CONFIG.getEndpoint("fetchWebImage")
        );
        this.webImages = response.data;
      } catch (error) {
        console.log("Error fetching webimages", error);
      } finally {
        this.loading = false;
      }
    },
    resetPagination() {
    this.currentPage = 1;
    
    this.$forceUpdate();
  },
  
  goToPage(page) {
    const pageNum = Number(page);
    if (pageNum >= 1 && pageNum <= this.totalPages) {
      this.currentPage = pageNum;
    }
  },

getPageRange() {
    const range = [];
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    const delta = 2;

    if (currentPage > delta + 1) {
      range.push(1);
      if (currentPage > delta + 2) range.push('...');
    }

    for (let i = Math.max(1, currentPage - delta); i <= Math.min(totalPages, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage < totalPages - delta - 1) {
      if (currentPage < totalPages - delta - 2) range.push('...');
      range.push(totalPages);
    }

    return range;
  },
    async createWebImage() {
      this.loading = true;
      try {
        const formData = new FormData();
        const webImage = {
          id: this.form.id,
          imageText: this.form.imageText,
          imageType: this.form.imageType,
          webLink: this.form.webLink,
          status: this.form.status,
          imageFile: this.form.imageFile,
          imageLink: this.form.imageLink,
        };

        formData.append(
          "webImage",
          new Blob([JSON.stringify(webImage)], {
            type: "application/json",
          })
        );
        formData.append("imageFile", this.form.imageFile);

        let response = await axios.post(
          API_CONFIG.getEndpoint("createWebImage"),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Webimages created successfully:", response.data);

        this.fetchWebImage();
        this.isFormVisible = false;
        this.resetForm();
      } catch (error) {
        console.error("Error creating webimages:", error);
      } finally {
        this.loading = false;
      }
    },

    async updateWebImage() {
      this.loading = true;
      try {
        const formData = new FormData();
        const webImage = {
          id: this.form.id,
          imageText: this.form.imageText,
          imageType: this.form.imageType,
          webLink: this.form.webLink,
          status: this.form.status,
          imageFile: this.form.imageFile,
          imageLink: this.form.imageLink,
        };

        formData.append(
          "webImage",
          new Blob([JSON.stringify(webImage)], {
            type: "application/json",
          })
        );

        if (this.form.imageFile) {
          formData.append("imageFile", this.form.imageFile);
        }

        let response = await axios.put(
          `${API_CONFIG.getEndpoint("updateWebImage")}/${this.form.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("webimages updated successfully:", response.data);

        this.fetchWebImage();
        this.isFormVisible = false;
        this.resetForm();
      } catch (error) {
        console.error("Error updating webimages:", error);
      } finally {
        this.loading = false;
      }
    },

    async deleteWebImage() {
      if (this.webImageToDelete) {
        this.loading = true;
        try {
          await axios.delete(
            `${API_CONFIG.getEndpoint("deleteWebImage")}/${
              this.webImageToDelete.id
            }`
          );
          console.log("Web Image deleted successfully");

          this.fetchWebImage();
        } catch (error) {
          console.error("Error deleting webimage:", error);
        } finally {
          this.loading = false;
        }

        this.showDeleteModal = false;
        this.webImageToDelete = null;
      }
    },

    deleteWebCardDialog(webImage) {
      this.showDeleteModal = true;
      this.webImageToDelete = webImage;
    },

    filterImages(type) {
      this.selectedImageType = type;
    },
  },

  mounted() {
    this.fetchWebImage();
  },
};
</script>

<style scoped>
.table-container {
  max-width: 100%;
  overflow-x: auto;
}
.container {
  margin-top: 20px;
}
th {
  font-weight: bold;
}
.custom-dropdown {
  border: 2px solid #d1d1d1;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: white;
  cursor: pointer;
}
.custom-dropdown:focus {
  border-color: #4b49ac;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(5 0 249 / 38%);
}
</style>
