<template>
  <div class="container my-5">
    <h4> Clone Package </h4>
    <hr>

    <div>

      <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>

      <div class="form-group row mb-3">
        <label for="itineraryType" class="col-sm-3 col-form-label">Itinerary Type <span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <select class="form-control" id="itineraryType" v-model="packageInfo.itineraryType">
            <option disabled value="">Select Itinerary Type</option>
            <option value="Quotation">Quotation</option>
            <option value="Final">Final</option>
          </select>
          <span class="text-danger" v-if="v$.packageInfo.itineraryType.$error">Itinerary Type must be selected </span>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="customerName" class="col-sm-3 col-form-label">Customer Name <span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <input type="text" class="form-control" id="customerName" v-model="packageInfo.customerName" placeholder="Customer Name">
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="email" class="col-sm-3 col-form-label">Email <span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <input type="email" class="form-control" id="email" v-model="packageInfo.email" placeholder="Email">
          <span class="text-danger" v-if="v$.packageInfo.email.$error">Email is required </span>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="mobileNo" class="col-sm-3 col-form-label">Mobile Number <span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <!-- <input type="tel" class="form-control" id="mobileNo" v-model="packageInfo.mobileNo" placeholder="Mobile No"> -->
          <input type="tel" v-model="v$.packageInfo.mobileNo.$model" class="form-control" id="mobileNo" placeholder="Mobile No" required>
          <div class="text-danger" v-for="(error, index) in v$.packageInfo.mobileNo.$errors" :key="index">
            <div> {{ error.$message }}</div>
          </div>

        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="altContactNo" class="col-sm-3 col-form-label">Alternate Contact Number</label>
        <div class="col-sm-6">
          <input type="tel" class="form-control" id="altContactNo" v-model="packageInfo.altContactNo" placeholder="Alt Contact No">
          <!-- <span class="text-danger" v-if="v$.packageInfo.altContactNo.$error">Alternate contact is required </span> -->
          <div class="text-danger" v-for="(error, index) in v$.packageInfo.altContactNo.$errors" :key="index">
            <div> {{ error.$message }}</div>
          </div>

        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="noOfAdults" class="col-sm-3 col-form-label">No. of Adults <span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <input type="number" class="form-control" id="noOfAdults" v-model="packageInfo.noOfAdults" placeholder="No. of Adults">
          <span class="text-danger" v-if="v$.packageInfo.noOfAdults.$error">This value is required </span>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="noOfChildren" class="col-sm-3 col-form-label">No. of Children</label>
        <div class="col-sm-6">
          <input type="number" class="form-control" id="noOfChildren" v-model="packageInfo.noOfChildren" placeholder="No. of Children">
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="packageTitle" class="col-sm-3 col-form-label">Package Title <span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <input type="text" class="form-control" id="packageTitle" v-model="packageInfo.packageTitle" placeholder="Package Title">
          <span class="text-danger" v-if="v$.packageInfo.packageTitle.$error">This value is required </span>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="description" class="col-sm-3 col-form-label">Description</label>
        <div class="col-sm-6">
          <textarea class="form-control" id="description" v-model="packageInfo.description" placeholder="Description"></textarea>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="packageStartDate" class="col-sm-3 col-form-label">Package Start Date <span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <input type="date" class="form-control" id="packageStartDate" v-model="packageInfo.packageStartDate" placeholder="Package Start Date" :min="minTravelDate">
          <span class="text-danger" v-if="v$.packageInfo.packageStartDate.$error">This value is required </span>
        </div>
      </div>

      <div class="form-group row mb-3  d-flex align-items-center">
        <label for="priceOnWebsite" class="col-sm-3 col-form-label">Price on Website</label>
        <div class="col-sm-2 d-flex text-nowrap d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.priceOnWebsite" class="form-check-input m-0 me-2" id="statusActive" value="price_per_person">
          <label for="statusActive" class="form-check-label m-0">Price Per Person</label>
        </div>
        <div class="col-sm-2 d-flex text-nowrap d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.priceOnWebsite" class="form-check-input m-0 me-2" id="statusActive" value="total_price">
          <label for="statusActive" class="form-check-label m-0">Total Price</label>
        </div>
      </div>
      <div class="form-group row mb-3">
        <label for="noOfDays" class="col-sm-3 col-form-label">No. of Days <span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <input type="number" step="0.1" min="0" max="365" class="form-control" id="noOfDays" v-model="packageInfo.noOfDays" placeholder="No. of Days">
          <span class="text-danger" v-if="v$.packageInfo.noOfDays.$error">This value is required </span>
        </div>
      </div>
      <div class="form-group row mb-3">
        <label for="noOfNights" class="col-sm-3 col-form-label">No. of Nights</label>
        <div class="col-sm-6">
          <input type="number" class="form-control" id="noOfNights" v-model="packageInfo.noOfNights" placeholder="No. of Nights" min="0">
        </div>
      </div>
      <div class="form-group row mb-3 d-flex align-items-center">
        <label for="status" class="col-sm-3 col-form-label">Status</label>
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.status" class="form-check-input m-0 me-2" id="statusActive" value="Active">
          <label for="statusActive" class="form-check-label m-0">Active</label>
        </div>
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.status" class="form-check-input m-0 me-2" id="statusInactive" value="Inactive">
          <label for="statusInactive" class="form-check-label m-0">Inactive</label>
        </div>
      </div>
      <hr>
      <div class="form-actions">
        <button type="submit" class="btn btn-success" @click="savePackages" v-if="!isEdit">
          <font-awesome-icon :icon="['fas', 'fa-save']" class="me-2" /> Save </button>
        <button type="button" class="ml-3 btn btn-light" @click="cancel"> <font-awesome-icon :icon="['fas', 'xmark']" /> Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import { API_CONFIG } from '@/apiConfig';
import axios from 'axios';
import { required, email, numeric, minLength, maxLength } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core'
import {useRoute} from "vue-router";
import {ref} from "vue";

export default {
  data() {
    return {
      packageInfo: {
        itineraryType: '',
        customerName: '',
        email: '',
        mobileNo: '',
        altContactNo: '',
        noOfAdults: 1,
        noOfChildren: 0,
        packageTitle: '',
        description: '',
        destinationId: '',
        packageStartDate: '',
        priceOnWebsite: 0,
        //packageTypeId: '',
        packageType: '',
        noOfDays: 1,
        noOfNights: 0,
        status: 'Active'
      },
      errorMessage: '',
      isEdit : false
    };
  },
  validations() {
    return {
      packageInfo: {
        itineraryType : { required },
        email : { required, email },
        mobileNo :  { required , minLength: minLength(10), maxLength:maxLength(15) },
        altContactNo : { minLength: minLength(10), maxLength:maxLength(15) },
        noOfAdults : { required, numeric},
        packageTitle :{ required },
        packageStartDate : { required},
        noOfDays : { required, numeric }
      }
    }
  },

  setup() {
    const v$ = useVuelidate();
    const packageCode = ref(null);
    const route = useRoute(); // Access the current route object
    // Extract the packageCode from the last part of the URL
    packageCode.value = route.path.split('/').pop();
    console.log(packageCode.value);
    return { v$, packageCode };
  },

  computed: {
    noOfNights() {
      return this.packageInfo.noOfDays > 0 ? this.packageInfo.noOfDays - 1 : 0;
    },
    minTravelDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  },
  },
  watch: {
    'packageInfo.noOfDays'(newVal) {
      this.packageInfo.noOfNights = newVal > 0 ? newVal - 1 : 0;
    }
  },

  methods: {
    fetchPackageDetails() {
      axios.get(`${API_CONFIG.getEndpoint('fetchPackages')}/${this.packageCode}`)
          .then(response => {
            this.packageInfo = response.data;
            this.packageInfo.customerName = "";
            this.packageInfo.email = "";
            this.packageInfo.mobileNo = "";
            this.packageInfo.altContactNo = "";
            console.log(this.packageInfo)
          })
          .catch(error => {
            console.error('Error fetching package details..' + error);
          });
    },

    async updatePackages() {
      try {
        const response = await axios.put(  API_CONFIG.getEndpoint('updatePackages') +`/` + this.packageInfo.packageCode, this.packageInfo );
        console.log('Success:', response.data);
        if (this.packageInfo.packageCode.includes('-V')) {
          this.$router.push(
              '/packages/versions/' +
              this.packageInfo.packageCode.split('-V')[0]
          );
        } else {
          this.$router.push('/viewpackages');
        }
      }catch(error) {
        console.log('Error updating packages ', error);
        this.error = 'Failed to updating packages. Please try again.';
      }
    },

    savePackages() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      const endpoint = `${API_CONFIG.getEndpoint('clonePackages')}/${this.packageCode}`;

      axios.post(endpoint, this.packageInfo)
          .then(response => {
            console.log('Success:', response.data);
            this.$router.push('/viewpackages')
          })
          .catch(error => {
            console.error('Error:', error);
            this.errorMessage="Failed, please try again.";
          });

    },

    cancel(){
      this.$router.push('/viewpackages');
    },
  },

  mounted() {
    console.log("Mounted called.")
    this.fetchPackageDetails()
  }

};
</script>

<style scoped>
.ml_0_5_rem  {
  margin-left: 0.5rem !important;
}
.ml-3  {
  margin-left: 1rem !important;
}
.ml-6  {
  margin-left: 2rem !important;
}
.ml-9  {
  margin-left: 3rem !important;
}
.mr-3 {
  margin-right: 1rem !important;
}
.mr-6 {
  margin-right: 2rem !important;
}
.form-group {
  margin-bottom: 15px;
}
.text-danger {
  color: red;
  font-size: 0.875em;
}
</style>