<template>
    <div class="container">
    <div class="card-header">
    <div class="d-flex justify-content-between align-items-center">
        <h4>Packages List</h4>
        <div style="display: flex !important; gap: 20px !important">
          <div>
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div>  
  <button class="btn " @click="createNewPackage">
    <font-awesome-icon :icon="['fas', 'fa-plus']" class="me-2" /> New Package</button>
        </div>
          </div>
          </div>

        <div v-if="loading">Loading...</div>
        <div v-if="error">{{ error }}</div>
        <div class="table-container mt-2">
          <table v-if="packages.length" class="table table-bordered table-hover table-responsive" style="table-layout: auto;">
            <thead>
                <tr>
                  <th>SI.No</th>
                    <th>Itinerary Type</th>
                    <th>Customer Name</th>
                    <th>Email</th>
                    <th>Mobile No</th>
                    <th>Alt Contact No</th>
                    <th>No of Adults</th>
                    <th>No of Children</th>
                    <th>Title</th>                    
                    <th>Destination</th>
                    <th>Start Date</th>
                    <th>Price</th>
                    <th>Package Type</th>
                    <th>Status</th>
                    <th>No of Days</th>
                    <th>No of Nights</th>
                    <th>Package Code</th>
                    <th>Price</th>
                    <th>Edit Installment</th>
                    <th>Versions</th>
                    <th>Action Menu</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for=" (pkg,index) in paginatedUsers" :key="index">
                  <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>
                  <td>{{ pkg.itineraryType }}</td>
                    <td class="text-nowrap">{{ pkg.customerName }}</td>
                    <td class="text-nowrap">{{ pkg.email }}</td>
                    <td>{{ pkg.mobileNo }}</td>
                    <td>{{ pkg.altContactNo }}</td>
                    <td>{{ pkg.noOfAdults }}</td>
                    <td>{{ pkg.noOfChildren }}</td>
                    <td class="text-nowrap">{{ pkg.packageTitle }}</td>                    
                    <td>{{ pkg.selectDestination }}</td>
                    <td class="text-nowrap">{{ pkg.packageStartDate }}</td>
                    <td>{{ pkg.priceOnWebsite }}</td>
                    <td>{{ pkg.packageType }}</td>
                    <td>{{ pkg.status }}</td>
                    <td>{{ pkg.noOfDays }}</td>
                    <td>{{ pkg.noOfNights }}</td>                    
                    <td>{{ pkg.packageCode }}</td>
                    <td>{{ pkg.priceSummary.netSellingPrice}}</td>
                    <td>
                      <div class="col-md-4">
                        <a href="#" class="text-success mr-3 faq-button" role="button"
                           @click="openEditInstallmentModal(pkg)">
                          <font-awesome-icon :icon="['fas', 'fa-edit']" class="edit"/>
                          <span class="tooltip">Edit Installment</span></a>
                      </div>
                    </td>
                    <td>
                      <div class="col-md-4">
                        <a href="#" class="text-success mr-3 faq-button" role="button"
                           @click="() => router().push(`/packages/versions/${pkg.packageCode}`)">
                          <font-awesome-icon :icon="['fas', 'fa-edit']" class="edit" /><span class="tooltip">Edit Versions</span></a>
                      </div>
                    </td>
                    <td>
                        <div class="row">
                          <div class="col-md-4">
                            <a href="#" class="text-danger mr-3 faq-button" role="button" @click="addItinerary(pkg.packageCode)" >
                              <font-awesome-icon :icon="['fas', 'fa-plus']" class="plus" /><span class="tooltip">Add Itinerary</span></a>
                          </div>
                          <div class="col-md-4">
                            <a href="#" class="text-success mr-3 faq-button" role="button" @click="editPackage(pkg.packageCode)">
                               <font-awesome-icon :icon="['fas', 'fa-edit']" class="edit" /><span class="tooltip">Edit Package</span></a>
                          </div>
                          <div class="col-md-4">
                            <a href="#" class="text-danger mr-3 faq-button" role="button" @click="confirmDeletePackage(pkg.packageCode)">
                              <font-awesome-icon :icon="['fas', 'fa-trash']" class="delete" /><span class="tooltip">Delete</span></a>
                          </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>

        <!-- Edit Installment Modal -->
        <div v-if="showEditModal" class="modal fade show d-block" tabindex="-1">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Edit Installment</h5>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeEditInstallmentModal"
                    aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <form @submit.prevent="submitInstallment">
                  <!-- Total Price Input -->
                  <div class="mb-3">
                    <label for="totalPrice" class="form-label">Total Price</label>
                    <input
                        type="text"
                        v-model="totalPrice"
                        id="totalPrice"
                        class="form-control"
                        readonly
                    />
                  </div>
                  <!-- Installments Selector -->
                  <div class="mb-3">
                    <label for="numInstallments" class="form-label"
                    >Number of Installments</label
                    >
                    <select
                        v-model="numInstallments"
                        id="numInstallments"
                        class="form-select"
                    >
                      <option v-for="n in 7" :key="n" :value="n + 1">{{ n + 1 }}</option>
                    </select>
                  </div>
                  <!-- Installment Table -->
                  <div class="table-responsive mt-3">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th>#</th>
                        <th>Installment Percentage (%)</th>
                        <th>Installment Amount</th>
                        <th>Days</th>
                        <th>Reference</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(installment, index) in installmentList" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>
                          <input
                              type="number"
                              v-model="installment.percentage"
                              class="form-control"
                              min="0"
                              @input="updateInstallmentAmount(index)"
                          />
                        </td>
                        <td>{{ installment.installmentAmount.toFixed(2) }}</td>
                        <td>
                          <input type="number"
                                 v-model="installment.days"
                                 class="form-control"/>
                        </td>
                        <td>
                          <div>
                            <select
                                id="referenceDateInput"
                                v-model="installment.referenceDate"
                                class="form-control">
                              <option value="booking">Booking Date</option>
                              <option value="travel">Travel Date</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td><strong>Total</strong></td>
                          <td><strong>{{ totalRow.percentage }}</strong></td>
                          <td><strong>{{ totalRow.installmentAmount }}</strong></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn" @click="closeEditInstallmentModal">
                      <font-awesome-icon :icon="['fas', 'xmark']" class="me-2" />  Cancel
                    </button>
                    <button type="submit" class="btn">
                      <font-awesome-icon :icon="['fas', 'floppy-disk']" class="me-2" />  Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- Modal Backdrop -->
          <div class="modal-backdrop fade show"></div>
        </div>
  <DeleteModal
  :visible="showDeleteModal"
  @confirmDelete="deletePackage"
  @cancelDelete="showDeleteModal = false"
  />  
  <div class="d-flex justify-content-between mt-3">
      <div class="d-flex align-items-center mt-0">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredPackages.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination mb-0" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
     
    </div>

    </div>
</template>
<script>
import {computed, onMounted, ref, watch} from 'vue';
import {useRouter} from 'vue-router';
import axios from 'axios';
import {API_CONFIG} from '@/apiConfig';
import router from "@/router";
import DeleteModal from './DeleteModal.vue';

export default {
        name: 'PackageList',
  methods: {
    router() {
      return router
    }
  },
  components: {
        DeleteModal,
        },
        setup() {
          const packages= ref([]);
          const loading = ref(true);
          const error = ref(null);
          const showDeleteModal = ref(false);
          const imageToDelete = ref(null);
          const router = useRouter();
          const showEditModal = ref(false); // Modal visibility
          const totalPrice = ref(0); // Total price for the modal
          const numInstallments = ref(2); // Default installments value
          const installmentList = ref([]); // List of installments
          const packageCode = ref(null)
          const searchQuery = ref('');
          const currentPage = ref(1);
          const itemsPerPage = ref(10);

          const filteredPackages = computed(() => {
            return packages.value.filter(pkg => {
              return (
                  (pkg.itineraryType?.toLowerCase().includes(searchQuery.value.toLowerCase()) ?? false) ||
                  (pkg.customerName?.toLowerCase().includes(searchQuery.value.toLowerCase()) ?? false) ||
                  (pkg.email?.toLowerCase().includes(searchQuery.value.toLowerCase()) ?? false) ||
                  (pkg.mobileNo?.toLowerCase().includes(searchQuery.value.toLowerCase()) ?? false) ||
                  (pkg.packageTitle?.toLowerCase().includes(searchQuery.value.toLowerCase()) ?? false) ||
                  (pkg.selectDestination?.toLowerCase().includes(searchQuery.value.toLowerCase()) ?? false) ||
                  (pkg.packageCode?.toLowerCase().includes(searchQuery.value.toLowerCase()) ?? false)
              );
            });
          });

const clearSearch = () => {
  searchQuery.value = '';
};

const getPageRange = () => {
  const range = [];
  const total = totalPages.value;
  const current = currentPage.value;
  const delta = 2;

  if (current > delta + 1) {
    range.push(1);
    if (current > delta + 2) range.push('...');
  }

  for (let i = Math.max(1, current - delta); i <= Math.min(total, current + delta); i++) {
    range.push(i);
  }

  if (current < total - delta - 1) {
    if (current < total - delta - 2) range.push('...');
    range.push(total);
  }

  return range;
};

const resetPagination = () => {
  currentPage.value = 1;
};

const goToPage = (page) => {
  const pageNum = Number(page);
  if (pageNum >= 1 && pageNum <= totalPages.value) {
    currentPage.value = pageNum;
  }
};

const paginatedUsers = computed(() => {
  const pageSize = Number(itemsPerPage.value) || 10;
  const current = Number(currentPage.value) || 1;
  
  const startIndex = (current - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  
  if (!filteredPackages.value.length) return [];
  
  return filteredPackages.value.slice(startIndex, endIndex);
});

const totalPages = computed(() => {
  const pageSize = Number(itemsPerPage.value) || 10;
  return Math.max(1, Math.ceil(filteredPackages.value.length / pageSize));
});

watch(filteredPackages, () => {
  if (currentPage.value > totalPages.value) {
    currentPage.value = totalPages.value || 1;
  }
});

watch(itemsPerPage, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    resetPagination();
  }
});
          const fetchPackages = async () => {
                try {
                    const response = await axios.get(API_CONFIG.getEndpoint('fetchPackages'));
                    packages.value = response.data;
                }catch(err) {
                    error.value = err;
                }finally {
                    loading.value = false;

                }
          };

    const confirmDeletePackage = (packageCode) => {
    imageToDelete.value = packageCode; 
    showDeleteModal.value = true; 
};


const deletePackage = async () => {
    if (!imageToDelete.value) return; 

    try {
        await axios.delete(API_CONFIG.getEndpoint('fetchPackages') + `/${imageToDelete.value}`);
        fetchPackages(); // Refresh the package list after deletion
        showDeleteModal.value = false; 
        imageToDelete.value = null; 
    } catch (err) {
        error.value = err;
        alert("Failed to delete package. Please try again.");
    }
};


          const addItinerary = (packageCode) => {
              router.push({ name: 'itinerary', params: { id: packageCode } });
          };

          const editPackage = (packageCode) => {
              router.push({ name: 'packages', params: { id: packageCode } });
          }

          const createNewPackage = () => {
              router.push({ name: 'packages' });
          };

          const openEditInstallmentModal = (pkg) => {
              // Pre-fill modal defaults
              totalPrice.value = pkg.priceSummary.netSellingPrice;       // Pre-fill Total Price
              numInstallments.value = 2;      // Reset to default (2 installments)
              showEditModal.value = true;     // Show the edit modal
              packageCode.value = pkg.packageCode;

              // Construct the API endpoint
              const url = API_CONFIG.getEndpoint("fetchInstallmentSchedule") + `/${packageCode.value}`;

              // Fetch installment schedule
              axios.get(url)
                  .then((emiResponse) => {
                    // Handle the successful response
                    numInstallments.value = emiResponse.data['numInstallments'];
                    installmentList.value = emiResponse.data['installmentList'];

                    // Update the UI
                    updateInstallmentTable(false);
                  })
                  .catch((error) => {
                    console.error("Failed to fetch installment schedule:", error);
                    // Optional: Display an error message to the user
                    updateInstallmentTable(true);
                  });
            };


          const closeEditInstallmentModal = () => {
            showEditModal.value = false; // Hide the modal
            resetFields();
          };

          const resetFields = () => {
            totalPrice.value = 0.0; // Reset the total price
            numInstallments.value = 2; // Default value for installments
          };

          const submitInstallment = () => {
            // Remove installmentAmount from each entry in the installmentList
            const cleanedInstallmentList = installmentList.value.map((installment) => {
              // eslint-disable-next-line no-unused-vars
              const { installmentAmount, ...rest } = installment;
              return rest;
            });

            // Collect the clean data to be submitted
            const payload = {
              packageCode: packageCode.value, // Include the package code
              numInstallments: numInstallments.value,
              installmentList: cleanedInstallmentList, // Use the cleaned list
            };

            // Construct the API endpoint
            const url = API_CONFIG.getEndpoint("saveInstallmentSchedule");

            // POST the data to the backend
            axios.post(url, payload)
                .then((response) => {
                  console.log(response);
                  if (response.data) {
                    console.log("Installment data saved successfully!");

                    // Close the modal on a successful save
                    showEditModal.value = false;

                    // Provide success feedback to the user
                    alert("Installment data saved successfully!");
                  } else {
                    console.warn("Failed to save installment data:", response.data.message);

                    // Display an error message to the user
                    alert(response.data.message || "Unknown error occurred.");
                  }
                })
                .catch((error) => {
                  console.error("An error occurred while saving installment data:", error);

                  // Notify user of the failure
                  alert("Failed to save installment data. Please try again.");
                });
          };

          const updateInstallmentTable = (redistribute) => {
            const currentLength = installmentList.value.length;

            if (numInstallments.value > currentLength) {
              // If the new number of installments is greater, add new rows
              for (let i = currentLength; i < numInstallments.value; i++) {
                installmentList.value.push({
                  percentage: 0, // Default percentage for new rows
                  installmentAmount: 0,
                  days: 0,
                  referenceDate: 'booking',
                });
              }
            } else if (numInstallments.value < currentLength) {
              // If the new number of installments is less, remove extra rows
              installmentList.value.splice(numInstallments.value);
            }

            if (redistribute === true) {
              // Recalculate percentage distribution
              const totalPercentage = 100;
              const evenPercentage = (totalPercentage / numInstallments.value).toFixed(2);
              installmentList.value.forEach((installment) => {
                installment.percentage = parseFloat(evenPercentage);
                installment.installmentAmount = (totalPrice.value * installment.percentage) / 100;
              });
            } else {
              installmentList.value.forEach((installment) => {
                installment.installmentAmount = (totalPrice.value * installment.percentage) / 100;
              });
            }
          };

          //this fetchPackages will be loaded onload of component.
          onMounted(fetchPackages);

          // Watch for changes to numInstallments
          watch(numInstallments, () => {
            console.log("Num installments: " + numInstallments.value);
            updateInstallmentTable();
          });

          // Reactive totalRow
          const totalRow = computed(() => {
            const totalPercentage = installmentList.value.reduce((sum, installment) => sum + installment.percentage, 0);
            const totalAmount = installmentList.value.reduce((sum, installment) => sum + installment.installmentAmount, 0);
            if (typeof totalPercentage === "number" &&
                !isNaN(totalPercentage)) {
              return {
                percentage: totalPercentage.toFixed(2),
                installmentAmount: totalAmount.toFixed(2),
              };
            } else {
              return {
                percentage: 0.0,
                installmentAmount: 0.0,
              };
            }
          });

          const updateInstallmentAmount = (index) => {
            if (installmentList.value[index]) {
              const installment = installmentList.value[index];
              if (installment.percentage === '') {
                installment.percentage = 0.0;
              }

              installment.installmentAmount = (totalPrice.value * installment.percentage) / 100;
            }
          }

          return {
              showEditModal,
              totalPrice,
              numInstallments,
              installmentList,
              packages,
              packageCode,
              loading,
              error,
              editPackage,
              deletePackage,
              createNewPackage,
              addItinerary,
              openEditInstallmentModal,
              closeEditInstallmentModal,
              resetFields,
              submitInstallment,
              updateInstallmentTable,
              updateInstallmentAmount,
              totalRow,
              showDeleteModal,
              imageToDelete,
              confirmDeletePackage,
              filteredPackages,
              clearSearch,
              searchQuery,
              paginatedUsers,
              resetPagination,
              goToPage,
              itemsPerPage,
              totalPages,
              currentPage,
              getPageRange,
          };
        }
    };
</script>

<style scoped>
    .container {
    margin-top: 20px;
    }

.btn{
  color: #ffffff;
  background-color: #7978E9;
}
.btn:hover{
  background-color: #4B49AC;
  color: #ffffff;
}
    .modal {
      width: 100%;
    }

    .table-container {
  max-width: 100%; 
  overflow-x: auto; 
  overflow-y: auto; 
  max-height: 75vh; 
  margin-bottom: 20px;
    }
    .modal-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1040;
    }



    /* Modal Body */
    .modal-container {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1050;
    }

    .modal-dialog {
      background: white;
      padding: 15px;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
      max-height: 80vh; 
      overflow-y: auto; 
    }

    /* Prevent clicks on the modal from propagating to the backdrop */
    .modal-content {
      position: relative;
      z-index: 1060;
    }
</style>