<template>
  <div class="container">
   <div class="card-header">
    <div class="d-flex justify-content-between align-items-center">
    <h4>Versions List</h4>
    <div style="display: flex !important; gap: 20px !important">
      <div>
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div>  
  <button class="btn" @click="createNewVersion" :disabled="!isVersionCreationAllowed">
    <font-awesome-icon :icon="['fas', 'fa-plus']" class="me-2" />New Version</button>
    </div>
      </div>
      </div>
      <div class="d-flex justify-content-end mt-4 mt-1">
      <button class="btn btn-secondary" @click="back_to_packages">
        <font-awesome-icon :icon="['fas', 'arrow-left']" class="me-2" /> Back to Packages
      </button>
    </div>

    <div v-if="loading">Loading...</div>
    <div v-if="error">{{ error }}</div>
    <div class="table-container">
      <table v-if="packages.length" class="table table-bordered table-hover table-responsive" style="table-layout: auto;">
        <thead class="table-primary">
        <tr>
          <th>SI.No</th>
          <th>Itinerary Type</th>
          <th>Customer Name</th>
          <th>Email</th>
          <th>Mobile No</th>
          <th>Alt Contact No</th>
          <th>No of Adults</th>
          <th>No of Children</th>
          <th>Title</th>
          <th>Destination</th>
          <th>Start Date</th>
          <th>Price</th>
          <th>Package Type</th>
          <th>Status</th>
          <th>No of Days</th>
          <th>No of Nights</th>
          <th>Package Code</th>
          <th>Price</th>
          <th>Action Menu</th>
          <th>Clone</th>
          <th>Vouchers</th>
          <th>Share with Website Package</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for=" (pkg,index) in paginatedUsers" :key="index">
          <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>
          <td>{{ pkg.itineraryType }}</td>
          <td class="text-nowrap">{{ pkg.customerName }}</td>
          <td>{{ pkg.email }}</td>
          <td>{{ pkg.mobileNo }}</td>
          <td>{{ pkg.altContactNo }}</td>
          <td>{{ pkg.noOfAdults }}</td>
          <td>{{ pkg.noOfChildren }}</td>
          <td class="text-nowrap">{{ pkg.packageTitle }}</td>
          <td>{{ pkg.selectDestination }}</td>
          <td class="text-nowrap">{{ pkg.packageStartDate }}</td>
          <td>{{ pkg.priceOnWebsite }}</td>
          <td>{{ pkg.packageTypeName }}</td>
          <td>{{ pkg.status }}</td>
          <td>{{ pkg.noOfDays }}</td>
          <td>{{ pkg.noOfNights }}</td>
          <td>{{ pkg.packageCode }}</td>
          <td>{{ pkg.priceSummary.netSellingPrice}}</td>
          <td>
            <div class="row">
              <div class="col-md-4" v-if="pkg.itineraryType === 'Quotation'">
                <a href="#" class="text-danger mr-3 faq-button" role="button" @click="addItinerary(pkg.packageCode)" >
                  <font-awesome-icon :icon="['fas', 'fa-plus']" class="plus" /><span class="tooltip">Add Itinerary</span></a>
              </div>
              <div class="col-md-4" v-if="pkg.itineraryType === 'Quotation'">
                <a href="#" class="text-success mr-3 faq-button" role="button" @click="editPackage(pkg.packageCode)">
                  <font-awesome-icon :icon="['fas', 'fa-edit']"  class="edit"/><span class="tooltip">Edit Package</span></a>
              </div>
              <div class="col-md-4" v-if="pkg.itineraryType === 'Quotation'">
                <a href="#" class="text-danger mr-3 faq-button" role="button" @click="confirmDeletePackage(pkg.packageCode)">
                  <font-awesome-icon :icon="['fas', 'fa-trash']"  class="delete"/><span class="tooltip">Delete Package</span></a>
              </div>
            </div>
          </td>
          <td>
            <div class="text-center">
            <a href="#" class="text-primary faq-button" role="button" @click="clonePackage(pkg.packageCode)">
              <font-awesome-icon :icon="['fas', 'fa-clone']" class="clone" /><span class="tooltip">Clone</span></a>
            </div>
          </td>
          <td>
            <div class="text-center" v-if="pkg.itineraryType === 'Final'">
              <a href="#" class="text-primary faq-button" role="button" @click="addVouchers(pkg.packageCode)">
                <font-awesome-icon :icon="['fas', 'fa-plus']" class="plus" /><span class="tooltip">Add Vouchers</span></a>
            </div>
          </td>
          <td>
            <div class="text-center">
              <a href="#" class="text-primary faq-button" role="button" @click="shareWithWebsite(pkg.packageCode)">
              <font-awesome-icon :icon="['fas', 'share']" class="clone" /><span class="tooltip">Share</span></a>
            </div>
          </td>              
        </tr>
        </tbody>
      </table>
    </div>
    <DeleteModal
  :visible="showDeleteModal"
  @confirmDelete="deletePackage"
  @cancelDelete="showDeleteModal = false"
  /> 
  <div class="d-flex justify-content-between mt-3"  v-if="!isFormVisible">
      <div class="d-flex align-items-center">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredVersions.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination mb-0" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>     
    </div>
  </div>
</template>
<script>
import {useRouter, useRoute} from 'vue-router';
import axios from 'axios';
import {API_CONFIG} from '@/apiConfig';
import {onMounted, ref, computed, watch} from "vue";
import DeleteModal from './DeleteModal.vue';

export default {
  name: 'PackageList',
  components: {
        DeleteModal,
        },
  setup() {
    const packages= ref([]);
    const loading = ref(true);
    const error = ref(null);
    const router = useRouter();
    const packageCode = ref(null);
    const isVersionCreationAllowed = ref(true);
    const showDeleteModal = ref(false);
    const imageToDelete = ref(null);
    const currentPage = ref(1);
    const itemsPerPage = ref(10);

    const route = useRoute(); // Access the current route object

    // Extract the packageCode from the last part of the URL
    packageCode.value = route.path.split('/').pop();

    const fetchPackages = async () => {
      try {
        const response = await axios.get(`${API_CONFIG.getEndpoint('fetchPackagesVersions')}/${packageCode.value}`);
        packages.value = response.data;
        checkItineraryType();
      }catch(err) {
        error.value = err;
      }finally {
        loading.value = false;

      }
    };
    const searchQuery = ref('');

const filteredVersions = computed(() => {
return packages.value.filter(pkg => {
return (
pkg.itineraryType.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
pkg.customerName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
pkg.email.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
pkg.mobileNo.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
pkg.packageTitle.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
pkg.selectDestination.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
pkg.packageCode.toLowerCase().includes(searchQuery.value.toLowerCase())
);
});
});

const getPageRange = () => {
  const range = [];
  const total = totalPages.value;
  const current = currentPage.value;
  const delta = 2;

  if (current > delta + 1) {
    range.push(1);
    if (current > delta + 2) range.push('...');
  }

  for (let i = Math.max(1, current - delta); i <= Math.min(total, current + delta); i++) {
    range.push(i);
  }

  if (current < total - delta - 1) {
    if (current < total - delta - 2) range.push('...');
    range.push(total);
  }

  return range;
};

const resetPagination = () => {
  currentPage.value = 1;
};

const goToPage = (page) => {
  const pageNum = Number(page);
  if (pageNum >= 1 && pageNum <= totalPages.value) {
    currentPage.value = pageNum;
  }
};

const paginatedUsers = computed(() => {
  const pageSize = Number(itemsPerPage.value) || 10;
  const current = Number(currentPage.value) || 1;
  
  const startIndex = (current - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  
  if (!filteredVersions.value.length) return [];
  
  return filteredVersions.value.slice(startIndex, endIndex);
});

const totalPages = computed(() => {
  const pageSize = Number(itemsPerPage.value) || 10;
  return Math.max(1, Math.ceil(filteredVersions.value.length / pageSize));
});

watch(filteredVersions, () => {
  if (currentPage.value > totalPages.value) {
    currentPage.value = totalPages.value || 1;
  }
});

watch(itemsPerPage, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    resetPagination();
  }
});

const clearSearch = () => {
searchQuery.value = '';
};
    const checkItineraryType = () => {
    // Check if any package has 'Final' itinerary type
      isVersionCreationAllowed.value = !packages.value.some(pkg => pkg.itineraryType === 'Final');
    };
    const clonePackage = async (packageCode) => {
      router.push({ name: 'packages/versions/clone', params: { packageCode: packageCode } });
    }

    const addVouchers = async (packageCode) => {
      router.push('/packages/vouchers/' + packageCode);
    }

    const shareWithWebsite = async (packageCode) => {
      router.push('/web-packages/create/' + 'parent-'+packageCode);
    };

    const confirmDeletePackage = (packageCode) => {
    imageToDelete.value = packageCode;
    showDeleteModal.value = true; 
};

const deletePackage = async () => {
    if (!imageToDelete.value) return; 

    try {
        await axios.delete(API_CONFIG.getEndpoint('fetchPackages') + `/${imageToDelete.value}`);
        fetchPackages(); 
        showDeleteModal.value = false;
        imageToDelete.value = null; 
    } catch (err) {
        error.value = err;
        alert("Failed to delete package. Please try again.");
    }
};

    const addItinerary = (packageCode) => {
      router.push({ name: 'itinerary', params: { id: packageCode } });
    };

    const editPackage = (packageCode) => {
      router.push({ name: 'packages', params: { id: packageCode } });
    }

    const createNewPackage = () => {
      router.push({ name: 'packages' });
    };

    const createNewVersion = () => {
        axios
            .get(API_CONFIG.getEndpoint("createPackages") + `/${packageCode.value}/new_version`)
      .then((response) => {
          // Extract packageCode from the response (adjust the key based on your API's actual response structure)
          const packageCode = response.data.packageCode;

          // Perform navigation to the desired route
          router.push({ name: 'packages', params: { id: packageCode } });
        }).catch((error) => {
          // Handle error scenario
          console.error('Error fetching data:', error);
        });
      };

      const back_to_packages = () => {
      router.push({ path: `/viewpackages` });
    };

    //this fetchPackages will be loaded onload of component.
    onMounted(fetchPackages);

    return {
      packages,
      packageCode,
      loading,
      error,
      editPackage,
      deletePackage,
      createNewPackage,
      createNewVersion,
      addItinerary,
      clonePackage,
      addVouchers,
      isVersionCreationAllowed,
      imageToDelete,
      showDeleteModal,
      confirmDeletePackage,
      back_to_packages,
      shareWithWebsite,
      filteredVersions,
      searchQuery,
      clearSearch,
      paginatedUsers,
    goToPage,
    resetPagination,
    itemsPerPage,
    currentPage,
    totalPages,
    getPageRange
    };
  }
};
</script>

<style scoped>
.container {
  margin-top: 20px;
}

.table-container {
  max-width: 100%; /* Ensure it fits within the parent container */
  overflow-x: auto; /* Enable horizontal scrolling */
}

</style>