<template>
  <div class="container">
    <div class="card-header" v-if="!isFormVisible">
      <div class="d-flex justify-content-between align-items-center">
        <h4>Manage Coupons</h4>
        <div style="display: flex !important; gap: 20px !important">
          <div v-if="!isFormVisible">
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div> 
  <button class="btn" @click="showForm('create')">
          <font-awesome-icon :icon="['fas', 'plus']" class="me-2" /> New Coupon
        </button>
        </div>
      </div>
    </div>
    <div v-if="loading">Loading...</div>
    <div v-if="error">{{ error }}</div>

    <div class="table-container">
      <div v-if="!isFormVisible">
        <table
          class="table table-bordered table-hover table-responsive"
          style="table-layout: auto"
        >
          <thead>
            <tr>
              <th>SI.No</th>
              <th>Coupon Name</th>
              <th>Coupon Code</th>
              <th>Coupon Offer %</th>
              <th>Description</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pkg, index) in paginatedUsers" :key="index">
              <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>
              <td>{{ pkg.couponName }}</td>
              <td>{{ pkg.couponCode }}</td>
              <td>{{ pkg.couponOfferPercentage }}%</td>
              <td>{{ pkg.description }}</td>
              <td>{{ pkg.status }}</td>
              <td>
                <div style="display: flex; justify-content: space-evenly;">
                <a
                  href="#"
                  class="text-success mr-3 faq-button"
                  role="button"
                  @click="showForm('edit', pkg)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'edit']"
                    class="edit"
                  />
                  <span class="tooltip">Edit</span>
                </a>

                <a
                  href="#"
                  class="text-success mr-3 faq-button"
                  role="button"
                  @click="deleteCouponDialog(pkg)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'fa-trash']"
                    class="delete"
                  />
                  <span class="tooltip">Delete</span>
                </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Form Section -->
    <div v-if="isFormVisible">
      <h5>{{ formTitle }}</h5>
      <hr />
      <form @submit.prevent="submitForm">
        <div class="form-group row mb-3">
          <label for="couponName" class="col-sm-3 col-form-label">
            Coupon Name <span style="color: red !important">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              v-model="form.couponName"
              class="form-control"
              id="couponName"
              required
              placeholder="Enter Coupon Name"
            />
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="couponCode" class="col-sm-3 col-form-label">
            Coupon Code <span style="color: red !important">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              v-model="form.couponCode"
              class="form-control"
              id="couponCode"
              required
              placeholder="Enter Coupon Code"
              @input="checkDuplicateCouponCode"  
              @blur="checkDuplicateCouponCode"   
            />
         
            <div v-if="isDuplicateCouponCode" class="text-danger mt-2">
              This coupon code already exists.
            </div>
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="couponOfferPercentage" class="col-sm-3 col-form-label">
            Coupon Offer % <span style="color: red !important">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              v-model="form.couponOfferPercentage"
              class="form-control"
              id="couponOfferPercentage"
              required
              placeholder="Enter Coupon Offer Percentage"
              min="0"
              max="100"
            />
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="description" class="col-sm-3 col-form-label"
            >Description</label
          >
          <div class="col-sm-6">
            <textarea
              type="text"
              v-model="form.description"
              class="form-control"
              id="description"
              placeholder="Enter Description"
            ></textarea>
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="startDate" class="col-sm-3 col-form-label"
            >Coupon Start Date   <span style="color: red !important">*</span></label
          >
          <div class="col-sm-6">
            <input
              type="date"
              v-model="form.startDate"
              class="form-control"
              id="startDate"
              required
              placeholder="dd-mm-yyyy"
            />
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="endDate" class="col-sm-3 col-form-label"
            >Coupon End Date   <span style="color: red !important">*</span></label
          >
          <div class="col-sm-6">
            <input
              type="date"
              v-model="form.endDate"
              class="form-control"
               required
              id="endDate"
              placeholder="dd-mm-yyyy"
              @input="checkDateValidation"
               :class="{'is-invalid': endDateError}"
           />
           <div v-if="endDateError" class="text-danger mt-2">
      End date should be greater than start date.
    </div>
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="status" class="col-sm-3 col-form-label">Status</label>
          <div class="col-sm-2 mt-2">
            <input
              type="radio"
              v-model="form.status"
              class="form-check-input"
              id="statusActive"
              value="ACTIVE"
            />
            <label for="statusActive" class="form-check-label">Active</label>
          </div>
          <div class="col-sm-2 mt-2">
            <input
              type="radio"
              v-model="form.status"
              class="form-check-input"
              id="statusInactive"
              value="INACTIVE"
            />
            <label for="statusInactive" class="form-check-label"
              >Inactive</label
            >
          </div>
        </div>

        <hr />
        <div class="d-flex justify-content-end">
          <button type="submit" class="btn btn-success me-2" :disabled="isDuplicateCouponCode">
            <font-awesome-icon :icon="['fas', 'save']" class="me-2" /> Submit
          </button>
          <button type="button" class="btn btn-light" @click="cancelForm">
            <font-awesome-icon :icon="['fas', 'times']" /> Cancel
          </button>
        </div>
        <br />
      </form>
    </div>

    <DeleteModal
      :visible="showDeleteModal"
      @confirmDelete="deleteCoupons"
      @cancelDelete="showDeleteModal = false"
    />
    <div class="d-flex justify-content-between mt-3" v-if="!isFormVisible">
      <div class="d-flex align-items-center">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredCoupons.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination mb-0" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>     
    </div>   
  </div>
</template>

<script>
import axios from "axios";
import { API_CONFIG } from "@/apiConfig";
import DeleteModal from "./DeleteModal.vue";

export default {
  components: {
    DeleteModal,
  },
  data() {
    return {
      coupons: [],
      isFormVisible: false,
      formTitle: "Add/Update Coupon",
      form: {
        couponName: "",
        couponCode: "",
        couponOfferPercentage: "",
        description: "",
        startDate: "",
        endDate: "",
        status: "ACTIVE",
      },
      loading: false,
      error: null,
      showDeleteModal: false,
      couponToDelete: null,
      isDuplicateCouponCode: false, 
      endDateError: false, 
      searchQuery: "", 
      currentPage: 1, 
      itemsPerPage: 10,  
    };
  },
  computed: {
        filteredCoupons() {
    return this.coupons.filter(pkg => {
      const searchQueryLower = this.searchQuery.toLowerCase();
      return (
        pkg.couponName.toLowerCase().includes(searchQueryLower) ||
        pkg.couponCode.toLowerCase().includes(searchQueryLower) 
      );
    });
  },
  paginatedUsers() {
    const pageSize = Number(this.itemsPerPage) || 10;
    const currentPage = Number(this.currentPage) || 1;
    
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    
    if (!this.filteredCoupons.length) return [];
    
    return this.filteredCoupons.slice(startIndex, endIndex);
  },
  
  totalPages() {
    const pageSize = Number(this.itemsPerPage) || 10;
    return Math.max(1, Math.ceil(this.filteredCoupons.length / pageSize));
  },
},
watch: {
  itemsPerPage(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.resetPagination();
    }
  },
  searchQuery() {
    this.resetPagination();
  },
  filteredCoupons: {
    handler() {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages || 1;
      }
    },
    immediate: true
  }
},
  methods: {
    resetPagination() {
    this.currentPage = 1;
    
    this.$forceUpdate();
  },
  
  goToPage(page) {
    const pageNum = Number(page);
    if (pageNum >= 1 && pageNum <= this.totalPages) {
      this.currentPage = pageNum;
    }
  },

getPageRange() {
    const range = [];
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    const delta = 2;

    if (currentPage > delta + 1) {
      range.push(1);
      if (currentPage > delta + 2) range.push('...');
    }

    for (let i = Math.max(1, currentPage - delta); i <= Math.min(totalPages, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage < totalPages - delta - 1) {
      if (currentPage < totalPages - delta - 2) range.push('...');
      range.push(totalPages);
    }

    return range;
  },
    clearSearch() {
    this.searchQuery = ""; 
  },
    showForm(mode, pkg = null) {
      this.isFormVisible = true;
      this.formTitle = mode === "create" ? "Create Coupon" : "Edit Coupon";
      if (mode === "create") {
        this.resetForm();
      } else if (mode === "edit" && pkg) {
        this.form = { ...pkg };
      }
      this.checkDuplicateCouponCode(); 
    },
    cancelForm() {
      this.isFormVisible = false;
      this.resetForm();
    },

    resetForm() {
      this.form = {
        couponName: "",
        couponCode: "",
        couponOfferPercentage: "",
        description: "",
        status: "ACTIVE",
        startDate: "",
        endDate: "",
      };
      this.isDuplicateCouponCode = false; 
      this.endDateError = false;
    },
    async fetchCoupons() {
      this.loading = true;
      try {
        const response = await axios.get(
          API_CONFIG.getEndpoint("fetchCoupons")
        );
        this.coupons = response.data;
      } catch (error) {
        console.log("Error fetching coupons", error);
      } finally {
        this.loading = false;
      }
    },
    checkDuplicateCouponCode() {
      if (this.form.couponCode.trim() === "") {
        this.isDuplicateCouponCode = false;
        return;
      }

      const duplicate = this.coupons.some(
        (coupon) => coupon.couponCode === this.form.couponCode && coupon.id !== this.form.id
      );
      this.isDuplicateCouponCode = duplicate;
    },
    async createCoupons() {
      this.loading = true;
      try {
        const coupon = {
          id: this.form.id,
          couponName: this.form.couponName,
          couponCode: this.form.couponCode,
          couponOfferPercentage: this.form.couponOfferPercentage,
          description: this.form.description,
          status: this.form.status,
        };

        let response = await axios.post(
          API_CONFIG.getEndpoint("createCoupons"),
          coupon
        );
        console.log("Coupon created successfully:", response.data);

        this.fetchCoupons();
        this.isFormVisible = false;
        this.resetForm();
      } catch (error) {
        console.error("Error creating coupon:", error);
      } finally {
        this.loading = false;
      }
    },

    async updateCoupons() {
      this.loading = true;
      try {
        const coupon = {
          id: this.form.id,
          couponName: this.form.couponName,
          couponCode: this.form.couponCode,
          couponOfferPercentage: this.form.couponOfferPercentage,
          description: this.form.description,
          status: this.form.status,
        };

        let response = await axios.put(
          `${API_CONFIG.getEndpoint("fetchCoupons")}/${this.form.id}`,
          coupon
        );
        console.log("Coupon updated successfully:", response.data);

        this.fetchCoupons();
        this.isFormVisible = false;
        this.resetForm();
      } catch (error) {
        console.error("Error updating coupon:", error);
      } finally {
        this.loading = false;
      }
    },

    checkDateValidation() {
      if (this.form.startDate && this.form.endDate) {
        const startDate = new Date(this.form.startDate);
        const endDate = new Date(this.form.endDate);

        if (endDate <= startDate) {
          this.endDateError = true;
        } else {
          this.endDateError = false;
        }
      }
    },

    async submitForm() {
      this.checkDateValidation();

if (this.endDateError) {
  return; 
}
      if (this.form.id) {
        await this.updateCoupons();
      } else {
        await this.createCoupons();
      }
    },

    async deleteCoupons() {
      if (this.couponToDelete) {
        this.loading = true;
        try {
          await axios.delete(
            `${API_CONFIG.getEndpoint("fetchCoupons")}/${
              this.couponToDelete.id
            }`
          );
          console.log("Coupon deleted successfully");

          this.fetchCoupons();
        } catch (error) {
          console.error("Error deleting coupon:", error);
        } finally {
          this.loading = false;
        }

        this.showDeleteModal = false;
        this.couponToDelete = null;
      }
    },

    deleteCouponDialog(coupon) {
      this.showDeleteModal = true;
      this.couponToDelete = coupon;
    },
  },
  mounted() {
    this.fetchCoupons();
  },
};
</script>

<style scoped>
.table-container {
  max-width: 100%;
  overflow-x: auto;
}
.container {
  margin-top: 20px;
}
th {
  font-weight: bold;
}
</style>