<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-9"><h4>Vouchers for <span style="color: #4b49ac">{{ packageCode }}</span></h4></div>
      <div class="col-sm-3">
        <button type="button" class="btn btn-success me-2 text-right float-right" @click="back_to_packages()"><font-awesome-icon :icon="['fas', 'fa-arrow-left']" class="me-2" />Back to Packages</button>
      </div>
    </div>
    <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>

    <div class="clickable-div container border p-3">
      <table class="border table table-bordered">
        <tbody>
        <template v-for="(day, dIndex) in packageInfo.days" :key="dIndex">
          <tr>
            <td colspan="3" class="table-merged-row text-center">{{ formatDayHeader(dIndex, day.itineraryDate) }}</td>
          </tr>
          <template v-for="(city, cIndex) in day.cities" :key="cIndex">
            <tr v-for="(itinerary, iIndex) in city.itineraries" :key="iIndex">
              <td>{{ itinerary.excursionType }}</td>
              <td>{{ itinerary.subCode }}</td>
              <td>
                <input type="file" @change="event => selectFile(itinerary, event)" />
                <div v-if="isFileSelected(itinerary.itineraryId)" class="mt-3">
                  <button type="button" class="btn btn-primary" @click="uploadVoucher(itinerary)" :disabled="uploading">
                    <font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']" title="Picture"/>
                    Upload
                  </button>
                  <div v-if="uploading" class="spinner-border text-primary" role="status">
                    <span class="sr-only">Uploading...</span>
                  </div>
                </div>
              </td>
              <td>
                <a v-if="voucherInfo[itinerary.itineraryId] && 'link' in voucherInfo[itinerary.itineraryId] && voucherInfo[itinerary.itineraryId]['link'] !== ''"
                   :href="voucherInfo[itinerary.itineraryId]['link']">Download Voucher</a>
                <h6 v-else>No Voucher</h6>
              </td>
              <td>
                <input v-if="voucherInfo[itinerary.itineraryId] && 'text' in voucherInfo[itinerary.itineraryId]"
                       type="text"
                       v-model="voucherInfo[itinerary.itineraryId].text"
                       class="form-control"
                />
                <input type="text" class="form-control" v-else value="Description" disabled />
              </td>
            </tr>
          </template>
        </template>
        </tbody>
      </table>
      <hr />
      <div class="form-actions d-flex justify-content-end">
        <button type="submit" class="btn btn-success" @click="saveVouchers">
          <font-awesome-icon :icon="['fas', 'save']" title="Picture"/>
          Save Info
        </button>
      </div>
      <br>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import { API_CONFIG } from '@/apiConfig';

export default {
  name: 'VouchersForm',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const packageCode = ref(route.params.id);
    const voucherInfo = reactive({});
    const packageInfo = reactive({
      days: [
        {
          day_number: '',
          itineraryDate: '',
          cities: [
            {
              cityName: '',
              itineraries: [
                {
                  itineraryId: null,
                  excursionType: '',
                  subCode: '',
                  price: 0
                }
              ]
            }
          ]
        }
      ]
    });

    const errorMessage = ref("");
    const itineraries = reactive([]);
    const selectedFiles = reactive({});
    const uploading = ref(false);

    onMounted(() => {
      if (packageCode.value) {
        fetchPackageWithVouchersByCode();
      }
    });

    const selectFile = (itinerary, event) => {
      selectedFiles[itinerary.itineraryId] = event.target.files[0];
    };

    const isFileSelected = (itineraryId) => {
      return !!selectedFiles[itineraryId];
    };

    const saveVouchers = async () => {
      try {
        console.log(voucherInfo);
        const response = await axios.post(
            `${API_CONFIG.getEndpoint('saveVouchers')}/${packageCode.value}/vouchers`,
            voucherInfo); // Save the vouchers
        console.log('Vouchers saved successfully:', response.data);
        alert("Vouchers Data Saved. " + response.data);
        router.push('/packages/versions/' + packageCode.value);
      } catch (error) {
        console.error('Error saving vouchers:', error);
        errorMessage.value = "Error saving vouchers.";
      }
    };

    const back_to_packages = () => {
      router.push('/packages/versions/' + packageCode.value);
    };

    const uploadVoucher = async (itinerary) => {
      const file = selectedFiles[itinerary.itineraryId];
      if (!file) {
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('itineraryId', itinerary.itineraryId);

      uploading.value = true;
      try {
        const response = await axios.post(API_CONFIG.getEndpoint('uploadVoucher'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('Voucher uploaded successfully:', response.data);
        alert("Voucher uploaded successfully. Now Download Link is available.");
        voucherInfo[itinerary.itineraryId]['link'] = response.data;
        // Update the itinerary with the uploaded voucher link if needed
      } catch (error) {
        console.error('Error uploading voucher:', error);
        errorMessage.value = "Error uploading voucher.";
      } finally {
        uploading.value = false;
        // Clear the selected file
        delete selectedFiles[itinerary.itineraryId];
      }
    };

    const cancel = () => {
      console.log('Cancel button clicked');
    };

    const formatDayHeader = (dIndex, itineraryDate) => {
      const date = new Date(itineraryDate);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `Day ${dIndex + 1} - ${day}-${month}-${year}`;
    };

    const fetchPackageWithVouchersByCode = async () => {
      try {
        const response = await axios.get(
            `${API_CONFIG.getEndpoint('fetchPackages')}/${packageCode.value}/vouchers`);
        Object.assign(packageInfo, response.data['packageDTO']);
        console.log("fetchPackageByCode ::: fetched successfully");
        console.log("fetchPackageByCode ::: fetched successfully priceSummary=" + JSON.stringify(packageInfo.priceSummary));

        Object.assign(voucherInfo, response.data['vouchers']);
        console.log(voucherInfo);
      } catch (error) {
        console.error('Error fetching package details..' + error);
        errorMessage.value = "Error fetching package details..";
      }
    };

    return {
      packageInfo,
      fetchPackageByCode: fetchPackageWithVouchersByCode,
      packageCode,
      errorMessage,
      cancel,
      itineraries,
      formatDayHeader,
      uploadVoucher,
      saveVouchers,
      voucherInfo,
      selectFile,
      isFileSelected,
      uploading,
      back_to_packages
    };
  }
};
</script>
<style scoped>
.table-merged-row {
  font-size: 1.2em;
  background: aliceblue;
}
</style>