<template>
    <div class="container">
      <div class="card-header" v-if="!isFormVisible">
        <div class="d-flex justify-content-between align-items-center">
          <h4>Manage FAQ</h4>
          <div style="display: flex !important; gap: 20px !important">
            <div v-if="!isFormVisible">
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div>
  <button class="btn" @click="showForm('create')">
            <font-awesome-icon :icon="['fas', 'plus']" class="me-2" /> New FAQ
          </button>
          </div>
        </div>
      </div> 
      <div v-if="loading">Loading...</div>
      <div v-if="error" class="text-danger">{{ error }}</div>
  
      <div class="table-container" v-if="!isFormVisible">
        <table
          class="table table-bordered table-hover table-responsive"
          style="table-layout: auto"
        >
          <thead>
            <tr>
              <th>SI.No</th>
              <th>FAQ Question</th>
              <th>FAQ Answer</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pkg, index) in paginatedUsers" :key="index">
              <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>
              <td>{{ pkg.question }}</td>
              <td>{{ pkg.answer }}</td>
              <td>{{ pkg.status }}</td>
              <td>
                <div style="display: flex;">
                <a
                  href="#"
                  class="text-success mr-3 faq-button"
                  role="button"
                  @click="showForm('edit', pkg)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'edit']"
                    class="edit"
                  />
                  <span class="tooltip">Edit</span>
                </a>
  
                <a
                  href="#"
                  class="text-success mr-3 faq-button"
                  role="button"
                  @click="deleteWebCardDialog(pkg)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'fa-trash']"
                    class="delete"
                  />
                  <span class="tooltip">Delete</span>
                </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <!-- Form Section -->
      <div v-if="isFormVisible">
        <h5>{{ formTitle }}</h5>
        <hr />
        <form @submit.prevent="submitForm">
          <div class="form-group row mb-3">
            <label for="question" class="col-sm-3 col-form-label"
              >FAQ Question <span class="text-danger">*</span></label
            >
            <div class="col-sm-6">
              <input
                type="text"
                v-model="form.question"
                class="form-control"
                id="question"
                required
                placeholder="Enter FAQ Question"
              />
            </div>
          </div>
  
          <div class="form-group row mb-3">
            <label for="answer" class="col-sm-3 col-form-label"
              >FAQ Answer <span class="text-danger">*</span></label
            >
            <div class="col-sm-6">
              <textarea
                type="text"
                v-model="form.answer"
                class="form-control"
                id="answer"
                required
                placeholder="Enter FAQ Answer"
              ></textarea>
            </div>
          </div>
  
          <div class="form-group row mb-3">
            <label for="status" class="col-sm-3 col-form-label">Status</label>
            <div class="col-sm-6 d-flex align-items-center">
              <div class="form-check me-3">
                <input
                  type="radio"
                  v-model="form.status"
                  class="form-check-input"
                  id="statusActive"
                  value="ACTIVE"
                />
                <label for="statusActive" class="form-check-label">Active</label>
              </div>
              <div class="form-check">
                <input
                  type="radio"
                  v-model="form.status"
                  class="form-check-input"
                  id="statusInactive"
                  value="INACTIVE"
                />
                <label for="statusInactive" class="form-check-label"
                  >Inactive</label
                >
              </div>
            </div>
          </div>
  
          <hr />
          <div class="d-flex justify-content-end">
            <button type="submit" class="btn btn-success me-2">
              <font-awesome-icon :icon="['fas', 'save']" class="me-2" /> Submit
            </button>
            <button type="button" class="btn btn-light" @click="cancelForm">
              <font-awesome-icon :icon="['fas', 'times']" /> Cancel
            </button>
          </div>
        </form>
      </div>
      <DeleteModal
        :visible="showDeleteModal"
        @confirmDelete="deleteFAQ"
        @cancelDelete="showDeleteModal = false"
      />
      <div class="d-flex justify-content-between mt-3"  v-if="!isFormVisible">
      <div class="d-flex align-items-center mt-0">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredFAQ.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination mb-0" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>     
    </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { API_CONFIG } from "@/apiConfig";
  import DeleteModal from "./DeleteModal.vue";
 
  export default {
    components: {
      DeleteModal,
    },
    data() {
      return {
        faq: [],
        isFormVisible: false,
        form: {
            question: "",
            answer: "",
          status: "ACTIVE",
        },
        loading: false,
        error: null,
        showDeleteModal: false,
        faqToDelete: null,
        searchQuery: "",
        currentPage: 1, 
        itemsPerPage: 10, 
      };
    },
    computed: {
    filteredFAQ() {
    return this.faq.filter(pkg => {
      const searchQueryLower = this.searchQuery.toLowerCase();
      return (
        pkg.question.toLowerCase().includes(searchQueryLower) ||
        pkg.answer.toLowerCase().includes(searchQueryLower) 
      );
    });
  },
  paginatedUsers() {
    const pageSize = Number(this.itemsPerPage) || 10;
    const currentPage = Number(this.currentPage) || 1;
    
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    
    if (!this.filteredFAQ.length) return [];
    
    return this.filteredFAQ.slice(startIndex, endIndex);
  },
  
  totalPages() {
    const pageSize = Number(this.itemsPerPage) || 10;
    return Math.max(1, Math.ceil(this.filteredFAQ.length / pageSize));
  },
  },
  watch: {
  itemsPerPage(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.resetPagination();
    }
  },
  searchQuery() {
    this.resetPagination();
  },
  filteredFAQ: {
    handler() {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages || 1;
      }
    },
    immediate: true
  }
},
    methods: {
      clearSearch() {
    this.searchQuery = ""; 
  },
        showForm(mode, pkg = null) {
      this.isFormVisible = true;
      this.formTitle = mode === "create" ? "Create FAQ" : "Edit FAQ";
      if (mode === "create") {
        this.resetForm();
      } else if (mode === "edit" && pkg) {
        this.form = { ...pkg };
      }
    },
    cancelForm() {
      this.isFormVisible = false;
      this.resetForm();
    },

    resetPagination() {
    this.currentPage = 1;
    
    this.$forceUpdate();
  },
  
  goToPage(page) {
    const pageNum = Number(page);
    if (pageNum >= 1 && pageNum <= this.totalPages) {
      this.currentPage = pageNum;
    }
  },

getPageRange() {
    const range = [];
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    const delta = 2;

    if (currentPage > delta + 1) {
      range.push(1);
      if (currentPage > delta + 2) range.push('...');
    }

    for (let i = Math.max(1, currentPage - delta); i <= Math.min(totalPages, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage < totalPages - delta - 1) {
      if (currentPage < totalPages - delta - 2) range.push('...');
      range.push(totalPages);
    }

    return range;
  },
    resetForm() {
      this.form = {
        question: "",
        answer: "",
        status: "ACTIVE",
      };
    },
      async submitForm() {
        if (this.form.id) {
          await this.updateFAQ();
        } else {
          await this.createFAQ();
        }
      },
  
      async fetchFAQ() {
        this.loading = true;
        try {
          const response = await axios.get(
            API_CONFIG.getEndpoint("fetchFAQ")
          );
          this.faq = response.data;
        } catch (error) {
          console.log("Error fetching FAQ", error);
        } finally {
          this.loading = false;
        }
      },
  
      async createFAQ() {
      this.loading = true;
      try {
        const faqs = {
          id: this.form.id,
          question: this.form.question,
          answer: this.form.answer,
          status: this.form.status,
        };

        let response = await axios.post(
          API_CONFIG.getEndpoint("createFAQ"),
          faqs
        );
        console.log("FAQ created successfully:", response.data);

        this.fetchFAQ();
        this.isFormVisible = false;
        this.resetForm();
      } catch (error) {
        console.error("Error creating FAQ:", error);
      } finally {
        this.loading = false;
      }
    },

    async updateFAQ() {
      this.loading = true;
      try {
        const faqs = {
            id: this.form.id,
          question: this.form.question,
          answer: this.form.answer,
          status: this.form.status,
        };

        let response = await axios.put(
          `${API_CONFIG.getEndpoint("updateFAQ")}/${this.form.id}`,
          faqs
        );
        console.log("FAQ updated successfully:", response.data);

        this.fetchFAQ();
        this.isFormVisible = false;
        this.resetForm();
      } catch (error) {
        console.error("Error updating FAQ:", error);
      } finally {
        this.loading = false;
      }
    },
      async deleteFAQ() {
        if (this.faqToDelete) {
          this.loading = true;
          try {
            await axios.delete(
              `${API_CONFIG.getEndpoint("deleteFAQ")}/${
                this.faqToDelete.id
              }`
            );
            console.log("FAQ deleted successfully");
  
            this.fetchFAQ();
          } catch (error) {
            console.error("Error deleting FAQ:", error);
          } finally {
            this.loading = false;
          }
  
          this.showDeleteModal = false;
          this.faqToDelete = null;
        }
      },
  
      deleteWebCardDialog(faqs) {
        this.showDeleteModal = true;
        this.faqToDelete = faqs;
      },
  
    },
  
    mounted() {
      this.fetchFAQ();
    },
  };
  </script>
  
  <style scoped>
  .table-container {
    max-width: 100%;
    overflow-x: auto;
  }
  .container {
    margin-top: 20px;
  }
  th {
    font-weight: bold;
  }
  </style>
  