<template>
    <div class="container mb-5">
        <div class="card-header" v-if="!isFormVisible">
        <div class="d-flex justify-content-between align-items-center" >
            <h4>Manage Cities </h4>
            <div style="display: flex !important; gap: 20px !important">
                <div v-if="!isFormVisible">
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div>     
  <button @click="showForm('create')" class="btn1"><font-awesome-icon :icon="['fas', 'fa-plus']" class="me-2" />New City</button>
            </div>
        </div> 
        </div>   
       <!-- <hr> -->     
        <div v-if="!isFormVisible" class="table-container" >
       <!-- <h4>Cities</h4>  -->      
        <table class="table table-bordered mt-3">
            <thead>
                <tr>
                    <th>SI.No</th>
                    <th>Destination Name</th>
                    <th>City Name</th>
                    <th>Country</th>
                    <th>State Name</th>
                    <th>Continent</th>
                    <th>Region</th>                    
                    <th>Nearest Cities</th>
                    <th>Search Tags</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(city, index) in paginatedUsers" :key="city.id">
                    <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>
                    <td>{{ city.destinationName }}</td>    
                    <td>{{ city.cityName }}</td>
                    <td>{{ city.countryName }}</td>
                    <td>{{ city.stateName }}</td>
                    <td>{{ city.continentName }}</td>
                    <td>{{ city.regionName }}</td>                    
                    <td>{{ city.nearestCities }}</td>
                    <td>{{ city.searchTags }}</td>                
                    <td>{{ city.status }}</td>
                    <td style="display: flex;">
                        <button @click="showForm('edit',city)" class="btn-sm  mr-3 faq-button"><font-awesome-icon :icon="['fas', 'fa-edit']" class="edit" /><span class="tooltip">Edit</span></button>                        
                        <button @click="confirmDelete(city.id)" class="btn-sm faq-button">
                            <font-awesome-icon :icon="['fas', 'fa-trash']" class="delete" /><span class="tooltip">Delete</span>
                        </button>  
                   </td>
                </tr>
            </tbody>
        </table>
        </div>

        <div v-if="isFormVisible">
            <h5>{{ formTitle }}</h5> 
            <hr/>       
            <form @submit.prevent="submitForm">

                <div class="form-group row mb-3">
                    <label for="destination" class="col-sm-3 col-form-label">Select Destination <span style="color:red !important">*</span></label>                    
                    <div class="col-sm-6">
                        <select v-model="form.destinationId" class="form-control" id="destination" required :disabled="form.cityName.trim().length > 0">
                            <option disabled value="">--Please select a destination--</option>
                            <option v-for="destination in destinations" :key="destination.id" :value="destination.id">
                                {{ destination.destinationName }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group row mb-3">
                    <label for="cityName" class="col-sm-3 col-form-label">City Name <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <input type="text" v-model="form.cityName" class="form-control" id="cityName" required @input="validateCityName" 
                        :class="{'is-invalid': errorMessages.cityName}">
                        <div v-if="errorMessages.cityName"
                        class="invalid-feedback">{{ errorMessages.cityName }}</div>
                    </div>
                </div>


                <div class="form-group row mb-3">
                    <label for="stateName" class="col-sm-3 col-form-label">State Name <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <input type="text" v-model="form.stateName" class="form-control" id="stateName" required="required">
                    </div>
                </div>

                <div class="form-group row mb-3">
                    <label for="continentName" class="col-sm-3 col-form-label">Continent Name </label>
                    <div class="col-sm-6">
                        <input type="text" v-model="form.continentName" class="form-control" id="continentName">
                    </div>
                </div>
                
                <div class="form-group row mb-3">
                    <label for="regionName" class="col-sm-3 col-form-label">Region Name </label>
                    <div class="col-sm-6">
                        <input type="text" v-model="form.regionName" class="form-control" id="regionName">
                    </div>
                </div>

                <div class="form-group row mb-3">
                    <label for="countryName" class="col-sm-3 col-form-label">Country Name </label>
                    <div class="col-sm-6">
                        <input type="text" v-model="form.countryName" class="form-control" id="countryName">
                    </div>
                </div>

                <div class="form-group row mb-3">
                    <label for="nearestCities" class="col-sm-3 col-form-label">Nearest Cities <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <input type="text" v-model="form.nearestCities" class="form-control" id="nearestCities" placeholder="Nearest city(add multiple city names by ,)">
                    </div>
                </div>

                <div class="form-group row mb-3">
                    <label for="searchTags" class="col-sm-3 col-form-label">Search Tags <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <input type="text" v-model="form.searchTags" class="form-control" placeholder="Search Tags(add multiple city / places names by ,)" id="searchTags" required="required">
                    </div>
                </div>                

                <div class="form-group row mb-3">
                    <label for="status" class="col-sm-3 col-form-label">Status</label>                
                    <div class="col-sm-2 mt-2">
                        <input type="radio" v-model="form.status" class="form-check-input" id="statusActive" value="Active">
                        <label for="statusActive" class="form-check-label">Active</label>
                    </div>
                    <div class="col-sm-2 mt-2">
                        <input type="radio" v-model="form.status" class="form-check-input" id="statusInactive" value="Inactive">
                        <label for="statusInactive" class="form-check-label">Inactive</label>
                    </div>                
                </div>

                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-success me-2" :disabled="!isFormValid || loading" ><span v-if="loading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span><font-awesome-icon :icon="['fas', 'fa-save']" class="me-2" />Submit</button>
                    <button type="button" class="btn btn-light" @click="cancelForm"> <font-awesome-icon :icon="['fas', 'xmark']" /> Cancel</button>
                </div>

            </form>  
        </div> 
        <div v-if="showDeleteModal" class="modal-overlay">
      <div class="modal-box">
        <h5>Confirm Delete</h5>
        <hr/>
        <p>Are you sure you want to delete this city?</p>
        <div class="modal-buttons">
            <button @click="cancelDelete" class="btn btn-light">Cancel</button>
          <button @click="deleteCity" class="btn btn-danger" style="background-color: #dc3545 !important;">Delete</button>
        </div>
      </div>
    </div>   
    <div class="d-flex justify-content-between mt-3" v-if="!isFormVisible">
      <div class="d-flex align-items-center mt-0">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredCities.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
    <ul class="pagination mb-0">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
                <span aria-hidden="true">&laquo; Previous</span>
            </a>
        </li>
        <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
                <span aria-hidden="true">Next &raquo;</span>
            </a>
        </li>
    </ul>
</nav>
    </div>   
    </div>
</template>
<script>

    import axios from 'axios';
    import useVuelidate from '@vuelidate/core';
    import { required } from '@vuelidate/validators';
    import { API_CONFIG } from '@/apiConfig';

    export default {
    data() {
        return {
            form: {
                destinationId : '',                    
                cityName: '',
                stateName: '',
                continentName: '',
                regionName: '',
                countryName: '',
                nearestCities: '',
                searchTags: '',            
                status: 'Active'
            },
            errorMessages: {
                cityName: ''
            },
            // this list should be empty after integrating with backend..
            cities: [],
            isFormVisible: false,
            formTitle: 'Add / Update City details',
            showDeleteModal: false,
            cityToDelete: null, 
            loading : false,
            searchQuery: "", 
            currentPage: 1, 
            itemsPerPage: 10, 
        };
    },
    validations() {
        return {
            form: {
                destinationId: { required },
                cityName: { required },
                nearestCities: {required},
                searchTags: {required},
                stateName: { required }
            }
        };
    },    
    setup() {        
        const v$ = useVuelidate();
        return { v$ };

    },
    computed: {
        filteredCities() {
    if (!this.searchQuery) return this.cities;
    
    const searchQueryLower = this.searchQuery.toLowerCase();
    return this.cities.filter(city => {
      return (
        (city.destinationName || '').toLowerCase().includes(searchQueryLower) ||
        (city.cityName || '').toLowerCase().includes(searchQueryLower) ||
        (city.countryName || '').toLowerCase().includes(searchQueryLower) ||
        (city.continentName || '').toLowerCase().includes(searchQueryLower) ||
        (city.stateName || '').toLowerCase().includes(searchQueryLower) ||
        (city.regionName || '').toLowerCase().includes(searchQueryLower) ||
        (city.searchTags || '').toLowerCase().includes(searchQueryLower) ||
        (city.nearestCities || '').toLowerCase().includes(searchQueryLower)
      );
    });
  },
  
  paginatedUsers() {
    const pageSize = Number(this.itemsPerPage) || 10;
    const currentPage = Number(this.currentPage) || 1;
    
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    
    if (!this.filteredCities.length) return [];
    
    return this.filteredCities.slice(startIndex, endIndex);
  },
  
  totalPages() {
    const pageSize = Number(this.itemsPerPage) || 10;
    return Math.max(1, Math.ceil(this.filteredCities.length / pageSize));
  },
            isFormValid() {
                return (
                    this.form.destinationId &&
                    this.form.cityName &&
                    this.form.nearestCities &&
                    this.form.searchTags &&
                    this.form.stateName
                );
        }
    },
    watch: {
  itemsPerPage(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.resetPagination();
    }
  },
  searchQuery() {
    this.resetPagination();
  },
  filteredCities: {
    handler() {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages || 1;
      }
    },
    immediate: true
  }
},
    methods: {
        resetPagination() {
    this.currentPage = 1;
    
    this.$forceUpdate();
  },
  
  goToPage(page) {
    const pageNum = Number(page);
    if (pageNum >= 1 && pageNum <= this.totalPages) {
      this.currentPage = pageNum;
    }
  },

getPageRange() {
    const range = [];
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    const delta = 2;

    if (currentPage > delta + 1) {
      range.push(1);
      if (currentPage > delta + 2) range.push('...');
    }

    for (let i = Math.max(1, currentPage - delta); i <= Math.min(totalPages, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage < totalPages - delta - 1) {
      if (currentPage < totalPages - delta - 2) range.push('...');
      range.push(totalPages);
    }

    return range;
  },
        clearSearch() {
    this.searchQuery = ""; 
  },
        validateCityName() {
            // this.form.cityName = this.form.cityName.replace(/[^a-zA-Z\s]/g, '');
            if (!this.form.cityName.trim()){
                this.errorMessages.cityName = 'Please enter city name';
            } else {
                this.errorMessages.cityName = '';
            }
        },
        async submitForm() {
            this.v$.$touch();
            if (this.v$.$invalid) {
                console.log('Form is invalid');
                return;
            }    
            this.loading = true;
            try {

                let response;
                if( this.form.id) {
                    //edit city                    
                    response = await axios.put(API_CONFIG.getEndpoint('createCities') + `/${this.form.id}`, this.form);
                    console.log('Form updated successfully:', response.data);
                }else {
                    //create cities
                    response = await axios.post(API_CONFIG.getEndpoint('createCities'), this.form);
                    console.log('Form submitted successfully:', response.data);
                }
                // if (!this.form.id) {
                //     this.cities.unshift(response.data); // Add to the top
                // } else {
                //     // Update the city in the list (find the city by id and update)
                //     const index = this.cities.findIndex(city => city.id === response.data.id);
                //     if (index !== -1) {
                //         this.cities.splice(index, 1, response.data);
                //     }
                // }                
                this.fetchCities(); // Fetch the updated list after submission
                this.isFormVisible = false; // Hide the form after submission
                this.resetForm();
            } catch (error) {
                console.error('Error submitting form:', error);
            } finally {
                this.loading = false;
            }
        },
        async fetchCities() {
            this.loading = true;
            try {                
                const resp = await axios.get(API_CONFIG.getEndpoint('fetchCities'));
                this.cities = resp.data;
                this.cities.reverse();
                console.log(this.cities); // Check the structure of the cities array
            }catch(error) {
                console.log('Error to fetch cities list', error);
            } finally {
                this.loading = false;
            }
        },
        async fetchDestinations() {
            this.loading = true;
            try {
                const resp = await axios.get(API_CONFIG.getEndpoint('fetchDestinations'));                
                this.destinations = resp.data;
            } catch (error) {
                console.log('Error fetching destinations list', error);
                this.error = 'Failed to fetch destinations list. Please try again.';
            } finally {
                this.loading = false;
            }
        },        
        showForm(action, city = null) {
            if( action === 'create') {
                this.formTitle = 'Add City details';
                this.resetForm();
            } else if (action === 'edit' && city) {
                this.formTitle = 'Edit City details';
                this.form = {...city };
            }
            this.isFormVisible = true;            
        },
        resetForm() {
            this.form = {
                destinationId : '',                    
                cityName: '',
                stateName: '',
                continentName: '',
                regionName: '',
                countryName: '',
                nearestCities: '',
                searchTags: '',
                status: 'Active'
            }
        },
        cancelForm() {
           this.isFormVisible = false; 
           this.resetForm();
        },
        confirmDelete(id) {
      this.cityToDelete = id;
      this.showDeleteModal = true;
    },
    async deleteCity() {
      if (!this.cityToDelete) return;
      this.loading = true;
      try {
        await axios.delete(API_CONFIG.getEndpoint('fetchCities') + `/${this.cityToDelete}`);
        this.cities = this.cities.filter(city => city.id !== this.cityToDelete);
        this.fetchCities();
      } catch (error) {
        console.error('Error deleting city:', error);
      } finally {
        this.showDeleteModal = false;
        this.cityToDelete = null;
        this.loading = false;
      }
    },
    cancelDelete() {
      this.showDeleteModal = false;
      this.cityToDelete = null;
    }
    },
    mounted() {
        this.fetchCities();  // Fetch the list when the component is mounted
        this.fetchDestinations();
    }
  }; // end of default 

</script>

<style>
.btn1{
  color: #ffffff;
  background-color: #7978E9;
  border-radius:6px;
}
.btn1:hover{
  background-color: #4B49AC;
}
    .mr-3 {        
        margin-right: 1rem !important;        
    }
    .container {
        margin-top: 20px;
    }

    h3, h4 {
        margin-bottom: 20px;
    }

    .table {
        margin-top: 20px;
    }

    .btn-primary {
        margin-bottom: 20px;
    }

    .form-group {
        margin-bottom: 15px;
    }

    .text-danger {
        color: red;
        font-size: 0.875em;
    }
    .parent-container {
        display: flex;
        justify-content: center; /* This centers the button horizontally */
    }
    .button {
        padding: 10px 20px; /* Adjust padding as needed */
        margin: 0 10px; /* Add equal margins on both sides */
}

label{
    margin-left: 5px;
}
</style>