<template>
  <div class="container">
    <div class="card-header" v-if="!isFormVisible">
      <div class="d-flex justify-content-between align-items-center">
        <h4>Manage Leads</h4>
        <div style="display: flex !important; gap: 20px !important">
          <div v-if="!isFormVisible">
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div> 
  <button class="btn" @click="showForm('create')">
          <font-awesome-icon :icon="['fas', 'plus']" class="me-2" /> New Leads
        </button>
        </div>
      </div>
    </div>
    <div v-if="loading">Loading...</div>
    <div v-if="error" class="text-danger">{{ error }}</div>

    <div class="table-container" v-if="!isFormVisible">
      <table
        class="table table-bordered table-hover table-responsive"
        style="table-layout: auto"
      >
        <thead>
          <tr>
            <th>SI.No</th>
            <th>Name</th>
            <th>Email</th>
            <th>Contact No</th>
            <th>Destination</th>
            <th>Date of travel</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pkg, index) in paginatedUsers" :key="index">
            <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>
            <td>{{ pkg.name }}</td>
            <td>{{ pkg.email }}</td>
            <td>{{ pkg.contactNo }}</td>
            <td>{{ pkg.destination }}</td>
            <td>{{ pkg.travelDate }}</td>
            <td>
              <div style="display: flex; justify-content: space-evenly;">
                <a
                  href="#"
                  class="text-success mr-3 faq-button"
                  role="button"
                  @click="showForm('edit', pkg)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'edit']"
                    class="edit"
                  />
                  <span class="tooltip">Edit</span>
                </a>
                <a
                  href="#"
                  class="text-success mr-3 faq-button"
                  role="button"
                  @click="deleteCouponDialog(pkg)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'fa-trash']"
                    class="delete"
                  />
                  <span class="tooltip">Delete</span>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Form Section -->
    <div v-if="isFormVisible">
      <h5>{{ formTitle }}</h5>
      <hr />
      <form @submit.prevent="submitForm">
        <div class="form-group row mb-3">
          <label for="name" class="col-sm-3 col-form-label"
            >Name <span class="text-danger">*</span></label
          >
          <div class="col-sm-6">
            <input
              type="text"
              v-model="form.name"
              class="form-control"
              id="name"
              required
              placeholder="Enter Name"
            />
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="email" class="col-sm-3 col-form-label"
            >Email <span class="text-danger">*</span></label
          >
          <div class="col-sm-6">
            <input
              type="email"
              v-model="form.email"
              class="form-control"
              id="email"
              required
              placeholder="Email"
            />
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="contactNo" class="col-sm-3 col-form-label"
            >Contact No <span class="text-danger">*</span></label
          >
          <div class="col-sm-6">
            <input
              type="tel"
              v-model="form.contactNo"
              class="form-control"
              id="contactNo"
              required
              placeholder="Mobile No"
              pattern="^\+?[0-9]{10,15}$"
      maxlength="16"
      @input="validateContactNo"
            />
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="howFarReached" class="col-sm-3 col-form-label"
            >How far have you reached?</label
          >
          <div class="col-sm-6">
            <select
              v-model="form.howFarReached"
              id="howFarReached"
              class="form-select"
            >
              <option value="">Select an option</option>
              <option value="Searching">Searching for a destination</option>
              <option value="Decided">Decided my destination</option>
              <option value="Booked">Booked the flight</option>
              <option value="Confused">Confused, help me</option>
            </select>
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="destination" class="col-sm-3 col-form-label"
            >Destination <span class="text-danger">*</span></label
          >
          <div class="col-sm-6">
            <input
              type="text"
              v-model="form.destination"
              class="form-control"
              id="destination"
              required
              placeholder="Your Destination"
            />
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="travelDate" class="col-sm-3 col-form-label"
            >When do you want to travel?
            <span class="text-danger">*</span></label
          >
          <div class="col-sm-6">
            <input
              type="date"
              v-model="form.travelDate"
              class="form-control"
              id="travelDate"
              :min="minTravelDate"
              required
            />
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="travelingWith" class="col-sm-3 col-form-label"
            >Whom are you traveling with?</label
          >
          <div class="col-sm-6">
            <select
              v-model="form.travelingWith"
              id="travelingWith"
              class="form-select"
            >
              <option value="">Select an option</option>
              <option value="Alone">Travelling Alone</option>
              <option value="Family">Family with kids</option>
              <option value="Partner">With a spouse/partner</option>
              <option value="Elders">Elders are traveling</option>
              <option value="Group">Group more than 6</option>
            </select>
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="stayPreference" class="col-sm-3 col-form-label"
            >Where would you like to stay?</label
          >
          <div class="col-sm-6">
            <select
              v-model="form.stayPreference"
              id="stayPreference"
              class="form-select"
            >
              <option value="">Select an option</option>
              <option value="Hostel">
                Hostels (neat and clean shared accommodation)
              </option>
              <option value="1-2 star">
                1 or 2 star (simple, basic, neat and clean)
              </option>
              <option value="3 star">
                3 star (deluxe amenities, quality service)
              </option>
              <option value="4 star">
                4 star (comfort, care, superior amenities)
              </option>
              <option value="5 star">5 star</option>
            </select>
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="subject" class="col-sm-3 col-form-label">Subject</label>
          <div class="col-sm-6">
            <input
              type="text"
              v-model="form.subject"
              class="form-control"
              id="subject"
              placeholder="Enter Subject"
            />
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="additionalInfo" class="col-sm-3 col-form-label"
            >Additional Information</label
          >
          <div class="col-sm-6">
            <textarea
              v-model="form.additionalInfo"
              class="form-control"
              id="additionalInfo"
              placeholder="Anything else you'd want us to know?"
            ></textarea>
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="assignedSubAdmin" class="col-sm-3 col-form-label"
            >Assign Subadmin</label
          >
          <div class="col-sm-6">
            <select
              v-model="form.assignedSubAdmin"
              id="assignedSubAdmin"
              class="form-select"
            >
              <option value="">Select an option</option>
              <option value="">Test</option>
              <option value="">Test Subadmin</option>
            </select>
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="subject" class="col-sm-3 col-form-label"
            >Assign Agent</label
          >
          <div class="col-sm-6">
            <select
              v-model="form.assignedAgent"
              id="accommodation"
              class="form-select"
            >
              <option value="">Select an option</option>
              <option value="">Test</option>
              <option value="">Test Agent</option>
            </select>
          </div>
        </div>

        <hr />
        <div class="d-flex justify-content-end">
          <button type="submit" class="btn btn-success me-2">
            <font-awesome-icon :icon="['fas', 'save']" class="me-2" /> Submit
          </button>
          <button type="button" class="btn btn-light" @click="cancelForm">
            <font-awesome-icon :icon="['fas', 'times']" /> Cancel
          </button>
        </div>
      </form>
    </div>
    <DeleteModal
      :visible="showDeleteModal"
      @confirmDelete="deleteLeads"
      @cancelDelete="showDeleteModal = false"
    />
    <div class="d-flex justify-content-between mt-3" v-if="!isFormVisible">
      <div class="d-flex align-items-center">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredLeads.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination mb-0" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>     
    </div>   
  </div>
</template>

<script>
import axios from "axios";
import { API_CONFIG } from "@/apiConfig";
import DeleteModal from "./DeleteModal.vue";

export default {
  components: {
    DeleteModal,
  },
  data() {
    return {
      leads: [],
      isFormVisible: false,
      formTitle: "Create New Lead",
      form: {
        name: "",
        email: "",
        contactNo: "",
        howFarReached: "",
        destination: "",
        travelDate: "",
        travelingWith: "",
        stayPreference: "",
        additionalInfo: "",
        assignedSubAdmin: null,
        assignedAgent: null,
        subject: "",
      },
      loading: false,
      error: null,
      showDeleteModal: false,
      leadsToDelete: null,
      searchQuery: "", 
      currentPage: 1, 
      itemsPerPage: 10,  
    };
  },
  computed: {
    minTravelDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  },
        filteredLeads() {
    return this.leads.filter(pkg => {
      const searchQueryLower = this.searchQuery.toLowerCase();
      return (
        pkg.name.toLowerCase().includes(searchQueryLower) ||
        pkg.email.toLowerCase().includes(searchQueryLower) ||
        pkg.contactNo.toLowerCase().includes(searchQueryLower) ||
        pkg.destination.toLowerCase().includes(searchQueryLower)
      );
    });
  },
  paginatedUsers() {
    const pageSize = Number(this.itemsPerPage) || 10;
    const currentPage = Number(this.currentPage) || 1;
    
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    
    if (!this.filteredLeads.length) return [];
    
    return this.filteredLeads.slice(startIndex, endIndex);
  },
  
  totalPages() {
    const pageSize = Number(this.itemsPerPage) || 10;
    return Math.max(1, Math.ceil(this.filteredLeads.length / pageSize));
  },
},
watch: {
  itemsPerPage(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.resetPagination();
    }
  },
  searchQuery() {
    this.resetPagination();
  },
  filteredLeads: {
    handler() {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages || 1;
      }
    },
    immediate: true
  }
},
  methods: {
    validateContactNo() {
    this.form.contactNo = this.form.contactNo.replace(/[^0-9+]/g, '');
    
    if (this.form.contactNo.startsWith('+')) {
      this.form.contactNo = '+' + this.form.contactNo.slice(1).replace(/[^0-9]/g, '');
    }
    
    if (this.form.contactNo.startsWith('+') && this.form.contactNo.length > 16) {
      this.form.contactNo = this.form.contactNo.slice(0, 16);
    } else if (this.form.contactNo.length > 15) {
      this.form.contactNo = this.form.contactNo.slice(0, 15);
    }
  },
    resetPagination() {
    this.currentPage = 1;
    
    this.$forceUpdate();
  },
  
  goToPage(page) {
    const pageNum = Number(page);
    if (pageNum >= 1 && pageNum <= this.totalPages) {
      this.currentPage = pageNum;
    }
  },

getPageRange() {
    const range = [];
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    const delta = 2;

    if (currentPage > delta + 1) {
      range.push(1);
      if (currentPage > delta + 2) range.push('...');
    }

    for (let i = Math.max(1, currentPage - delta); i <= Math.min(totalPages, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage < totalPages - delta - 1) {
      if (currentPage < totalPages - delta - 2) range.push('...');
      range.push(totalPages);
    }

    return range;
  },
        clearSearch() {
    this.searchQuery = ""; 
  },
    showForm(mode, pkg = null) {
      this.isFormVisible = true;
      this.formTitle = mode === "create" ? "Create Lead" : "Edit Lead";
      if (mode === "create") {
        this.resetForm();
      } else if (mode === "edit" && pkg) {
        this.form = { ...pkg };
      }
    },
    cancelForm() {
      this.isFormVisible = false;
      this.resetForm();
    },
    resetForm() {
      this.form = {
        name: "",
        email: "",
        contactNo: "",
        howFarReached: "",
        destination: "",
        assignedSubAdmin: "",
        assignedAgent: "",
        subject: "",
        travelDate: "",
        travelingWith: "",
        stayPreference: "",
        additionalInfo: "",
      };
    },
    async submitForm() {
      if (this.form.id) {
        await this.updateLeads();
      } else {
        await this.createLeads();
      }
    },

    async fetchLeads() {
      this.loading = true;
      try {
        const response = await axios.get(API_CONFIG.getEndpoint("fetchLeads"));
        this.leads = response.data;
      } catch (error) {
        console.log("Error fetching leads", error);
      } finally {
        this.loading = false;
      }
    },

    async createLeads() {
      this.loading = true;
      try {
        const lead = {
          id: this.form.id,
          name: this.form.name,
          email: this.form.email,
          contactNo: this.form.contactNo,
          howFarReached: this.form.howFarReached,
          destination: this.form.destination,
          assignedSubAdmin: null,
          assignedAgent: null,
          subject: this.form.subject,
          travelDate: this.form.travelDate,
          stayPreference: this.form.stayPreference,
          travelingWith: this.form.travelingWith,
          additionalInfo: this.form.additionalInfo,
        };

        let response = await axios.post(
          API_CONFIG.getEndpoint("createLeads"),
          lead
        );
        console.log("Leads created successfully:", response.data);

        this.fetchLeads();
        this.isFormVisible = false;
        this.resetForm();
      } catch (error) {
        console.error("Error creating leads:", error);
      } finally {
        this.loading = false;
      }
    },

    async updateLeads() {
      this.loading = true;
      try {
        const lead = {
          id: this.form.id,
          name: this.form.name,
          email: this.form.email,
          contactNo: this.form.contactNo,
          howFarReached: this.form.howFarReached,
          destination: this.form.destination,
          assignedSubAdmin: null,
          assignedAgent: null,
          subject: this.form.subject,
          travelDate: this.form.travelDate,
          stayPreference: this.form.stayPreference,
          travelingWith: this.form.travelingWith,
          additionalInfo: this.form.additionalInfo,
        };

        let response = await axios.put(
          `${API_CONFIG.getEndpoint("updateLeads")}/${this.form.id}`,
          lead
        );
        console.log("Leads updated successfully:", response.data);

        this.fetchLeads();
        this.isFormVisible = false;
        this.resetForm();
      } catch (error) {
        console.error("Error updating Leads:", error);
      } finally {
        this.loading = false;
      }
    },

    deleteCouponDialog(pkg) {
      this.leadsToDelete = pkg;
      this.showDeleteModal = true;
    },

    async deleteLeads() {
      this.loading = true;
      try {
        await axios.delete(
          `${API_CONFIG.getEndpoint("deleteLeads")}/${this.leadsToDelete.id}`
        );
        this.fetchLeads();
        this.showDeleteModal = false;
      } catch (error) {
        console.log("Error deleting leads", error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchLeads();
  },
};
</script>

<style scoped>
.table-container {
  max-width: 100%;
  overflow-x: auto;
}
.container {
  margin-top: 20px;
}

th {
  font-weight: bold;
}
</style>
