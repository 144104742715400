
<template>
    <div class="container">
         <div class="card-header" v-if="!isFormVisible">
    <div class="d-flex justify-content-between align-items-center" >
            <h4>Manage Sourcing Partner</h4>
            <div style="display: flex !important; gap: 20px !important">
                <div v-if="!isFormVisible">
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div>  
  <button @click="showForm('create')" class="btn1"><font-awesome-icon :icon="['fas', 'fa-plus']" class="me-2" /> New Partner</button>     
            </div>
        </div> 
        </div>
        <!-- <hr> -->
        <!--  List all Source Partners  -->
        <div v-if="!isFormVisible" class="table-container">
        <!-- <h4>Sourcing Partners List</h4> -->      
        <table class="table table-bordered mt-3">
            <thead>
                <tr>
                    <th>SI.No</th>
                  <th>Destination</th>
                  <th>Partner Name</th>
                  <th>Phone No</th>
                  <th>Email</th>
                  <th>Commission Percentage</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(partner, index) in paginatedUsers" :key="partner.id">
                <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>
                <td>{{ partner.destinationName }}</td>
                <td>{{ partner.partnerName }}</td>
                <td>{{ partner.partnerPhone }}</td>
                <td>{{ partner.partnerEmail }}</td>
                <td>{{ partner.commissionPercentage }}</td>
                <td>{{ partner.status }}</td>
                <td style="display: flex;">
                    <button @click="showForm('edit',partner)" class=" btn-sm  mr-3 faq-button"><font-awesome-icon :icon="['fas', 'fa-edit']" class="edit" /><span class="tooltip">Edit</span></button>
                    <button @click="deletePartner(partner.partnerCode)" class="btn-sm faq-button">
                      <font-awesome-icon :icon="['fas', 'fa-trash']" class="delete" /><span class="tooltip">Delete</span>
                    </button>                   
                </td>
                </tr>
            </tbody>
        </table>
        </div>

        <div v-if="isFormVisible">
            <h5>{{ formTitle }}</h5>  
            <hr/>      
            <form @submit.prevent="submitForm">
                <div class="form-group row mb-3">
                    <label for="destination" class="col-sm-3 col-form-label">Select Destination <span style="color:red !important">*</span></label>                    
                    <div class="col-sm-6">
                        <select v-model="form.destinationId" class="form-control" id="destination" required>
                            <option disabled value="">--Please select a destination--</option>
                            <option v-for="destination in destinations" :key="destination.id" :value="destination.id">
                                {{ destination.destinationName }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="partnerName" class="col-sm-3 col-form-label">Partner Name <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <input type="text" v-model="form.partnerName" class="form-control" id="partnerName" required @input="validatePartnerName" :class="{'is-invalid': errorMessages.partnerName}">
                        <div v-if="errorMessages.partnerName" class="invalid-feedback">{{ errorMessages.partnerName }}</div>
                    </div>
                </div>

                <div class="form-group row mb-3">
                    <label for="partnerPhone" class="col-sm-3 col-form-label">Partner Phone No <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <input type="text" v-model="v$.form.partnerPhone.$model" class="form-control" id="partnerPhone" required>
                        
                        <div class="text-danger" v-for="(error, index) in v$.form.partnerPhone.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                </div>               

                <div class="form-group row mb-3">
                    <label for="partnerEmail" class="col-sm-3 col-form-label">Partner Email <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <input type="email" v-model="v$.form.partnerEmail.$model" class="form-control" id="partnerEmail" required>
                        <div class="text-danger" v-for="(error, index) of v$.form.partnerEmail.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="commissionPercentage" class="col-sm-3 col-form-label">Commission Percentage (numbers only) <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <input type="number" step="0.01" min="0" v-model="v$.form.commissionPercentage.$model" class="form-control" id="commissionPercentage" required>
                        <div class="text-danger" v-for="(error, index) of v$.form.commissionPercentage.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="status" class="col-sm-3 col-form-label">Status</label>                
                    <div class="col-sm-2 mt-2">
                        <input type="radio" v-model="form.status" class="form-check-input mr-3" id="statusActive" value="Active">
                        <label for="statusActive" class="form-check-label">Active</label>
                    </div>
                    <div class="col-sm-2 mt-2">
                        <input type="radio" v-model="form.status" class="form-check-input mr-3" id="statusInactive" value="Inactive">
                        <label for="statusInactive" class="form-check-label">Inactive</label>
                    </div>
                <!-- </div> -->
                </div>

                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-success me-2" :disabled="!isFormValid || loading">
                      <font-awesome-icon :icon="['fas', 'fa-save']" class="me-2" />Submit</button>
                    <button type="button" class="btn btn-light" @click="cancelForm"> <font-awesome-icon :icon="['fas', 'xmark']" /> Cancel</button>
                </div>

            </form>  
        </div> 
        <div v-if="showDeleteModal" class="modal-overlay">
            <div class="modal-box">
                <h5>Confirm Delete</h5>
                <hr/>
                <p>Are you sure you want to delete this partner?</p>
                <div class="modal-buttons">
                    <button @click="cancelDelete" class="btn btn-secondary">Cancel</button>
                    <button @click="confirmDelete" class="btn btn-danger" style="background-color: #dc3545 !important;">Delete</button>
                </div>
            </div>
        </div>  
        <div class="d-flex justify-content-between mt-3" v-if="!isFormVisible">
      <div class="d-flex align-items-center">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredPartners.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination mb-0" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>     
    </div>   
    </div>
</template>  
  
  <script>
  import axios from 'axios';
  import useVuelidate from '@vuelidate/core';
  import { required, email,minValue, maxValue } from '@vuelidate/validators';
  import { API_CONFIG } from '@/apiConfig';
  
  export default {
    data() {
      return {
            form: {
            destinationId: '',
            partnerName: '',
            partnerPhone: '',
            partnerEmail: '',
            commissionPercentage: '',
            status: 'Active'
        },
        errorMessages: {  // ✅ Separate error messages for each field
            partnerName: ''
        },
        // this list should be empty after integrating with backend..
        partners: [],
        isFormVisible: false,
        formTitle: 'Add / Update Sourcing Partner',
        loading : false,
        showDeleteModal: false,
        partnerToDelete: null,
        searchQuery: "", 
        currentPage: 1, 
        itemsPerPage: 10,  
        };
    },
    validations() {
      return {
        form: {
            destinationId: { required },
            partnerName: { required },
            partnerPhone: { required},
            partnerEmail: { required, email },
            commissionPercentage: { required,
                minValue: minValue(0),
                maxValue: maxValue(100),
                // Ensure the value is greater than or equal to 0 }
            }
        }
      };
    },
    // computed: {
    //     totalPages() {
    //         return Math.ceil(this.partners.length / this.pageSize);
    //     },
    //     paginatedPartners() {
    //         const start = (this.currentPage - 1) * this.pageSize;
    //         const end = start + this.pageSize;
    //         return this.partners.slice(start, end);
    //     }
    // },
    setup() {
        //return { v$: useVuelidate() };
        const v$ = useVuelidate();
        return { v$ };

    },
    computed: {
        filteredPartners() {
    return this.partners.filter(partner => {
      const searchQueryLower = this.searchQuery.toLowerCase();
      return (
        partner.destinationName.toLowerCase().includes(searchQueryLower) ||
        partner.partnerName.toLowerCase().includes(searchQueryLower) ||
        partner.partnerEmail.toLowerCase().includes(searchQueryLower) ||
        partner.partnerPhone.toLowerCase().includes(searchQueryLower)
      );
    });
  },
  paginatedUsers() {
    const pageSize = Number(this.itemsPerPage) || 10;
    const currentPage = Number(this.currentPage) || 1;
    
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    
    if (!this.filteredPartners.length) return [];
    
    return this.filteredPartners.slice(startIndex, endIndex);
  },
  
  totalPages() {
    const pageSize = Number(this.itemsPerPage) || 10;
    return Math.max(1, Math.ceil(this.filteredPartners.length / pageSize));
  },
        isFormValid() {
            return (
                this.form.destinationId &&
                this.form.partnerName &&
                this.form.partnerPhone &&
                this.form.partnerEmail &&
                (this.form.commissionPercentage !== '' && this.form.commissionPercentage !== null) &&
                !this.v$.$invalid
            );
        }
    },
    watch: {
  itemsPerPage(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.resetPagination();
    }
  },
  searchQuery() {
    this.resetPagination();
  },
  filteredPartners: {
    handler() {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages || 1;
      }
    },
    immediate: true
  }
},
    methods: {
        resetPagination() {
    this.currentPage = 1;
    
    this.$forceUpdate();
  },
  
  goToPage(page) {
    const pageNum = Number(page);
    if (pageNum >= 1 && pageNum <= this.totalPages) {
      this.currentPage = pageNum;
    }
  },

getPageRange() {
    const range = [];
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    const delta = 2;

    if (currentPage > delta + 1) {
      range.push(1);
      if (currentPage > delta + 2) range.push('...');
    }

    for (let i = Math.max(1, currentPage - delta); i <= Math.min(totalPages, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage < totalPages - delta - 1) {
      if (currentPage < totalPages - delta - 2) range.push('...');
      range.push(totalPages);
    }

    return range;
  },
        clearSearch() {
    this.searchQuery = ""; 
  },
        validatePartnerName() {
            this.form.partnerName = this.form.partnerName.replace(/[^a-zA-Z0-9\s().]/g, ''); // Allow only letters & spaces
            if (!this.form.partnerName.trim()) {
                this.errorMessages.partnerName = "Only letters and spaces allowed.";
            } else {
                this.errorMessages.partnerName = ""; // Clear error if valid
            }
        },
        async submitForm() {
            this.v$.$touch();
            if (this.v$.$invalid) {
                console.log('Form is invalid');
                return;
            }    
            this.loading = true;
            try {

                let response;
                if( this.form.partnerCode) {
                    //edit partner                    
                    response = await axios.put(API_CONFIG.getEndpoint('createPartners') + `/${this.form.partnerCode}`, this.form);
                    console.log('Form updated successfully:', response.data);
                }else {
                    //create partners
                    response = await axios.post(API_CONFIG.getEndpoint('createPartners'), this.form);
                    console.log('Form submitted successfully:', response.data);
                }                
                this.fetchPartners(); // Fetch the updated list after submission
                this.isFormVisible = false; // Hide the form after submission
                this.resetForm();
            } catch (error) {
                console.error('Error submitting form:', error);
            } finally {
                this.loading = false;
            }
        },
        async fetchPartners() {
            this.loading = true;
            try {                
                const resp = await axios.get(API_CONFIG.getEndpoint('fetchPartners'));
                this.partners = resp.data;
                console.log(this.partners); // Check the structure of the partners array
            }catch(error) {
                console.log('Error to fetch partners list', error);
            } finally {
                this.loading = false;
            }
        },
        async fetchDestinations() {
            this.loading = true;
            try {
                const resp = await axios.get(API_CONFIG.getEndpoint('fetchDestinations'));                
                this.destinations = resp.data;
            } catch (error) {
                console.log('Error fetching destinations list', error);
                this.error = 'Failed to fetch destinations list. Please try again.';
            } finally {
                this.loading = false;
            }
        },
        editPartner(partner) {
            this.form = (partner);
        },
        showForm(action, partner = null) {
            if( action === 'create') {
                this.formTitle = 'Add sourcing partner';
                this.resetForm();
            } else if (action === 'edit' && partner) {
                this.formTitle = 'Edit sourcing partner';
                this.form = {...partner };
            }
            this.isFormVisible = true;            
        },
        resetForm() {
            this.form = {
                destinationId : '',
                partnerName : '',
                partnerPhone : '',
                partnerEmail : '',
                commissionPercentage : '',
                status : 'Active'    
            }
        },
        cancelForm() {
           this.isFormVisible = false; 
           this.resetForm();
        },
        deletePartner(partnerId) {
            this.partnerToDelete = partnerId;
            this.showDeleteModal = true;
        },
        async confirmDelete() {
            try {
                await axios.delete(API_CONFIG.getEndpoint('fetchPartners') + `/${this.partnerToDelete}`);
                this.fetchPartners();
            } catch (error) {
                console.error('Error deleting partner:', error);
            } finally {
                this.showDeleteModal = false;
                this.partnerToDelete = null;
            }
        },
        cancelDelete() {
            this.showDeleteModal = false;
            this.partnerToDelete = null;
        },

    },
    mounted() {
        this.fetchPartners();  // Fetch the list when the component is mounted
        this.fetchDestinations();
    }
  }; // end of default 
</script>
  
<style>
    .mr-3 {        
        margin-right: 1rem !important;        
    }
    .container {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    h3, h4 {
        margin-bottom: 20px;
    }

    .table {
        margin-top: 20px;
    }

    .btn-primary {
        margin-bottom: 20px;
    }

    .form-group {
        margin-bottom: 15px;
    }

    .text-danger {
        color: red;
        font-size: 0.875em;
    }
.btn1{
  color: #ffffff;
  background-color: #7978E9;
  border-radius:6px;
}
.btn1:hover{
  background-color: #4B49AC;
}
</style>