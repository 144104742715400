<template>
  <div>
    <HotelForm v-if="currentForm === 'hotel'" @hotelSaved="handleHotelSaved" />
    <RoomForm v-if="currentForm === 'room'" :hotel="selectedHotel" />
  </div>
</template>

<script>
import { ref } from 'vue';
import HotelForm from './HotelForm.vue';
import RoomForm from './RoomForm.vue';

export default {
  components: {
    HotelForm,
    RoomForm
  },
  setup() {
    const currentForm = ref('hotel');
    const selectedHotel = ref(null);

    const handleHotelSaved = (hotel) => {
      selectedHotel.value = hotel;
      currentForm.value = 'room';
    };

    return {
      currentForm,
      selectedHotel,
      handleHotelSaved
    };
  }
};
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
