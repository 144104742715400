<template>
    <div class="container my-5">
      <h4>Add Room Details for Hotel {{ hotelCode }}</h4>
      <h4>Room Form</h4>
      <hr/>
      <form @submit.prevent="submitRoomForm">
        <div class="form-group">
          <label for="roomType">Room Type <span style="color:red !important">*</span></label>
          <input type="text" v-model="room.roomType" class="form-control" id="roomType" required>
        </div>
        <div class="form-group">
          <label for="currency">Currency</label>
          <input type="text" v-model="room.currency" class="form-control" id="currency">
        </div>
        <div class="form-group">
          <label for="partner">Partner</label>
          <input type="text" v-model="room.partner" class="form-control" id="partner">
        </div>
        <div class="form-group">
          <label for="actualPrice">Actual Price <span style="color:red !important">*</span></label>
          <input type="number" v-model="room.actualPrice" class="form-control" id="actualPrice" required>
        </div>
        <div class="form-group">
          <label for="companyMarkupType">Company Markup Type <span style="color:red !important">*</span></label>
          <select v-model="room.companyMarkupType" class="form-control" id="companyMarkupType" required>
            <option value="Percentage">Percentage</option>
            <option value="Absolute">Absolute</option>
          </select>
        </div>
        <div class="form-group">
          <label for="companyMarkup">Company Markup <span style="color:red !important">*</span></label>
          <input type="number" v-model="room.companyMarkup" class="form-control" id="companyMarkup" required>
        </div>
        <div class="form-group">
          <label for="roomSalePrice">Room Sale Price <span style="color:red !important">*</span></label>
          <input type="number" v-model="room.roomSalePrice" class="form-control" id="roomSalePrice" required>
        </div>
        <div class="form-group">
          <label for="imageUrl">Image URL</label>
          <input type="text" v-model="room.imageUrl" class="form-control" id="imageUrl">
        </div>
        <div class="form-group">
          <label for="status">Status</label>
          <select v-model="room.status" class="form-control" id="status">
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
        </div>
        <button type="submit" class="btn btn-primary"><font-awesome-icon :icon="['fas', 'floppy-disk']" /> Save Room</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  //import { reactive, toRefs } from 'vue';
  
  export default {

    props: ['hotelCode'],
    
    
    data() {
      return {
        room: {
          roomType: '',
          currency: '',
          partner: '',
          actualPrice: '',
          companyMarkupType: '',
          companyMarkup: '',
          roomSalePrice: '',
          imageUrl: '',
          status: '',
          hotel: {
            code: this.hotelCode
          }
        }
      };
    },
    methods: {
      async submitRoomForm() {
        try {
          const response = await axios.post('http://localhost:9092/api/rooms', this.room);
          console.log('Room saved successfully:', response.data);
        } catch (error) {
          console.error('Error saving room:', error);
        }
      }
    }
  };
  </script>
  
  <style>
  /* Add any custom styles here */
  </style>