import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTachometerAlt, faUsers, faCogs, faBoxOpen } from '@fortawesome/free-solid-svg-icons';
library.add(faTachometerAlt, faUsers, faCogs, faBoxOpen);
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon); // Register FontAwesomeIcon globally
app.use(router);
app.mount('#app');


// import store from './store'; // If using Vuex 
// import Vuetify from 'vuetify'; // If using Vuetify 
// import 'vuetify/dist/vuetify.min.css';

// const app = createApp(App); 
// app.use(router); 
// app.use(store); // If using Vuex 
// app.use(Vuetify); // If using Vuetify
// app.mount('#app');
