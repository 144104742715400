<template>
    <div class="container">
        <div class="card-header" v-if="!isFormVisible">
        <div class="d-flex justify-content-between align-items-center" >
            <h4 style="margin-bottom:0px">Manage Airports </h4>
            <div style="display: flex !important; gap: 20px !important">
                <div v-if="!isFormVisible">
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div>  
  <button @click="showForm('create')" class="btn1"> <font-awesome-icon :icon="['fas', 'fa-plus']" class="me-2" />New Airport</button>
            </div>
        </div>
        </div>
       <!-- <hr> -->
        <div v-if="submitErrorMessage" class="alert alert-danger mt-3">{{ submitErrorMessage }}</div>
        <div v-if="!isFormVisible" class="table-container">
           <!-- <h4> Airports </h4> -->
            <table class="table table-bordered mt-3">
                <thead>
                    <tr>
                        <th>SI.No</th>
                        <th>Destination Name</th>
                        <th>City Name</th>
                        <th>Airport Name</th>                    
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(airport, index) in paginatedUsers" :key = "airport.id">
                        <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>
                        <td>{{ airport.destinationName }}</td>
                        <td>{{ airport.cityName }}</td>
                        <td>{{ airport.airportName }}</td>
                        <td>{{ airport.status }}</td>
                        <td style="display: flex;">
                            <button @click="showForm('edit',airport)" class="btn-sm mr-3 faq-button"><font-awesome-icon :icon="['fas', 'fa-edit']" class="edit" /><span class="tooltip">Edit</span></button>                        
                            <button @click="confirmDelete(airport.id)" class="btn-sm faq-button">
                               <font-awesome-icon :icon="['fas', 'fa-trash']" class="delete" /><span class="tooltip">Delete</span>
                            </button>                             
                       </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            <h5>{{ formTitle }}</h5>
            <hr/>
            <form @submit.prevent="submitForm">
                
                <div class="form-group row mb-3">
                    <label for="destination" class="col-sm-3 col-form-label">Select Destination <span style="color:red !important">*</span></label>                    
                    <div class="col-sm-6">
                        <select v-model="form.destinationId" @change="fetchCitiesByDestination" class="form-control" id="destination" required  :disabled="form.airportName.trim().length > 0">
                            <option disabled value="">--Please select a destination--</option>
                            <option v-for="destination in destinations" :key="destination.id" :value="destination.id">
                                {{ destination.destinationName }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group row mb-3">
                    <label for="city" class="col-sm-3 col-form-label">Select City <span style="color:red !important">*</span></label>                    
                    <div class="col-sm-6">
                        <select v-model="form.cityId" class="form-control" id="city" required :disabled="form.airportName.trim().length > 0">
                            <option disabled value="">-- Please select a city --</option>
                            <option v-for="city in cities" :key="city.id" :value="city.id">
                                {{ city.cityName }}
                            </option>
                        </select>
                        <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span>
                    </div>                    
                </div>

                <div class="form-group row mb-3">
                    <label for="airportName" class="col-sm-3 col-form-label">Airport Name <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <input type="text" v-model="form.airportName" class="form-control" id="airportName" required @input="validateInput" :class="{'is-invalid': validationMsg.airportName}">
                        <div v-if="validationMsg.airportName" class="invalid-feedback">{{ validationMsg.airportName }}</div>
                    </div>
                </div>
                
                <div class="form-group row mb-3 d-flex align-items-center">
                    <label for="status" class="col-sm-3 col-form-label">Status</label>                
                    <div class="col-sm-2">
                        <input type="radio" v-model="form.status" class="form-check-input mr-3" id="statusActive" value="Active">
                        <label for="statusActive" class="form-check-label">Active</label>
                    </div>
                    <div class="col-sm-2">
                        <input type="radio" v-model="form.status" class="form-check-input mr-3" id="statusInactive" value="Inactive">
                        <label for="statusInactive" class="form-check-label">Inactive</label>
                    </div>                
                </div>

                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-success me-2" :disabled="!isFormValid || loading"><span v-if="loading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span><font-awesome-icon :icon="['fas', 'fa-save']" class="me-2" />{{ loading ? 'Submitting...' : 'Submit' }}</button> 
                     <!-- :disabled="!isFormValid || loading" -->
                    <button type="button" class="btn btn-light" @click="cancelForm"> <font-awesome-icon :icon="['fas', 'xmark']" /> Cancel</button>
                </div>

            </form> 
        </div>
        <div v-if="showDeleteModal" class="modal-overlay">
            <div class="modal-box">
        <h5>Confirm Delete</h5>
        <hr/>
        <p>Are you sure you want to delete this airport?</p>
        <div class="modal-buttons">
            <button @click="cancelDelete" class="btn btn-light">Cancel</button>
          <button @click="deleteAirport" class="btn btn-danger" style="background-color: #dc3545 !important;">Delete</button>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between mt-3" v-if="!isFormVisible">
      <div class="d-flex align-items-center mt-0">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredAirports.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
    <ul class="pagination mb-0">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
                <span aria-hidden="true">&laquo; Previous</span>
            </a>
        </li>
        <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
                <span aria-hidden="true">Next &raquo;</span>
            </a>
        </li>
    </ul>
</nav>
    </div>   
    </div>
</template>

<script>

    import axios from 'axios';
    //import useVuelidate from '@vuelidate/core';
    //import { required } from '@vuelidate/validators';
    import { API_CONFIG } from '@/apiConfig';

    export default {
        data() {
            return {
                form : {
                    destinationId: "", 
                    cityName : "", 
                    cityId: "",
                    airportName: "", 
                    status: 'Active'
                },
                validationMsg: { 
                    airportName: ''
                },
                airports: [],
                cities:[],
                errorMessage: "",
                submitErrorMessage : "",
                destinations: [],
                isFormVisible: false,
                formTitle: 'Add / Update Airport details',
                loading : false,
                showDeleteModal: false,
                airportToDelete: null,
                searchQuery: "", 
                currentPage: 1, 
                itemsPerPage: 10, 
            };            
        },
        computed: {
            filteredAirports() {
    return this.airports.filter(airport => {
      const searchQueryLower = this.searchQuery.toLowerCase();
      return (
        airport.destinationName.toLowerCase().includes(searchQueryLower) ||
        airport.cityName.toLowerCase().includes(searchQueryLower) ||
        airport.airportName.toLowerCase().includes(searchQueryLower) 
      );
    });
  },
  paginatedUsers() {
    const pageSize = Number(this.itemsPerPage) || 10;
    const currentPage = Number(this.currentPage) || 1;
    
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    
    if (!this.filteredAirports.length) return [];
    
    return this.filteredAirports.slice(startIndex, endIndex);
  },
  
  totalPages() {
    const pageSize = Number(this.itemsPerPage) || 10;
    return Math.max(1, Math.ceil(this.filteredAirports.length / pageSize));
  },
            isFormValid() {
                return this.form.destinationId !== "" && this.form.cityId !== "" &&
                this.form.airportName !== "" && this.form.status !== "";
            }
        },
        watch: {
  itemsPerPage(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.resetPagination();
    }
  },
  searchQuery() {
    this.resetPagination();
  },
  filteredCities: {
    handler() {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages || 1;
      }
    },
    immediate: true
  }
},
        methods : {
            resetPagination() {
    this.currentPage = 1;
    
    this.$forceUpdate();
  },
  
  goToPage(page) {
    const pageNum = Number(page);
    if (pageNum >= 1 && pageNum <= this.totalPages) {
      this.currentPage = pageNum;
    }
  },

getPageRange() {
    const range = [];
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    const delta = 2;

    if (currentPage > delta + 1) {
      range.push(1);
      if (currentPage > delta + 2) range.push('...');
    }

    for (let i = Math.max(1, currentPage - delta); i <= Math.min(totalPages, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage < totalPages - delta - 1) {
      if (currentPage < totalPages - delta - 2) range.push('...');
      range.push(totalPages);
    }

    return range;
  },
            clearSearch() {
    this.searchQuery = ""; 
  },
            validateInput() {
                this.form.airportName = this.form.airportName.replace(/[^a-zA-Z\s0-9()]/g, '');
                if (!this.form.airportName.trim()) {
                    this.validationMsg.airportName = "Only letters, numbers & spaces allowed.";
                } else {
                    this.validationMsg.airportName = ""; 
                }
            },
            async submitForm() {
                this.loading = true;
                try {
                    let response;
                    if( this.form.id) {
                        //edit city                    
                        response = await axios.put(API_CONFIG.getEndpoint('createAirports') + `/${this.form.id}`, this.form);
                        console.log('Form updated successfully:', response.data);
                    }else {
                        //create cities
                        response = await axios.post(API_CONFIG.getEndpoint('createAirports'), this.form);
                        console.log('Form submitted successfully:', response.data);
                    }
                    this.fetchAirports(); // Fetch the updated list after submission
                    this.isFormVisible = false; // Hide the form after submission
                    this.resetForm();
                } catch (error) {
                    console.error('Error submitting form:', error);
                    this.submitErrorMessage = error.response.data; // 'Failed to submit form. Please try again.';
                } finally {
                    this.loading = false;
                }

            },
            async fetchDestinations() {
                //this.loading = true;
                try {
                    const resp = await axios.get(API_CONFIG.getEndpoint('fetchDestinations'));                
                    this.destinations = resp.data;
                } catch (error) {
                    console.log('Error fetching destinations list', error);
                    this.error = 'Failed to fetch destinations list. Please try again.';
                } finally {
                    //this.loading = false;
                }
            },
            async fetchAirports() {
                try {
                    const resp = await axios.get(API_CONFIG.getEndpoint('fetchAirports'));
                    this.airports = resp.data;
                }catch (error) {
                    console.error("Error fetching airports list",error);
                    this.error = "Failed to fetch airports list. Please try again";
                }
            },
            async fetchCitiesByDestination() {                
                try {
                    const response = await axios.get(API_CONFIG.getEndpoint('citiesByDestination')  + `/${this.form.destinationId}`);                     
                    this.cities =  response.data;
                    if (this.cities.length === 0) {
                        this.errorMessage = "No cities available for the selected destination.";
                    } else {
                        this.errorMessage = "";
                    }
                } catch (error) {
                    console.log("Error fetching cities list ", error);
                    this.error = "Failed to fetch cities list. Please try again !!";
                }finally {
                    //this.loading = false;
                }                
            },
            confirmDelete(id) {
              this.airportToDelete = id;
              this.showDeleteModal = true;
            },
            async deleteAirport() {
              if (!this.airportToDelete) return;
              alert("Delete is not allowed in this version.");
              // try {
              //   await axios.delete(API_CONFIG.getEndpoint("fetchAirports") + `/${this.airportToDelete}`);
              //   this.airports = this.airports.filter(airport => airport.id !== this.airportToDelete);
              //   this.fetchAirports();
              // } catch (error) {
              //   console.error("Unable to delete airport", error);
              // } finally {
              //   this.showDeleteModal = false;
              //   this.airportToDelete = null;
              // }
            },
            cancelDelete() {
              this.showDeleteModal = false;
              this.airportToDelete = null;
            },
            showForm(action, airport = null) {
                if( action === 'create') {
                    this.formTitle = 'Add Airport details';
                    this.resetForm();
                    this.submitErrorMessage = "";
                } else if (action === 'edit' && airport) {
                    this.formTitle = 'Edit Airport details';
                    this.submitErrorMessage = "";
                    this.form = {...airport };
                    this.cities = this.fetchCitiesByDestination(this.form.destination);
                }
                this.isFormVisible = true;            
            },
            resetForm() {
                this.form = {
                    destinationId: "", 
                    cityName : "", 
                    cityId: "",
                    airportName: "", 
                    status: 'Active'
                }
            },
            cancelForm() {
                this.isFormVisible = false; 
                this.resetForm();
            }
        },
        mounted() {
            this.fetchAirports();
            this.fetchDestinations();            
        }
    };

</script>

<style scoped>
    .mr-3 {        
        margin-right: 1rem !important;        
    }
    .container {
        margin-top: 20px;
    }

    h3, h4 {
        margin-bottom: 20px;
    }

    .table {
        margin-top: 20px;
    }

    .btn-primary {
        margin-bottom: 20px;
    }

    .form-group {
        margin-bottom: 15px;
    }

    .text-danger {
        color: red;
        font-size: 0.875em;
    }
    
.btn1{
  color: #ffffff;
  background-color: #7978E9;
  border-radius:6px;
   border: none !important;
}
.btn1:hover{
  background-color: #4B49AC;
}
</style>
