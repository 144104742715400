<template>
    <div class="container mb-5">
        <div class="card-header" v-if="!isFormVisible">
        <div class="d-flex justify-content-between align-items-center" >
            <h4>Manage Bookings</h4>
            <div class="d-flex justify-content-end" v-if="!isFormVisible">
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div>        
        </div> 
        </div>  
        <div v-if="!isFormVisible" class="table-container" >     
        <table class="table table-bordered mt-3">
            <thead>
                <tr>
                    <th>SI.No</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Contact No</th>
                    <th>Package</th>
                    <th>Package Length</th>
                    <th>Date of Booking</th>                    
                    <th>Total Amount</th>
                    <th>Start Trip</th>
                    <th>End Trip</th>
                    <th>Passengers</th>
                    <th>Payments</th>
                    <th>Installment Link</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(booking, index) in paginatedUsers" :key="booking.id">
                    <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>
                    <td class="text-nowrap">{{ booking.packageInfo.customerName }}</td>    
            <td class="text-nowrap">{{ booking.packageInfo.email }}</td>
            <td>{{ booking.packageInfo.mobileNo }}</td>
            <td class="text-nowrap">{{ booking.packageInfo.packageTitle }}</td>
            <td class="text-nowrap">{{ `${booking.packageInfo.noOfDays} days and ${booking.packageInfo.noOfNights} nights` }}</td>
            <td>{{ booking.packageInfo.bookingDate }}</td>   
            <td>{{ booking.totalAmount }}</td>   
            <td class="text-nowrap" >{{ booking.packageInfo.packageStartDate }}</td>
                    <td>{{ booking.packageInfo.packageEndDate }}</td>   
                    <td>
                        <button class="btn btn-primary" @click="showPassengerInfo(booking)" style="    margin-left: 17px;
    margin-top: 1px;">Info</button>
                    </td>  

                    <td class="d-flex justify-content-center">
                        <button class="btn btn-primary" @click="goToInstallmentInfo(booking.id)">Installment Info</button>
                    </td>    
                    <td>
                        <button class="btn btn-primary">Copy Link</button>
                    </td>        
                </tr>
            </tbody>
        </table>
        </div>

        <div v-if="showDeleteModal" class="modal-overlay">
      <div class="modal-box">
        <h5>Confirm Delete</h5>
        <hr/>
        <p>Are you sure you want to delete this city?</p>
        <div class="modal-buttons">
            <button @click="cancelDelete" class="btn btn-light">Cancel</button>
          <button @click="deleteBookings" class="btn btn-danger" style="background-color: #dc3545 !important;">Delete</button>
        </div>
      </div>
    </div>  
    
    <div class="d-flex justify-content-between mt-3" v-if="!isFormVisible">
      <div class="d-flex align-items-center mt-0">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredBookings.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
    <ul class="pagination mb-0">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
                <span aria-hidden="true">&laquo; Previous</span>
            </a>
        </li>
        <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
                <span aria-hidden="true">Next &raquo;</span>
            </a>
        </li>
    </ul>
</nav>
    </div> 
    <div v-if="showPassengerInfoModal" class="modal-overlay">
          <div class="modal-box">
            <h4>List of Passengers</h4>
            <hr />
            <table class="table table-bordered">
              <thead>
                <tr>
                    <th>SI.No</th>
                    <th>Name</th>
                    <th> Age</th>
                    <th>Passport No</th>
                    <th>Issue Date</th>
                    <th>Expiry Date</th>
                    <th>Issue City</th>
                    <th>Issue Country</th>
                    <th>Food Type</th> 
                </tr>
              </thead>
              <tbody>
                <tr v-for="(passenger, index) in passengerInfo" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ passenger.name }}</td>
                  <td>{{ passenger.age }}</td>    
                   <td>{{ passenger.passportNo }}</td>
                   <td>{{ passenger.issueDate }}</td>
                   <td>{{ passenger.expiryDate }}</td>
                   <td>{{ passenger.issueCity }}</td>
                   <td>{{ passenger.issueCountry }}</td>
                   <td>{{ passenger.foodType }}</td>
                </tr>
              </tbody>
            </table>
            <div class="modal-buttons">
                <button class="close-button" @click="closePassengerInfoModal">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
      </svg>
    </button>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { API_CONFIG } from '@/apiConfig';

    export default {
    data() {
        return {
            bookings:[],
            loading : false,
            isFormVisible: false,
            searchQuery: "", 
            currentPage: 1, 
            itemsPerPage: 10, 
            showDeleteModal: false,
            bookingToDelete: null, 
            passengerInfo: [],
            showPassengerInfoModal: false,  
        };
    },
    computed: {
        filteredBookings() {
    if (!this.searchQuery) return this.bookings;
    
    const searchQueryLower = this.searchQuery.toLowerCase();
    return this.bookings.filter(booking => {
      return (
        (booking.packageInfo.customerName || '').toLowerCase().includes(searchQueryLower) ||
        (booking.packageInfo.email || '').toLowerCase().includes(searchQueryLower) ||
        (booking.packageInfo.mobileNo || '').toLowerCase().includes(searchQueryLower) ||
        (booking.packageInfo.packageTitle || '').toLowerCase().includes(searchQueryLower)
      );
    });
  },
  
  paginatedUsers() {
    const pageSize = Number(this.itemsPerPage) || 10;
    const currentPage = Number(this.currentPage) || 1;
    
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    
    if (!this.filteredBookings.length) return [];
    
    return this.filteredBookings.slice(startIndex, endIndex);
  },
  
  totalPages() {
    const pageSize = Number(this.itemsPerPage) || 10;
    return Math.max(1, Math.ceil(this.filteredBookings.length / pageSize));
  },
    },
    watch: {
  itemsPerPage(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.resetPagination();
    }
  },
  searchQuery() {
    this.resetPagination();
  },
  filteredCities: {
    handler() {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages || 1;
      }
    },
    immediate: true
  }
},
    methods: {
        resetPagination() {
    this.currentPage = 1;
    
    this.$forceUpdate();
  },
  
  goToPage(page) {
    const pageNum = Number(page);
    if (pageNum >= 1 && pageNum <= this.totalPages) {
      this.currentPage = pageNum;
    }
  },

getPageRange() {
    const range = [];
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    const delta = 2;

    if (currentPage > delta + 1) {
      range.push(1);
      if (currentPage > delta + 2) range.push('...');
    }

    for (let i = Math.max(1, currentPage - delta); i <= Math.min(totalPages, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage < totalPages - delta - 1) {
      if (currentPage < totalPages - delta - 2) range.push('...');
      range.push(totalPages);
    }

    return range;
  },
        clearSearch() {
    this.searchQuery = ""; 
  },     
  async fetchBookings() {
      this.loading = true;
      try {
        const response = await axios.get(
          API_CONFIG.getEndpoint("fetchBookings")
        );
        console.log(response.data);
        this.bookings = response.data;
      } catch (error) {
        console.log("Error fetching bookings", error);
      } finally {
        this.loading = false;
      }
    },
    confirmDelete(id) {
      this.bookingToDelete = id;
      this.showDeleteModal = true;
    },
    async deleteBookings() {
      if (!this.cityToDelete) return;
      this.loading = true;
      try {
        await axios.delete(API_CONFIG.getEndpoint('fetchBookings') + `/${this.bookingToDelete}`);
        this.bookings = this.bookings.filter(booking => booking.id !== this.bookingToDelete);
        this.fetchBookings();
      } catch (error) {
        console.error('Error deleting city:', error);
      } finally {
        this.showDeleteModal = false;
        this.bookingToDelete = null;
        this.loading = false;
      }
    },
    cancelDelete() {
      this.showDeleteModal = false;
      this.bookingToDelete = null;
    },
    closePassengerInfoModal() {
            this.showPassengerInfoModal = false;
            this.passengerInfo = []; 
        },
        showPassengerInfo(booking) {
    this.passengerInfo = booking.passengersInfo || [];
    if (this.passengerInfo.length === 0) {
        this.passengerInfo = [{
            name: 'No passengers available.',
            age: '',
            passportNo: '',
            issueDate: '',
            expiryDate: '',
            issueCity: '',
            issueCountry: '',
            foodType: ''
        }];
    }
    this.showPassengerInfoModal = true;
},

goToInstallmentInfo(id) {
        this.$router.push({ name: 'installmentpayments', params: { id: id } });
    },
    },
    mounted() {
        this.fetchBookings();  
    }
  }; 

</script>
<style scoped>
.modal-box {
  width: 80%;
  max-width: 900px;
  height: 80%;
  max-height: 600px;
  overflow-y: auto;
  padding: 20px;
  overflow-x: auto; 
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative; 
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.close-button svg {
  width: 20px;
  height: 20px;
  stroke: #333;
}

.modal-buttons {
  margin-top: 20px;
  text-align: right;
}

</style>