<template>
  <div class="container my-5">    
     <h4> Create Stitchable Template </h4>
     <hr/>
    <div>

      <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>   

      <div class="form-group row mb-3">
        <label for="itineraryType" class="col-sm-3 col-form-label">Itinerary Type<span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <select class="form-control" id="itineraryType" v-model="templateInfo.itineraryType">
            <option disabled value="">Select Itinerary Type</option>
            <option value="Quotation">Quotation</option>
            <option value="Final">Final</option>
          </select>
          <span class="text-danger" v-if="v$.templateInfo.itineraryType.$error">Itinerary Type must be selected </span>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="customerName" class="col-sm-3 col-form-label">Customer Name <span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <input type="text" class="form-control" id="customerName" v-model="templateInfo.customerName" placeholder="Customer Name">
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="email" class="col-sm-3 col-form-label">Email <span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <input type="email" class="form-control" id="email" v-model="templateInfo.email" placeholder="Email">
          <span class="text-danger" v-if="v$.templateInfo.email.$error">Email is required </span>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="mobileNo" class="col-sm-3 col-form-label">Mobile Number <span style="color:red !important">*</span></label>
        <div class="col-sm-6">          
          <input type="tel" v-model="v$.templateInfo.mobileNo.$model" class="form-control" id="mobileNo" placeholder="Mobile No" required>        
          <div class="text-danger" v-for="(error, index) in v$.templateInfo.mobileNo.$errors" :key="index">
              <div> {{ error.$message }}</div>
          </div>

        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="altContactNo" class="col-sm-3 col-form-label">Alternate Contact Number</label>
        <div class="col-sm-6">
          <input type="tel" class="form-control" id="altContactNo" v-model="templateInfo.altContactNo" placeholder="Alt Contact No">          
          <div class="text-danger" v-for="(error, index) in v$.templateInfo.altContactNo.$errors" :key="index">
              <div> {{ error.$message }}</div>
          </div>

        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="noOfAdults" class="col-sm-3 col-form-label">No. of Adults <span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <input type="number" class="form-control" id="noOfAdults" v-model="templateInfo.noOfAdults" placeholder="No. of Adults">
          <span class="text-danger" v-if="v$.templateInfo.noOfAdults.$error">This value is required </span>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="noOfChildren" class="col-sm-3 col-form-label">No. of Children</label>
        <div class="col-sm-6">
          <input type="number" class="form-control" id="noOfChildren" v-model="templateInfo.noOfChildren" placeholder="No. of Children">
        </div>
      </div>
      
      <div class="form-group row mb-3">
        <label for="packageTitle" class="col-sm-3 col-form-label">Package Title <span style="color:red !important">*</span></label>

        <div class="col-sm-6">
          <input type="text" class="form-control" id="packageTitle" v-model="templateInfo.packageTitle" placeholder="Package Title">
          <span class="text-danger" v-if="v$.templateInfo.packageTitle.$error">This value is required </span>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="description" class="col-sm-3 col-form-label">Description</label>
        <div class="col-sm-6">
          <textarea class="form-control" id="description" v-model="templateInfo.description" placeholder="Description"></textarea>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="destinationId" class="col-sm-3 col-form-label">Destination<span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <select v-model="templateInfo.destinationId"  class="form-control" id="destination" required>
              <option disabled value="">--Please select a destination--</option>
              <option v-for="destination in destinations" :key="destination.id" :value="destination.id">
                  {{ destination.destinationName }}
              </option>
          </select>   
          <span class="text-danger" v-if="v$.templateInfo.destinationId.$error">This value is required </span>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="packageStartDate" class="col-sm-3 col-form-label">Package Start Date<span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <input type="date" class="form-control" id="packageStartDate" v-model="templateInfo.packageStartDate" placeholder="Start Date" :min="minDate">
          <span class="text-danger" v-if="v$.templateInfo.packageStartDate.$error">This value is required </span>
        </div>
      </div>

      <div class="form-group row mb-3 d-flex align-items-center">
        <label for="priceOnWebsite" class="col-sm-3 col-form-label">Price on Website</label>
        <div class="col-sm-2 d-flex align-items-center mt-2">
          <input type="radio" v-model="templateInfo.priceOnWebsite" class="form-check-input m-0 me-2" id="statusActive" value="price_per_person">
          <label for="statusActive" class="form-check-label m-0 text-nowrap">Price Per Person</label>
        </div>
        <div class="col-sm-2 d-flex align-items-center mt-2">
          <input type="radio" v-model="templateInfo.priceOnWebsite" class="form-check-input m-0 me-2" id="statusActive" value="total_price">
          <label for="statusActive" class="form-check-label m-0">Total Price</label>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="status" class="col-sm-3 col-form-label">Status</label>                
        <div class="col-sm-2 mt-2">
          <input type="radio" v-model="templateInfo.status" class="form-check-input" id="statusActive" value="Active">
          <label for="statusActive" class="form-check-label ml-3">Active</label>
        </div>
        <div class="col-sm-2 mt-2">
          <input type="radio" v-model="templateInfo.status" class="form-check-input" id="statusInactive" value="Inactive">
          <label for="statusInactive" class="form-check-label ml-3">Inactive</label>
        </div>
      </div>
      
        <template v-for="(trip, index) in templateInfo.templatePackageItems" :key="index">
          <div class="card card-body mb-3">
          <div class="form-group row mb-3">
            <label :for="'itinerary' + '-' + index" class="col-sm-3 col-form-label">Select Template Trip <span style="color:red !important">*</span></label>
            <div class="col-sm-6">
                <select v-model="trip.packageInfo" class="form-control" :id="'itinerary' + '-' + index" @change="onChangeTemplateTrip(trip)">
                  <option disabled value="">-- Select Template Trip --</option>
                  <option v-for="template in templates.filter(t => t.status === 'Active')" :key="template.packageCode" :value="template">
                      {{ template.packageTitle }}
                  </option>
                </select>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label :for="'excursion' + '-' + index" class="col-sm-3 col-form-label">Select Tour to transfer <span style="color:red !important">*</span></label>
            <div class="col-sm-6">
                <select v-model="trip.excursionType" class="form-control" :id="'excursion' + '-' + index" @change="onChangeByExcursionType(trip,templateInfo)">
                  <option disabled value="">-- Select excursion Type --</option>
                  <option v-for="exc_type in excursionTypes" :key="exc_type.value" :value="exc_type.value">
                      {{ exc_type.name }}
                  </option>
                </select>                               
            </div>
            <div v-if="trip.excursionType === 'intercity'" class="mt-3">
              <div class="form-group row mb-3">
                  <label :for="'intercity' +'-'+ index" class="col-sm-3 col-form-label">Select Transfer Type <span style="color:red !important">*</span></label>
                  <div class="col-sm-6">
                    <select v-model="trip.excursion" class="form-control" :id="'intercity' +'-'+ index" @change="() => on_change_by_selected_excursion(trip)">
                      <option value="">-- Select excursion Type --</option>                                            
                      <option v-for="excursionItem  in trip.excursions" :key="excursionItem.id" :value="excursionItem">
                          {{ excursionItem.titleDescriptor }} 
                      </option>  
                    </select>
                  </div>                    
              </div>
              <div v-if="trip.excursion.transportDetails && trip.excursion.transportDetails.length > 0">
                <div class="form-group row mb-3">
                  <label :for="'intercity-price' +'-'+ index" class="col-sm-3 col-form-label">Price </label>
                    <div class="col-sm-6">
                      <input type="number" step="0.01" min="0" v-model="trip.price" class="form-control" :id="'intercity-price' +'-'+ index" placeholder="Enter the price">
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label :for="'intercity-desc' +'-'+ index"  class="col-sm-3 col-form-label">Description</label>
                    <div class="col-sm-6">
                      <textarea type="text" class="form-control" :id="'intercity-desc' +'-'+ index" v-model="trip.description" placeholder="Enter description" rows="5"></textarea>
                    </div>
                </div>
              </div>
            </div>

            </div>
            <div v-if="trip.excursionType === 'airport'">
              <div class="form-group row mb-3">
                  <label :for="'intercity' +'-'+ index" class="col-sm-3 col-form-label">Select Flight <span style="color:red !important">*</span></label>
                  <div class="col-sm-6">
                    <select v-model="trip.excursion" class="form-control" :id="'intercity' +'-'+ index" @change="() => on_change_by_selected_excursion(trip)">
                      <option value="">-- Select excursion Type --</option>                                            
                      <option v-for="excursionItem  in trip.excursions" :key="excursionItem.id" :value="excursionItem">
                          {{ excursionItem.titleDescriptor }} 
                      </option>  
                    </select>
                  </div>                    
              </div>
              <div v-if="trip.excursion.transportDetails && trip.excursion.transportDetails.length > 0">
                <div class="form-group row mb-3">
                  <label :for="'intercity-price' +'-'+ index" class="col-sm-3 col-form-label">Ticket Price </label>
                    <div class="col-sm-6">
                      <input type="number" step="0.01" min="0" v-model="trip.price" class="form-control" :id="'intercity-price' +'-'+ index" placeholder="Enter the price"><span class="tooltip">Ticket in local currency</span>
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label :for="'intercity-desc' +'-'+ index"  class="col-sm-3 col-form-label">Description</label>
                    <div class="col-sm-6">
                      <textarea type="text" class="form-control" :id="'intercity-desc' +'-'+ index" v-model="trip.description" placeholder="Enter description" rows="5"></textarea>
                    </div>
                </div>
              </div>            
            </div>
            <div class="justify-content-end">
            <button v-if="templateInfo.templatePackageItems.length > 1" 
            type="button" 
            class="btn btn-primary mt-3 custom-btn align-content-end" 
            style="background-color: #dc3545 !important;"
            @click="openDeleteModal(index)">Delete</button></div>
          </div>
        </template>
        <button type="button" class="btn btn-primary" @click="addTrip">Add More Trip</button>
      
      <hr>
      <div class="form-actions">
        <button type="submit" class="btn btn-success" @click="savePackages">
          <font-awesome-icon :icon="['fas', 'fa-save']" class="me-2" /> Save </button>        
        <button type="button" class="ml-3 btn btn-light" @click="cancel"> <font-awesome-icon :icon="['fas', 'xmark']" /> Cancel</button>
      </div>

    </div>
    <DeleteModal
      :visible="showDeleteModal"
      @confirmDelete="confirmDelete"
      @cancelDelete="showDeleteModal = false"
    />
  </div>
</template>

<script>  
import { API_CONFIG } from '@/apiConfig';
import axios from 'axios';
import { required, email, numeric, minLength, maxLength } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import DeleteModal from "./DeleteModal.vue";

export default {
  components: {
    DeleteModal,
  },
  data() {
    return {
      templateInfo: {
        itineraryType: '',
        customerName: '',
        email: '',
        mobileNo: '',
        altContactNo: '',
        noOfAdults: 1,
        noOfChildren: 0,
        packageTitle: '',
        description: '',        
        destinationId: '',
        packageStartDate: '',
        priceOnWebsite: 'price_per_person',
        packageType: 'template',        
        status: 'Active',
        templatePackageItems : [{
          packageInfo: {},
          excursionType: '',
          excursion: '',
          excursions : [],
          excursionId: '',
          price: '',
          description:'',
          packageCode:''    
        }]
      },
      errorMessage: '',
      destinations : [],
      exchangeRates : [],
      types: [],      
      excursionTypes: [
        // { name: 'Hotel', value: 'hotel' },
        { name: 'Intercity', value: 'intercity' },
        { name: 'Airport Transfer', value: 'airport' }         
      ],
      templates: [],
      error: null, 
      dIndex: 0,
      showDeleteModal: false,
    };
  },
  created(){
    const pkgCode = this.$route.params.id;
    if(pkgCode) {      
      this.fetchPackageByCode(pkgCode);
    }
  },
  validations() {
    return {
      templateInfo: {
        itineraryType : { required },
        destinationId : { required },
        email : { required, email },
        mobileNo :  { required, minLength: minLength(10), maxLength:maxLength(15) },        
        altContactNo : {  minLength: minLength(10), maxLength:maxLength(15) },
        noOfAdults : { required, numeric},
        packageTitle :{ required },
        packageStartDate : { required}
      }
    }
  },

  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },

  computed: { 
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, '0');
      const day = today.getDate().toString().padStart(2, '0');  
      return `${year}-${month}-${day}`;
    },
  },
  watch: {
    'templateInfo.destinationId': function(newVal) {
      if (newVal) {        
        this.fetchAllTemplates(this.templateInfo.destinationId);
        this.templateInfo.templatePackageItems = [{
          packageInfo: '',
          excursionType: '',
          excursion: '',
          excursionId: '',
          price : '',
          description: '',
          packageCode:''
        }];
      }
    }
  },
  methods: {
    openDeleteModal(index) {
      this.deleteIndex = index; 
      this.showDeleteModal = true; 
    },
    confirmDelete() {
      if (this.deleteIndex !== null) {
        if (this.templateInfo.templatePackageItems.length > 1) {
          this.templateInfo.templatePackageItems.splice(this.deleteIndex, 1);
          this.showDeleteModal = false; 
        } else {
          alert("At least one template must remain.");
        }
      }
    },
    cancelDelete() {
      this.showDeleteModal = false;
    },
    onChangeByExcursionType(trip, templateInfo) {
      console.log('excursionType', trip.excursionType + ' destinationId ' + templateInfo.destinationId  );
      let firstCity = null;
      if (trip.packageInfo.days && trip.packageInfo.days.length > 0) {
        console.log('days length ==> ', trip.packageInfo.days.length);
        for (let day of trip.packageInfo.days) {
          //console.log('day ==> ', day);
          if (day.cities && day.cities.length > 0) {
            firstCity = day.cities[0];
            break;
          }
        }
      }
      if (firstCity) {
        console.log('firstCity identified ==> ', firstCity.cityId); 
        this.fetchExcursionByType(trip,trip.excursionType, templateInfo.destinationId, firstCity.cityId);
        this.fetchExchangeRates();
      }
    },

    on_change_by_selected_excursion(trip) {
      console.log('excursion ==> ', JSON.stringify(trip.excursion));

      trip.excursionId = trip.excursion.id;
      if (trip.excursion.transportDetails && trip.excursion.transportDetails.length > 0) {                
        const rate = this.exchangeRates.find(rate => rate.currency === trip.excursion.transportDetails[0].currency )?.rate || 1; // Access rate using currency key
        const finalPrice = trip.excursion.transportDetails[0].finalPrice;
        trip.price = (finalPrice && !isNaN(finalPrice)) ? (finalPrice * rate).toFixed(2) : 0; // Ensure finalPrice is valid        
        trip.description = trip.excursion.transportDetails[0].description;        
      }
    },

    async onChangeTemplateTrip(trip) {      
      trip.excursionType = '';
      if (trip.packageInfo.packageCode) {
        const response = await this.fetchPackageByCode(trip.packageInfo.packageCode);
        trip.packageInfo.days = response.data.days;
        trip.packageCode = trip.packageInfo.packageCode;
        console.log('days', trip.packageInfo.days);
      }
    },

    async fetchExchangeRates() {
      try {
        const response = await axios.get(  API_CONFIG.getEndpoint("fetchExchangeRates")  );
        this.exchangeRates = Object.entries(response.data).map(([currency, rate]) => ({ currency, rate })); // Convert Map to array of objects
      } catch (error) {
        console.error("Error fetching exchange rates: ", error);
        this.exchangeRates = []; // Fallback to an empty array in case of error
      }
    },

    async fetchExcursionByType( trip, excursionType, destinationId, cityId) {
      try {
          const response = await axios.get(API_CONFIG.getEndpoint('fetchExcursions') + `/type/` + excursionType + '/' + destinationId + '/' + cityId);
          trip.excursions = response.data;          
      } catch (error) {
          console.error('Error fetching excursions by type: ' + error);
      }
    },
    
    async fetchPackageByCode(packageCode) {
      try {
        const response = await axios.get(`${API_CONFIG.getEndpoint('fetchPackages')}/${packageCode}`);
        return response;
      } catch (error) {
        console.error('Error fetching package details..' + error);
      }
    },

    async fetchAllTemplates (destinationId) {
      try {
          const response = await axios.get(API_CONFIG.getEndpoint('fetchStitchAblePackages')+'/destination/' + destinationId );
          this.templates = response.data;
      }catch(err) {
        this.error = err;
      }finally {
          this.loading = false;
      }
    },

    async fetchDestinations() {      
      this.loading = true;
      try {
          const resp = await axios.get(API_CONFIG.getEndpoint('fetchDestinations'));                
          this.destinations = resp.data;
      } catch (error) {
          console.log('Error fetching destinations list', error);
          this.error = 'Failed to fetch destinations list. Please try again.';
      } finally {
          this.loading = false;
      }
    },

    async savePackages() {
      this.v$.$touch();
      if (this.v$.$invalid) {
          const invalidFields = [];
          for (const field in this.v$.templateInfo) {
              if (this.v$.templateInfo[field].$invalid) {
                  invalidFields.push(field);
              }
          }
          alert(`Please fill all the required fields: ${invalidFields.join(', ')}`);
          return;
      }
      console.log("savePackages ==> " + JSON.stringify(this.templateInfo));
      try {
        const response = await axios.post(API_CONFIG.getEndpoint('createPackages')+'/template', this.templateInfo);      
        console.log('Success:', response.data);
        this.$router.push({ name: 'itinerary', params: { id: response.data.packageCode } });      
      } catch (error) {
        console.error('Error:', error);
        this.errorMessage="Failed, please try again.";
      }
    },

    addTrip() {
      this.templateInfo.templatePackageItems.push({
        packageInfo: '',
        excursionType: '',
        excursion: '',
        excursionId: '',
        price: '',
        description: '',
        packageCode:''
      });
    },

    cancel(){      
      this.$router.push('/template/view');
    },
    
    clear() {
      this.templateInfo =  {
        itineraryType: '',
        customerName: '',
        email: '',
        mobileNo: '',
        altContactNo: '',
        noOfAdults: 1,
        noOfChildren: 0,
        packageTitle: '',
        description: '',        
        destinationId: '',
        packageStartDate: '',
        priceOnWebsite: 'price_per_person',        
        packageType: 'template',
        status: 'Active'
      }
    },
    
  },

  mounted() {      
    this.fetchDestinations();
    //this.fetchAllTemplates();
  }

};
</script>

<style scoped>
  .ml_0_5_rem  {
    margin-left: 0.5rem !important;        
  }
  .ml-3  {
      margin-left: 1rem !important;        
  }
  .ml-6  {
      margin-left: 2rem !important;        
  }
  .ml-9  {
      margin-left: 3rem !important;        
  }
  .mr-3 {        
      margin-right: 1rem !important;        
  }
  .mr-6 {
      margin-right: 2rem !important;
  }
  .form-group {
      margin-bottom: 15px;
  }
  .text-danger {
      color: red;
      font-size: 0.875em;
  }
  label{
    margin:0px;
  }
  .custom-btn {
  width: 90px;
  padding: 5px 15px; 
  float: right;
}

</style>
