<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-9">
        <h5>
          Package Itinerary of
          <span style="color: #4b49ac">{{ packageInfo.packageTitle }}</span>
        </h5>
      </div>
      <div class="col-sm-3">
        <button
          type="button"
          class="btn btn-secondary me-2 text-right float-right"
          @click="back_to_packages(packageInfo)">
          <font-awesome-icon :icon="['fas', 'fa-arrow-left']" class="me-2" />
          Back to Packages
        </button>
      </div>
    </div>
    <hr />
    <div v-if="errorMessage" class="alert alert-danger mt-3">
      {{ errorMessage }}
    </div>
    <div class="d-flex gap-3">
      <div v-for="(day, dIndex) in packageInfo.days" :key="dIndex" class="mb-2">
        <div
          class="clickable-div container border p-2 btn"
          @click="toggleCollapsible(dIndex)"
        >
          Day {{ dIndex + 1 }}
        </div>
      </div>
    </div>

    <div v-for="(day, dIndex) in packageInfo.days" :key="dIndex" class="mb-2">
      <div :id="'day' + dIndex" class="collapse" :class="{ show: dIndex === 0 }">
        <div class="card card-body">
          <div class="form-group row mb-3">
            <div
              class="col-sm-12 d-flex justify-content-between align-items-center"
            >
              <div>
                Day {{ dIndex + 1 }}<span v-if="getDateForDay(dIndex)"> - {{ formatDate(getDateForDay(dIndex)) }}</span>
              </div>
              <div class="text-right d-flex gap-2">
                <button type="button" class="btn btn-primary" @click="add_more_day(dIndex)">
        <font-awesome-icon :icon="['fas', 'fa-plus']" class="me-2" />Add More
        Day
      </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="add_more_city(dIndex)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'fa-plus']"
                    class="me-2"
                  />Add More City
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  style="background-color: #dc3545 !important"
                  @click.stop="confirmRemoveDay(dIndex)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'minus']"
                    class="me-2"
                  />Remove Day
                </button>
              </div>
            </div>
          </div>

          <div
            v-for="(city, cIndex) in day.cities"
            :key="cIndex"
            class="card card-body form-section border mb-4"
          >
            <div class="form-group row mb-3">
              <label
                :for="'cityId' + '-' + dIndex + '-' + cIndex"
                class="col-sm-3 col-form-label"
                >Select Destination City
                <span style="color: red !important">*</span></label
              >
              <div class="col-sm-6">
                <select
                  v-model="city.cityId"
                  class="form-control"
                  :id="'city' + '-' + dIndex + '-' + cIndex"
                  :disabled="
                    city.itineraries.length > 0 &&
                    city.itineraries[0].excursionType !== ''
                  "
                  @change="onChangeByCity(city)"
                >
                  <option disabled value="">-- Please select a city --</option>
                  <option
                    v-for="cityOption in cities.list"
                    :key="cityOption.id"
                    :value="cityOption.id"
                  >
                    {{ cityOption.cityName }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row mb-3">
              <label
                :for="'title' + '-' + dIndex + '-' + cIndex"
                class="col-sm-3 col-form-label"
                >Title
              </label>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  :id="'title' + '-' + dIndex + '-' + cIndex"
                  v-model="city.title"
                  placeholder="Enter the title"
                />
              </div>
            </div>

            <div class="form-group row mb-3">
              <label
                :for="'description' + '-' + dIndex + '-' + cIndex"
                class="col-sm-3 col-form-label"
                >Description
              </label>
              <div class="col-sm-6">
                <textarea
                  type="text"
                  class="form-control"
                  :id="'description' + '-' + dIndex + '-' + cIndex"
                  v-model="city.description"
                  placeholder="Enter the description"
                ></textarea>
              </div>
            </div>

            <div class="form-section">
              <div class="bg-light clearfix p-2 d-flex align-items-center">
                <span class="fw-bold">Add Excursions :</span>
                <a
                  href="#"
                  @click.prevent="add_more_excursion(dIndex, cIndex)"
                  class="btn btn-link ms-auto text-decoration-none"
                >
                  <font-awesome-icon
                    :icon="['fas', 'fa-plus']"
                    class="me-2"
                  />Add More Excursion</a
                >
              </div>

              <div
                v-for="(itinerary, index) in city.itineraries"
                :key="index"
                class="mb-3"
              >
                <div class="text-right float-right">
                  <a
                    href="#"
                    @click.prevent="
                      confirmRemoveItinerary(city.itineraries, index)
                    "
                    class="btn float-right text-decoration-none remove-excursion"
                    style="background-color: #dc3545 !important"
                    v-if="city.itineraries.length > 0"
                    ><font-awesome-icon
                      :icon="['fas', 'minus']"
                      class="me-2"
                    />Remove Excursion</a
                  >
                </div>
                <div class="container border card card-body">
                  <div class="form-group row mb-3">
                    <label
                      :for="
                        'itinerary' + '-' + dIndex + '-' + cIndex + '-' + index
                      "
                      class="col-sm-3 col-form-label"
                      >Select Excursion Type
                      <span style="color: red !important">*</span></label
                    >
                    <div class="col-sm-3">
                      <select
                        v-model="itinerary.excursionType"
                        @change="onChangeByExcursionType(itinerary, city)"
                        class="form-control"
                        :id="
                          'itinerary' +
                          '-' +
                          dIndex +
                          '-' +
                          cIndex +
                          '-' +
                          index
                        "
                      >
                        <option disabled value="">
                          -- Select excursion Type --
                        </option>
                        <option
                          v-for="exc_type in excursionTypes"
                          :key="exc_type.value"
                          :value="exc_type.value"
                        >
                          {{ exc_type.name }}
                        </option>
                      </select>
                    </div>
                    <label
                      :for="
                        'sort_position' +
                        '-' +
                        dIndex +
                        '-' +
                        cIndex +
                        '-' +
                        index
                      "
                      class="col-sm-3 col-form-label"
                      >Sort Position
                      <span style="color: red !important">*</span></label
                    >
                    <div class="col-sm-3">
                      <input
                        type="number"
                        disabled
                        class="form-control"
                        :id="
                          'sort_position' +
                          '-' +
                          dIndex +
                          '-' +
                          cIndex +
                          '-' +
                          index
                        "
                        v-model="itinerary.sortNo"
                      />
                    </div>
                  </div>
                  <div v-if="itinerary.excursionType === 'intercity'">
                    <div class="form-group row mb-3">
                      <label
                        :for="'intercity' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >Select Transfer Type
                        <span style="color: red !important">*</span></label
                      >
                      <div class="col-sm-6">
                        <select
                          v-model="itinerary.selected_excursion"
                          class="form-control"
                          :id="'intercity' + '-' + dIndex"
                          @change="on_change_by_selected_excursion(itinerary)"
                        >
                          <option value="">-- Select excursion Type --</option>
                          <option
                            v-for="excursionItem in itinerary.filtered_excursions"
                            :key="excursionItem.id"
                            :value="excursionItem"
                          >
                            {{ excursionItem.titleDescriptor }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label
                        :for="'intercity-price' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >Price
                      </label>
                      <div class="col-sm-6">
                        <input
                          type="number"
                          step="0.01"
                          min="0"
                          v-model="itinerary.price"
                          class="form-control"
                          :id="'intercity-price' + '-' + dIndex"
                          placeholder="Enter the price"
                             @input="limitDecimals(itinerary, 'price')"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label
                        :for="'intercity-desc' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >Description</label
                      >
                      <div class="col-sm-6">
                        <textarea
                          type="text"
                          class="form-control"
                          :id="'intercity-desc' + '-' + dIndex"
                          v-model="itinerary.desc"
                          placeholder="Enter description"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div v-if="itinerary.excursionType === 'information'">
                    <div class="form-group row mb-3">
                      <label
                        :for="'information' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >Select New information
                        <span style="color: red !important">*</span></label
                      >
                      <div class="col-sm-3">
                        <select
                          v-model="itinerary.selected_excursion"
                          class="form-control"
                          :id="'information' + '-' + dIndex"
                          @change="on_change_by_selected_excursion(itinerary)"
                        >
                          <option disabled value="">
                            -- Select informations --
                          </option>
                          <option
                            v-for="excursionItem in itinerary.filtered_excursions"
                            :key="excursionItem.id"
                            :value="excursionItem"
                          >
                            {{ excursionItem.informationName }}
                          </option>
                        </select>
                      </div>
                      <label
                        :for="'information_pkg' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >Select Package
                        <span style="color: red !important">*</span></label
                      >
                      <div class="col-sm-3">
                        <select
                          v-model="itinerary.selected_sub_excursion"
                          @change="onChangeBySubExcursion(itinerary)"
                          class="form-control"
                          :id="'information_pkg' + '-' + dIndex"
                        >
                          <option disabled value="">
                            -- Select Package --
                          </option>
                          <option
                            v-for="pkg in itinerary.selected_excursion
                              .packageDetails"
                            :key="pkg.id"
                            :value="pkg"
                          >
                            {{ pkg.packageName }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label
                        :for="'information-price' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >Price
                      </label>
                      <div class="col-sm-3">
                        <input
                          type="number"
                          step="0.01"
                          min="0"
                          v-model="itinerary.price"
                          class="form-control"
                          :id="'information-price' + '-' + dIndex"
                          placeholder="Enter the price"
                           @input="limitDecimals(itinerary, 'price')"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label
                        :for="'information_desc' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >Package Description
                      </label>
                      <div class="col-sm-6">
                        <textarea
                          type="text"
                          class="form-control"
                          :id="'information_desc' + '-' + dIndex"
                          v-model="itinerary.desc"
                          placeholder="Package Description "
                        ></textarea>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label
                        :for="'information_customer_note' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >Notes for Customer
                      </label>
                      <div class="col-sm-6">
                        <textarea
                          type="text"
                          class="form-control"
                          :id="'information_cust_note' + '-' + dIndex"
                          v-model="itinerary.customerNote"
                          placeholder="Notes to customer "
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div v-if="itinerary.excursionType === 'sightseeing'">
                    <div class="form-group row mb-3">
                      <label
                        :for="'sightseeing' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >Select Sightseeing Packages
                        <span style="color: red !important">*</span></label
                      >
                      <div class="col-sm-3">
                        <select
                          v-model="itinerary.selected_excursion"
                          class="form-control"
                          :id="'sightseeing' + '-' + dIndex"
                          @change="on_change_by_selected_excursion(itinerary)"
                        >
                          <option disabled value="">
                            -- Select Package --
                          </option>
                          <option
                            v-for="excursionItem in itinerary.filtered_excursions"
                            :key="excursionItem.id"
                            :value="excursionItem"
                          >
                            {{ excursionItem.sightseeingName }}
                          </option>
                        </select>
                      </div>
                      <label
                        :for="'sightseeing_pkg' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >Select Packages
                        <span style="color: red !important">*</span></label
                      >
                      <div class="col-sm-3">
                        <select
                          v-model="itinerary.selected_sub_excursion"
                          @change="onChangeBySubExcursion(itinerary)"
                          class="form-control"
                          :id="'sightseeing_pkg' + '-' + dIndex"
                        >
                          <option disabled value="">
                            -- Select Package --
                          </option>
                          <option
                            v-for="pkg in itinerary.selected_excursion
                              .packageDetails"
                            :key="pkg.id"
                            :value="pkg"
                          >
                            {{ pkg.packageName }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label
                        :for="'sightseeing-price' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >Price
                      </label>
                      <div class="col-sm-3">
                        <input
                          type="number"
                          step="0.01"
                          min="0"
                          v-model="itinerary.price"
                          class="form-control"
                          :id="'sightseeing-price' + '-' + dIndex"
                          placeholder="Enter the price"
                             @input="limitDecimals(itinerary, 'price')"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="hotelLocation" class="col-sm-3 col-form-label"
                        >Package Description
                      </label>
                      <div class="col-sm-6">
                        <textarea
                          type="text"
                          class="form-control"
                          id="hotelLocation"
                          v-model="itinerary.desc"
                          placeholder="Package Description "
                        ></textarea>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="hotelLocation" class="col-sm-3 col-form-label"
                        >Notes for Customer
                      </label>
                      <div class="col-sm-6">
                        <textarea
                          type="text"
                          class="form-control"
                          id="hotelLocation"
                          v-model="itinerary.customerNote"
                          placeholder="Notes to customer "
                        ></textarea>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="hotelLocation" class="col-sm-3 col-form-label"
                        >Notes for Agent
                      </label>
                      <div class="col-sm-6">
                        <textarea
                          type="text"
                          class="form-control"
                          id="hotelLocation"
                          v-model="itinerary.agentNote"
                          placeholder="Notes to Agent"
                        ></textarea>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="hotelLocation" class="col-sm-3 col-form-label"
                        >Notes for Admin
                      </label>
                      <div class="col-sm-6">
                        <textarea
                          type="text"
                          class="form-control"
                          id="hotelLocation"
                          v-model="itinerary.adminNote"
                          placeholder="Notes to Admin"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div v-if="itinerary.excursionType === 'hotel'">
                    <div class="form-group row mb-3">
                      <label
                        :for="'exc_hotels' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >Select Hotels
                        <span style="color: red !important">*</span></label
                      >
                      <div class="col-sm-3">
                        <select
                          v-model="itinerary.selected_excursion"
                          class="form-control"
                          :id="'exc_hotels' + '-' + dIndex"
                          @change="on_change_by_selected_excursion(itinerary)"
                        >
                          <option disabled value="">
                            -- Select Hotel Name --
                          </option>
                          <option
                            v-for="excursionItem in itinerary.filtered_excursions"
                            :key="excursionItem.id"
                            :value="excursionItem"
                          >
                            {{ excursionItem.hotelName }}
                          </option>
                        </select>
                      </div>
                      <label
                        :for="'exc_hotels' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >Select Rooms
                        <span style="color: red !important">*</span></label
                      >
                      <div class="col-sm-3">
                        <select
                          v-model="itinerary.selected_sub_excursion"
                          @change="onChangeBySubExcursion(itinerary)"
                          class="form-control"
                          :id="'exc_hotels' + '-' + dIndex"
                          :disabled="!itinerary.selected_excursion.roomDetails"
                        >
                          <option disabled value="">
                            -- Select Room Type --
                          </option>
                          <option
                            v-for="room in itinerary.selected_excursion
                              .roomDetails"
                            :key="room.id"
                            :value="room"
                          >
                            {{ room.roomType }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-if="itinerary.selected_sub_excursion">
                      <div class="form-group row mb-3">
                        <label
                          :for="'exc_hotels-price' + '-' + dIndex"
                          class="col-sm-3 col-form-label"
                          >Price
                        </label>
                        <div class="col-sm-3">
                          <input
                            type="number"
                            step="0.01"
                            min="0"
                            v-model="itinerary.price"
                            class="form-control"
                            :id="'exc_hotels-price' + '-' + dIndex"
                            placeholder="Enter the price"
                               @input="limitDecimals(itinerary, 'price')"
                          />
                        </div>
                        <label
                          :for="'exc_hotels-no-of-nights' + '-' + dIndex"
                          class="col-sm-3 col-form-label"
                          >Booked for no of nights
                        </label>
                        <div class="col-sm-3">
                          <input
                            type="number"
                            step="1" min="1"
                            v-model="itinerary.noOfNightsBooked"
                            class="form-control"
                            :id="'exc_hotels-no-of-nights' + '-' + dIndex"
                            placeholder="Enter the number" 
                            @change="updatePriceBasedOnNights(itinerary)"                            
                          />
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label
                          :for="'exc_hotels-desc' + '-' + dIndex"
                          class="col-sm-3 col-form-label"
                          >Package Description
                        </label>
                        <div class="col-sm-6">
                          <textarea
                            type="text"
                            class="form-control"
                            :id="'exc_hotels-desc' + '-' + dIndex"
                            v-model="itinerary.desc"
                            placeholder="Package Description "
                          ></textarea>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label
                          :for="'exc_hotels-customer_note' + '-' + dIndex"
                          class="col-sm-3 col-form-label"
                          >Notes for Customer
                        </label>
                        <div class="col-sm-6">
                          <textarea
                            type="text"
                            class="form-control"
                            :id="'exc_hotels-custnote' + '-' + dIndex"
                            v-model="itinerary.customerNote"
                            placeholder="Notes to customer "
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="itinerary.excursionType === 'airport'">
                    <div class="form-group row mb-3">
                      <label
                        :for="'airport' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >Select airports
                        <span style="color: red !important">*</span></label
                      >
                      <div class="col-sm-6">
                        <select
                          v-model="itinerary.selected_excursion"
                          class="form-control"
                          :id="'airport' + '-' + dIndex"
                          @change="on_change_by_selected_excursion(itinerary)"
                        >
                          <option disabled value="">
                            -- Select excursion Type --
                          </option>
                          <option
                            v-for="excursionItem in itinerary.filtered_excursions"
                            :key="excursionItem.id"
                            :value="excursionItem"
                          >

                            {{ excursionItem.titleDescriptor }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label
                        :for="'airport-price' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >Price
                      </label>
                      <div class="col-sm-6">
                        <input
                          type="number"
                          step="0.01"
                          min="0"
                          v-model="itinerary.price"
                          class="form-control"
                          :id="'sightseeing-price' + '-' + dIndex"
                          placeholder="Enter the price"
                             @input="limitDecimals(itinerary, 'price')"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label
                        :for="'airport-transferFrom' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >TransferFrom</label
                      >
                      <div class="col-sm-6">
                        <input
                          type="text"
                          class="form-control"
                          :id="'airport-transferFrom' + '-' + dIndex"
                          v-model="itinerary.transferFrom"
                          placeholder="Enter description"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label
                        :for="'airport-desc' + '-' + dIndex"
                        class="col-sm-3 col-form-label"
                        >Description</label
                      >
                      <div class="col-sm-6">
                        <textarea
                          type="text"
                          class="form-control"
                          :id="'airport-desc' + '-' + dIndex"
                          v-model="itinerary.desc"
                          placeholder="Enter description"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row mb-3">
              <div class="col-sm-12 d-flex align-items-center">
                <button
                  type="button"
                  class="btn btn-danger me-2"
                  @click="confirmRemoveCity(dIndex, cIndex)"
                  style="background-color: #dc3545 !important"
                >
                  <font-awesome-icon
                    :icon="['fas', 'minus']"
                    class="me-2"
                  />Remove City
                </button>
                <span class="text-danger" v-show="removeCityErrorMessage">{{
                  removeCityErrorMessage
                }}</span>
              </div>
            </div>
          </div>
          <!-- end of city for loop -->
        </div>
      </div>
    </div>
    <!-- end of day for loop -->
    <hr />
    <div class="form-actions float-right mb-3">
      <button
        type="submit"
        class="btn btn-success mr-3"
        @click="validateAndUpdatePackages"
      >
        <font-awesome-icon :icon="['fas', 'save']" class="me-2" /> Save &
        continue
      </button>
      <button
        
        type="submit"
        class="btn btn-dark mr-3"
        @click="next_page()"
      >
        <font-awesome-icon :icon="['fas', 'fa-arrow-right']" class="me-2" />
        Next
      </button>
      <button
        type="button"
        class="btn btn-danger"
        @click="cancel(packageInfo)"
      >
        <font-awesome-icon :icon="['fas', 'times']" class="me-2" /> Cancel
      </button>
    </div>

    <DeleteModal
      :visible="showDeleteModal"
      @confirmDelete="remove_day"
      :actionType="'Remove'"
      @cancelDelete="showDeleteModal = false"
    />

    <DeleteModal
      :visible="showDeleteCityModal"
      @confirmDelete="remove_city(selectedDIndex, selectedCIndex)"
      :actionType="'Remove'"
      @cancelDelete="showDeleteCityModal = false"
    />

    <DeleteModal
      :visible="showDeleteExcursionModal"
      @confirmDelete="removeItinerary"
      :actionType="'Remove'"
      @cancelDelete="showDeleteExcursionModal = false"
    />
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios"; // Import axios for HTTP requests
import { API_CONFIG } from "../apiConfig";
import * as bootstrap from "bootstrap"; // Import the entire Bootstrap bundle
import DeleteModal from "./DeleteModal.vue";

export default {
  name: "PackageList",
  components: {
    DeleteModal,
  },
  setup() {
    const route = useRoute(); // Access current route's properties
    const router = useRouter(); // Get the router instance
    const packageCode = ref(route.params.id);
    const showDeleteModal = ref(false);
    const dayToRemove = ref(null);
    const showDeleteCityModal = ref(false);
    const showDeleteExcursionModal = ref(false);
    const selectedDIndex = ref(null);
    const selectedCIndex = ref(null);
    const packages = ref([]);
    const loading = ref(true);
    const error = ref(null);
    const packageInfo = reactive({ days: [] });
    const cities = reactive({ list: [] });
    const excursions = reactive({ list: [] });
    const isCollapsed = ref(false);
    const removeCityErrorMessage = ref("");
    const errorMessage = ref("");
    const isAddingNewDay = ref(false);

    const add_more_excursion = (dIndex, cIndex) => {
      let _length = packageInfo.days[dIndex].cities[cIndex].itineraries.length;
      const newItineraryInfo = reactive({
        excursionType: "",
        sortNo: _length + 1,
        excursionId: "",
        code: "",
        subCode: "",
        price: "",
        noOfNightsBooked: 1,
        desc: "",
        customerNote: "",
        agentNote: "",
        adminNote: "",
        selected_excursion: {},
        selected_sub_excursion: {},
        filtered_excursions: [],
        transferFrom: "",
      });
      packageInfo.days[dIndex].cities[cIndex].itineraries.push(
        newItineraryInfo
      );
    };

    const limitDecimals = (obj, field) => {
  if (obj[field] !== undefined && obj[field] !== null) {
    const valueStr = obj[field].toString();
    const decimalIndex = valueStr.indexOf('.');

    if (decimalIndex !== -1 && valueStr.length - decimalIndex > 3) {
      obj[field] = parseFloat(valueStr.substring(0, decimalIndex + 3));
    }
  }
};

    const add_more_day = (dIndex = null) => {
      isAddingNewDay.value = true;
      const newDay = reactive({
        day_number: packageInfo.days.length,  
        itineraryDate: getDateForDay(packageInfo.days.length),
        cities: [
          {
            cityId: "",
            title: "",
            description: "",
            itineraries: [
              {
                excursionType: "",
                sortNo: 1,
                excursionId: "",
                code: "",
                subCode: "",
                price: "",
                noOfNightsBooked: 1,
                desc: "",
                customerNote: "",
                agentNote: "",
                adminNote: "",
                selected_excursion: {},
                selected_sub_excursion: {},
                filtered_excursions: [],
                transferFrom: "",
              },
            ],
          },
        ],
      });

      console.log('Received index:', dIndex);
      console.log('Current days length:', packageInfo.days.length);

      if (dIndex !== null && dIndex >= 0 && dIndex < packageInfo.days.length) {
        console.log(`Inserting day after index ${dIndex}`);        

        packageInfo.days.splice(dIndex + 1, 0, newDay); 
        
        for (let i = dIndex + 2; i < packageInfo.days.length; i++) {
          packageInfo.days[i].day_number++; 
        }

        newDay.day_number = dIndex + 1;  
      } else {
        console.log("Invalid index provided. Adding day to the end.");
        packageInfo.days.push(newDay);  
      }

      packageInfo.days.sort((a, b) => a.day_number - b.day_number);

      packageInfo.days.forEach((day, index) => {
        day.day_number = index;  
      });

      packageInfo.noOfDays = packageInfo.days.length;

      console.log('Updated days:', packageInfo.days);

      validateAndUpdatePackages();
      isAddingNewDay.value = false; 

    };

    const confirmRemoveDay = (dIndex) => {
      if (packageInfo.days.length > 1) {
        dayToRemove.value = dIndex;
        showDeleteModal.value = true;
      } else {
        console.log("Cannot remove the last day from the list.");
        errorMessage.value = "Cannot remove the last day from the list.";
        setTimeout(() => {
          errorMessage.value = "";
        }, 3000);
      }
    };

    const remove_day = () => {
      if (dayToRemove.value !== null && packageInfo.days.length > 1) {
        packageInfo.days.splice(dayToRemove.value, 1);
        packageInfo.noOfDays = packageInfo.days.length;
        showDeleteModal.value = false;
        dayToRemove.value = null;
      }
    };

    const add_more_city = (dIndex) => {
      removeCityErrorMessage.value = ""; // Reset the error message
      const newCity = reactive({
        cityId: "",
        title: "",
        description: "",
        itineraries: [
          {
            excursionType: "",
            sortNo: 1,
            excursionId: "",
            code: "",
            subCode: "",
            price: "",
            noOfNightsBooked: 1,
            desc: "",
            customerNote: "",
            agentNote: "",
            adminNote: "",
            selected_excursion: {},
            selected_sub_excursion: {},
            filtered_excursions: [],
            transferFrom: "",
          },
        ],
      });
      packageInfo.days[dIndex].cities.push(newCity);
    };

    const confirmRemoveCity = (dIndex, cIndex) => {
      selectedDIndex.value = dIndex;
      selectedCIndex.value = cIndex;
      showDeleteCityModal.value = true;
    };

    const remove_city = (dIndex, cIndex) => {
      if (packageInfo.days[dIndex].cities.length > 1) {
        packageInfo.days[dIndex].cities.splice(cIndex, 1);
      } else {
        console.log("Cannot remove the last city from the list.");
        removeCityErrorMessage.value =
          "Cannot remove the last city from the list.";
        setTimeout(() => {
          removeCityErrorMessage.value = "";
        }, 3000);
      }
      showDeleteCityModal.value = false;
    };

    const itineraryToRemove = ref({ itineraries: null, index: null });

    const confirmRemoveItinerary = (itineraries, index) => {
      itineraryToRemove.value = { itineraries, index };
      showDeleteExcursionModal.value = true;
    };

    const removeItinerary = () => {
      if (
        itineraryToRemove.value.itineraries &&
        itineraryToRemove.value.index !== null
      ) {
        itineraryToRemove.value.itineraries.splice(
          itineraryToRemove.value.index,
          1
        );
        showDeleteExcursionModal.value = false;
        itineraryToRemove.value = { itineraries: null, index: null };
      }
    };

    const excursionTypes = reactive([
      { name: "Hotel", value: "hotel" },
      { name: "Intercity", value: "intercity" },
      { name: "Airport Transfer", value: "airport" },
      { name: "New Information", value: "information" },
      { name: "Sightseeing", value: "sightseeing" },
    ]);

    const checkValuePresent = (value) => {
      return excursionTypes.some((type) => type.value === value);
    };

const toggleCollapsible = (dayNumber) => {
    const collapsibles = document.querySelectorAll(".collapse");

    collapsibles.forEach((collapsible, index) => {
        const bsCollapse = new bootstrap.Collapse(collapsible, {
            toggle: false,
        });

        if (index !== dayNumber && collapsible.classList.contains("show")) {
            bsCollapse.hide();
        }
    });

    const selectedCollapsible = document.getElementById("day" + dayNumber);
    const bsSelectedCollapse = new bootstrap.Collapse(selectedCollapsible, {
        toggle: false,
    });

    if (selectedCollapsible.classList.contains("show")) {
        bsSelectedCollapse.hide();
    } else {
        bsSelectedCollapse.show(); 
    }
};


    const getDateForDay = (dayNumber) => {
      if (!packageInfo.packageStartDate) {
        return null;
      }
      const startDate = new Date(packageInfo.packageStartDate);
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + dayNumber - 1);
      return date;
    };

    const formatDate = (date) => {
      const day = ("0" + date.getDate()).slice(-2);
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();

      let suffix;
      if (day >= 11 && day <= 13) {
        suffix = "th";
      } else {
        switch (day % 10) {
          case 1:
            suffix = "st";
            break;
          case 2:
            suffix = "nd";
            break;
          case 3:
            suffix = "rd";
            break;
          default:
            suffix = "th";
            break;
        }
      }
      return `${day}${suffix} ${month}, ${year}`;
    };

    const fetchPackageByCode = async () => {
      try {
        const response = await axios.get(
          API_CONFIG.getEndpoint("fetchPackages") + `/` + packageCode.value
        );
        Object.assign(packageInfo, response.data); // Assign response data to reactive object

        if (packageInfo.days.length > 0) {
          packageInfo.days.forEach((day) => {
            if (day.cities.length == 0) {
              day.cities.push({
                cityId: "",
                title: "",
                description: "",
                itineraries: [
                  {
                    excursionType: "",
                    sortNo: 1,
                    excursionId: "",
                    code: "",
                    subCode: "",
                    price: "",
                    noOfNightsBooked: 1,
                    desc: "",
                    customerNote: "",
                    agentNote: "",
                    adminNote: "",
                    selected_excursion: {},
                    selected_sub_excursion: {},
                    filtered_excursions: [],
                    transferFrom: "",
                  },
                ],
              });
            }

            day.cities.forEach((city) => {
              city.itineraries.forEach((itinerary) => {
                if (!Array.isArray(itinerary.filtered_excursions)) {
                  itinerary.filtered_excursions = [];
                }
                //TODO: this is adding only selected excursion into filtered_excursions but not filtered_excursion should be added by the selected excursionType
                if (itinerary.selected_excursion) {
                  itinerary.filtered_excursions.push(
                    itinerary.selected_excursion
                  );
                }
                // Find and set selected_sub_excursion based on subCode
                if (itinerary.subCode && itinerary.selected_excursion) {
                  let details = [];
                  if (
                    itinerary.excursionType === "sightseeing" ||
                    itinerary.excursionType === "information"
                  ) {
                    details = itinerary.selected_excursion.packageDetails;
                  } else if (itinerary.excursionType === "hotel") {
                    details = itinerary.selected_excursion.roomDetails;
                  }
                  if (
                    itinerary.excursionType !== "airport" &&
                    itinerary.excursionType !== "intercity"
                  ) {
                    itinerary.selected_sub_excursion =
                      details.find(
                        (detail) => detail.id === itinerary.subCode
                      ) || {};
                  }
                }
              });
            });
          });

          packageInfo.days.sort((a, b) => a.day_number - b.day_number);
          
        }else {
          packageInfo.days = [];
        }

        if (packageInfo.noOfDays > 0 && packageInfo.days.length == 0) {
          for (let i = 0; i < packageInfo.noOfDays; i++) {
            packageInfo.days.push({
              dayNumber: i,
              itineraryDate: getDateForDay(i),
              cities: [
                {
                  cityId: "",
                  title: "",
                  description: "",
                  itineraries: [
                    {
                      excursionType: "",
                      sortNo: 1,
                      excursionId: "",
                      code: "",
                      subCode: "",
                      price: "",
                      noOfNightsBooked: 1,
                      desc: "",
                      customerNote: "",
                      agentNote: "",
                      adminNote: "",
                      transferFrom: "",
                      selected_excursion: {},
                      selected_sub_excursion: {},
                      filtered_excursions: [],
                    },
                  ],
                },
              ],
            });
          }
        }

        fetchCitiesByDestination();
      } catch (error) {
        console.error("Error fetching package details.." + error);
        errorMessage.value = "Error fetching package details..";
      }
    };

    // const saveItineraries = async (days) => {
    //     try {
    //         const response = await axios.post(API_CONFIG.getEndpoint('fetchPackages') + `/${packageCode.value}/days`, days);
    //         console.log('Itineraries saved successfully:', response.data);
    //         next_page();
    //     } catch (error) {
    //         console.error('Error saving itineraries: ' + error);
    //         errorMessage.value = "Unable to save the itineraries.. Please try again later.";
    //     }

    // };

    const validateAndUpdatePackages = () => {
      if (isAddingNewDay.value) {
        return;
    }
      let isValid = true;
      packageInfo.days.forEach((day) => {
        day.cities.forEach((city) => {
          if (!city.cityId) {
            isValid = false;
            return;
          }
          city.itineraries.forEach((itinerary) => {
            if (!itinerary.excursionId) {
              isValid = false;
              return;
            }
          });
        });
        if (!isValid) {
          errorMessage.value =
            "City and Excursion must be selected for each Day.";
          return;
        }
      });
      if (isValid) {
        updatePackages(packageInfo);
      } else {
        setTimeout(() => {
          errorMessage.value = ""; // Hide the error message after 3 seconds
        }, 3000);
      }
    };

    const updatePackages = async (packageInfo) => {
      console.log("packageInfo", packageInfo);
      packageInfo.days.forEach((day, index) => {
    day.day_number = index;  // Ensure dayNumber is in the correct order
  });
      try {
        const payload = {
      ...packageInfo,
      days: packageInfo.days.map((day) => ({
        ...day,  // Include all day data
        day_number: day.day_number,  // Ensure dayNumber is in the payload
      })),
    };
        const response = await axios.put(
          API_CONFIG.getEndpoint("updatePackages") +
            `/` +
            packageInfo.packageCode,
          payload
        );
        console.log("Success:", response.data);
        // if (!packageInfo.stitchAble) {
           next_page();
        // } else {
        //  backToTemplatesList();
        //}
      } catch (error) {
        console.log("Error updating packages ", error);
        errorMessage.value =
          "Unable to save the itineraries.. Please try again later.";
      }
    };

    const fetchCitiesByDestination = async () => {
      try {
        const response = await axios.get(
          API_CONFIG.getEndpoint("citiesByDestination") +
            `/` +
            packageInfo.destinationId
        );
        cities.list = response.data;
        if (cities.length === 0) {
          errorMessage.value =
            "No cities available for the selected destination.";
        } else {
          errorMessage.value = "";
        }
      } catch (error) {
        console.log("Error fetching cities list ", error);
      }
    };
    const exchangeRates = reactive({ list: [] });

    const fetchExchangeRates = async () => {
      try {
        const response = await axios.get(  API_CONFIG.getEndpoint("fetchExchangeRates")  );
        exchangeRates.list = Object.entries(response.data).map(([currency, rate]) => ({ currency, rate })); // Convert Map to array of objects
      } catch (error) {
        console.error("Error fetching exchange rates: ", error);
        exchangeRates.list = []; // Fallback to an empty array in case of error
      }
    };

    const calculatePrice = (itinerary) => {      
      const rate = exchangeRates.list.find(rate => rate.currency === itinerary.currency)?.rate || 1; // Access rate using currency key
      console.log("calculatePrice :: Before :: price=" + itinerary.price+" :: currency = "+ itinerary.currency + " :: rate = " + rate );
      if(!itinerary.originalPrice) {
        itinerary.originalPrice = itinerary.price; // Store the original price only once
      }
      itinerary.price = ( itinerary.originalPrice * rate * itinerary.noOfNightsBooked ).toFixed(2); // Adjust to two decimal places
      console.log("calculatePrice :: After :: itinerary.price=" + itinerary.price );
      return parseFloat(itinerary.price); // Ensure the result is a number
    };

    const updatePriceBasedOnNights = (itinerary) => {
      console.log("itinerary.selected_sub_excursion.finalPrice=" + itinerary.selected_sub_excursion.finalPrice + ", noOfNightsBooked=" + itinerary.noOfNightsBooked);      
      if (itinerary.noOfNightsBooked > 0) {
        itinerary.originalPrice = itinerary.selected_sub_excursion.finalPrice; 
        const rate = exchangeRates.list.find(rate => rate.currency === itinerary.currency)?.rate || 1;
        itinerary.price = ( itinerary.originalPrice * rate * itinerary.noOfNightsBooked ).toFixed(2);
        return parseFloat(itinerary.price); // Ensure the result is a number
      }
    };

    onMounted(async () => {
      if (packageCode.value) {
        await fetchExchangeRates(); // Ensure exchange rates are fetched before usage
        fetchPackageByCode();
      }
    });

    const onChangeByCity = (city) => {
      city.title = "";
      city.description = "";
      city.itineraries.forEach((itinerary) => {
        itinerary.excursionType = itinerary.excursionType || "";
        itinerary.sortNo = itinerary.sortNo || 1;
        itinerary.excursionId = itinerary.excursionId || "";
        itinerary.code = itinerary.code || "";
        itinerary.subCode = itinerary.subCode || "";
        itinerary.price = itinerary.price || "";
        itinerary.noOfNightsBooked = itinerary.noOfNightsBooked || "1";
        itinerary.currency = itinerary.currency || "";
        itinerary.desc = itinerary.desc || "";
        itinerary.customerNote = itinerary.customerNote || "";
        itinerary.agentNote = itinerary.agentNote || "";
        itinerary.adminNote = itinerary.adminNote || "";
        itinerary.transferFrom = itinerary.transferFrom || "";
        itinerary.selected_excursion = itinerary.selected_excursion || {};
        itinerary.selected_sub_excursion =
          itinerary.selected_sub_excursion || {};
        itinerary.filtered_excursions = itinerary.filtered_excursions || [];
      });
    };

    const resetItinerary = (itinerary) => {      
      itinerary.sortNo = 1;
      itinerary.excursionId = "";
      itinerary.code = "";
      itinerary.subCode = "";
      itinerary.price = "";
      itinerary.currency = "";
      itinerary.noOfNightsBooked = 1;
      itinerary.desc = "";
      itinerary.customerNote = "";
      itinerary.agentNote = "";
      itinerary.adminNote = "";
      itinerary.transferFrom = "";
      itinerary.selected_excursion = {};
      itinerary.selected_sub_excursion = {};
      itinerary.filtered_excursions = [];
    }

    const onChangeByExcursionType = (itinerary, city) => {
      resetItinerary(itinerary);
      fetchExcursionByType(itinerary, city);
    };

    // this will be called only for hotel, information and sightseeing
    const onChangeBySubExcursion = (itinerary) => {      
      itinerary.subCode = itinerary.selected_sub_excursion.id;
      itinerary.price = itinerary.selected_sub_excursion.finalPrice;     
      itinerary.originalPrice = itinerary.selected_sub_excursion.finalPrice;
      itinerary.customerNote = itinerary.selected_sub_excursion.customerNote;
      itinerary.agentNote = itinerary.selected_sub_excursion.agentNote;
      itinerary.adminNote = itinerary.selected_sub_excursion.adminNote;
      if( itinerary.excursionType === 'hotel') {
        itinerary.currency = itinerary.selected_excursion.currency;  
      } else {
        itinerary.currency = itinerary.selected_sub_excursion.currency;
      }
      calculatePrice(itinerary);
    };

    const on_change_by_selected_excursion = (itinerary) => {
      itinerary.selected_sub_excursion = {};
      console.log(
        "on_change_by_selected_excursion :: itinerary.selected_excursion ==> " +
          JSON.stringify(itinerary.selected_excursion)
      );
      itinerary.excursionId = itinerary.selected_excursion.id;
      switch (itinerary.excursionType) {
        case "airport":
          itinerary.code = itinerary.selected_excursion.airportId;
          if (itinerary.selected_excursion.transportDetails.length > 0) {
            itinerary.subCode = itinerary.selected_excursion.title;
            itinerary.desc = itinerary.selected_excursion.transportDetails[0].description;
            itinerary.price = itinerary.selected_excursion.transportDetails[0].finalPrice;         
            itinerary.currency = itinerary.selected_excursion.transportDetails[0].currency;
            itinerary.transferFrom = itinerary.selected_excursion.transportDetails[0].transferFrom;
            calculatePrice(itinerary);
          }
          break;
        case "hotel":
          itinerary.code = itinerary.selected_excursion.hotelCode;
          itinerary.desc = itinerary.selected_excursion.hotelDesc;
          break;
        case "sightseeing":
          itinerary.code = itinerary.selected_excursion.sightseeingCode;
          itinerary.desc = itinerary.selected_excursion.description;
          break;
        case "information":
          itinerary.code = itinerary.selected_excursion.informationCode;
          itinerary.desc = itinerary.selected_excursion.description;
          break;
        case "intercity":
          itinerary.code = itinerary.selected_excursion.destinationCityId;
          if (itinerary.selected_excursion.transportDetails.length > 0) {
            itinerary.subCode = itinerary.selected_excursion.title;
            itinerary.desc = itinerary.selected_excursion.transportDetails[0].description;
            itinerary.price = itinerary.selected_excursion.transportDetails[0].finalPrice;            
            itinerary.currency = itinerary.selected_excursion.transportDetails[0].currency; 
            calculatePrice(itinerary);
          }
          break;
      }
    };

    // const filterByDestinationIdAndCityId = (itinerary, city) => {
    //     console.log("filterByDestinationIdAndCityId ==> " + itinerary.excursionType + " :: " + city.cityId);
    //     let _final_list = excursions.list.filter(excursion =>
    //         excursion.destinationId === packageInfo.destinationId &&
    //         excursion.cityId === city.cityId
    //     );
    //     console.log("filterByDestinationIdAndCityId ==> " + _final_list.length);
    //     Object.assign(itinerary.filtered_excursions, _final_list);
    //     reset_itinerary_info(itinerary);
    // };

    const reset_itinerary_info = (itinerary) => {
      itinerary.excursionId = "";
      itinerary.code = "";
      itinerary.subCode = "";
      itinerary.price = "";
      itinerary.currency = "";      
      itinerary.desc = "";
      itinerary.customerNote = "";
      itinerary.agentNote = "";
      itinerary.adminNote = "";
      itinerary.noOfNightsBooked = 1;
      itinerary.transferFrom = "";
    };

    const fetchExcursionByType = async (itinerary, city) => {
      try {
        const response = await axios.get(
          API_CONFIG.getEndpoint("fetchExcursions") +
            `/type/` +
            itinerary.excursionType +
            "/" +
            packageInfo.destinationId +
            "/" +
            city.cityId
        );
        excursions.list = response.data;
        itinerary.filtered_excursions = [...response.data]; // Ensure filtered_excursions is updated correctly
      } catch (error) {
        console.error("Error fetching excursions by type: " + error);
      }
    };

    const next_page = () => {
      if (packageInfo.stitchAble) {
        router.push({ 
          name: 'PricingSummary', 
          params: { id: packageCode.value } 
        });        
      } else {
        router.push({
          name: "inclusionExclusion",
          params: { id: packageCode.value },
        });
      }
    };

    const createNewPackage = () => {
      router.push({ name: "packages" });
    };

    const back_to_packages = () => {
      if( packageInfo.stitchAble ) {
        backToTemplatesList();
      } else if ( packageInfo.packageType == 'website_parent') {                    
        router.push({ path: '/web-packages/view'});  
      } else {
        router.push({ path: '/viewPackages' });
      }    
    };

    const backToTemplatesList = () => {
      router.push({ path: `/template/view` });
    };

    const cancel = () => {
      if( packageInfo.stitchAble) { backToTemplatesList(); }
      else if ( packageInfo.packageType == 'website_parent') {                    
        router.push({ path: '/web-packages/view'});  
      } else {    
        router.push({ path: `/viewPackages` });  
      }
    };

    onMounted(() => {
      if (packageCode.value) {
        fetchPackageByCode();
        fetchExchangeRates();
      }
    });

    return {
      packageCode,
      packages,
      loading,
      error,
      errorMessage,
      packageInfo,
      createNewPackage,
      fetchPackageByCode,
      fetchCitiesByDestination,
      checkValuePresent,
      excursionTypes,
      add_more_excursion,
      removeItinerary,
      isCollapsed,
      getDateForDay,
      formatDate,
      cities,
      excursions,
      fetchExcursionByType,
      onChangeByExcursionType,
      toggleCollapsible,
      onChangeByCity,
      on_change_by_selected_excursion,
      //saveItineraries,
      back_to_packages,
      onChangeBySubExcursion,
      reset_itinerary_info,
      next_page,
      add_more_city,
      remove_city,
      removeCityErrorMessage,
      updatePackages,
      add_more_day,
      remove_day,
      validateAndUpdatePackages,
      dayToRemove,
      showDeleteModal,
      confirmRemoveDay,
      showDeleteCityModal,
      selectedDIndex,
      selectedCIndex,
      confirmRemoveCity,
      showDeleteExcursionModal,
      confirmRemoveItinerary,
      cancel,
      backToTemplatesList,
      calculatePrice, updatePriceBasedOnNights, limitDecimals
    };
  },
};
</script>

<style scoped>
.ml-3 {
  margin-left: 1rem !important;
}
.ml-6 {
  margin-left: 2rem !important;
}
.ml-9 {
  margin-left: 3rem !important;
}
.mr-3 {
  margin-right: 1rem !important;
}
.mr-6 {
  margin-right: 2rem !important;
}
.container {
  margin-top: 20px;
  margin-bottom: 20px;
}
.clickable-div {
  cursor: pointer;
}
.float-right {
  float: right;
}
label {
  margin: 0px;
}
.remove-excursion {
  padding: 7px;
  font-size: 14px;
  margin-bottom: 10px;
  margin-right: 7px;
}

</style>
