
<template>
  <div class="container">
    <div v-if ="showSightseeing">        
          <div class="card-header" v-if="computedShowListView">
        <div class="d-flex align-content-center justify-content-between  mt-0">
          <h4 v-if="computedShowListView"  class="d-flex align-items-center">Manage Sightseeing </h4>
          <div style="display: flex !important; gap: 20px !important">
            <div v-if="computedShowListView">
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div> 
  <button type="button" class="btn1 " @click="showForm('create-sightseeing')" v-if="computedShowListView"> <font-awesome-icon :icon="['fas', 'fa-plus']" class="me-2" />New Sightseeing </button>
          </div>
          </div>
        </div>   
      <div v-if="computedShowListView" class="table-container">
         <!-- <h4> List of Sightseeings</h4> -->       
          <table class="table table-bordered mt-3 custom-table-width">
            <thead>
              <tr>
                <th>SI.No</th>
                <th>SightSeeing Name</th>  
                <th>SightSeeing Code</th>                  
                <th>Destination Name</th>
                <th>City </th>
                <th>Meeting Point</th>                           
                <th>Status</th>
                <th style="width: 200px;">Actions</th>
              </tr>
            </thead>
            <tbody>
              <!-- <template v-for="excursion in excursions.list" :key = "excursion.id"> -->
                <tr v-for="(excursion, tIndex) in paginatedUsers " :key="tIndex">   
                  <td>{{ (currentPage - 1) * itemsPerPage + (tIndex + 1) }}</td>               
                    <td  class="text-nowrap">{{ excursion.sightseeingName }}</td>
                    <td>{{ excursion.sightseeingCode }}</td>
                    <td>{{ excursion.destinationName }}</td>
                    <td>{{ excursion.cityName }}</td>
                    <td  class="text-nowrap">{{ excursion.meetingPoint }}</td>
                    <td>{{ excursion.status  }}</td>                
                    <td>
                        <div class="d-flex justify-content-evenly m-0">
                          <a href="#" @click="showForm('go-to-package',excursion)" class="text-warning mr-3 faq-button" role="button"> <font-awesome-icon :icon="['fas', 'fa-plus']" class="plus" /><span class="tooltip">Add</span></a>
                          <a href="#" @click="showForm('edit',excursion)" class="text-warning mr-3 faq-button" role="button"> <font-awesome-icon :icon="['fas', 'fa-edit']" class="edit" /><span class="tooltip">Edit</span></a>
                          <a href="#" @click="confirmDeleteExcursion(excursion.id)" class="text-warning mr-3 faq-button" role="button"><font-awesome-icon :icon="['fas', 'fa-trash']"  class="delete" /><span class="tooltip">Delete</span></a>
                          <a href="#" @click="showForm('upload-picture-sightseeing',excursion)" class="text-warning mr-3 faq-button" role="button"><font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']" class="upload"/><span class="tooltip">Picture</span></a>
                        </div>
                    </td>                            
                </tr>
              <!-- </template> -->
            </tbody>
          </table>
      </div>
      <div v-else>

        <div class="form-section">                      
          <!-- <h4>{{ formTitle }}</h4> -->
          <div class="form-group row mb-3">
            
            <label for="destination" class="col-sm-3 col-form-label">Select Destination <span style="color:red !important">*</span></label>                    
            <div class="col-sm-3">
                <select v-model="excursionInfo.destinationId"  @change="onChangeByDestination" class="form-control" id="destination" required :disabled="excursionInfo.sightseeingCode.trim() !== ''">
                    <option disabled value="">--Please select a destination--</option>
                    <option v-for="destination in destinations.list" :key="destination.id" :value="destination.id">
                        {{ destination.destinationName }}
                    </option>
                </select>                
                <div class="text-danger" v-for="(error, index) in v$.excursionInfo.destinationId.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
          <!-- </div>
          <div class="form-group row mb-3"> -->            
            <label for="city" class="col-sm-3 col-form-label">Select City <span style="color:red !important">*</span></label>
            <div class="col-sm-3">
              <select v-model="excursionInfo.cityId" class="form-control" id="city" required  :disabled="excursionInfo.sightseeingCode.trim() !== ''">
                  <option disabled value="">-- Please select a city --</option>
                  <option v-for="city in cities.list" :key="city.id" :value="city.id">
                      {{ city.cityName }}
                  </option>
              </select>              
              <div class="text-danger" v-for="(error, index) in v$.excursionInfo.cityId.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>                    
          </div>
          
          <div class="form-group row mb-3">                
            <label for="sightseeingcode" class="col-sm-3 col-form-label">Sightseeing Code <span style="color:red !important">*</span></label>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="sightseeingcode" v-model="excursionInfo.sightseeingCode" placeholder="Enter unique Sightseeing Code.">
            </div>

            <label for="sightseeingname" class="col-sm-3 col-form-label">Sightseeing Name <span style="color:red !important">*</span> </label>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="sightseeingname" v-model="excursionInfo.sightseeingName" placeholder="Enter Sightseeing Name">                    
            </div>                
          </div>
          <div class="form-section">
            <h5>Sightseeing Descriptions</h5> 
            <hr/>

            <div class="form-group row mb-3">
              <label for="description" class="col-sm-3 col-form-label">Description </label>
              <div class="col-sm-6">
                <textarea type="text" class="form-control" id="description" v-model="excursionInfo.description" placeholder="Enter description"></textarea>              
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="shortdescription" class="col-sm-3 col-form-label">Short Description</label>
              <div class="col-sm-6">
                  <input type="text" v-model="excursionInfo.shortDescription" class="form-control" id="shortdescription" >
              </div>       
            </div>
            <div class="form-group row mb-3">
              <label for="shortdescription-point-display-in" class="col-sm-3 col-form-label">Short Description Display In</label>
              <div class="col-sm-9 mt-2">
                <div class="form-group-inline-flex mr-3 col-sm-3" v-for="showIn in showInOptions" :key="showIn.value"> 
                  <input type="checkbox" v-model="excursionInfo.shortDescriptionDisplayIn" :id="showIn.value" :value="showIn.value"> 
                  <label class="ml-3" :for="showIn.value"> {{ showIn.label }} </label> 
                </div>
              </div>
            </div>
          </div>

          <div class="form-section">
            <h5>Sightseeing Activity</h5> 
            <hr/>
            <div class="form-group row mb-3">
              <label for="startTime" class="col-sm-3 col-form-label">Start Time</label>
              <div class="col-sm-6">
                  <input type="text" v-model="excursionInfo.startTime" class="form-control" id="startTime" placeholder="Start Time" >
              </div>       
            </div>
            <div class="form-group row mb-3">
              <label for="startTime-display-in" class="col-sm-3 col-form-label">Start Time Display In</label>
              <div class="col-sm-9 mt-2">
                <div class="form-group-inline-flex mr-3 col-sm-3" v-for="showIn in showInOptions" :key="showIn.value"> 
                  <input type="checkbox" v-model="excursionInfo.startTimeShowIn" :id="showIn.value" :value="showIn.value"> 
                  <label class="ml-3" :for="showIn.value"> {{ showIn.label }} </label> 
                </div>
              </div>
            </div>

            <div class="form-group row mb-3">
              <label for="finalPrice" class="col-sm-3 col-form-label">Meeting Point</label>
              <div class="col-sm-6">
                  <input type="text" v-model="excursionInfo.meetingPoint" class="form-control" id="finalPrice" placeholder="Meeting Point">
              </div>       
            </div>
            <div class="form-group row mb-3">
              <label for="meeting-point-display-in" class="col-sm-3 col-form-label">Meeting Point Display In</label>
              <div class="col-sm-9 mt-2">
                <div class="form-group-inline-flex mr-3 col-sm-3" v-for="showIn in showInOptions" :key="showIn.value"> 
                  <input type="checkbox" v-model="excursionInfo.meetingPointDisplayIn" :id="showIn.value" :value="showIn.value"> 
                  <label class="ml-3" :for="showIn.value"> {{ showIn.label }} </label> 
                </div>
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="meetingLocationLatitude" class="col-sm-3 col-form-label">Metting Point Latitude</label>
              <div class="col-sm-3">
                <input type="number" step="0.000001" class="form-control" id="meetingLocationLatitude" v-model="excursionInfo.meetingPointLocationLatitude" placeholder="Latitude">
                <!-- <div class="text-danger" v-for="(error, index) in v$.excursionInfo.meetingPointLocationLatitude.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div> -->
              </div>
              <div class="col-sm-3">
                <input type="number" step="0.000001" class="form-control" id="meetingLocationLongitude" v-model="excursionInfo.meetingPointLocationLongitude" placeholder="Longitude">
                <!-- <div class="text-danger" v-for="(error, index) in v$.excursionInfo.meetingLocationLongitude.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>   -->
              </div>
            </div>

            <div class="form-group row mb-3">
              <label for="local-partner-no" class="col-sm-3 col-form-label">Local Partner Number</label>
              <div class="col-sm-6">
                <input type="number" v-model="excursionInfo.partnerNumber" class="form-control" id="local-partner-no" placeholder="Local Partner Number">
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="local-partner-number-display-in" class="col-sm-3 col-form-label">Local Partner Number Display In</label>
              <div class="col-sm-9 mt-2">
                <div class="form-group-inline-flex mr-3 col-sm-3" v-for="showIn in showInOptions" :key="showIn.value"> 
                  <input type="checkbox" v-model="excursionInfo.partnerNumberDisplayIn" :id="showIn.value" :value="showIn.value"> 
                  <label class="ml-3" :for="showIn.value"> {{ showIn.label }} </label> 
                </div>
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="how-to-get-there" class="col-sm-3 col-form-label">How to Get there</label>
              <div class="col-sm-6">
                <input type="text" v-model="excursionInfo.howToGetThere" class="form-control" id="how-to-get-there" placeholder="How to Get there">
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="how-to-get-there-display-in" class="col-sm-3 col-form-label">How to Get there display In</label>
              <div class="col-sm-9 mt-2">
                <div class="form-group-inline-flex mr-3 col-sm-3" v-for="showIn in showInOptions" :key="showIn.value"> 
                  <input type="checkbox" v-model="excursionInfo.howToGetThereDisplayIn" :id="showIn.value" :value="showIn.value"> 
                  <label class="ml-3" :for="showIn.value"> {{ showIn.label }} </label> 
                </div>
              </div>
            </div>

            <div class="form-group row mb-3">
              <label for="activity" class="col-sm-3 col-form-label">Details of Activity</label>
              <div class="col-sm-6">
                <input type="text" v-model="excursionInfo.detailsOfActivity" class="form-control" id="how-to-get-there">
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="activity-display-in" class="col-sm-3 col-form-label">Details of Activity Display In</label>
              <div class="col-sm-9 mt-2">
                <div class="form-group-inline-flex mr-3 col-sm-3" v-for="showIn in showInOptions" :key="showIn.value"> 
                  <input type="checkbox" v-model="excursionInfo.detailsOfActivityDisplayIn" :id="showIn.value" :value="showIn.value"> 
                  <label class="ml-3" :for="showIn.value"> {{ showIn.label }} </label> 
                </div>
              </div>
            </div>

            <div class="form-group row mb-3">
              <label for="voucher" class="col-sm-3 col-form-label">Redemption of Voucher</label>
              <div class="col-sm-6">
                <input type="text" v-model="excursionInfo.redemptionOfVoucher" class="form-control" id="voucher">
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="voucher-display-in" class="col-sm-3 col-form-label">Redemption of Voucher Display In</label>
              <div class="col-sm-9 mt-2">
                <div class="form-group-inline-flex mr-3 col-sm-3" v-for="showIn in showInOptions" :key="showIn.value"> 
                  <input type="checkbox" v-model="excursionInfo.redemptionOfVoucherDisplayIn" :id="showIn.value" :value="showIn.value"> 
                  <label class="ml-3" :for="showIn.value"> {{ showIn.label }} </label> 
                </div>
              </div>
            </div>

            <div class="form-group row mb-3">
              <label for="others" class="col-sm-3 col-form-label">Other Information</label>
              <div class="col-sm-6">
                <input type="text" v-model="excursionInfo.others" class="form-control" id="voucher">
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="others-display-in" class="col-sm-3 col-form-label">Others to be Shown In</label>
              <div class="col-sm-9 mt-2">
                <div class="form-group-inline-flex mr-3 col-sm-3" v-for="showIn in showInOptions" :key="showIn.value"> 
                  <input type="checkbox" v-model="excursionInfo.othersDisplayIn" :id="showIn.value" :value="showIn.value"> 
                  <label class="ml-3" :for="showIn.value"> {{ showIn.label }} </label> 
                </div>
              </div>
            </div>

            
            <div class="form-group row mb-3">
              <label for="youtube-link" class="col-sm-3 col-form-label">YouTube Link</label>
              <div class="col-sm-6">
                <input type="text" v-model="excursionInfo.youTubeLink" class="form-control" id="youtube-link" placeholder="YouTube Embed Link for Sightseeing Video">
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="youtube-link-display-in" class="col-sm-3 col-form-label">YouTube Link Display In</label>
              <div class="col-sm-9 mt-2">
                <div class="form-group-inline-flex mr-3 col-sm-3" v-for="showIn in showInOptions" :key="showIn.value"> 
                  <input type="checkbox" v-model="excursionInfo.youTubeLinkDisplayIn" :id="showIn.value" :value="showIn.value"> 
                  <label class="ml-3" :for="showIn.value"> {{ showIn.label }} </label> 
                </div>
              </div>
            </div>
          </div>          

          <div class="form-section">            
            <div class="form-group row mb-3 d-flex align-items-center">
                <label for="status" class="col-sm-3 col-form-label">Status</label>                
                <div class="col-sm-2 d-flex align-items-center m-0">
                    <input type="radio" v-model="excursionInfo.status" class="form-check-input m-0 me-2" id="statusActive" value="Active">
                    <label for="statusActive" class="form-check-label">Active</label>
                </div>
                <div class="col-sm-2 d-flex align-items-center m-0">
                    <input type="radio" v-model="excursionInfo.status" class="form-check-input m-0 me-2" id="statusInactive" value="Inactive">
                    <label for="statusInactive" class="form-check-label">Inactive</label>
                </div>            
            </div>            
          </div>
        </div>
          
        <hr>
        <div class="form-actions">
          <button type="submit" class="btn btn-success" @click="saveSightseeing" v-if="!isEdit" :disabled="!isFormValid"> <font-awesome-icon :icon="['fas', 'floppy-disk']" /> Save </button>
          <button type="submit" class="btn btn-success" @click="updateExcursion" v-if="isEdit" > <font-awesome-icon :icon="['fas', 'file-pen']" /> Update </button>
          <button type="button" class="btn btn-light" @click="cancel"> <font-awesome-icon :icon="['fas', 'xmark']" /> Cancel</button>
        </div>
        <br>
      </div> <!-- End of List View-->     
    </div>
    <div v-else>
      <div class=" row">
        <div class="col-sm-3"></div>
        <div class="col-sm-3"></div>        
        <div class="col-sm-6 d-flex justify-content-end mb-2">
          <div v-if="computedShowPackageListView">
    <form class="search-input me-2">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div> 

          <button type="button" class="btn btn-success me-2" @click="showForm('create-new-package')" v-if="computedShowPackageListView"><font-awesome-icon :icon="['fas', 'fa-plus']" class="plus text-white me-2"/>Create New Package </button>
          <button type="button" class="btn btn-success me-2" @click="showForm('back-to-sightseeing')"><font-awesome-icon :icon="['fas', 'fa-arrow-left']" class="me-2" />Back to Sightseeing </button>
        </div>
      </div>

      <div v-if="computedShowPackageListView">
        <h4>List of Packages</h4>
        <h4>{{  excursionInfo.packageName }}</h4>
        <div class="table-container">  
        <table class="table table-bordered mt-3 custom-table-width">
          <thead>
            <tr>
              <th>SI.No</th>
              <th>Package Name</th>  
              <th>Inclusion</th>                  
              <th>Note for Customer</th>
              <th>Note for Admin</th>
              <th>Note for Agent</th>
              <th>Partner</th>
              <th>Actual Price</th>                           
              <th>Company Markup</th>
              <th>Company MarkupIn</th>              
              <th>Currency</th>
              <th>Final Price</th>
              <th>Tour Type</th>
              <th>Tour Duration</th>                
              <th>Status</th> 
              <th style="width: 200px;">Actions</th>
            </tr>
          </thead>
          <tbody>            
            <tr v-for="(pkg, tIndex) in paginatedSightSeeing" :key="tIndex">     
              <td>{{ (currentPage - 1) * itemsPerPage + (tIndex + 1) }}</td>                     
              <td class="text-nowrap">{{ pkg.packageName }}</td>
              <td class="text-nowrap" >{{ pkg.inclusions }}</td>
              <td>{{ pkg.customerNote }}</td>
              <td>{{ pkg.adminNote }}</td>
              <td class="text-nowrap">{{ pkg.agentNote }}</td>
              <td>{{ pkg.partner }}</td>
              <td>{{ pkg.actualPrice }}</td>
              <td>{{ pkg.companyMarkup }}</td>
              <td>{{ pkg.companyMarkupType }}</td>
              <td>{{ pkg.currency }}</td>
              <td>{{ pkg.finalPrice }}</td>              
              <td>{{ pkg.tourType }}</td>
              <td>{{ pkg.tourDuration }}</td>
              <td>{{ pkg.status  }}</td>                
              <td>
                <div class="d-flex align-items-baseline m-0">
                  <!-- <a href="#" @click="showForm('create-new-package')" class="text-warning mr-3" role="button"> <i class="bi bi-plus"></i></a> -->
                  <a href="#" @click="showForm('edit-package',pkg,tIndex)" class="text-warning mr-3 faq-button" role="button"> <font-awesome-icon :icon="['fas', 'fa-edit']" class="edit" /><span class="tooltip">Edit</span></a>
                  <a href="#" @click="confirmDeletePackage(tIndex)" class="text-warning mr-3 faq-button" role="button"> <font-awesome-icon :icon="['fas', 'fa-trash']"  class="delete" /><span class="tooltip">Delete</span></a>    
                </div>
              </td>
            </tr>            
          </tbody>
        </table>
        </div>
      </div>
      <div v-else>        
        <div class="form-section">
          <h5>Package Details</h5>
          <hr/>
          <div class="form-group row mb-3">
            <label for="packagename" class="col-sm-3 col-form-label">Package Name <span style="color:red !important">*</span> </label>
            <div class="col-sm-6">
              <textarea type="text" class="form-control" id="hotelLocation" v-model="packageInfo.packageName" placeholder="Enter package name"></textarea>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="tour-type" class="col-sm-3 col-form-label">Tour Type <span style="color:red !important">*</span></label>
            <div class="col-sm-3">
                <input type="text" min="0" class="form-control" id="tour-type" v-model="packageInfo.tourType" placeholder="Tour Type(Guided, Self Guided,Budget,Luxury,ect..)">
            </div>
            <label for="tour-duration" class="col-sm-3 col-form-label">Tour Duration <span style="color:red !important">*</span></label>
            <div class="col-sm-3">
              <input type="text" min="0" class="form-control" id="tour-duration" v-model="packageInfo.tourDuration" placeholder="Tour Duration(1 hour, 2 hour.. half-day, full-day)">
            </div>
          </div>
        </div>
        <div class="form-section">
          <h5>Price Details </h5>
          <hr/>
          <div class="form-group row mb-3">
            <label for="partners" class="col-sm-3 col-form-label">Select partner </label>                    
            <div class="col-sm-3">
                <select v-model="packageInfo.partner"  class="form-control" id="partners" >
                    <option disabled value="">--Please select a partner--</option>
                    <option v-for="partner in partners.list " :key="partner.partnerCode" :value="partner.partnerCode">
                        {{ partner.partnerName }}
                    </option>
                </select>
            </div>              
            <label for="partners" class="col-sm-3 col-form-label">Select Currency </label>                    
            <div class="col-sm-3">
                <select v-model="packageInfo.currency"  class="form-control" id="partners">
                    <option disabled value="">--Please select currency--</option>
                    <option v-for="currency in currencies " :key="currency" :value="currency">
                            {{ currency }}
                    </option>                      
                </select>
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="actualPrice" class="col-sm-3 col-form-label">Actual Price </label>
            <div class="col-sm-3">
                <input type="number" step="0.01" min="0" class="form-control" id="actualPrice" v-model="packageInfo.actualPrice" placeholder="Enter actual price"  @input="limitDecimals(packageInfo, 'actualPrice')">
            </div>              
            <label for="companyMarkupType" class="col-sm-3 col-form-label">Select Company Markup In </label>
            <div class="col-sm-3">
              <select v-model="packageInfo.companyMarkupType"  class="form-control" id="companyMarkupType" >
                <option disabled value="">-- Please select a markup type --</option>
                <option value="percentage">Percentage</option>
                <option value="absolute">Absolute</option>
              </select>  
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="companyMarkup" class="col-sm-3 col-form-label">Company Markup </label>
            <div class="col-sm-3">
                <input type="number" min="0" class="form-control" id="companyMarkup" v-model="packageInfo.companyMarkup" placeholder="Enter price"  @input="limitDecimals(packageInfo, 'companyMarkup')">
            </div>
            <label for="finalPrice" class="col-sm-3 col-form-label">Final Price </label>
            <div class="col-sm-3">
                <input type="number" step="0.01" class="form-control" id="finalPrice" :value="calculateFinalPrice(packageInfo)" readonly>
            </div>
          </div>
        </div>

        <div class="form-section">
          <h5>Notes Details</h5>
          <hr/>
          <div class="form-group row mb-3">
            <label for="hotelLocation" class="col-sm-3 col-form-label">Inclusions </label>
            <div class="col-sm-6">
              <textarea type="text" class="form-control" id="hotelLocation" v-model="packageInfo.inclusions" placeholder="Enter inclusions"></textarea>
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="hotelLocation" class="col-sm-3 col-form-label">Notes for Customer </label>
            <div class="col-sm-6">
              <textarea type="text" class="form-control" id="hotelLocation" v-model="packageInfo.customerNote" placeholder="Notes to customer "></textarea>
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="hotelLocation" class="col-sm-3 col-form-label">Notes for Agent </label>
            <div class="col-sm-6">
              <textarea type="text" class="form-control" id="hotelLocation" v-model="packageInfo.agentNote" placeholder="Notes to Agent"></textarea>
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="hotelLocation" class="col-sm-3 col-form-label">Notes for Admin </label>
            <div class="col-sm-6">
              <textarea type="text" class="form-control" id="hotelLocation" v-model="packageInfo.adminNote" placeholder="Notes to Admin"></textarea>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="hotelLocation" class="col-sm-3 col-form-label">Notes for Sub-Admin </label>
            <div class="col-sm-6">
              <textarea type="text" class="form-control" id="hotelLocation" v-model="packageInfo.subAdminNote" placeholder="Notes to sub-admin"></textarea>
            </div>
          </div>  
        </div>

        <div class="form-section align-items-center">
          <div class="form-group row mb-3 d-flex align-items-center">
            <label for="status" class="col-sm-3 col-form-label">Status</label>                
            <div class="col-sm-2 d-flex align-items-center">
                <input type="radio" v-model="packageInfo.status" class="form-check-input me-2 m-0" id="statusActive" value="Active">
                <label for="statusActive" class="form-check-label m-0">Active</label>
            </div>
            <div class="col-sm-2 d-flex align-items-center">
                <input type="radio" v-model="packageInfo.status" class="form-check-input me-2 m-0" id="statusInactive" value="Inactive">
                <label for="statusInactive" class="form-check-label m-0">Inactive</label>
            </div>            
          </div>
        </div>
        <hr>
        <div class="form-actions">
          <button type="submit" class="btn btn-success" @click="addPackage" v-if="!packageEdit" :disabled="!isFormValid" > <font-awesome-icon :icon="['fas', 'floppy-disk']" /> Save </button>
          <button type="submit" class="btn btn-success" @click="updatePackage" v-if="packageEdit" > <font-awesome-icon :icon="['fas', 'file-pen']" /> Update </button>
          <button type="button" class="btn btn-light" @click="resetPackage"> <font-awesome-icon :icon="['fas', 'xmark']" /> Cancel</button>
        </div>
      </div>
    </div>
    <DeleteModal
  :visible="showDeleteModal"
  @confirmDelete="deleteExcursion"
  @cancelDelete="showDeleteModal = false"
  />    
  <DeleteModal
  :visible="showDeletePackageModal"
  @confirmDelete="deletePackage"
  @cancelDelete="showDeletePackageModal = false"
  />    
  <div class="d-flex justify-content-between mt-3" v-if="computedShowListView">
      <div class="d-flex align-items-center mt-0">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredExcursions.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination mb-0" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>   
    </div>
    <div class="d-flex justify-content-between mt-3" v-if="computedShowPackageListView">
      <div class="d-flex align-items-center mt-0">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPaginationSight" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredSightseeing.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination mb-0" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPageSight(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRangeSight()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPageSight(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPageSight(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>   
    </div>
  </div> <!-- end of container -->
</template>
<script>
    import axios from 'axios';
    import { reactive,  onMounted, ref, computed, watch } from 'vue';
    import { API_CONFIG } from '@/apiConfig';
    import { required, numeric, minValue,between} from '@vuelidate/validators'
    import useVuelidate from '@vuelidate/core'
    import 'bootstrap-icons/font/bootstrap-icons.css'
    import { useRouter } from 'vue-router';
    import DeleteModal from './DeleteModal.vue';
    import currencies from '@/data/currencies';


    
    // import { toRefs } from 'vue';
    // import { useRouter } from 'vue-router';
    
    export default {
      components: {
        DeleteModal,
        },
        setup() {
            
            const excursionType = ref("sightseeing");
            const router = useRouter();
            const showDeleteModal = ref(false);
            const excursionToDelete = ref(null);
            const showDeletePackageModal = ref(false);
            const packageToDelete = ref(null);
            const searchQuery = ref('');
            const currentPage = ref(1);
            const itemsPerPage = ref(10);

            const excursions =  reactive({ list: [] });
            const destinations =  reactive({ list: [] });            
            const cities = reactive( { list :[] });            
            //const airports = reactive({ list: [] });
            const packages = reactive({ list: [] });
            const partners = reactive({ list: [] });
            const excursionInfo = reactive({ 
              destinationId : '',
              cityId : '',
              sightseeingCode : '',
              sightseeingName : '',
              description : '',
              shortDescription : '',
              shortDescriptionDisplayIn : [],
              startTime : '',
              startTimeShowIn: [],
              meetingPoint: '',
              meetingPointDisplayIn : [],
              meetingPointLocationLatitude : 0,
              meetingPointLocationLongitude : 0,
              partnerNumber : '',
              partnerNumberDisplayIn : [],
              howToGetThere : '',
              howToGetThereDisplayIn : [],
              detailsOfActivity : '',
              detailsOfActivityDisplayIn : [],
              redemptionOfVoucher : '',
              redemptionOfVoucherDisplayIn : [],
              others : '',
              othersDisplayIn : [],
              youTubeLink: '',
              youTubeLinkDisplayIn : [],
              packageDetails : [],
              status : 'Active'
            });

            const packageInfo = reactive({          
              id : '',
              packageName : '',
              tourType : '',
              tourDuration  : 0,
              partner: '',                
              currency : '',                           
              actualPrice  : '0',
              companyMarkupType : '', 
              companyMarkup  : 0,
              finalPrice  : '0',           
              inclusions : '',
              customerNote: '',             
              agentNote: '',
              adminNote: '',
              subAdminNote : '',
              description : '',
              status: 'Active'
            });

            const limitDecimals = (obj, field) => {
  if (obj[field] !== undefined && obj[field] !== null) {
    const valueStr = obj[field].toString();
    const decimalIndex = valueStr.indexOf('.');

    if (decimalIndex !== -1 && valueStr.length - decimalIndex > 3) {
      obj[field] = parseFloat(valueStr.substring(0, decimalIndex + 3));
    }
  }
};

            const filteredExcursions = computed(() => {
  return excursions.list.filter(excursion => {
    return (
      excursion.sightseeingName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      excursion.sightseeingCode.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      excursion.destinationName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      excursion.cityName.toLowerCase().includes(searchQuery.value.toLowerCase()) 
    );
  });
});

const getPageRange = () => {
  const range = [];
  const total = totalPages.value;
  const current = currentPage.value;
  const delta = 2;

  if (current > delta + 1) {
    range.push(1);
    if (current > delta + 2) range.push('...');
  }

  for (let i = Math.max(1, current - delta); i <= Math.min(total, current + delta); i++) {
    range.push(i);
  }

  if (current < total - delta - 1) {
    if (current < total - delta - 2) range.push('...');
    range.push(total);
  }

  return range;
};

const resetPagination = () => {
  currentPage.value = 1;
};

const goToPage = (page) => {
  const pageNum = Number(page);
  if (pageNum >= 1 && pageNum <= totalPages.value) {
    currentPage.value = pageNum;
  }
};

const paginatedUsers = computed(() => {
  const pageSize = Number(itemsPerPage.value) || 10;
  const current = Number(currentPage.value) || 1;
  
  const startIndex = (current - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  
  if (!filteredExcursions.value.length) return [];
  
  return filteredExcursions.value.slice(startIndex, endIndex);
});

const totalPages = computed(() => {
  const pageSize = Number(itemsPerPage.value) || 10;
  return Math.max(1, Math.ceil(filteredExcursions.value.length / pageSize));
});

watch(filteredExcursions, () => {
  if (currentPage.value > totalPages.value) {
    currentPage.value = totalPages.value || 1;
  }
});

watch(itemsPerPage, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    resetPagination();
  }
});

const filteredSightseeing = computed(() => {
  return packages.list.filter(pkg => {
    return (
      pkg.packageName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      pkg.inclusions.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      pkg.partner.toLowerCase().includes(searchQuery.value.toLowerCase()) 
    );
  });
});

const getPageRangeSight = () => {
  const range = [];
  const total = totalPagesInformation.value;
  const current = currentPage.value;
  const delta = 2;

  if (current > delta + 1) {
    range.push(1);
    if (current > delta + 2) range.push('...');
  }

  for (let i = Math.max(1, current - delta); i <= Math.min(total, current + delta); i++) {
    range.push(i);
  }

  if (current < total - delta - 1) {
    if (current < total - delta - 2) range.push('...');
    range.push(total);
  }

  return range;
};

const resetPaginationSight = () => {
  currentPage.value = 1;
};

const goToPageSight = (page) => {
  const pageNum = Number(page);
  if (pageNum >= 1 && pageNum <= totalPagesInformation.value) {
    currentPage.value = pageNum;
  }
};

const paginatedSightSeeing = computed(() => {
  const pageSize = Number(itemsPerPage.value) || 10;
  const current = Number(currentPage.value) || 1;
  
  const startIndex = (current - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  
  if (!filteredSightseeing.value.length) return [];
  
  return filteredSightseeing.value.slice(startIndex, endIndex);
});

const totalPagesInformation = computed(() => {
  const pageSize = Number(itemsPerPage.value) || 10;
  return Math.max(1, Math.ceil(filteredSightseeing.value.length / pageSize));
});

watch(filteredSightseeing, () => {
  if (currentPage.value > totalPagesInformation.value) {
    currentPage.value = totalPagesInformation.value || 1;
  }
});

watch(itemsPerPage, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    resetPaginationSight();
  }
});

const clearSearch = () => {
  searchQuery.value = '';
};

            const generateRoomId = (destinationId, cityId, sightseeingCode, packageName) => {
              const pkgName = packageName ? packageName.replace(/\s+/g, '') : '';
              const sCode = sightseeingCode ? sightseeingCode.replace(/\s+/g, '') : '';
              return `${ destinationId }_${ cityId }_${ sCode }_${ pkgName }`;
            };

            watch(
                [ () => excursionInfo.destinationId, () => excursionInfo.cityId, () => excursionInfo.sightseeingCode, () => packageInfo.packageName ], 
                () => { 
                  packageInfo.id = generateRoomId( excursionInfo.destinationId, excursionInfo.cityId, excursionInfo.sightseeingCode, packageInfo.packageName);
                }, { immediate: true }
            );

            const maxValue = 5;
            const items = ref([ { 
              content: 'This is the content for Accordion Item #1' }, 
              { content: 'This is the content for Accordion Item #2' } 
            ]); 
            const activeIndex = ref(null); 
            const toggle = (index) => { activeIndex.value = activeIndex.value === index ? null : index; }; 
            const addItem = () => { 
              { if (items.value.length < maxValue)
                items.value.push({ content: `This is the content for Accordion Item #${items.value.length + 1}` }); 
                activeIndex.value = items.value.length - 1; 
              }
            };
            const removeItem = (index) => { 
              items.value.splice(index, 1); 
              if (activeIndex.value === index) { 
                activeIndex.value = null; 
              } else if (activeIndex.value > index) { 
                activeIndex.value -= 1; 
              } 
            };
            const isFormValid = computed(() => {
              return (
                excursionInfo.destinationId &&
                excursionInfo.cityId &&
                excursionInfo.sightseeingCode &&
                excursionInfo.sightseeingName
              );
            });

            const showInOptions = reactive([ 
              { value: 'quotation', label: 'Quotation Itinerary' }, 
              { value: 'final', label: 'Final  Itinerary' }, 
              { value: 'website', label: 'Website' }, 
            ]);
            
            const showSightseeing = ref(true);
            const showListView = ref(true);
            const showPackageListView = ref(false);

            const computedShowListView = computed(() => showSightseeing.value && showListView.value);
            const computedShowPackageListView = computed(() => !showSightseeing.value && showPackageListView.value);
            

            const isEdit = ref(false);
            const packageEdit = ref(false);
            const selectedIndex = ref(-1);
            const errorMessage = ref("");

            const excursion_id = ref("");                        

            const rules = { 
              excursionInfo: {
                destinationId: { required }, 
                cityId: { required },
                sightseeingCode : { required},
                //tourDuration : { required },
                actualPrice : { numeric, minValue:minValue(0.0) },
                meetingPointLocationLatitude :  { between: between(-90, 90) },
                meetingLocationLongitude : { between: between(-180, 180) },                
                
              }
            };

            const v$ = useVuelidate(rules, { excursionInfo });
         
            // const currencies = ref (
            // [
            //     { value: 1, name: "AED", rate: 3.673 },
            //     { value: 2, name: "AFN", rate: 70.483864 },
            //     { value: 3, name: "ALL", rate: 94.101325 },
            //     { value: 4, name: "AMD", rate: 400.326463 },
            //     { value: 5, name: "ANG", rate: 1.804345 },
            //     { value: 6, name: "AOA", rate: 912 },
            //     { value: 7, name: "ARS", rate: 1030.200329 },
            //     { value: 8, name: "AUD", rate: 1.608752 },
            //     { value: 9, name: "AWG", rate: 1.8 },
            //     { value: 10, name: "AZN", rate: 1.7 },
            //     { value: 11, name: "BAM", rate: 1.875797 },
            //     { value: 12, name: "BBD", rate: 2 },
            //     { value: 13, name: "BDT", rate: 119.666241 },
            //     { value: 14, name: "BGN", rate: 1.875898 },
            //     { value: 15, name: "BHD", rate: 0.377452 },
            //     { value: 16, name: "BIF", rate: 2960.624136 },
            //     { value: 17, name: "BMD", rate: 1 },
            //     { value: 18, name: "BND", rate: 1.360284 },
            //     { value: 19, name: "BOB", rate: 6.917949 },
            //     { value: 20, name: "BRL", rate: 6.19575 },
            //     { value: 21, name: "BSD", rate: 1 },
            //     { value: 22, name: "BTC", rate: 0.000010551195 },
            //     { value: 23, name: "BTN", rate: 85.655778 },
            //     { value: 24, name: "BWP", rate: 13.925095 },
            //     { value: 25, name: "BYN", rate: 3.276459 },
            //     { value: 26, name: "BZD", rate: 2.011126 },
            //     { value: 27, name: "CAD", rate: 1.44155 },
            //     { value: 28, name: "CDF", rate: 2848.433737 },
            //     { value: 29, name: "CHF", rate: 0.901912 },
            //     { value: 30, name: "CLF", rate: 0.035968 },
            //     { value: 31, name: "CLP", rate: 992.480695 },
            //     { value: 32, name: "CNH", rate: 7.3006 },
            //     { value: 33, name: "CNY", rate: 7.2988 },
            //     { value: 34, name: "COP", rate: 4398.4079 },
            //     { value: 35, name: "CRC", rate: 507.936084 },
            //     { value: 36, name: "CUC", rate: 1 },
            //     { value: 37, name: "CUP", rate: 25.75 },
            //     { value: 38, name: "CVE", rate: 105.754547 },
            //     { value: 39, name: "CZK", rate: 24.188 },
            //     { value: 40, name: "DJF", rate: 178.286115 },
            //     { value: 41, name: "DKK", rate: 7.154 },
            //     { value: 42, name: "DOP", rate: 60.892915 },
            //     { value: 43, name: "DZD", rate: 135.55545 },
            //     { value: 44, name: "EGP", rate: 50.85791 },
            //     { value: 45, name: "ERN", rate: 15 },
            //     { value: 46, name: "ETB", rate: 127.756654 },
            //     { value: 47, name: "EUR", rate: 0.959325 },
            //     { value: 48, name: "FJD", rate: 2.3224 },
            //     { value: 49, name: "FKP", rate: 0.795197 },
            //     { value: 50, name: "GBP", rate: 0.795197 },
            //     { value: 51, name: "GEL", rate: 2.799991 },
            //     { value: 52, name: "GGP", rate: 0.795197 },
            //     { value: 53, name: "GHS", rate: 14.717307 },
            //     { value: 54, name: "GIP", rate: 0.795197 },
            //     { value: 55, name: "GMD", rate: 71.9954 },
            //     { value: 56, name: "GNF", rate: 8653.957027 },
            //     { value: 57, name: "GTQ", rate: 7.718794 },
            //     { value: 58, name: "GYD", rate: 209.370329 },
            //     { value: 59, name: "HKD", rate: 7.7617 },
            //     { value: 60, name: "HNL", rate: 25.438066 },
            //     { value: 61, name: "HRK", rate: 7.22598 },
            //     { value: 62, name: "HTG", rate: 130.906835 },
            //     { value: 63, name: "HUF", rate: 394.11 },
            //     { value: 64, name: "IDR", rate: 16185 },
            //     { value: 65, name: "ILS", rate: 3.684266 },
            //     { value: 66, name: "IMP", rate: 0.795197 },
            //     { value: 67, name: "INR", rate: 85.39575 },
            //     { value: 68, name: "IQD", rate: 1311.56349 },
            //     { value: 69, name: "IRR", rate: 42250 },
            //     { value: 70, name: "ISK", rate: 139.16462 },
            //     { value: 71, name: "JEP", rate: 0.795197 },
            //     { value: 72, name: "JMD", rate: 155.843283 },
            //     { value: 73, name: "JOD", rate: 0.7091 },
            //     { value: 74, name: "JPY", rate: 157.825 },
            //     { value: 75, name: "KES", rate: 129.650403 },
            //     { value: 76, name: "KGS", rate: 86.9994 },
            //     { value: 77, name: "KHR", rate: 4021.491937 },
            //     { value: 78, name: "KMF", rate: 471.824 },
            //     { value: 79, name: "KPW", rate: 900 },
            //     { value: 80, name: "KRW", rate: 1473.797724 },
            //     { value: 81, name: "KWD", rate: 0.308445 },
            //     { value: 82, name: "KYD", rate: 0.834316 },
            //     { value: 83, name: "KZT", rate: 524.068491 },
            //     { value: 84, name: "LAK", rate: 21884.539363 },
            //     { value: 85, name: "LBP", rate: 89661.111105 },
            //     { value: 86, name: "LKR", rate: 292.859536 },
            //     { value: 87, name: "LRD", rate: 182.218385 },
            //     { value: 88, name: "LSL", rate: 18.755383 },
            //     { value: 89, name: "LYD", rate: 4.923033 },
            //     { value: 90, name: "MAD", rate: 10.101472 },
            //     { value: 91, name: "MDL", rate: 18.461613 },
            //     { value: 92, name: "MGA", rate: 4696.722597 },
            //     { value: 93, name: "MKD", rate: 59.010694 },
            //     { value: 94, name: "MK", rate: 2098 },
            //     { value: 95, name: "MNT", rate: 3398 },
            //     { value: 96, name: "MOP", rate: 8.004412 },
            //     { value: 97, name: "MRO", rate: 357 },
            //     { value: 98, name: "MRU", rate: 39.937659 },
            //     { value: 99, name: "MUR", rate: 46.949997 },
            //     { value: 100, name: "MVR", rate: 15.4 },
            //     { value: 101, name: "MWK", rate: 1736.05821 },
            //     { value: 102, name: "MXN", rate: 20.33 },
            //     { value: 103, name: "MYR", rate: 4.4715 },
            //     { value: 104, name: "MZN", rate: 63.830001 },
            //     { value: 105, name: "NAD", rate: 18.755383 },
            //     { value: 106, name: "NGN", rate: 1550.283356 },
            //     { value: 107, name: "NIO", rate: 36.848416 },
            //     { value: 108, name: "NOK", rate: 11.3578 },
            //     { value: 109, name: "NPR", rate: 136.97173 },
            //     { value: 110, name: "NZD", rate: 1.775411 },
            //     { value: 111, name: "OMR", rate: 0.38501 },
            //     { value: 112, name: "PAB", rate: 1 },
            //     { value: 113, name: "PEN", rate: 3.746512 },
            //     { value: 114, name: "PGK", rate: 3.98854 },
            //     { value: 115, name: "PHP", rate: 57.918 },
            //     { value: 116, name: "PKR", rate: 278.705477 },
            //     { value: 117, name: "PLN", rate: 4.101568 },
            //     { value: 118, name: "PYG", rate: 7784.056779 },
            //     { value: 119, name: "QAR", rate: 3.64878 },
            //     { value: 120, name: "RON", rate: 4.7731 },
            //     { value: 121, name: "RSD", rate: 112.137345},
            //     { value: 122, name: "RUB", rate: 105.500406},
            //     { value: 123, name: "RWF", rate: 1381.183031},
            //     { value: 124, name: "SAR", rate: 3.7553},
            //     { value: 125, name: "SBD", rate: 8.38356},
            //     { value: 126, name: "SCR", rate: 13.9432},
            //     { value: 127, name: "SDG", rate: 601.5},
            //     { value: 128, name: "SEK", rate: 11.0062},
            //     { value: 129, name: "SGD", rate: 1.3588},
            //     { value: 130, name: "SHP", rate: 0.795197},
            //     { value: 131, name: "SLL", rate: 20969.5},
            //     { value: 132, name: "SOS", rate: 572.195879},
            //     { value: 133, name: "SRD", rate: 35.08},
            //     { value: 134, name: "SSP", rate: 130.26},
            //     { value: 135, name: "STD", rate: 22281.8},
            //     { value: 136, name: "STN", rate: 23.497818},
            //     { value: 137, name: "SVC", rate: 8.76037},
            //     { value: 138, name: "SYP", rate: 13002},
            //     { value: 139, name: "SZL", rate: 18.748382},
            //     { value: 140, name: "THB", rate: 34.069918},
            //     { value: 141, name: "TJS", rate: 10.937994},
            //     { value: 142, name: "TMT", rate: 3.51},
            //     { value: 143, name: "TND", rate: 3.195032},
            //     { value: 144, name: "TOP", rate: 2.39453},
            //     { value: 145, name: "TRY", rate: 35.139999},
            //     { value: 146, name: "TTD", rate: 6.803817},
            //     { value: 147, name: "TWD", rate: 32.8235},
            //     { value: 148, name: "TZS", rate: 2426.939935},
            //     { value: 149, name: "UAH", rate: 42.015248},
            //     { value: 150, name: "UGX", rate: 3672.380504},
            //     { value: 151, name: "USD", rate: 1},
            //     { value: 152, name: "UYU", rate: 43.865353},
            //     { value: 153, name: "UZS", rate: 12936.482705},
            //     { value: 154, name: "VEF", rate: 248487.642241},
            //     { value: 155, name: "VES", rate: 51.701112},
            //     { value: 156, name: "VND", rate: 25455.011984},
            //     { value: 157, name: "VUV", rate: 118.722},
            //     { value: 158, name: "WST", rate: 2.8},
            //     { value: 159, name: "XAF", rate: 629.275716},
            //     { value: 160, name: "XAG", rate: 0.03405713},
            //     { value: 161, name: "XAU", rate: 0.00038136},
            //     { value: 162, name: "XCD", rate: 2.70255},
            //     { value: 163, name: "XDR", rate: 0.767755},
            //     { value: 164, name: "XOF", rate: 629.275716},
            //     { value: 165, name: "XPD", rate: 0.00110064},
            //     { value: 166, name: "XPF", rate: 114.477881},
            //     { value: 167, name: "XPT", rate: 0.00108564},
            //     { value: 168, name: "YER", rate: 249.851663},
            //     { value: 169, name: "ZAR", rate: 18.6804},
            //     { value: 170, name: "ZMW", rate: 27.758117},
            //     { value: 171, name: "ZWL", rate: 322}
            // ]);

            const fetchExcursions = async () => {
              try {
                const response = await axios.get(API_CONFIG.getEndpoint('fetchExcursions') +'/type/' + excursionType.value);          
                excursions.list = response.data;
              }catch(error) {
                console.log("Error ::" , error);
              }
            };

            const fetchDestinations =  async () => {
              //loading = true;
              try {
                  const resp = await axios.get(API_CONFIG.getEndpoint('fetchDestinations'));                
                  destinations.list = resp.data;
              } catch (error) {
                  console.log('Error fetching destinations list', error);
                  //error = 'Failed to fetch destinations list. Please try again.';
              } finally {
                //loading = false;
              }
            };

            const fetchCitiesByDestination = async () => {        
              try {
                const response = await axios.get(API_CONFIG.getEndpoint('citiesByDestination')  + `/`+ excursionInfo.destinationId );
                cities.list =  response.data;
                if (cities.length === 0) {
                    errorMessage.value = "No cities available for the selected destination.";
                } else {
                    errorMessage.value = "";
                }
              } catch (error) {
                console.log("Error fetching cities list ", error);
                //error = "Failed to fetch cities list. Please try again !!";
              }finally {
                //loading = false;
              }
            };
            
            const fetchPartnersByDestination = async () => {                
                try {
                    const response = await axios.get(API_CONFIG.getEndpoint('searchPartners') + `/`  + excursionInfo.destinationId );
                    partners.list = response.data;
                } catch(error) {
                    console.error('Error fetching partners list', error);
                }
            };

            const saveSightseeing = async () => {        
                // alert("saveSightseeing called..." + packageInfo.tourDuration);                
                saveExcursion();                
            };

            const saveExcursion = async () => {
              // alert("save saveExcursion called.." + excursionInfo.destinationId);
              
              v$.value.$touch(); 
              if (v$.value.$invalid) { 
                alert('Form is invalid');
                showListView.value = false;
                return; 
              }

              try {
                  const response = await axios.post(API_CONFIG.getEndpoint('createExcursion') 
                    +`?excursionType=${ excursionType.value }`
                    +`&code=${ excursionInfo.sightseeingCode }`
                    +`&destinationId=${ excursionInfo.destinationId }`
                    +`&cityId=${ excursionInfo.cityId}`,  excursionInfo );
                  Object.assign(excursionInfo, response.data);          
                  console.log("Excursion details",excursionInfo);
                  fetchExcursions();
                  showListView.value = true;
              }catch(error) {
                if (error.response && error.response.status === 409) { 
                  errorMessage.value = 'Duplicate entry detected. The excursion already exists.'; 
                  alert(errorMessage.value = 'Duplicate entry detected. The excursion already exists.');
                } else { 
                  errorMessage.value = 'An error occurred while saving the excursion details.'; 
                  alert(errorMessage.value = 'An error occurred while saving the excursion details.');
                } 
                console.log("Error while saving excursion details", error);
              }
            };

            const showForm = (action, excursion = null, idx) => {
              //alert("Selected idx=" + idx);
              selectedIndex.value = idx;
              if( action === 'create-sightseeing') {
                //formTitle =  'Add/Update Hotel Details';
                reset();
                fetchDestinations();
                showListView.value = false;
                isEdit.value = false;
              } else if (action === 'edit' && excursion ) {
                //load excursion object to excursionInfo obj  
                Object.assign(excursionInfo, excursion); 
                console.log("This is called => " + JSON.stringify(excursionInfo) );
                fetchDestinations();
                onChangeByDestination();
                showListView.value = false;
                isEdit.value = true;                    
              } else if ( action === 'go-to-package' && excursion ) {
                excursion_id.value = excursion.id;
                //alert("go-to-package " + excursion_id.value);
                //Object.assign(excursionInfo,excursion );                
                fetchExcursionById();
                //packages.list = excursionInfo.packageDetails;
                showSightseeing.value = false;
                showPackageListView.value = true;
              } else if ( action === 'back-to-sightseeing' ) {
                showSightseeing.value = true;
                showListView.value = true;
              } else if ( action === 'create-new-package') {
                resetPackage();
                showPackageListView.value = false;
                packageEdit.value=false;
              } else if ( action === 'edit-package' && excursion ) {
                Object.assign(packageInfo,excursion);
                showPackageListView.value = false;
                packageEdit.value = true;
              } else if (action === 'upload-picture-sightseeing') {
                // alert("Excursion info id is present "+ excursion.id)
                router.push({ path: `/upload-picture-sightseeing/${excursion.id}` }); // ✅ Correct router usage
              }
                    
            };           

            const cancel = () => {
              reset();
              showListView.value  = true;
            };

            const reset = () => { 
              Object.assign(excursionInfo, {        
                destinationId : '',
                cityId : '',
                sightseeingCode : '',
                sightseeingName : '',
                description : '',
                shortDescription : '',
                shortDescriptionDisplayIn : [],
                startTime : '',
                startTimeShowIn: [],
                meetingPoint: '',
                meetingPointDisplayIn : [],
                meetingPointLocationLatitude : 0,
                meetingPointLocationLongitude : 0,
                partnerNumber : '',
                partnerNumberDisplayIn : [],
                howToGetThere : '',
                howToGetThereDisplayIn : [],
                detailsOfActivity : '',
                detailsOfActivityDisplayIn : [],
                redemptionOfVoucher : '',
                redemptionOfVoucherDisplayIn : [],
                others : '',
                othersDisplayIn : [],
                youTubeLink: '',
                youTubeLinkDisplayIn : [],
                packageDetails : [],
                status : 'Active'
              });

              Object.assign(packageInfo, {
                id : '',
                packageName : '', 
                tourDuration  : 0,
                partner: '',                
                currency : '',                           
                actualPrice  : '0',
                companyMarkupType : '', 
                companyMarkup  : 0,
                finalPrice  : '0',               
                tourType : '',                                
                inclusions : '',                                              
                customerNote: '',                
                agentNote: '',
                adminNote: '',
                subAdminNote : '',
                description : '',
                status: 'Active'
              });
            };

            const resetPackage = () => {
              Object.assign(packageInfo, {
                id : '',
                packageName : '', 
                tourDuration  : 0,
                partner: '',                
                currency : '',                           
                actualPrice  : '0',
                companyMarkupType : '', 
                companyMarkup  : 0,
                finalPrice  : '0',               
                tourType : '',                                
                inclusions : '',                                              
                customerNote: '',                
                agentNote: '',
                adminNote: '',
                subAdminNote : '',
                description : '',
                status: 'Active'
              });
              showPackageListView.value = true;
            };
            
            const onChangeByDestination = () => {
              fetchCitiesByDestination();
              fetchPartnersByDestination();
              //fetchAirportsByDestination();
            }

            const calculateFinalPrice = ( transport ) => {                            
              let fPrice = transport.actualPrice;              
              if( transport.companyMarkupType === 'percentage' ) {
                fPrice *= (1 + transport.companyMarkup / 100);
              } else if( transport.companyMarkupType === 'absolute' ) {
                fPrice += transport.companyMarkup;
              }
              transport.finalPrice = (Math.round(fPrice * 100) / 100).toFixed(2);              
              return transport.finalPrice;
            };

            

     const confirmDeleteExcursion = (id) => {
      excursionToDelete.value = id;
      showDeleteModal.value = true;
    };

    const deleteExcursion = async () => {
      if (!excursionToDelete.value) return;
      try {
        await axios.delete(`${API_CONFIG.getEndpoint('fetchExcursions')}/${excursionToDelete.value}`);
        excursions.list = excursions.list.filter(excursion => excursion.id !== excursionToDelete.value);
       fetchExcursions();
      } catch (error) {
        console.error('Error deleting excursion:', error);
      } finally {
        showDeleteModal.value = false;
        excursionToDelete.value = null;
      }
    };

    const cancelDelete = () => {
      showDeleteModal.value = false;
      excursionToDelete.value = null;
    };

    
            const updateExcursion = async () => {
              //alert("updateExcursion :: "+ excursionInfo.id);
              try {                  
                const response = await axios.post(API_CONFIG.getEndpoint('createExcursion') + `/${excursionInfo.id}`
                    +`?excursionType=${ excursionType.value }`
                    +`&code=${ excursionInfo.sightseeingCode }`
                    +`&destinationId=${ excursionInfo.destinationId }`
                    +`&cityId=${ excursionInfo.cityId}`,  excursionInfo );

                Object.assign(excursionInfo, response.data);                 
                showListView.value = true;
                fetchExcursions();                
              } catch(error) {
                console.log("Update Sightseeing failed" + error);
              }
            };

            const addPackage = async () => {
                //alert("addPackage called..." + packageInfo.packageName+ ", " + excursionInfo.packageDetails.length);
                excursionInfo.packageDetails.push({ ...packageInfo}); // add new package to packages list                
                
                //alert("addPackage after..." + packageInfo.packageName+ ", " + excursionInfo.packageDetails.length);
                updateExcursion();
                showPackageListView.value = true;
            };
  const confirmDeletePackage = (index) => {
  packageToDelete.value = index;
  showDeletePackageModal.value = true;
};        
const deletePackage = () => {
  if (
    packageToDelete.value !== null &&
    packageToDelete.value >= 0 &&
    packageToDelete.value < packages.list.length
  ) {
    packages.list.splice(packageToDelete.value, 1);
    packages.list = [...packages.list]; 

    excursionInfo.packageDetails.splice(packageToDelete.value, 1);
    excursionInfo.packageDetails = [...excursionInfo.packageDetails];

    updateExcursion();
  }

  showDeletePackageModal.value = false;
  packageToDelete.value = null;
};

            const updatePackage = () => {
              //alert("updatePackage called.." + packageInfo.packageName + ", idx=" + selectedIndex.value);
              excursionInfo.packageDetails.splice(selectedIndex.value, 1, packageInfo);
              updateExcursion();
              showPackageListView.value = true;
            };

            const fetchExcursionById = async () => {
              //alert("excursion_id ===> " + excursion_id.value);
              try {
                  const response = await axios.get(API_CONFIG.getEndpoint('fetchExcursions') +'/' +excursion_id.value );
                  //excursionInfo = response.data;
                  Object.assign(excursionInfo, response.data);
                  console.log("This is called => " + JSON.stringify(excursionInfo) );
                  packages.list = excursionInfo.packageDetails;                  
                  console.log("This is called => " + JSON.stringify( packages.list) );
                  fetchPartnersByDestination();
              }catch(error) {
                  console.log("Error ::" , error);
              }
            };

            onMounted ( () => {        
              fetchExcursions();
            });

            return {
                //...toRefs(packageInfo),
                v$,
                packageInfo,packages,excursionInfo,
                destinations,partners,excursions, //airports,
                fetchDestinations, fetchPartnersByDestination,
                currencies,
                fetchExcursions,updateExcursion,deleteExcursion,
                showListView,                
                cities,
                showForm,
                reset,
                errorMessage,
                isEdit,packageEdit,
                cancel,resetPackage,
                onChangeByDestination,
                showInOptions,saveSightseeing,saveExcursion,
                items,
                activeIndex,
                toggle,
                isFormValid,
                addItem,
                removeItem,
                maxValue,
                calculateFinalPrice,
                showPackageListView,showSightseeing,
                computedShowListView,computedShowPackageListView,
                addPackage,updatePackage,deletePackage,
                excursion_id,fetchExcursionById,
                generateRoomId,cancelDelete, confirmDeleteExcursion, showDeleteModal,excursionToDelete, packageToDelete, confirmDeletePackage,showDeletePackageModal,
                filteredExcursions, searchQuery, clearSearch, filteredSightseeing, limitDecimals,
                paginatedUsers, goToPage, resetPagination, totalPages, getPageRange, currentPage, itemsPerPage, paginatedSightSeeing, getPageRangeSight, goToPageSight, resetPaginationSight
            };
        }
    };
</script>

<style scoped> 
  /* @import 'bootstrap/dist/css/bootstrap.css'; */

  .custom-table-width { width: 100%; /* Set desired width (e.g., 100% for full width, 80% for 80% width, etc.) */ }

  .ml-3  {
    margin-left: 0.5rem !important;        
  }
  .mr-3 {        
      margin-right: 1rem !important;        
  }
  .container {
      margin-top: 20px;
  }   
  h3 {
      margin-bottom: 20px;
  }
  .d-flex {
      margin-top: 20px;
  }
  .form-section {
    margin-bottom: 20px;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-group label {
    display: block;
  }
  .form-group input,
  .form-group select {
    /* width: 100%; */
    padding: 8px;
    box-sizing: border-box;
  }
  .form-group-inline-flex {
    display: inline-flex;
  }
  .form-actions {
    display: flex;
    justify-content: flex-end;
  }
  .btn {
    padding: 10px 20px;
    margin-left: 10px;
  }
  .text-danger {
    color: red;
    font-size: 0.875em;
  }
.btn1{
  color: #ffffff;
  background-color: #7978E9;
  border-radius:6px;
}
.btn1:hover{
  background-color: #4B49AC;
}
 label{
  margin: 0px;
 } 
</style>