<template>
    <div class="container my-5">
      <h4>Hotel Form</h4>
      <hr/>
      <form @submit.prevent="submitHotelForm">
        <div class="form-group">
          <label for="destination">Destination <span style="color:red !important">*</span></label>
          <input type="text" v-model="hotel.destination" class="form-control" id="destination" required>
          <span v-if="submitted && !hotel.destination" class="text-danger">Destination is required.</span>
        </div>
        <div class="form-group">
          <label for="city">City <span style="color:red !important">*</span></label>
          <input type="text" v-model="hotel.city" class="form-control" id="city" required>
          <span v-if="!hotel.city && submitted" class="text-danger">City is required.</span>
        </div>
        <div class="form-group">
          <label for="hotelCode">Hotel Code <span style="color:red !important">*</span></label>
          <input type="text" v-model="hotel.hotelCode" class="form-control" id="hotelCode" required>
          <span v-if="!hotel.hotelCode && submitted" class="text-danger">Hotel Code is required.</span>
        </div>
        <div class="form-group">
          <label for="hotelName">Hotel Name <span style="color:red !important">*</span></label>
          <input type="text" v-model="hotel.hotelName" class="form-control" id="hotelName" required>
          <span v-if="!hotel.hotelName && submitted" class="text-danger">Hotel Name is required.</span>
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <textarea v-model="hotel.description" class="form-control" id="description"></textarea>
        </div>
        <div class="form-group">
          <label for="status">Status</label>
          <select v-model="hotel.status" class="form-control" id="status">
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
        </div>
        <!-- Add other fields as needed -->
        <button type="submit" class="btn btn-primary"> <font-awesome-icon :icon="['fas', 'fa-save']" class="me-2" /> Save Hotel</button>
      </form>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
    data() {
        return {
        hotel: {
            destination: '',
            city: '',
            hotelCode: '',
            hotelName: '',
            description: '',
            status: ''
        },
        submitted: false
        };
    },
    methods: {
      isValidForm() {
        let isValid = true;
        if (!this.hotel.destination) {
          alert("Destination is required.");
          isValid = false;
        }
        if (!this.hotel.city) {
          alert("City is required.");
          isValid = false;
        }
        if (!this.hotel.hotelCode) {
          alert("Hotel Code is required.");
          isValid = false;
        }
        if (!this.hotel.hotelName) {
          alert("Hotel Name is required.");
          isValid = false;
        }
        return isValid;
      },
        async submitHotelForm() {
          this.submitted = true; // Mark form as submitted to trigger validation messages
          if (!this.isValidForm()) {
            return; // Prevent submission if form is invalid
          }
            try {
                const response = await axios.post('http://localhost:9092/api/hotels', this.hotel);
                //const response = await axios.get(API_CONFIG.getEndpoint('fetchPackages'));
                console.log('Hotel saved successfully:', response.data);
                //this.$emit('hotelSaved', response.data);
                this.$router.push(`/room-form/${this.hotel.hotelCode}`);
            } catch (error) {
                console.error('Error saving hotel:', error);
            }
        }
    }
};
</script>