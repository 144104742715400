
<template>
  <div class="container mb-5">
    <h4>Settings</h4>    
    <p class="text-muted pt-2">Manage your settings here.</p>
    <button class="btn btn-primary">Primary Button</button>
  </div>
</template>
  
<script>
  export default {
    name: 'TheSettings'
  }
</script>
  
<style scoped>
/* You can add your custom styles here if needed */
.btn{
  background-color: #4B49AC;
  outline:none;
  border:none;
}
.btn:hover{
  background-color:  #7978E9;
}
</style>
  