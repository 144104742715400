<template>
  <div class="container">
    <div class="card-header p-3" v-if="!isFormVisible">
      <div class="d-flex justify-content-between align-items-center">
        <h4>Manage Installments</h4>
      </div>
    </div>

    <div v-if="error" class="text-danger">{{ error }}</div>

    <div v-if="!isFormVisible">
      <form @submit.prevent="submitInstallment">
                  <!-- Total Price Input -->
                  <!-- <div class="mb-3">
                    <label for="totalPrice" class="form-label">Total Price</label>
                    <input
                        type="text"
                        v-model="totalPrice"
                        id="totalPrice"
                        class="form-control"
                        readonly
                    />
                  </div> -->
                  <!-- Installments Selector -->
                  <div class="mt-3">
                    <label for="numInstallments" class="form-label"
                    >Number of Installments</label
                    >
                    <select
                        v-model="numInstallments"
                        @change="updateInstallmentTable(true)"
                        id="numInstallments"
                        class="form-select"
                    >
                    <option v-for="n in 7" :key="n" :value="n + 1">{{ n + 1 }}</option>
                    </select>
                  </div>
                  <!-- Installment Table -->
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th>#</th>
                        <th>Installment Percentage (%)</th>
                        <!-- <th>Installment Amount</th> -->
                        <th>Days</th>
                        <th>Reference</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(installment, index) in installmentList" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>
                          <input
                              type="number"
                              v-model="installment.percentage"
                              class="form-control"
                              min="0"
                              step="any"
                              @input="updateInstallmentAmount(index)"
                          />
                        </td>
                        <!-- <td>{{ installment.installmentAmount.toFixed(2) }}</td> -->
                        <td>
                          <input type="number"
                                 v-model="installment.days"
                                 class="form-control"/>
                        </td>
                        <td>
                          <div>
                            <select
                                id="referenceDateInput"
                                v-model="installment.referenceDate"
                                class="form-control">
                              <option value="booking">Booking Date</option>
                              <option value="travel">Travel Date</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td><strong>Total</strong></td>
                          <td><strong>{{ totalRow.percentage }}</strong></td>
                          <!-- <td><strong>{{ totalRow.installmentAmount }}</strong></td> -->
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div class="modal-footer">
                    <button type="submit" class="btn">
                      <font-awesome-icon :icon="['fas', 'floppy-disk']" class="me-2" />  Save Changes
                    </button>
                  </div>
                </form>
    </div>
  </div>
</template>

<script>
import {computed, ref, watch, onMounted} from 'vue';
import axios from 'axios';
import {API_CONFIG} from '@/apiConfig';
import router from "@/router";


export default {
        name: 'PackageList',
  methods: {
    router() {
      return router
    }
  },
        setup() {
          const packages= ref([]);
          const loading = ref(true);
          const error = ref(null);          
          const showEditModal = ref(false); 
          const totalPrice = ref(0);
          const numInstallments = ref(2); 
          const installmentList = ref([]); 
          const packageCode = ref("default")

              const fetchInstallmentSchedule = () => {    
              numInstallments.value = 2;      
              showEditModal.value = true;     

              const url = API_CONFIG.getEndpoint("fetchInstallmentSchedule") + `/${packageCode.value}`;

              axios.get(url)
                  .then((emiResponse) => {
                    numInstallments.value = emiResponse.data['numInstallments'];
                    installmentList.value = emiResponse.data['installmentList'];

                    updateInstallmentTable(false);
                  })
                  .catch((error) => {
                    console.error("Failed to fetch installment schedule:", error);
                    updateInstallmentTable(true);
                  });
            };


          const closeEditInstallmentModal = () => {
            showEditModal.value = false; 
            resetFields();
          };

          const resetFields = () => {
            totalPrice.value = 0.0; 
            numInstallments.value = 2; 
          };

          const submitInstallment = () => {
            // Remove installmentAmount from each entry in the installmentList
            const cleanedInstallmentList = installmentList.value.map((installment) => {
              // eslint-disable-next-line no-unused-vars
              const { installmentAmount, ...rest } = installment;
              return rest;
            });

            const payload = {
              packageCode: packageCode.value,
              numInstallments: numInstallments.value,
              installmentList: cleanedInstallmentList, 
            };

            const url = API_CONFIG.getEndpoint("saveInstallmentSchedule");

            axios.post(url, payload)
                .then((response) => {
                  console.log(response);
                  if (response.data) {
                    console.log("Installment data saved successfully!");

                    showEditModal.value = false;
                  } else {
                    console.warn("Failed to save installment data:", response.data.message);
                  }
                })
                .catch((error) => {
                  console.error("An error occurred while saving installment data:", error);
                });

                fetchInstallmentSchedule();
          };
          const updateInstallmentTable = (redistribute) => {
  const currentLength = installmentList.value.length;

  if (numInstallments.value > currentLength) {
    for (let i = currentLength; i < numInstallments.value; i++) {
      installmentList.value.push({
        percentage: 0,
        installmentAmount: 0,
        days: 0,
        referenceDate: 'booking',
      });
    }
  } else if (numInstallments.value < currentLength) {
    installmentList.value.splice(numInstallments.value);
  }

  if (redistribute === true) {
    const totalPercentage = 100;
    const basePercentage = Math.floor(totalPercentage / numInstallments.value * 100) / 100; 
    let sumPercentage = basePercentage * (numInstallments.value - 1); 

    installmentList.value.forEach((installment, index) => {
      if (index < numInstallments.value - 1) {
        installment.percentage = basePercentage;
      } else {
        const remainingPercentage = totalPercentage - sumPercentage;
        installment.percentage = remainingPercentage;
      }

      installment.installmentAmount = (totalPrice.value * installment.percentage) / 100;
    });
  } else {
    installmentList.value.forEach((installment) => {
      installment.installmentAmount = (totalPrice.value * installment.percentage) / 100;
    });
  }
};

          onMounted(fetchInstallmentSchedule);

          watch(numInstallments, () => {
            console.log("Num installments: " + numInstallments.value);
            updateInstallmentTable();
          },{immediate: true});

          const totalRow = computed(() => {
            const totalPercentage = installmentList.value.reduce((sum, installment) => sum + installment.percentage, 0);
            const totalAmount = installmentList.value.reduce((sum, installment) => sum + installment.installmentAmount, 0);
            if (typeof totalPercentage === "number" &&
                !isNaN(totalPercentage)) {
              return {
                percentage: totalPercentage.toFixed(2),
                installmentAmount: totalAmount.toFixed(2),
              };
            } else {
              return {
                percentage: 0.0,
                installmentAmount: 0.0,
              };
            }
          });

          const updateInstallmentAmount = (index) => {
            if (installmentList.value[index]) {
              const installment = installmentList.value[index];
              if (installment.percentage === '') {
                installment.percentage = 0.0;
              }

              installment.installmentAmount = (totalPrice.value * installment.percentage) / 100;
            }
          }
          onMounted(() => {
            fetchInstallmentSchedule();
    });
          return {
              showEditModal,
              totalPrice,
              numInstallments,
              installmentList,
              packages,
              packageCode,
              loading,
              error,
              closeEditInstallmentModal,
              resetFields,
              submitInstallment,
              updateInstallmentTable,
              updateInstallmentAmount,
              totalRow,
    
          };
          
        }
    };
</script>

<style scoped>
.table-container {
  max-width: 100%;
  overflow-x: auto;
}
.container {
  margin-top: 20px;
}
th {
  font-weight: bold;
}
td {
  height: 80px;
}
</style>
