<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-9"><h5> Inclusion & Exclusion for <span style="color:#4B49AC"> {{ packageCode }}</span></h5></div>
            <div class="col-sm-3">                
                <button type="button" class="btn btn-success me-2 text-right float-right" @click="back_to_packages"><font-awesome-icon :icon="['fas', 'fa-arrow-left']" class="me-2" />Back to Packages</button> 
            </div> 
        </div> 
        <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>            
        
        <div class="clickable-div container border p-3">
            <table class="border table table-bordered">
                <thead>
                    <tr>
                        <th class="text-center">Heading</th>
                        <th class="text-center">Inclusion</th>
                        <th class="text-center">Exclusion</th>
                    </tr> 
                </thead>
                <tbody>
                    <tr class="table-merged-row">
                        <td colspan="3" class="text-center"><strong>Meal Plan</strong></td>
                    </tr>
                    <tr v-for="(inclusion,dIndex) in packageInfo.inclusions.filter(item => mealInclusions.map(meal => meal.value).includes(item.name))" :key="dIndex">
                        <td> {{ inclusion.name }} </td>
                        <td class="text-center"> 
                            <input type="radio" v-model="inclusion.included" class="form-check-input" :id="'included' + dIndex" :value="true" @click="toggleSelection(inclusion, 'included', true)">
                        </td>
                        <td class="text-center"> 
                            <input type="radio" v-model="inclusion.excluded" class="form-check-input" :id="'excluded' + dIndex" :value="true" @click="toggleSelection(inclusion, 'excluded', true)">
                        </td>
                    </tr>
                    <tr class="table-merged-row">
                        <td colspan="3" class="text-center"><strong>Visa Options</strong></td>
                    </tr>
                    <tr v-for="(inclusion,dIndex) in packageInfo.inclusions.filter(item => visaInclusions.map(visa => visa.value).includes(item.name))" :key="dIndex">
                        <td> {{ inclusion.name }} </td>
                        <td class="text-center"> 
                            <input type="radio" v-model="inclusion.included" class="form-check-input" :id="'included' + dIndex" :value="true" @click="toggleSelection(inclusion, 'included', true)">
                        </td>
                        <td class="text-center"> 
                            <input type="radio" v-model="inclusion.excluded" class="form-check-input" :id="'excluded' + dIndex" :value="true" @click="toggleSelection(inclusion, 'excluded', true)">
                        </td>
                    </tr>
                    <tr class="table-merged-row">
                        <td colspan="3" class="text-center"><strong>Other Inclusion & Exclusion </strong></td>
                    </tr>
                    <tr v-for="(inclusion,dIndex) in packageInfo.inclusions.filter(item => item.name == 'Other Inclusions' || item.name == 'Other Exclusions')" :key="dIndex">
                        <td> 
                            {{ inclusion.name }}                         
                            <textarea type="text" class="form-control" id="other-Inclusion" v-model="inclusion.desc" placeholder="Enter the description" rows="5" @input="inclusion.included = inclusion.desc ? true : null"></textarea>
                        </td>
                        <td class="text-center"> 
                            <input type="radio" v-model="inclusion.included" class="form-check-input" :id="'included' + dIndex" :value="true" @click="toggleSelection(inclusion, 'included', true)">
                        </td>
                        <td class="text-center"> 
                            <input type="radio" v-model="inclusion.excluded" class="form-check-input" :id="'excluded' + dIndex" :value="true" @click="toggleSelection(inclusion, 'excluded', true)">
                        </td>
                    </tr>
                    <tr class="table-merged-row">
                        <td colspan="3" class="text-center"><strong>Itineraries Inclusions</strong></td>
                    </tr>                    
                    <tr v-for="(inclusion,dIndex) in packageInfo.inclusions.filter(item => excursionTypes.map(excursion => excursion.value).includes(item.name))" :key="dIndex">
                        <td> 
                            {{ inclusion.name }} 
                            <textarea type="text" rows="5" class="form-control" id="other-Inclusion" v-model="inclusion.desc" placeholder="Enter the description" @input="inclusion.included = inclusion.desc ? true : null"></textarea> 
                        </td>
                        <td class="text-center">
                            <input type="radio" v-model="inclusion.included" class="form-check-input" :id="'included' + dIndex" :value="true" @click="toggleSelection(inclusion, 'included', true)">
                        </td>
                        <td class="text-center"> 
                            <input type="radio" v-model="inclusion.excluded" class="form-check-input" :id="'excluded' + dIndex" :value="true" @click="toggleSelection(inclusion, 'excluded', true)">
                        </td>
                    </tr>                    
                </tbody>
            </table>           
            <hr>
            <div class="form-actions d-flex justify-content-end">
                <button type="submit" class="btn btn-success" @click="updatePackages(packageInfo)" style="margin-right: 10px;"><font-awesome-icon :icon="['fas', 'fa-save']" class="me-2" />  Save and Next page</button>                    
                <button type="button" class="btn btn-light" @click="showForm('summary')"> <font-awesome-icon :icon="['fas', 'arrow-right']" /> Go to Next Page</button>
            </div>
            <br>
        </div>    
        
        
    </div>
</template>
<script>
    
    import { ref, reactive, onMounted } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import axios from 'axios';
    import { API_CONFIG } from '@/apiConfig';

    export default {
        
        name: 'InclusionsAndExclusionsList',
        setup() {
            const router = useRouter(); // Get the router instance
            const route = useRoute(); // Access current route's properties
            const packageCode = ref(route.params.id);
            const packageInfo = reactive({ 
                days: [] ,
                inclusions: [
                    {  name:'breakfast', desc: '', included: null, excluded: null},
                    {  name:'lunch', desc: '', included: null, excluded: null},
                    {  name:'dinner', desc: '', included: null, excluded: null},
                    {  name:'snacks', desc: '', included: null, excluded: null},

                    {  name:'assistance', desc: '', included: null, excluded: null},
                    {  name:'fees', desc: '', included: null, excluded: null},
                    {  name:'Airfare', desc: '', included: null, excluded: null},
                    {  name:'Government Tax/ GST/ Service Charges', desc: '', included: null, excluded: null},
                    {  name:'City Tax on Hotel', desc: '', included: null, excluded: null},
                    { name: 'Travel Insurance', desc: '', included: null, excluded: null },

                    { name: 'Other Inclusions', desc: '', included: null, excluded: false },
                    { name: 'Other Exclusions', desc: '', included: false, excluded: null }
                ]
            });
            
            //packageInfo.inclusions.push({ name:'Other Inclusions', desc: '', included: null, excluded: false});
            //packageInfo.inclusions.push({ name:'Other Exclusions', desc: '', included: false, excluded: null});

            
            const errorMessage = ref("");
            const isEdit = ref(false);
            const itineraries = reactive([]);

            const groupByExcursionType = (packageInfo) => {
                const grouped = {};
                packageInfo.days.forEach(day => {      
                    day.cities.forEach(city => {                        
                        city.itineraries.forEach(itinerary => {                            
                            if (!grouped[itinerary.excursionType]) {
                                grouped[itinerary.excursionType] = [];
                            }
                            grouped[itinerary.excursionType].push(itinerary.subCodeDescriptor);
                        });
                    });
                });
                console.log("groupByExcursionType :: called :: " + JSON.stringify(grouped) );

                if (Object.keys(grouped).length > 0) {
                    // Remove all excursionTypes from inclusions list
                    packageInfo.inclusions = packageInfo.inclusions.filter(inclusion => !excursionTypes.map(excursion => excursion.value).includes(inclusion.name));

                    // Add grouped list into inclusions list
                    Object.keys(grouped).forEach(key => {
                        const desc = grouped[key].join('\n');
                        packageInfo.inclusions.push({ name: key, desc: desc, included: true, excluded: false });
                    });
                }
            };

            const toggleSelection = (inclusion, key, value) => {
                if (key === 'included' && value === true && inclusion.excluded === true) {
                    inclusion.excluded = false;
                } else if (key === 'excluded' && value === true && inclusion.included === true) {
                    inclusion.included = false;
                }
                inclusion[key] = value;
            };

            const formatItineraries = (itineraries) => {
                let temp = '';                
                for (let jj = 0; jj < itineraries.length; jj++) {
                    temp += itineraries[jj] + '\n';
                    console.log(" itineraries[jj] ==> " + itineraries[jj].value );
                }
                return temp.trim();
            };

            const mealInclusions = reactive([
                { name : 'Breakfast', value : 'breakfast' },
                { name : 'Lunch', value : 'lunch' },
                { name : 'Dinner', value : 'dinner' },
                { name : 'Snacks', value : 'snacks' },
            ]);

            const visaInclusions = reactive ([
                { name : 'Assistance', value : 'assistance' },
                { name : 'Fees', value : 'fees' },
                { name : 'Airfare', value : 'Airfare' },
                { name : 'Government Tax/ GST/ Service Charges', value : 'Government Tax/ GST/ Service Charges' },
                { name : 'City Tax on Hotel', value : 'City Tax on Hotel' },
                { name : 'Travel Insurance', value : 'travel Insurance' }
            ]);

            const excursionTypes = reactive ([                
                { name : 'Sightseeing', value : 'sightseeing' },
                { name : 'Intercity Transfer', value : 'intercity' },
                { name : 'Hotel', value : 'hotel' },
                { name : 'Information', value : 'information' },
                { name : 'Airport Transfer', value : 'airport' },
            ]);

            const cancel = () => {
                console.log('Cancel button clicked');                
            };

            const fetchPackageByCode = async () => {
                try {
                    const response = await axios.get(API_CONFIG.getEndpoint('fetchPackages') + `/` + packageCode.value);
                    Object.assign(packageInfo, response.data);
                    console.log("fetchPackageByCode ::: " +  JSON.stringify(packageInfo.inclusions));
                    if (!response.data.inclusions || response.data.inclusions.length === 0) {
                        // Populate inclusions list with default values if response.data.inclusions is empty
                        packageInfo.inclusions = [
                            { name: 'breakfast', desc: '', included: null, excluded: null },
                            { name: 'lunch', desc: '', included: null, excluded: null },
                            { name: 'dinner', desc: '', included: null, excluded: null },
                            { name: 'snacks', desc: '', included: null, excluded: null },
                            { name: 'assistance', desc: '', included: null, excluded: null },
                            { name: 'fees', desc: '', included: null, excluded: null },
                            { name: 'Airfare', desc: '', included: null, excluded: null },
                            { name: 'Government Tax/ GST/ Service Charges', desc: '', included: null, excluded: null },
                            { name: 'City Tax on Hotel', desc: '', included: null, excluded: null },
                            { name: 'Travel Insurance', desc: '', included: null, excluded: null },
                            { name: 'Other Inclusions', desc: '', included: null, excluded: null },
                            { name: 'Other Exclusions', desc: '', included: null, excluded: null }
                        ];
                    }
                    groupByExcursionType(packageInfo);    
                    console.log("fetchPackageByCode ::: fetched successfully");
                } catch (error) {
                    console.error('Error fetching package details..' + error);
                    errorMessage.value = "Error fetching package details..";
                }
            };

            const showForm = (action) => {
                 if( action == 'summary') {
                    //if( inclusionsInfo.mealInclusions.length == 0 && inclusionsInfo.visaInclusions.length ==0) {
                    //    alert("Please include meals & visas ");
                    //    return;
                    //}else{
                        summary_page();
                    //}
                 }
            };

            const summary_page = () => {                
                router.push({ name: 'PricingSummary', params: { id: packageCode.value } });
            };
            const back_to_packages = () => {
                if ( packageInfo.packageType == 'website_parent') {                    
                    router.push({ path: '/web-packages/view'});  
                } else {
                    router.push({ path: '/viewpackages' });
                }
            }

            const updatePackages = async (packageInfo)  => {
                try {        
                    const response = await axios.put(  API_CONFIG.getEndpoint('updatePackages') +`/` + packageInfo.packageCode, packageInfo );
                    console.log('Itineraries saved successfully:', response.data);                    
                    summary_page();
                }catch(error) {
                    console.log('Error updating inclusions and exclusions ', error);
                    errorMessage.value = "Unable to save the itineraries.. Please try again later.";
                }
            };

            // const saveInclusionExclusions = async () => {
            //     console.log(" saveItineraries :: packageInfo.inclusionInfo ===> " + JSON.stringify(packageInfo.inclusions));
            //     try {
            //         const response = await axios.post(API_CONFIG.getEndpoint('fetchPackages') + `/${packageCode.value}/inclusions-exclusion`, packageInfo.inclusions);
            //         console.log('Itineraries saved successfully:', response.data);                    
            //         summary_page();
            //     } catch(error) {
            //         console.error('Error saving itineraries: ' + error);
            //     }
            // };

            onMounted ( () => {                    
                if (packageCode.value) {    
                    fetchPackageByCode();                    
                }
            });

            return {
                packageInfo,                
                mealInclusions,visaInclusions,
                fetchPackageByCode, 
                back_to_packages,summary_page,
                packageCode,errorMessage,
                cancel,isEdit,itineraries,
                groupByExcursionType,formatItineraries,                            
                //saveInclusionExclusions,
                showForm,
                toggleSelection, excursionTypes,
                updatePackages
            };
        }
    };
</script>
<style scoped>

.table-merged-row {
  font-size: 1.2em;
  background: darkgray;
}
</style>