<template> 
  <div id="app">       
    <!-- Add navbar items here --> 
    <TheHeader/>  
    <div class="container-fluid" style="height:88vh">      
      <div class="row" style="height: 100%"> 
        <div class="col-md-2 row-class">             
          <LeftPanel /> 
        </div> 
        <div class="col-md-10 right-panel">              
          <router-view></router-view> 
        </div> 
      </div> 
    </div> 
  </div> 
</template>
  
<script>    
  import TheHeader from './components/TheHeader.vue';
  import LeftPanel from './components/LeftPanel.vue';
  export default {
    name: 'App',
    components: {
      LeftPanel,
      TheHeader
    }
  }

</script>  

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

body {
  font-family: 'Poppins', sans-serif !important; 
}

.row-class{
  padding:0px !important;
}

.right-panel{
  background-color: #F5F7FF; 
  overflow: auto;
}

thead > tr > th{
  background-color: #7DA0FA !important;
  color:white !important;
  white-space: nowrap;
}

thead > tr > td{
  background-color: #7DA0FA !important;
  color:white !important;
  white-space: nowrap;
}

.edit{
  color:  #007bff;
}

.edit:hover {
  color: #0056b3; 
}

.delete{
  color: #dc3545;
}

.delete:hover{
  color: #c82333;
}

.save{
  color: #ffffff;
}
 .card-header {
 background-color: #ffffff !important; 
  padding: 8px 16px!important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); 
  border: 1px solid #ddd !important; 
  border-radius: 7px!important;
}

.plus{
  color: green;
}

.plus:hover{
  color: #57B657;
}

.btn{
  color: #ffffff!important;
  background-color: #7978E9!important;
  border: none !important;
  white-space: nowrap;
}
.btn:hover{
  background-color: #4B49AC!important;
  color: #ffffff!important;
}
.upload{
  color: #4B49AC;
}
.upload:hover{
  color: black;
}
h4{
  padding: 0px!important;
  margin: 0px !important;
  font-size: 22px !important;
}
tbody tr {
  background-color: white;
}
thead,tr,th, td{
  font-size:14px !important;
}

button{
  font-size: 14px !important;
}

.table-container {
  max-width: 100%; 
  overflow-x: auto; 
  overflow-y: auto; 
  max-height: 75vh; 
  margin-bottom: 20px;
    }
    
    .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

    .modal-box {
    background: white;
    padding: 20px;
    width: 400px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.modal-box h5 {
    margin-bottom: 10px;
    font-size: 18px;
}

.modal-box p {
    margin-bottom: 20px;
}

.modal-buttons {
    display: flex;
    justify-content: space-around;
}

.modal-buttons .btn {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.modal-buttons .btn-danger {
    background: #dc3545;
    color: white;
}

.modal-buttons .btn-danger:hover {
    background: #c82333;
}

.modal-buttons .btn-secondary {
    background: #6c757d;
    color: white;
}

.modal-buttons .btn-secondary:hover {
    background: #5a6268;
}

.search-input button {
  border: none;
  background: none;
  color: black;
}

.search-input {
  --timing: 0.3s;
  --width-of-input: 350px;
  --height-of-input: 40px;
  --border-height: 2px;
  --input-bg: #fff;
  --border-color: #4B49AC;
  --border-radius: 30px;
  --after-border-radius: 1px;
  position: relative;
  width: var(--width-of-input);
  height: var(--height-of-input);
  display: flex;
  align-items: center;
  padding-inline: 0.8em;
  border-radius: var(--border-radius);
  transition: border-radius 0.5s ease;
  background: var(--input-bg,#fff);
}

.input {
  font-size: 0.9rem;
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding-inline: 0.5em;
  padding-block: 0.7em;
  border: none;
}

.search-input:before {
  content: "";
  position: absolute;
  background: var(--border-color);
  transform: scaleX(0);
  transform-origin: center;
  width: 100%;
  height: var(--border-height);
  left: 0;
  bottom: 0;
  border-radius: 1px;
  transition: transform var(--timing) ease;
}

.search-input:focus-within {
  border-radius: var(--after-border-radius);
}

input:focus {
  outline: none;
}

.input::placeholder{
  color: black;
}

.search-input:focus-within:before {
  transform: scale(1);
}

.reset {
  border: none;
  background: none;
  opacity: 0;
  visibility: hidden;
}

input:not(:placeholder-shown) ~ .reset {
  opacity: 1;
  visibility: visible;
}

.search-input svg {
  width: 17px;
}

.faq-button {
   border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.faq-button svg {
  fill: white;
}
.faq-button:hover svg {
  animation: jello-vertical 0.7s both;
}
@keyframes jello-vertical {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.tooltip {
  position: absolute;
  top: -20px;
  opacity: 0;
  background-color: white;
 background-image: white;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.2s;
  pointer-events: none;
  letter-spacing: 0.5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.tooltip::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background-color: white;
  background-size: 1000%;
  background-position: center;
  transform: rotate(45deg);
  bottom: -15%;
  left: 42%;
  transition-duration: 0.3s;
}

.faq-button:hover .tooltip {
  top: -40px;
  opacity: 1;
  transition-duration: 0.3s;
  white-space: nowrap;
}

</style>
  