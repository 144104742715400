<template>
    <div class="container">
        <div class="form-group row mb-3">
            <div class="d-flex align-items-center justify-content-between mb-3">
                <h4>Manage Excursions </h4>
            <div class="d-flex justify-content-end">   
                <button type="button" class="btn btn-success me-2" @click="showForm('create')" v-if="showRoomsListView"><font-awesome-icon :icon="['fas', 'fa-plus']" class="plus me-2" style="color:#ffffff" />Add New Room</button>
                <button type="button" class="btn btn-success me-2" @click="backtohotels"> <font-awesome-icon :icon="['fas', 'fa-arrow-left']" class="me-2" />Back to Hotels</button> 
            </div>
         </div>
            <h5> Room Details for <span style="color:#4B49AC">{{ excursionInfo.hotelName }} </span></h5> 
           
        </div>

        <div class="d-flex justify-content-end mt-5" v-if="showRoomsListView">
    <form class="search-input">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div> 

        <div v-if="showRoomsListView">                        
            <table class="table table-bordered mt-3">
                <thead>
                <tr>                  
                    <th>Room Type</th>                    
                    <!-- <th>Partner Name</th>                     -->
                    <th>Actual Price</th>
                    <th>Company Markup In</th>
                    <th>Company Markup </th>
                    <th>Final Price</th>
                    <!-- <th>Currency</th> -->
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for=" (room,idx) in filteredRoomExcursions" :key = "room.roomType">
                    <td>{{ room.roomType }}</td>
                    <!-- <td>{{ room.partner  }}</td> -->
                    <td>{{ room.actualPrice }}</td>
                    <td>{{ room.companyMarkupType }}</td>
                    <td>{{ room.companyMarkup }}</td>
                    <td>{{ room.finalPrice }}</td>
                    <!-- <td>{{ room.currency }}</td> -->
                    <td style="display: flex;">
                        <button @click="showForm('upload-picture',room,idx)" class=" btn-sm mr-3 faq-button"><font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']"  class="upload"/><span class="tooltip">Picture</span> </button>
                        <button @click="showForm('edit',room, idx)" class=" btn-sm  mr-3 faq-button"><font-awesome-icon :icon="['fas', 'fa-edit']" class="edit" /><span class="tooltip">Edit</span></button>
                        <button @click="deleteRoom(idx)" class=" btn-sm faq-button "><font-awesome-icon :icon="['fas', 'fa-trash']" class="delete" /><span class="tooltip">Delete</span></button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-if="!showRoomsListView">
            <div class="form-section">
                <!-- <h2>Add Rooms for {{ excursionInfo.hotelName }} </h2>                 -->
                <div class="form-group row mb-3">
                    <label for="roomType" class="col-sm-3 col-form-label">Room Type <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" id="roomType" v-model="roomInfo.roomType" placeholder="Enter room type" required>
                    </div>
                </div>

                <!-- <div class="form-group row mb-3">
                    <label for="partners" class="col-sm-3 col-form-label">Select Currency *</label>                    
                    <div class="col-sm-6">
                        <select v-model="roomInfo.currency"  class="form-control" id="partners">
                            <option disabled value="">--Please select a partner--</option>
                            <option v-for="currency in currencies " :key="currency.name" :value="currency.name">
                                    {{ currency.name }}
                            </option>                      
                        </select>
                    </div>
                </div> -->

                <!-- <div class="form-group row mb-3">
                    <label for="partners" class="col-sm-3 col-form-label">Select partner *</label>                    
                    <div class="col-sm-6">
                        <select v-model="roomInfo.partner"  class="form-control" id="partners" required>
                            <option disabled value="">--Please select a partner--</option>
                            <option v-for="partner in partners.list " :key="partner.partnerCode" :value="partner.partnerCode">
                                {{ partner.partnerName }}
                            </option>
                        </select>
                    </div>
                </div> -->

                <div class="form-group row mb-3">
                    <label for="actualPrice" class="col-sm-3 col-form-label">Actual Price<span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <input type="number" class="form-control" id="actualPrice" v-model="roomInfo.actualPrice" placeholder="Enter price"  step="0.01"  min="0" required  @input="limitDecimals(roomInfo, 'actualPrice')">
                    </div>
                </div>
                
                <div class="form-group row mb-3">
                    <label for="companyMarkupType" class="col-sm-3 col-form-label">Select Company Markup In <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <select v-model="roomInfo.companyMarkupType"  class="form-control" id="companyMarkupType" required>
                            <option disabled value="">--Please select a markupr--</option>
                            <option value="Percentage">Percentage</option>
                            <option value="Absolute">Absolute</option>
                        </select>  
                    </div>
                </div>

                <div class="form-group row mb-3">
                    <label for="companyMarkup" class="col-sm-3 col-form-label">Company Markup <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <input type="number" class="form-control" id="companyMarkup" v-model="roomInfo.companyMarkup" placeholder="Enter price"  step="0.01" min="0" @input="limitDecimals(roomInfo, 'companyMarkup')">
                    </div>
                </div>

                <div class="form-group row mb-3">
                    <label for="finalPrice" class="col-sm-3 col-form-label">Room Sale Price <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <input type="number" class="form-control" id="finalPrice" :value="calculateFinalPrice(roomInfo)" required min="0">
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="status" class="col-sm-3 col-form-label">Status</label>                
                    <div class="col-sm-2">
                        <input type="radio" v-model="roomInfo.status" class="form-check-input mr-3" id="statusActive" value="Active">
                        <label for="statusActive" class="form-check-label">Active</label>
                    </div>
                    <div class="col-sm-2">
                        <input type="radio" v-model="roomInfo.status" class="form-check-input mr-3" id="statusInactive" value="Inactive">
                        <label for="statusInactive" class="form-check-label">Inactive</label>
                    </div>            
                </div>
                <hr>
                <div class="form-actions d-flex justify-content-end">
                <button type="submit" class="btn btn-success me-2" @click="addRooms" v-if="!isEdit"> <font-awesome-icon :icon="['fas', 'floppy-disk']" /> Save Rooms</button>
                <button type="submit" class="btn btn-success me-2" @click="updateRooms" v-if="isEdit"> <font-awesome-icon :icon="['fas', 'file-pen']" /> Update Rooms</button>
                <button type="button" class="btn btn-light" @click="cancelRoom"> <font-awesome-icon :icon="['fas', 'xmark']" /> Cancel</button>
                </div>
                <br>
            </div>
        </div>
 <DeleteModal
  :visible="showDeleteModal"
  @confirmDelete="confirmDelete"
  @cancelDelete="showDeleteModal = false"
/>

    </div>
</template>
<script>
    import axios from 'axios';
    import { reactive,  onMounted, ref, watch, computed  } from 'vue';
    import { API_CONFIG } from '@/apiConfig';
    import { toRefs } from 'vue';
    import { useRouter } from 'vue-router';
    import DeleteModal from './DeleteModal.vue';


    export default {
        components: {
        DeleteModal,
        },
        
        props: ['excursionId'],

        setup(props) {
            
            const router = useRouter();

            const { excursionId } = toRefs(props); 
            const excursion_id = excursionId.value;           
            const excursionInfo = reactive({ });
            const showRoomsListView = ref(true);
            const isEdit = ref(true);
            const selectedIndex = ref(-1);
            const excursionType = ref("hotel");
            const showDeleteModal = ref(false);
            const roomToDelete = ref(null);

            // Initialize the roomInfo object
            const roomInfo = reactive({
                id : '',
                roomType: '',                
                actualPrice: 0,
                companyMarkupType: 'Percentage',
                companyMarkup: 0,
                finalPrice: 0,
                status: 'Active',
            });

            const limitDecimals = (obj, field) => {
  if (obj[field] !== undefined && obj[field] !== null) {
    const valueStr = obj[field].toString();
    const decimalIndex = valueStr.indexOf('.');

    if (decimalIndex !== -1 && valueStr.length - decimalIndex > 3) {
      obj[field] = parseFloat(valueStr.substring(0, decimalIndex + 3));
    }
  }
};

            const searchQuery = ref('');

const filteredRoomExcursions = computed(() => {
return rooms.list.filter(room => {
return (
room.roomType.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
room.companyMarkupType.toLowerCase().includes(searchQuery.value.toLowerCase()) 
);
});
});

const clearSearch = () => {
searchQuery.value = '';
};

            // Function to generate unique ID using UUID
            const generateRoomId = (destinationId, cityId, code, roomType) => {
                const safeRoomType = roomType ? roomType.replace(/\s+/g, '') : '';
                const mCode = code ? code.replace(/\s+/g, '') : '';
                return `${ destinationId }_${ cityId }_${ mCode }_${ safeRoomType }`;
            };

            watch(
                [ () => excursionInfo.destinationId, () => excursionInfo.cityId, () => excursionInfo.hotelCode, () => roomInfo.roomType ], 
                () => { 
                    roomInfo.id = generateRoomId( excursionInfo.destinationId, excursionInfo.cityId, excursionInfo.hotelCode, roomInfo.roomType);
                }, { immediate: true }
            );

            // const currencies = ref ([
            //     { value: 1, name: "AED", rate: 3.673 },
            //     { value: 2, name: "AFN", rate: 70.483864 },
            //     { value: 3, name: "ALL", rate: 94.101325 },
            //     { value: 4, name: "AMD", rate: 400.326463 },
            //     { value: 5, name: "ANG", rate: 1.804345 },
            //     { value: 6, name: "AOA", rate: 912 },
            //     { value: 7, name: "ARS", rate: 1030.200329 },
            //     { value: 8, name: "AUD", rate: 1.608752 },
            //     { value: 9, name: "AWG", rate: 1.8 },
            //     { value: 10, name: "AZN", rate: 1.7 },
            //     { value: 11, name: "BAM", rate: 1.875797 },
            //     { value: 12, name: "BBD", rate: 2 },
            //     { value: 13, name: "BDT", rate: 119.666241 },
            //     { value: 14, name: "BGN", rate: 1.875898 },
            //     { value: 15, name: "BHD", rate: 0.377452 },
            //     { value: 16, name: "BIF", rate: 2960.624136 },
            //     { value: 17, name: "BMD", rate: 1 },
            //     { value: 18, name: "BND", rate: 1.360284 },
            //     { value: 19, name: "BOB", rate: 6.917949 },
            //     { value: 20, name: "BRL", rate: 6.19575 },
            //     { value: 21, name: "BSD", rate: 1 },
            //     { value: 22, name: "BTC", rate: 0.000010551195 },
            //     { value: 23, name: "BTN", rate: 85.655778 },
            //     { value: 24, name: "BWP", rate: 13.925095 },
            //     { value: 25, name: "BYN", rate: 3.276459 },
            //     { value: 26, name: "BZD", rate: 2.011126 },
            //     { value: 27, name: "CAD", rate: 1.44155 },
            //     { value: 28, name: "CDF", rate: 2848.433737 },
            //     { value: 29, name: "CHF", rate: 0.901912 },
            //     { value: 30, name: "CLF", rate: 0.035968 },
            //     { value: 31, name: "CLP", rate: 992.480695 },
            //     { value: 32, name: "CNH", rate: 7.3006 },
            //     { value: 33, name: "CNY", rate: 7.2988 },
            //     { value: 34, name: "COP", rate: 4398.4079 },
            //     { value: 35, name: "CRC", rate: 507.936084 },
            //     { value: 36, name: "CUC", rate: 1 },
            //     { value: 37, name: "CUP", rate: 25.75 },
            //     { value: 38, name: "CVE", rate: 105.754547 },
            //     { value: 39, name: "CZK", rate: 24.188 },
            //     { value: 40, name: "DJF", rate: 178.286115 },
            //     { value: 41, name: "DKK", rate: 7.154 },
            //     { value: 42, name: "DOP", rate: 60.892915 },
            //     { value: 43, name: "DZD", rate: 135.55545 },
            //     { value: 44, name: "EGP", rate: 50.85791 },
            //     { value: 45, name: "ERN", rate: 15 },
            //     { value: 46, name: "ETB", rate: 127.756654 },
            //     { value: 47, name: "EUR", rate: 0.959325 },
            //     { value: 48, name: "FJD", rate: 2.3224 },
            //     { value: 49, name: "FKP", rate: 0.795197 },
            //     { value: 50, name: "GBP", rate: 0.795197 },
            //     { value: 51, name: "GEL", rate: 2.799991 },
            //     { value: 52, name: "GGP", rate: 0.795197 },
            //     { value: 53, name: "GHS", rate: 14.717307 },
            //     { value: 54, name: "GIP", rate: 0.795197 },
            //     { value: 55, name: "GMD", rate: 71.9954 },
            //     { value: 56, name: "GNF", rate: 8653.957027 },
            //     { value: 57, name: "GTQ", rate: 7.718794 },
            //     { value: 58, name: "GYD", rate: 209.370329 },
            //     { value: 59, name: "HKD", rate: 7.7617 },
            //     { value: 60, name: "HNL", rate: 25.438066 },
            //     { value: 61, name: "HRK", rate: 7.22598 },
            //     { value: 62, name: "HTG", rate: 130.906835 },
            //     { value: 63, name: "HUF", rate: 394.11 },
            //     { value: 64, name: "IDR", rate: 16185 },
            //     { value: 65, name: "ILS", rate: 3.684266 },
            //     { value: 66, name: "IMP", rate: 0.795197 },
            //     { value: 67, name: "INR", rate: 85.39575 },
            //     { value: 68, name: "IQD", rate: 1311.56349 },
            //     { value: 69, name: "IRR", rate: 42250 },
            //     { value: 70, name: "ISK", rate: 139.16462 },
            //     { value: 71, name: "JEP", rate: 0.795197 },
            //     { value: 72, name: "JMD", rate: 155.843283 },
            //     { value: 73, name: "JOD", rate: 0.7091 },
            //     { value: 74, name: "JPY", rate: 157.825 },
            //     { value: 75, name: "KES", rate: 129.650403 },
            //     { value: 76, name: "KGS", rate: 86.9994 },
            //     { value: 77, name: "KHR", rate: 4021.491937 },
            //     { value: 78, name: "KMF", rate: 471.824 },
            //     { value: 79, name: "KPW", rate: 900 },
            //     { value: 80, name: "KRW", rate: 1473.797724 },
            //     { value: 81, name: "KWD", rate: 0.308445 },
            //     { value: 82, name: "KYD", rate: 0.834316 },
            //     { value: 83, name: "KZT", rate: 524.068491 },
            //     { value: 84, name: "LAK", rate: 21884.539363 },
            //     { value: 85, name: "LBP", rate: 89661.111105 },
            //     { value: 86, name: "LKR", rate: 292.859536 },
            //     { value: 87, name: "LRD", rate: 182.218385 },
            //     { value: 88, name: "LSL", rate: 18.755383 },
            //     { value: 89, name: "LYD", rate: 4.923033 },
            //     { value: 90, name: "MAD", rate: 10.101472 },
            //     { value: 91, name: "MDL", rate: 18.461613 },
            //     { value: 92, name: "MGA", rate: 4696.722597 },
            //     { value: 93, name: "MKD", rate: 59.010694 },
            //     { value: 94, name: "MK", rate: 2098 },
            //     { value: 95, name: "MNT", rate: 3398 },
            //     { value: 96, name: "MOP", rate: 8.004412 },
            //     { value: 97, name: "MRO", rate: 357 },
            //     { value: 98, name: "MRU", rate: 39.937659 },
            //     { value: 99, name: "MUR", rate: 46.949997 },
            //     { value: 100, name: "MVR", rate: 15.4 },
            //     { value: 101, name: "MWK", rate: 1736.05821 },
            //     { value: 102, name: "MXN", rate: 20.33 },
            //     { value: 103, name: "MYR", rate: 4.4715 },
            //     { value: 104, name: "MZN", rate: 63.830001 },
            //     { value: 105, name: "NAD", rate: 18.755383 },
            //     { value: 106, name: "NGN", rate: 1550.283356 },
            //     { value: 107, name: "NIO", rate: 36.848416 },
            //     { value: 108, name: "NOK", rate: 11.3578 },
            //     { value: 109, name: "NPR", rate: 136.97173 },
            //     { value: 110, name: "NZD", rate: 1.775411 },
            //     { value: 111, name: "OMR", rate: 0.38501 },
            //     { value: 112, name: "PAB", rate: 1 },
            //     { value: 113, name: "PEN", rate: 3.746512 },
            //     { value: 114, name: "PGK", rate: 3.98854 },
            //     { value: 115, name: "PHP", rate: 57.918 },
            //     { value: 116, name: "PKR", rate: 278.705477 },
            //     { value: 117, name: "PLN", rate: 4.101568 },
            //     { value: 118, name: "PYG", rate: 7784.056779 },
            //     { value: 119, name: "QAR", rate: 3.64878 },
            //     { value: 120, name: "RON", rate: 4.7731 },
            //     { value: 121, name: "RSD", rate: 112.137345},
            //     { value: 122, name: "RUB", rate: 105.500406},
            //     { value: 123, name: "RWF", rate: 1381.183031},
            //     { value: 124, name: "SAR", rate: 3.7553},
            //     { value: 125, name: "SBD", rate: 8.38356},
            //     { value: 126, name: "SCR", rate: 13.9432},
            //     { value: 127, name: "SDG", rate: 601.5},
            //     { value: 128, name: "SEK", rate: 11.0062},
            //     { value: 129, name: "SGD", rate: 1.3588},
            //     { value: 130, name: "SHP", rate: 0.795197},
            //     { value: 131, name: "SLL", rate: 20969.5},
            //     { value: 132, name: "SOS", rate: 572.195879},
            //     { value: 133, name: "SRD", rate: 35.08},
            //     { value: 134, name: "SSP", rate: 130.26},
            //     { value: 135, name: "STD", rate: 22281.8},
            //     { value: 136, name: "STN", rate: 23.497818},
            //     { value: 137, name: "SVC", rate: 8.76037},
            //     { value: 138, name: "SYP", rate: 13002},
            //     { value: 139, name: "SZL", rate: 18.748382},
            //     { value: 140, name: "THB", rate: 34.069918},
            //     { value: 141, name: "TJS", rate: 10.937994},
            //     { value: 142, name: "TMT", rate: 3.51},
            //     { value: 143, name: "TND", rate: 3.195032},
            //     { value: 144, name: "TOP", rate: 2.39453},
            //     { value: 145, name: "TRY", rate: 35.139999},
            //     { value: 146, name: "TTD", rate: 6.803817},
            //     { value: 147, name: "TWD", rate: 32.8235},
            //     { value: 148, name: "TZS", rate: 2426.939935},
            //     { value: 149, name: "UAH", rate: 42.015248},
            //     { value: 150, name: "UGX", rate: 3672.380504},
            //     { value: 151, name: "USD", rate: 1},
            //     { value: 152, name: "UYU", rate: 43.865353},
            //     { value: 153, name: "UZS", rate: 12936.482705},
            //     { value: 154, name: "VEF", rate: 248487.642241},
            //     { value: 155, name: "VES", rate: 51.701112},
            //     { value: 156, name: "VND", rate: 25455.011984},
            //     { value: 157, name: "VUV", rate: 118.722},
            //     { value: 158, name: "WST", rate: 2.8},
            //     { value: 159, name: "XAF", rate: 629.275716},
            //     { value: 160, name: "XAG", rate: 0.03405713},
            //     { value: 161, name: "XAU", rate: 0.00038136},
            //     { value: 162, name: "XCD", rate: 2.70255},
            //     { value: 163, name: "XDR", rate: 0.767755},
            //     { value: 164, name: "XOF", rate: 629.275716},
            //     { value: 165, name: "XPD", rate: 0.00110064},
            //     { value: 166, name: "XPF", rate: 114.477881},
            //     { value: 167, name: "XPT", rate: 0.00108564},
            //     { value: 168, name: "YER", rate: 249.851663},
            //     { value: 169, name: "ZAR", rate: 18.6804},
            //     { value: 170, name: "ZMW", rate: 27.758117},
            //     { value: 171, name: "ZWL", rate: 322}
            // ]);

            const partners = reactive({ list: [] });
            const rooms = reactive( { list :[] });
            
            const backtohotels = () => {                
                router.push({path: `/hotelregistration`});
            };
            const validateForm = () => {
                if (!roomInfo.roomType) {
                    alert("Room Type is required");
                    return false;
                }
                if (!roomInfo.actualPrice || roomInfo.actualPrice <= 0) {
                    alert("Actual Price is required and must be greater than 0");
                    return false;
                }
                if (!roomInfo.companyMarkupType) {
                    alert("Company Markup Type is required");
                    return false;
                }
                if (roomInfo.companyMarkup === null || roomInfo.companyMarkup === undefined) {
                    alert("Company Markup is required");
                    return false;
                }
                if (!roomInfo.finalPrice) {
                    alert("Room Sale Price is required");
                    return false;
                }
                return true;
            };
            const addRooms = async () => {        
                //alert("addrooms called..." + roomInfo.roomType);
                if (!validateForm()) return;
                excursionInfo.roomDetails.unshift({ ...roomInfo }); // add new room to rooms list
                saveRooms();
                showRoomsListView.value = true;
            };
            
            const deleteRoom = (index) => {
      if (index < 0 || index >= rooms.list.length) {
        console.error("Invalid index for deletion");
        return;
      }
      roomToDelete.value = index;
      showDeleteModal.value = true; 
    };

    const confirmDelete = () => {
      if (roomToDelete.value !== null) {
        rooms.list.splice(roomToDelete.value, 1);
        saveRooms(); 
      }
      showDeleteModal.value = false;
      roomToDelete.value = null;
    };

            const updateRooms = () => {                
                //alert("actual price=" + roomInfo.actualPrice + ", idx=" + selectedIndex.value);
                //excursionInfo.roomDetails[ selectedIndex.value ] = roomInfo; 
                if (!validateForm()) return;
                excursionInfo.roomDetails.splice(selectedIndex.value, 1, roomInfo);
                saveRooms();
                showRoomsListView.value = true;
            };

            const saveRooms = async () => {                
                try {
                    // Send the updated excursionInfo object to the backend                    
                    const response = await axios.post(API_CONFIG.getEndpoint('createExcursion') +`/${excursionInfo.id}`
                        +`?excursionType=${ excursionType.value }`
                        +`&code=${ excursionInfo.destinationCityId }`
                        +`&destinationId=${ excursionInfo.destinationId }`
                        +`&cityId=${excursionInfo.cityId}`,  excursionInfo );

                    console.log("saveRooms successfully", response.data);
                } catch (error) {
                    console.error('Error saving room details and excursion info:', error);
                }
            };

            const calculateFinalPrice = ( room_info ) => {                            
              let fPrice = room_info.actualPrice;              
              if( room_info.companyMarkupType === 'Percentage' ) {
                fPrice *= (1 + room_info.companyMarkup / 100);                
              } else if( room_info.companyMarkupType === 'Absolute' ) {
                fPrice += room_info.companyMarkup;                
              }
              room_info.finalPrice = (Math.round(fPrice * 100) / 100).toFixed(2);         
              return room_info.finalPrice;
            };

            const cancel = () => {
                console.log('Cancel button clicked');
                // Implement any desired logic for cancel action
            };

            const fetchPartnersByDestination = async () => {                
                try {
                    const response = await axios.get(API_CONFIG.getEndpoint('searchPartners') + `/`  + excursionInfo.destinationId );                                  
                    partners.list = response.data;
                } catch(error) {
                    console.error('Error fetching partners list', error);
                }
            };

            const fetchExcursionById = async () => {
                //alert("excursion_id ===> " + excursion_id);
                try {
                    const response = await axios.get(API_CONFIG.getEndpoint('fetchExcursions') +'/' +excursion_id );                    
                    Object.assign(excursionInfo, response.data);
                    
                    console.log(" fetchExcursionById ::" , excursionInfo);
                    console.log(" roomDetails ::" , excursionInfo.roomDetails[0]);

                    rooms.list = excursionInfo.roomDetails;
                    console.log(" rooms.list ::" , rooms.list );

                    fetchPartnersByDestination();

                }catch(error) {
                    console.log("Error ::" , error);
                }
            };

            const showForm = (action, room = null, idx) => {                
                selectedIndex.value = idx;
                if( action === 'create') {                    
                    reset();
                    showRoomsListView.value = false;                    
                    isEdit.value = false;
                } else if (action === 'edit' && room) {                    
                    Object.assign(roomInfo,room);
                    showRoomsListView.value = false;
                    isEdit.value = true;                    
                } else if (action === 'upload-picture' && excursionInfo) {
                    console.log("RoomInfo ==> " + room.id);
                    router.push({ path: `/upload-picture-room/${excursionInfo.id}/id/${room.id}` });
                } else {
                    console.error("Invalid action or room data:", action, room);
                }
            };            

            const cancelRoom = () => {
                showRoomsListView.value  = true;
            };

            const reset = () => {
                roomInfo.id = '';
                roomInfo.roomType = ''; 
                //roomInfo.currency = 'USD'; 
                //roomInfo.partner = ''; 
                roomInfo.actualPrice = 0; 
                roomInfo.companyMarkupType = 'Percentage'; 
                roomInfo.companyMarkup = 0; 
                roomInfo.finalPrice = 0; 
                roomInfo.status = 'Active';
            };
            
            onMounted ( () => {        
                fetchExcursionById();        
                //fetchPartnersByDestination();
            });

            return {
                //...toRefs(roomInfo),
                roomInfo,excursionType,
                excursionInfo,
                partners,
                fetchPartnersByDestination,
                addRooms,
                cancel,
                // currencies,
                fetchExcursionById,
                showRoomsListView,
                excursion_id,
                rooms,
                showForm,
                reset,
                cancelRoom,
                deleteRoom,
                updateRooms,
                saveRooms,
                backtohotels,
                calculateFinalPrice,isEdit,generateRoomId,
                showDeleteModal,
                roomToDelete,
                confirmDelete,
                filteredRoomExcursions,
                clearSearch,
                searchQuery,
                limitDecimals
            };
        }
    };
</script>
<style scoped>
</style>