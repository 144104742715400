import { createRouter, createWebHistory } from 'vue-router';
import TheDashboard from '@/components/TheDashboard.vue';
import TheUsers from '@/components/TheUsers.vue';
import TheSettings from '@/components/TheSettings.vue';
import TheHome from '@/views/TheHome.vue';
import CreatePackage from '@/components/CreatePackage.vue';
import ViewPackages from '@/components/ViewPackages.vue';
import HotelForm from '@/components/HotelForm.vue';
import RoomForm from '@/components/RoomForm.vue';
import PartnerForm from '@/components/PartnerForm.vue';
import DestinationsForm from '@/components/DestinationsForm.vue';
import CityForm from '@/components/CityForm.vue';
import AirportsForm from '@/components/AirportsForm.vue';
import HotelRegisteration from '@/components/HotelRegisteration.vue';
import RoomManagement from '@/components/RoomManagement.vue';
import AirportTransfer from '@/components/AirportTransfer.vue';
import InterCityTransfer from '@/components/InterCityTransfer.vue';
import SightSeeing from '@/components/SightSeeing.vue'
import NewInformation from '@/components/NewInformation.vue';
import CreateItinerary from '@/components/CreateItinerary.vue';
import InclusionsExclusions from '@/components/InclusionsExclusions.vue';
import PricingSummary from '@/components/PricingSummary.vue'
import PackageSummary from '@/components/PackageSummary.vue';
import UploadPicture from '@/components/UploadPictureHotel.vue';
import UploadPictureSightseeing from '@/components/UploadPictureSightseeing.vue';
import ViewPackagesVersion from "@/components/ViewPackagesVersion.vue";
import UploadPictureNewInformation from '@/components/UploadPictureNewInformation.vue';
import ClonePackage from "@/components/ClonePackage.vue";
import VouchersForm from "@/components/VouchersForm.vue";
import ViewTemplates from '@/components/ViewTemplates.vue';
import CreateTemplate from '@/components/CreateTemplate.vue';
import CreateWebPackage from '@/components/CreateWebPackage.vue';
import CreatePackageType from '@/components/CreatePackageType.vue';
import CreatePackageTheme from '@/components/CreatePackageTheme.vue';
import ExchangeRates from '@/components/ExchangeRates.vue';
import CreateLeads from '@/components/CreateLeads.vue';
import GenerateCoupons from '@/components/GenerateCoupons.vue';
import CreateWebCards from '@/components/CreateWebCards.vue';
import CreateTestimonials from '@/components/CreateTestimonials.vue';
import CreateInstallmentSettings from '@/components/CreateInstallmentSettings.vue';
import CreateWebImage from '@/components/CreateWebImage.vue';
import ViewWebPackages from '@/components/ViewWebPackages.vue';
import CreateFAQ from '@/components/CreateFAQ.vue';
import CreatePopularDestination from '@/components/CreatePopularDestination.vue';
import ManageBookings from '@/components/ManageBookings.vue';
import PaymentInstallment from '@/components/PaymentInstallment.vue';
import UploadPictureHotelRoom from '@/components/UploadPictureRoom.vue';

const routes = [
  {
    path: '/',
    component: TheHome,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: TheDashboard
      },
      {
        path: 'users',
        name: 'users',
        component: TheUsers
      },
      {
        path: 'settings',
        name: 'settings',
        component: TheSettings
      },
      {
        path : 'packages/:id?',
        name: 'packages',
        component: CreatePackage
      },
      {
        path: 'viewpackages',
        name: 'viewpackages',
        component: ViewPackages
      },
      {
        path: 'packages/versions/:packageCode',
        name: 'packages/versions',
        component: ViewPackagesVersion
      },
      {
        path: 'packages/versions/clone/:packageCode',
        name: 'packages/versions/clone',
        component: ClonePackage
      },
      {
        path : 'itinerary/:id?',
        name: 'itinerary',
        component: CreateItinerary,
        props: true
      },
      {
        path : 'packages/inclusion/:id?',
        name: 'inclusionExclusion',
        component: InclusionsExclusions,
        props: true
      },      
      {
        path : 'packages/price-summary/:id?',
        name: 'PricingSummary',
        component: PricingSummary,
        props: true
      },
      {
        path : 'packages/vouchers/:id?',
        name: 'vouchers',
        component: VouchersForm,
        props: true
      },
      {
        path : 'packages/summary/:id?',
        name: 'PackageSummary',
        component: PackageSummary,
        props: true
      },
      {
        path: '/hotel-form',
        name: 'hotels',
        component: HotelForm
      },      
      {
        path: '/room-form/:hotelCode',
        name: 'rooms',
        component: RoomForm,
        props: true
      },
      {
        path: 'hotelregistration',
        name: 'hotelregistration',
        component: HotelRegisteration
      },
      {
        path: 'airport-transfers',
        name: 'AirportTransfer',
        component: AirportTransfer
      },
      {
        path: 'intercity-transfer',
        name: 'InterCityTransfer',
        component: InterCityTransfer
      },
      {
        path: 'sightseeing',
        name: 'Sightseeing',
        component: SightSeeing
      },
      {
        path: 'information',
        name: 'information',
        component: NewInformation
      },
      {        
        name: 'RoomsManagement',
        component: RoomManagement,
        path: '/rooms/:excursionId',
        props: true,
        /*
        path: '/rooms/:hotelName/:hotelCode',
          props: route => ({ 
          hotelName: route.params.hotelName, 
          hotelCode: route.params.hotelCode
        }), 
        */
        //path: '/rooms/:excursionInfo',
        //props: route => ({ excursionInfo: JSON.parse(decodeURIComponent(route.params.excursionInfo)) })
      },
      {
        path: 'partner',
        name: 'partner',
        component: PartnerForm
      }, 
      {
        path:'destinations',
        name:'destinations',
        component: DestinationsForm
      },
      {
        path : 'cities',
        name : 'cities',
        component : CityForm
      },
      {
        path: 'airports',
        name: 'airports',
        component: AirportsForm
      },
      {
        path: 'upload-picture/:excursionId',
        name: 'uploadpicture',
        component: UploadPicture,
        props: true
      },
      {
        path: '/upload-picture-sightseeing/:excursionId',
        name: 'UploadPictureSightseeing',
        component: UploadPictureSightseeing,
        props: true, 
      },
      {
        path: '/upload-picture-newInformation/:excursionId',
        name: 'UploadPictureNewInformation',
        component: UploadPictureNewInformation,
        props: true, 
      },
      {
        path: '/upload-picture-room/:excursionId/id/:roomId',
        name: 'UploadPictureHotelRoom',
        component: UploadPictureHotelRoom,
        props: route => ({ excursionId: route.params.excursionId, roomId: route.params.roomId }),
      },
      {
        path: '/template/view',
        name: 'ViewTemplates',
        component: ViewTemplates
      },
      {
        path:'/template/create',
        name:'CreateTemplate',
        component: CreateTemplate
      },
      {
        path:'/web-packages/create/:packageCode?',
        name:'createWebPackage',
        component: CreateWebPackage,
        props: true        
      },
      {
        path:'/web-packages/view',
        name:'ViewWebPackages',
        component: ViewWebPackages
      },
      {
        path:'/web-packages/packagetype',
        name:'packagetype',
        component: CreatePackageType
      },

      {
        path:'/web-packages/packagetheme',
        name:'packagetheme',
        component: CreatePackageTheme
      },

      {
        path: '/exchangerates',
        name: 'exchangerates',
        component: ExchangeRates
      },

      {
        path: '/leads',
        name:'leads',
        component: CreateLeads
      },

      {
        path: '/coupons',
        name: 'coupons',
        component: GenerateCoupons
      },
      
      {
        path: '/webcards',
        name: 'webcards',
        component: CreateWebCards
      },

      {
        path: '/testimonials',
        name: 'testimonials',
        component: CreateTestimonials
      },

      {
        path: '/installmentsettings',
        name: 'installmentsettings',
        component: CreateInstallmentSettings
      },

      {
        path: '/webimage',
        name: 'webimage',
        component: CreateWebImage
      },

      {
        path: '/faq',
        name: 'faq',
        component: CreateFAQ
      },

      {
        path: '/populardestinations',
        name: 'populardestinations',
        component: CreatePopularDestination
      },

      {
        path: '/bookings',
        name: 'bookings',
        component: ManageBookings
      },

      {
        path: '/installmentpayments/:id?',
        name: 'installmentpayments',
        component: PaymentInstallment,
        props: true
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
