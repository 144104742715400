<template>
  <div class="container">
  <div class="card-header">
  <div class="d-flex justify-content-between align-items-center">
      <h4>Website Packages List</h4>
      <div style="display: flex !important; gap: 20px !important">
        <div>
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div>
  <button class="btn " @click="createWebPackage">
    <font-awesome-icon :icon="['fas', 'fa-plus']" class="me-2" /> New Website Package</button>
      </div>
        </div>
        </div>
      <div v-if="loading">Loading...</div>
      <div v-if="error">{{ error }}</div>
      <div class="table-container">
        <table v-if="packages.length" class="table table-bordered table-hover table-responsive" style="table-layout: auto;">
          <thead>
              <tr>
                  <th>SI.No</th>
                  <th>Code</th>
                  <th>Package Title</th>
                  <th>Destination</th>
                  <th>Duration</th>                    
                  <th>Package Price</th>

                  <th>Best Deals</th>
                  <th>Featured Tours</th>     

                  <th>Tour Tags</th>
                  <th>Theme Tags</th>
                  <th>Other Tags</th>

                  <th>Customer can customize </th>
                  <th>Status</th> 

                  <th>Action Menu</th>
              </tr>
          </thead>
          <tbody>
              <tr v-for=" (pkg,index) in paginatedUsers" :key="index">
                    <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>
                    <td>{{ pkg.packageCode }}</td>
                    <td class="text-nowrap">{{ pkg.packageTitle }}</td>
                    <td>{{ pkg.destinationId }}</td>
                    <td>{{ pkg.noOfNights }}</td>
                    <td>{{ pkg.priceSummary.netSellingPrice}}</td>

                    <td>{{ pkg.bestDeal }}</td>
                    <td>{{ pkg.featuredTour }}</td>                                       
                    
                    <td>{{ pkg.packageType }}</td>
                    <td>{{ pkg.packageTheme }}</td>
                    <td>{{ pkg.otherTags }}</td>

                    <td>{{ pkg.customerCanCustomize }}</td> 
                    <td>{{ pkg.status }}</td>

                  <td>
                      <div class="row">
                        <div class="col-md-4">
                          <a href="#" class="text-danger mr-3 faq-button" role="button" @click="viewItinerary(pkg.packageCode)" >
                            <font-awesome-icon :icon="['fas', 'fa-plus']" class="plus"/><span class="tooltip">Add Itinerary</span></a>
                        </div>
                        <div class="col-md-4">
                          <a href="#" class="text-success mr-3 faq-button" role="button" @click="editTemplate(pkg.packageCode)">
                             <font-awesome-icon :icon="['fas', 'fa-edit']" class="edit" /><span class="tooltip">Edit Package</span></a>
                        </div>
                        <div class="col-md-4">
                          <a href="#" class="text-danger mr-3 faq-button" role="button" @click="confirmDelete(pkg.packageCode)">
                            <font-awesome-icon :icon="['fas', 'fa-trash']"  class="delete" /><span class="tooltip">Delete Package</span></a>
                        </div>
                      </div>
                  </td>
              </tr>
          </tbody>
      </table>
      </div>  
      <DeleteModal
      :visible="showDeleteModal"
      @confirmDelete="deletePackage"
      @cancelDelete="showDeleteModal = false"
    />
    <div class="d-flex justify-content-between mt-3">
      <div class="d-flex align-items-center">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredWebPackages.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination mb-0" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>     
    </div>
  </div>
</template>
<script>
import {onMounted, ref, computed, watch} from 'vue';
import {useRouter} from 'vue-router';
import axios from 'axios';
import {API_CONFIG} from '@/apiConfig';
import router from "@/router";
import DeleteModal from "./DeleteModal.vue";

export default {
  components: {
    DeleteModal,
  },
      name: 'PackageList',
methods: {
  router() {
    return router
  }
},
      setup() {
        const searchQuery = ref('');
        const packages= ref([]);
        const loading = ref(true);
        const error = ref(null);
        const router = useRouter();          
        const packageCode = ref(null);
        const showDeleteModal = ref(false);
        const packageCodeToDelete = ref(null); 
        const currentPage = ref(1);
        const itemsPerPage = ref(10);

        const fetchAllWebPackages = async () => {
          try {
              const response = await axios.get(API_CONFIG.getEndpoint('websitePackages'));
              packages.value = response.data;
          }catch(err) {
              error.value = err;
          }finally {
              loading.value = false;

          }
        };

        const confirmDelete = (packageCode) => {
      packageCodeToDelete.value = packageCode;
      showDeleteModal.value = true;
    };

    const filteredWebPackages = computed(() => {
  return packages.value.filter(pkg => {
    return (
      pkg.packageTitle.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      pkg.packageCode.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      pkg.packageType.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      pkg.packageTheme.toLowerCase().includes(searchQuery.value.toLowerCase())
    );
  });
});

const clearSearch = () => {
  searchQuery.value = '';
};

const getPageRange = () => {
  const range = [];
  const total = totalPages.value;
  const current = currentPage.value;
  const delta = 2;

  if (current > delta + 1) {
    range.push(1);
    if (current > delta + 2) range.push('...');
  }

  for (let i = Math.max(1, current - delta); i <= Math.min(total, current + delta); i++) {
    range.push(i);
  }

  if (current < total - delta - 1) {
    if (current < total - delta - 2) range.push('...');
    range.push(total);
  }

  return range;
};

const resetPagination = () => {
  currentPage.value = 1;
};

const goToPage = (page) => {
  const pageNum = Number(page);
  if (pageNum >= 1 && pageNum <= totalPages.value) {
    currentPage.value = pageNum;
  }
};

const paginatedUsers = computed(() => {
  const pageSize = Number(itemsPerPage.value) || 10;
  const current = Number(currentPage.value) || 1;
  
  const startIndex = (current - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  
  if (!filteredWebPackages.value.length) return [];
  
  return filteredWebPackages.value.slice(startIndex, endIndex);
});

const totalPages = computed(() => {
  const pageSize = Number(itemsPerPage.value) || 10;
  return Math.max(1, Math.ceil(filteredWebPackages.value.length / pageSize));
});

watch(filteredWebPackages, () => {
  if (currentPage.value > totalPages.value) {
    currentPage.value = totalPages.value || 1;
  }
});

watch(itemsPerPage, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    resetPagination();
  }
});  
        const deletePackage = async () => {
          try {
              await axios.delete( API_CONFIG.getEndpoint('websitePackages') + `/${packageCodeToDelete.value}` );
              fetchAllWebPackages();
              showDeleteModal.value = false;
          }catch(err) {
              error.value = err;
          }
        };

        const viewItinerary = (packageCode) => {
          router.push({ name: 'itinerary', params: { id: packageCode } });
        };

        const editTemplate = (packageCode) => {
          router.push({ name: 'createWebPackage', params: { packageCode: packageCode } });
        }

        const createWebPackage = () => {              
          router.push({ name: 'createWebPackage' });
        };

        //this fetchPackages will be loaded onload of component.
        onMounted(fetchAllWebPackages);

        return {              
            packages,
            packageCode,
            loading,
            error,
            editTemplate,
            deletePackage,
            createWebPackage,
            viewItinerary,
            fetchAllWebPackages,
            confirmDelete,
            packageCodeToDelete,
            showDeleteModal,
            filteredWebPackages,
            clearSearch,
            searchQuery ,
            paginatedUsers,
              resetPagination,
              goToPage,
              itemsPerPage,
              totalPages,
              currentPage,
              getPageRange   
        };
      }
  };
</script>

<style scoped>
  .container {
  margin-top: 20px;
  }

.btn{
color: #ffffff;
background-color: #7978E9;
}
.btn:hover{
background-color: #4B49AC;
color: #ffffff;
}
  .modal {
    width: 100%;
  }

  .table-container {
    max-width: 100%; 
  overflow-x: auto; 
  overflow-y: auto; 
  max-height: 75vh; 
  margin-bottom: 20px;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1040;
  }



  /* Modal Body */
  .modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1050;
  }

  .modal-dialog {
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  /* Prevent clicks on the modal from propagating to the backdrop */
  .modal-content {
    position: relative;
    z-index: 1060;
  }
</style>