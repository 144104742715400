<template>
  <div class="container">
    <!-- Header -->
  <div class="card-header">
    <div class="d-flex justify-content-between align-items-center">
      <h4>User Management</h4>
      <div style="display: flex !important; gap: 20px !important">
        <div>
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div>
  <button class="btn1" @click="showCreateUserModal">
        <font-awesome-icon :icon="['fas', 'fa-user-plus']" class="me-2" /> Create New User
      </button>
      </div>
    </div>
  </div>

    <!-- User List Table -->
    <div class="table-container">
      <table class="table table-bordered table-striped" v-if="!loading">
        <thead class="thead-light">
        <tr>
          <th>#</th>
          <th>User Name</th>
          <th>Email</th>
          <th>Password</th>
          <th>User Type</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(user, index) in paginatedUsers" :key="user.id">
          <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>
          <td>{{ user.userName }}</td>
          <td>{{ user.userEmail }}</td>
          <td>{{ user.password }}</td>
          <td>{{ user.userType }}</td>
          <td>
            <div style="display: flex; justify-content: space-evenly;">
            <button class="edit p-1 me-1 btn-sm faq-button" @click="editUser(user)">
              <font-awesome-icon :icon="['fas', 'fa-edit']"  /> <span class="tooltip">Edit</span>
            </button>
            <button class="delete p-1 btn-sm faq-button" style="margin-left: 10px" @click="showDeleteConfirmModal(user.id)">
  <font-awesome-icon :icon="['fas', 'fa-trash']" /><span class="tooltip">Delete</span>
</button>
</div>
          </td>
        </tr>
        <tr v-if="users.length === 0">
          <td colspan="5" class="text-center">No users found</td>
        </tr>
        </tbody>
      </table>
      <div v-if="loading" class="text-center">
        Loading users...
      </div>
      <div v-if="errorMessage" class="alert alert-danger">
        {{ errorMessage }}
      </div>
    </div>

    <!-- Create/Edit User Modal -->
    <div class="modal fade" id="createUserModal" tabindex="-1" aria-labelledby="createUserModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="createUserModalLabel">{{ editingUser ? 'Edit User' : 'Create New User' }}</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="saveUser">
              <div class="mb-3">
                <label for="userName" class="form-label">User Name</label>
                <input
                    type="text"
                    id="userName"
                    class="form-control"
                    v-model="newUser.userName"
                    placeholder="Enter User Name"
                    required
                />
              </div>
              <!-- Email -->
              <div class="mb-3">
                <label for="userEmail" class="form-label">Email</label>
                <input
                    type="email"
                    id="userEmail"
                    class="form-control"
                    v-model="newUser.userEmail"
                    placeholder="Enter E-Mail Id"
                    required
                />
              </div>
              <!-- Password -->
              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <input
                    type="password"
                    id="password"
                    class="form-control"
                    v-model="newUser.password"
                    placeholder="Enter Password"
                    required
                />
              </div>
              <!-- User Type -->
              <div class="mb-3">
                <label for="userType" class="form-label">User Type</label>
                <select
                    id="userType"
                    class="form-select"
                    v-model="newUser.userType"
                    required
                >
                  <option value="Agent">Agent</option>
                  <option value="Customer">Customer</option>
                  <option value="SubAgent">SubAgent</option>
                  <option value="SuperAdmin">SuperAdmin</option>
                </select>
              </div>
              <!-- Submit Buttons -->
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                  <font-awesome-icon :icon="['fas', 'xmark']" />  Close
                </button>
                <button type="submit" class="btn button-user">
                  {{ editingUser ? 'Update User' : 'Create User' }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Confirmation Modal -->
<div class="modal fade" id="deleteConfirmModal" tabindex="-1" aria-labelledby="deleteConfirmModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteConfirmModalLabel">Confirm Delete</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        Are you sure you want to delete this user?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn" style="background-color: #dc3545 !important;" @click="confirmDeleteUser">Delete</button>
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-between mt-3">
      <div class="d-flex align-items-center mt-0">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredUsers.length }} entries</span></span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>     
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Modal } from "bootstrap";
import { API_CONFIG } from "@/apiConfig";

export default {
  data() {
    return {
      users: [], // Table users fetched from API
      loading: false, // For loading state
      errorMessage: "", // For error handling
      newUser: {
        userName: "",
        userEmail: "",
        password: "",
        userType: "Agent", // Default user type
      }, // Form data for new user
      editingUser: false, // Determines if we are editing a user
      editUserId: null, // Holds the ID of the user being edited
      userToDelete: null,
      searchQuery: "", 
      currentPage: 1, 
      itemsPerPage: 10, 
    };
  },
  computed: {
    filteredUsers() {
      return this.users.filter(user => {
        const searchQueryLower = this.searchQuery.toLowerCase();
        return (
          user.userName.toLowerCase().includes(searchQueryLower) ||
          user.userEmail.toLowerCase().includes(searchQueryLower) ||
          user.userType.toLowerCase().includes(searchQueryLower)
        );
      });
    },
  
    paginatedUsers() {
    const pageSize = Number(this.itemsPerPage) || 10;
    const currentPage = Number(this.currentPage) || 1;
    
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    
    if (!this.filteredUsers.length) return [];
    
    return this.filteredUsers.slice(startIndex, endIndex);
  },
  
  totalPages() {
    const pageSize = Number(this.itemsPerPage) || 10;
    return Math.max(1, Math.ceil(this.filteredUsers.length / pageSize));
  },
  },
  watch: {
  itemsPerPage(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.resetPagination();
    }
  },
  searchQuery() {
    this.resetPagination();
  },
  filteredCities: {
    handler() {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages || 1;
      }
    },
    immediate: true
  }
},
  methods: {
    resetPagination() {
    this.currentPage = 1;
    
    this.$forceUpdate();
  },
  
  goToPage(page) {
    const pageNum = Number(page);
    if (pageNum >= 1 && pageNum <= this.totalPages) {
      this.currentPage = pageNum;
    }
  },

getPageRange() {
    const range = [];
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    const delta = 2;

    if (currentPage > delta + 1) {
      range.push(1);
      if (currentPage > delta + 2) range.push('...');
    }

    for (let i = Math.max(1, currentPage - delta); i <= Math.min(totalPages, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage < totalPages - delta - 1) {
      if (currentPage < totalPages - delta - 2) range.push('...');
      range.push(totalPages);
    }

    return range;
  },
    clearSearch() {
    this.searchQuery = ""; 
  },
 
    async fetchUsers() {
      this.loading = true;
      this.errorMessage = "";
      try {
        const response = await axios.get(API_CONFIG.getEndpoint("fetchUsers"));
        this.users = response.data;
      } catch (error) {
        console.error(error);
        this.errorMessage = "Failed to load users.";
      } finally {
        this.loading = false;
      }
    },

    // Show the "Create User" modal
    showCreateUserModal() {
      this.editingUser = false;
      this.newUser = { userEmail: "", password: "", userType: "Agent", userName: "" }; // Reset form data
      this.openModal("createUserModal");
    },

    // Handle Edit button click
    editUser(user) {
      this.editingUser = true;
      this.editUserId = user.id; // Store the user ID
      this.newUser = { ...user }; // Populate the form with the user's data
      this.openModal("createUserModal");
    },

    // Open a Bootstrap modal by ID
    openModal(modalId) {
      const modalElement = document.getElementById(modalId);
      if (modalElement) {
        const modal = new Modal(modalElement);
        modal.show();
      }
    },

    // Close a Bootstrap modal by ID
    closeModal(modalId) {
      const modalElement = document.getElementById(modalId);
      if (modalElement) {
        const modal = Modal.getInstance(modalElement);
        if (modal) modal.hide();
      }
    },

    // Handle Create/Update user form submission
    async saveUser() {
      try {
        if (this.editingUser) {
          // Edit existing user
          await axios.put(`${API_CONFIG.getEndpoint("createUser")}/${this.editUserId}`, this.newUser);
        } else {
          // Create new user
          await axios.post(API_CONFIG.getEndpoint("createUser"), this.newUser);
        }

        // Close the modal and refresh the user list
        this.fetchUsers();
        this.closeModal("createUserModal");
      } catch (error) {
        console.error(error);
        this.errorMessage = "Failed to save user. Please try again.";
      }
    },

    showDeleteConfirmModal(userId) {
      this.userToDelete = userId; 
      this.openModal("deleteConfirmModal");
    },

    async confirmDeleteUser() {
      if (!this.userToDelete) return;
      try {
        await axios.delete(`${API_CONFIG.getEndpoint("deleteUser")}/${this.userToDelete}`);
        this.fetchUsers(); 
        this.userToDelete = null; 
        this.closeModal("deleteConfirmModal");
      } catch (error) {
        console.error(error);
        this.errorMessage = "Failed to delete user.";
      }
    },
  },
  mounted() {
    this.fetchUsers();
  },
};
</script>

<style scoped>
/* You can add your custom styles here if needed */
.btn1{
  color: #ffffff;
  background-color: #7978E9;
}
.btn1:hover{
  background-color: #4B49AC;
}
.button-user{
   background-color: #4B49AC;
   color: #ffffff;
}
.button-user:hover{
  background-color: #7978E9;
  color: #ffffff;
}
.modal-title{
  color: #4B49AC;
}
.form-label{
  color: #4B49AC;
}
.form-control{
   border: 2px solid #ddd; 
  border-radius: 10px; 
  transition: border-color 0.3s ease;
}
.form-control:focus {
  border-color: #4B49AC; 
  outline: none; 
  box-shadow: none;
}
.form-select{
   border: 2px solid #ddd; 
  border-radius: 10px; 
  transition: border-color 0.3s ease;
}
.form-select:focus {
  border-color: #4B49AC; 
  outline: none; 
  box-shadow: none;
}

</style>
