<template>
    <div v-if="visible" class="modal-overlay">
      <div class="modal-box">
        <h5>Confirm Delete</h5>
        <hr />
        <p>Are you sure you want to delete?</p>
        <div class="modal-buttons justify-content-center gap-3">
          <button @click="$emit('cancelDelete')" class="btn btn-secondary">Cancel</button>
          <button @click="$emit('confirmDelete')" class="btn btn-danger" style="background-color: #dc3545 !important;" >{{ actionType }}</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      visible: Boolean,
      actionType: {
      type: String,
      default: "Delete",
    },
    },
  };
  </script>
  
  <style scoped>
  
  
  
  </style>
  