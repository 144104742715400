<template>
    <div class="container">
        <div class="card-header" v-if="!isFormVisible">
    <div class="d-flex justify-content-between align-items-center" style="margin-top: 0px">
            <h4>Manage Destinations</h4>
            <div style="display: flex !important; gap: 20px !important">
                <div v-if="!isFormVisible">
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div>
  <button class="btn" @click="showForm('create')">
    <font-awesome-icon :icon="['fas', 'fa-plus']" class="me-2" />New Destination</button>
            </div>
        </div>
        </div>
        <div class="table-container"> 
        <div v-if="!isFormVisible">
          <!--  <h4>Destinations List</h4> -->
            <table class="table table-bordered mt-3">
                <thead>
                    <tr>
                        <th>SI.No</th>
                        <th>Destination Name</th>
                        <th>Destination Banner</th>
                        <th>Website Image</th>
                        <th>Meal Inclusions</th>
                        <th>Visa Inclusions</th>
                        <th>Status</th>
                        <!-- <th>Continent</th>
                        <th>Region</th>
                        <th>Country</th>
                        <th>State</th>
                        <th>City</th> -->
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody
                dy>
                    <tr v-for="(destination, index) in paginatedUsers" :key="destination.id">
                        <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>
                        <td>{{ destination.destinationName }}</td>
                        <td>
                            <!-- {{ destination.destinationBanner }} -->
                            <img :src="destination.destinationBanner" alt="Banner" width="100">

                        </td>
                        <td>
                            <!-- {{ destination.websiteBannerUrl }} -->
                            <img :src="destination.websiteBannerUrl" alt="Website Image" width="100">

                        </td>
                        <td>{{ destination.mealPlan }}</td>
                        <td>{{ destination.visa }}</td>
                        <td>{{ destination.status }}</td>
                        <!-- <td>{{ destination.continentName }}</td>
                        <td>{{ destination.regionName }}</td>
                        <td>{{ destination.countryName }}</td>
                        <td>{{ destination.stateName }}</td>
                        <td>{{ destination.cityName }}</td> -->
                        <td style="display: flex;">
                            <button @click="showForm('edit', destination)" class="faq-button"><font-awesome-icon :icon="['fas', 'fa-edit']"  class="edit" /><span class="tooltip">Edit</span></button>
                            <button @click="showDeleteModal(destination.id)" class="faq-button">
  <font-awesome-icon :icon="['fas', 'fa-trash']"  class="delete" /><span class="tooltip">Delete</span>
</button>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
        <div v-if="isFormVisible">
            <h5>{{ formTitle }}</h5>
            <hr/>
            <form @submit.prevent="submitForm">
                <div class="form-group row mb-3">
                    <label for="destinationName" class="col-sm-3 col-form-label" >Destination Name <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <input type="text" v-model="form.destinationName" class="form-control" id="destinationName" required @input="validateInput" :class="{'is-invalid': errorMessages.destinationName}" placeholder="Enter Destination Name">
                        <div v-if="errorMessages.destinationName" class="invalid-feedback">{{ errorMessages.destinationName }}</div>
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label class="col-sm-3">Destination Banner <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <input type="file" @change="handleFileUpload('destinationBanner', $event)" class="form-control" id="destinationBanner" required>
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="websiteImage" class="col-sm-3 col-form-label">Website Image <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <input type="file" @change="handleFileUpload('websiteImage', $event)" class="form-control" id="websiteImage" required>
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="airportTransDesc" class="col-sm-3 col-form-label">Airport Transfer Description <span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <textarea v-model="form.airportTransDesc" class="form-control" placeholder="Enter Airport Transfer Description" id="airportTransDesc" required></textarea>
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="intercityTransDesc" class="col-sm-3 col-form-label text-nowrap">Intercity Transfer Description<span style="color:red !important">*</span></label>
                    <div class="col-sm-6">
                        <textarea v-model="form.intercityTransDesc" class="form-control" placeholder="Enter Intercity Transfer Description" id="intercityTransDesc" required></textarea>
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="description" class="col-sm-3 col-form-label">Short Description</label>
                    <div class="col-sm-6">
                        <textarea v-model="form.description" class="form-control" id="description" placeholder="Enter Short Description"></textarea>
                    </div>
                </div>                
                <div class="form-group row mb-3">
                    <label for="meal" class="col-sm-3 col-form-label">Meal Plan</label>
                      <div class="col-sm-6">
                      <input type="checkbox" 
                        :checked="isAllSelected" 
                         @change="toggleAllMeals" 
                         id="All">
                        <label for="All">All</label>
                          <div v-for="meal in mealPlans" :key="meal">
                       <input type="checkbox" 
                      :value="meal" 
                      v-model="form.mealPlan" 
                      :id="meal" 
                      :disabled="isAllSelected && form.mealPlan.length === mealPlans.length">
                     <label :for="meal">{{ meal }}</label>
                     
                    </div>
              </div>
           </div>

                <div class="form-group row mb-3">
                    <label for="visaOption" class="col-sm-3 col-form-label">Visa </label>
                    <div class="col-sm-6">
                        <div v-for="visaOption in visaOptions" :key="visaOption">
                            <input type="checkbox" :value="visaOption" v-model="form.visa" :id="visaOption">
                            <label :for="visaOption">{{ visaOption }}</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="transport" class="col-sm-3 col-form-label">Transport </label>
                    <div class="col-sm-6">
                        <input type="text" v-model="form.transport" class="form-control" id="transport" @input="validateTransport" :class="{'is-invalid': errorMessages.transport}">
                        <div v-if="errorMessages.transport" class="invalid-feedback">{{ errorMessages.transport }}</div>
                    </div>
                </div>
                <!-- <div class="form-group row mb-3">
                    <label for="popularDestination" class="col-sm-3 col-form-label">Popular Destination </label>
                    <div class="col-sm-6">
                        <input type="text" v-model="form.popularDestination" class="form-control" id="popularDestination">
                    </div>
                </div> -->
                <div class="form-group row mb-3">                           
                    <label for="popularDestination" class="col-sm-3 col-form-label">Popular Destination </label>
                    <div class="col-sm-2 mt-2">
                        <input type="radio" v-model="form.popularDestination" class="form-check-input" id="statusActive" value="yes">
                        <label for="statusActive" class="form-check-label ml-1">Yes</label>
                    </div>
                    <div class="col-sm-2 mt-2">
                        <input type="radio" v-model="form.popularDestination" class="form-check-input" id="statusInactive" value="no">
                        <label for="statusInactive" class="form-check-label">No</label>
                    </div>                
                </div>

                <!-- <div class="form-group row mb-3">
                    <label for="tags" class="col-sm-3 col-form-label">Tags</label>
                    <div class="col-sm-6">
                        <input type="text" v-model="form.tags" class="form-control" id="tags">
                    </div>
                </div> -->

                <div class="form-group row mb-3">
                    <label for="status" class="col-sm-3 col-form-label">Status</label>                
                    <div class="col-sm-2 mt-2">
                        <input type="radio" v-model="form.status" class="form-check-input" id="statusActive" value="Active">
                        <label for="statusActive" class="form-check-label">Active</label>
                    </div>
                    <div class="col-sm-2 mt-2">
                        <input type="radio" v-model="form.status" class="form-check-input" id="statusInactive" value="Inactive">
                        <label for="statusInactive" class="form-check-label">Inactive</label>
                    </div>                
                </div>
                <hr>
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-success me-2" :disabled="!isFormValid || loading"><font-awesome-icon :icon="['fas', 'fa-save']" class="me-2" />Submit</button>
                    <button type="button" class="btn btn-light" @click="cancelForm"> <font-awesome-icon :icon="['fas', 'xmark']" /> Cancel</button>
                </div>
                <br>
            </form>
        </div>
        <!-- Delete Confirmation Modal -->
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteModalLabel">Confirm Delete</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        Are you sure you want to delete this destination?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" style="background-color: #dc3545 !important;" @click="confirmDelete">Delete</button>
      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-between mt-3" v-if="!isFormVisible">
      <div class="d-flex align-items-center mt-0">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
        <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredDestinations.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination mb-0" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
     
    </div>

    </div>
</template>
<script>
import { API_CONFIG } from '@/apiConfig';
import axios from 'axios';
import { Modal } from 'bootstrap';

//import useVuelidate from '@vuelidate/core';
//import { required, email, numeric, minLength, maxValue, maxLength } from '@vuelidate/validators';

export default {
    data() {
        return {
            form: {
                destinationName: '',
                destinationBanner: null,
                websiteBannerUrl: null,
                airportTransDesc: '',
                intercityTransDesc: '',
                description: '',
                status: 'Active',
                // continentName: '',
                // regionName: '',
                // countryName: '',
                // stateName: '',
                // cityName: '',
                mealPlan: [],
                visa: [],
                transport: '',
                popularDestination: 'yes',
                tags: ''
            },
            errorMessages: { 
            destinationName: '',
            transport: ''
        },
            destinations: [],
            isFormVisible: false,
            formTitle: 'Add / Update Destination',
            mealPlans: ['Breakfast', 'Lunch', 'Dinner', 'Snacks'],
            visaOptions: ['Assistance', 'Fees', 'Airfare', 'Government Tax/ GST/ Service Charges', 
                'City Tax on Hotel', 'Travel Insurance', 'Sightseeing', 'Transfer', 'Hotel'],
            deleteTargetId: null,
            loading: false,
            searchQuery: "",
            currentPage: 1, 
            itemsPerPage: 5,  
        };
    },
    computed: {
        filteredDestinations() {
    return this.destinations.filter(destination => {
      const searchQueryLower = this.searchQuery.toLowerCase();
      return (
        destination.destinationName.toLowerCase().includes(searchQueryLower) 
      );
    });
  },
  paginatedUsers() {
    const pageSize = Number(this.itemsPerPage) || 10;
    const currentPage = Number(this.currentPage) || 1;
    
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    
    if (!this.filteredDestinations.length) return [];
    
    return this.filteredDestinations.slice(startIndex, endIndex);
  },
  
  totalPages() {
    const pageSize = Number(this.itemsPerPage) || 10;
    return Math.max(1, Math.ceil(this.filteredDestinations.length / pageSize));
  },
        isFormValid() {
            return (
                this.form.destinationName.trim() !== '' &&
                this.form.destinationBanner !== null &&
                this.form.websiteBannerUrl !== null &&
                this.form.airportTransDesc.trim() !== '' &&
                this.form.intercityTransDesc.trim() !== ''
            );
        },
        isAllSelected() {
        return this.form.mealPlan.length === this.mealPlans.length;
    }
    },
    watch: {
  itemsPerPage(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.resetPagination();
    }
  },
  searchQuery() {
    this.resetPagination();
  },
  filteredDestinations: {
    handler() {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages || 1;
      }
    },
    immediate: true
  }
},
    methods: {
        resetPagination() {
    this.currentPage = 1;
    
    this.$forceUpdate();
  },
  
  goToPage(page) {
    const pageNum = Number(page);
    if (pageNum >= 1 && pageNum <= this.totalPages) {
      this.currentPage = pageNum;
    }
  },

getPageRange() {
    const range = [];
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    const delta = 2;

    if (currentPage > delta + 1) {
      range.push(1);
      if (currentPage > delta + 2) range.push('...');
    }

    for (let i = Math.max(1, currentPage - delta); i <= Math.min(totalPages, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage < totalPages - delta - 1) {
      if (currentPage < totalPages - delta - 2) range.push('...');
      range.push(totalPages);
    }

    return range;
  },

        clearSearch() {
    this.searchQuery = ""; 
  },
        toggleAllMeals(event) {
        if (event.target.checked) {
            this.form.mealPlan = [...this.mealPlans];
        } else {
            this.form.mealPlan = [];
        }
    },
        validateInput() {
        this.form.destinationName = this.form.destinationName.replace(/[^a-zA-Z\s]/g, '');
        if (!this.form.destinationName.trim()) {
            this.errorMessages.destinationName = "Only letters and spaces allowed.";
        } else {
            this.errorMessages.destinationName = ""; 
        }
    },

    showDeleteModal(destinationId) {
    this.deleteTargetId = destinationId;
    let deleteModal = new Modal(document.getElementById('deleteModal'));
        deleteModal.show();
  },

  async confirmDelete() {
    if (!this.deleteTargetId) return;

    this.loading = true;
    try {
      await axios.delete(API_CONFIG.getEndpoint('fetchDestinations') + `/${this.deleteTargetId}`);
      console.log('Destination deleted successfully');
      this.fetchDestinations();
    } catch (error) {
      console.error('Error deleting destination:', error);
    } finally {
      this.loading = false;
      this.deleteTargetId = null;
      let deleteModal = Modal.getInstance(document.getElementById('deleteModal'));
      deleteModal.hide();
    }
  },

    validateTransport() {
        this.form.transport = this.form.transport.replace(/[^a-zA-Z0-9\s]/g, '');
        if (!this.form.transport.trim()) {
            this.errorMessages.transport = "Only letters and numbers allowed.";
        } else {
            this.errorMessages.transport = ""; 
        }
    },
        handleFileUpload(field, event) {
            const file = event.target.files[0];
            if (!file) {
    return;
  }
            const validTypes = ['image/jpeg', 'image/png'];
            const maxSize = 2 * 1024 * 1024; // 2MB
            console.log("File types :: " + file.type );
            if (!validTypes.includes(file.type)) {
                alert('Only JPEG and PNG files are allowed.');
                event.target.value = ''; // Clear the input
                return;
            }

            if (file.size > maxSize) {
                alert('File size should not exceed 2MB.');
                event.target.value = ''; // Clear the input
                return;
            }

            this.form[field] = file;
            console.log("field=" + field + ", " + this.form[field]);

            //this.form[field] = event.target.files[0];
            //console.log("field=" + field + ", " + this.form[field]);
        },
        async submitForm() {
            this.loading = true;
            try {
                const formData = new FormData();
                 // Ensure mealPlan and visa are arrays
                const mealPlanArray = Array.isArray(this.form.mealPlan) ? this.form.mealPlan : [];
                const visaArray = Array.isArray(this.form.visa) ? this.form.visa : [];    
                const destinationDTO = {
                    destinationName: this.form.destinationName,
                    airportTransDesc: this.form.airportTransDesc,
                    intercityTransDesc: this.form.intercityTransDesc,
                    description: this.form.description,
                    status: this.form.status,
                    // continentName: this.form.continentName,
                    // regionName: this.form.regionName,
                    // countryName: this.form.countryName,
                    // stateName: this.form.stateName,
                    // cityName: this.form.cityName,
                    mealPlan: mealPlanArray.join(','),
                    visa: visaArray.join(','),
                    //mealPlan: this.form.mealPlan.join(','),
                    //visa: this.form.visa.join(','),
                    transport: this.form.transport,
                    popularDestination: this.form.popularDestination,
                    tags: this.form.tags
                };

                formData.append('destinationDTO', new Blob([JSON.stringify(destinationDTO)], { type: 'application/json' }));
                formData.append('destinationBanner', this.form.destinationBanner);
                formData.append('websiteImage', this.form.websiteImage);

                let response;
                if (this.form.id) {
                    // Edit existing destination
                    response = await axios.post(API_CONFIG.getEndpoint('createDestination') + `/${this.form.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    console.log('Form updated successfully:', response.data);
                } else {
                    // Create new destination
                    response = await axios.post(API_CONFIG.getEndpoint('createDestination'), formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    console.log('Form submitted successfully:', response.data);
                }

                this.fetchDestinations();
                this.isFormVisible = false;
                this.resetForm();
            } catch (error) {
                console.error('Error submitting form:', error);
            } finally {
                this.loading = false;
            }
        },
        async fetchDestinations() {
            this.loading = true;
            try {
                const response = await axios.get(API_CONFIG.getEndpoint('fetchDestinations') + '/all');
                this.destinations = response.data;
            } catch (error) {
                console.log('Error fetching destinations list', error);
            } finally {
                this.loading = false;
            }
        },
        showForm(action, destination = null) {
            if (action === 'create') {
                this.formTitle = 'Add Destination';
                this.resetForm();
            } else if (action === 'edit' && destination) {
                this.formTitle = 'Edit Destination';
                this.form = { ...destination,
                    mealPlan: destination.mealPlan ? destination.mealPlan.split(',') : [],
                    visa: destination.visa ? destination.visa.split(',') : [],
                    destinationBanner: null, // Reset file input
                    websiteImage: null // Reset file input
                 };
            }
            this.isFormVisible = true;
        },
        resetForm() {
            this.form = {
                destinationName: '',
                destinationBanner: '',
                websiteBannerUrl: '',
                airportTransDesc: '',
                intercityTransDesc: '',
                description: '',
                status: 'Active',
                // continentName: '',
                // regionName: '',
                // countryName: '',
                // stateName: '',
                // cityName: '',
                mealPlan: [],
                visa: [],
                transport: '',
                popularDestination: 'yes',
                tags: ''
            };
        },
        cancelForm() {
            this.isFormVisible = false;
            this.resetForm();
        },
    //    async deleteDestination(destinationId) {
      //      if (confirm('Are you sure you want to delete this destination?')) {
        //        this.loading = true;
            //    try {
                //    await axios.delete(API_CONFIG.getEndpoint('fetchDestinations') + `/${destinationId}`);
                //    console.log('Destination deleted successfully');
                    //this.fetchDestinations();
              //  } catch (error) {
               //     console.error('Error deleting destination:', error);
               // } finally {
             //       this.loading = false;
             //   }
         //   }
      //  }
    },
    mounted() {
        this.fetchDestinations();
    }
};
</script>

<style scoped>
  .table-container {
  max-width: 100%; 
  overflow-x: auto; 
  overflow-y: auto; 
  max-height: 75vh; 
  margin-bottom: 20px;
    }

label{
    margin-left: 5px;
}

.btn1{
  color: #ffffff;
  background-color: #7978E9;
  border-radius:6px;
  border: none !important;
}
.btn1:hover{
  background-color: #4B49AC;
}

    .mr-3 {        
        margin-right: 1rem !important;        
    }
    .container {
        margin-top: 20px;
    }
    
    h3 {
        margin-bottom: 20px;
    }
    
    .form-group {
        margin-bottom: 15px;
    }
    
    .d-flex {
        margin-top: 20px;
    }
    .custom-file-button {
        input[type="file"] {
            margin-left: -2px !important;

            &::-webkit-file-upload-button {
                display: none;
            }
            &::file-selector-button {
                display: none;
            }
        }

        &:hover {
            label {
                background-color: #dde0e3;
                cursor: pointer;
            }
        }
    }
</style>
