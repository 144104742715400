<template>
    <div class="container">
    <div class="card-header">
    <div class="d-flex justify-content-between align-items-center">
        <h4>Templates List</h4>
        <div style="display: flex !important; gap: 20px !important">
          <div v-if="!isFormVisible">
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div>
  <button class="btn " @click="createNewPackage">
    <font-awesome-icon :icon="['fas', 'fa-plus']" class="me-2" /> Stitch Templates</button>
        </div>
          </div>
          </div>
        <div v-if="loading">Loading...</div>
        <div v-if="error">{{ error }}</div>
        <div class="table-container">
          <table v-if="packages.length" class="table table-bordered table-hover table-responsive" style="table-layout: auto;">
            <thead>
                <tr>
                    <th>SI.No</th>
                    <th>Itinerary Type</th>
                    <th>Title</th>                    
                    <th>Destination</th>
                    <th>Start Date</th>
                    <th>Price On Website</th>
                    <th>Package Type</th>
                    <th>Status</th>
                    <th>No of Days</th>
                    <th>No of Nights</th>
                    <th>Package Code</th>
                    <th>Price</th>                   
                    <th>Action Menu</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for=" (pkg,index) in paginatedUsers" :key="index">
                    <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>
                    <td>{{ pkg.itineraryType }}</td>
                    <td  class="text-nowrap">{{ pkg.packageTitle }}</td>                    
                    <td>{{ pkg.selectDestination }}</td>
                    <td class="text-nowrap">{{ pkg.packageStartDate }}</td>
                    <td>{{ pkg.priceOnWebsite }}</td>
                    <td>{{ pkg.packageType }}</td>
                    <td>{{ pkg.status }}</td>
                    <td>{{ pkg.noOfDays }}</td>
                    <td>{{ pkg.noOfNights }}</td>                    
                    <td>{{ pkg.packageCode }}</td>
                    <td>{{ pkg.priceSummary.netSellingPrice}}</td>
                    <td>
                        <div class="row">
                          <div class="col-md-4">
                            <a href="#" class="text-danger mr-3 faq-button" role="button" @click="viewItinerary(pkg.packageCode)" >
                              <font-awesome-icon :icon="['fas', 'fa-plus']" class="plus" />  <span class="tooltip">Add Itinerary</span></a>
                          </div>
                          <div class="col-md-4">
                            <a href="#" class="text-success mr-3 faq-button" role="button" @click="editTemplate(pkg.packageCode)">
                               <font-awesome-icon :icon="['fas', 'fa-edit']" class="edit" />  <span class="tooltip">Edit Template</span></a>
                          </div>
                          <div class="col-md-4">
                            <a href="#" class="text-danger mr-3 faq-button" role="button" @click="confirmDelete(pkg.packageCode)">
                              <font-awesome-icon :icon="['fas', 'fa-trash']"  class="delete" />  <span class="tooltip">Delete</span></a>
                          </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <DeleteModal
      :visible="showDeleteModal"
      @confirmDelete="deletePackage"
      @cancelDelete="showDeleteModal = false"
    />
    <div class="d-flex justify-content-between mt-3">
      <div class="d-flex align-items-center">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredTemplates.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination mb-0" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>     
    </div>
    </div>
</template>
<script>
import {onMounted, ref, computed,watch} from 'vue';
import {useRouter} from 'vue-router';
import axios from 'axios';
import {API_CONFIG} from '@/apiConfig';
import router from "@/router";
import DeleteModal from "./DeleteModal.vue";

export default {
  components: {
    DeleteModal,
  },
        name: 'PackageList',
  methods: {
    router() {
      return router
    }
  },
        setup() {
          const searchQuery = ref('');
          const currentPage = ref(1);
          const itemsPerPage = ref(10);
          const packages= ref([]);
          const loading = ref(true);
          const error = ref(null);
          const router = useRouter();          
          const packageCode = ref(null);
          const showDeleteModal = ref(false);
          const packageCodeToDelete = ref(null);

          const fetchAllStitchAblePackages = async () => {
                try {
                    const response = await axios.get(API_CONFIG.getEndpoint('fetchStitchAblePackages'));
                    packages.value = response.data;
                }catch(err) {
                    error.value = err;
                }finally {
                    loading.value = false;

                }
          };

          const confirmDelete = (packageCode) => {
          packageCodeToDelete.value = packageCode;
          showDeleteModal.value = true;
          };

          const deletePackage = async () => {
              try {
                  await axios.delete( API_CONFIG.getEndpoint('fetchPackages') + `/${packageCodeToDelete.value}`);
                  fetchAllStitchAblePackages();
                  showDeleteModal.value = false;

              }catch(err) {
                  error.value = err;
              }
          };

          const filteredTemplates = computed(() => {
  return packages.value.filter(pkg => {
    return (
      pkg.itineraryType.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      pkg.packageTitle.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      pkg.selectDestination.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      pkg.packageCode.toLowerCase().includes(searchQuery.value.toLowerCase())
    );
  });
});

const getPageRange = () => {
  const range = [];
  const total = totalPages.value;
  const current = currentPage.value;
  const delta = 2;

  if (current > delta + 1) {
    range.push(1);
    if (current > delta + 2) range.push('...');
  }

  for (let i = Math.max(1, current - delta); i <= Math.min(total, current + delta); i++) {
    range.push(i);
  }

  if (current < total - delta - 1) {
    if (current < total - delta - 2) range.push('...');
    range.push(total);
  }

  return range;
};

const resetPagination = () => {
  currentPage.value = 1;
};

const goToPage = (page) => {
  const pageNum = Number(page);
  if (pageNum >= 1 && pageNum <= totalPages.value) {
    currentPage.value = pageNum;
  }
};

const paginatedUsers = computed(() => {
  const pageSize = Number(itemsPerPage.value) || 10;
  const current = Number(currentPage.value) || 1;
  
  const startIndex = (current - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  
  if (!filteredTemplates.value.length) return [];
  
  return filteredTemplates.value.slice(startIndex, endIndex);
});

const totalPages = computed(() => {
  const pageSize = Number(itemsPerPage.value) || 10;
  return Math.max(1, Math.ceil(filteredTemplates.value.length / pageSize));
});

watch(filteredTemplates, () => {
  if (currentPage.value > totalPages.value) {
    currentPage.value = totalPages.value || 1;
  }
});

watch(itemsPerPage, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    resetPagination();
  }
});

const clearSearch = () => {
  searchQuery.value = '';
};
          const viewItinerary = (packageCode) => {
              router.push({ name: 'itinerary', params: { id: packageCode } });
          };

          const editTemplate = (packageCode) => {
              router.push({ name: 'packages', params: { id: packageCode } });
          }

          const createNewPackage = () => {              
              router.push(`/template/create`);
          };

          //this fetchPackages will be loaded onload of component.
          onMounted(fetchAllStitchAblePackages);

          return {              
              packages,
              packageCode,
              loading,
              error,
              editTemplate,
              deletePackage,
              createNewPackage,
              viewItinerary,
              showDeleteModal,
              packageCodeToDelete,
              confirmDelete,
              filteredTemplates,
              searchQuery,
              clearSearch,
              paginatedUsers,
              itemsPerPage,
              currentPage,
              goToPage,
              resetPagination,
              totalPages,
              getPageRange
          };
        }
    };
</script>

<style scoped>
    .container {
    margin-top: 20px;
    }

.btn{
  color: #ffffff;
  background-color: #7978E9;
}
.btn:hover{
  background-color: #4B49AC;
  color: #ffffff;
}
    .modal {
      width: 100%;
    }

    .table-container {
      max-width: 100%; 
      overflow-x: auto; 
      overflow-y: auto; 
      max-height: 75vh; 
     margin-bottom: 20px;
    }
    .modal-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1040;
    }



    /* Modal Body */
    .modal-container {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1050;
    }

    .modal-dialog {
      background: white;
      padding: 15px;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    }

    /* Prevent clicks on the modal from propagating to the backdrop */
    .modal-content {
      position: relative;
      z-index: 1060;
    }
</style>