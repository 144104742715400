<template>
  <div class="container mb-5">
    <div class="card-header" v-if="!isFormVisible">
      <div class="d-flex justify-content-between align-items-center">
        <h4>List of Installments</h4>
        <button @click="goToBookings" class="btn">
          <font-awesome-icon :icon="['fas', 'fa-arrow-left']" class="me-2" />Back to Bookings
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <button @click="addRow" class="btn"><font-awesome-icon :icon="['fas', 'fa-plus']"/> Add More Row</button>
    </div>

    <div v-if="!isFormVisible" class="table-container">
      <table class="table table-bordered mt-3">
        <thead>
          <tr>
            <th>SI.No</th>
            <th>Installment No</th>
            <th>Installment Amount</th>
            <th>Trans ID</th>
            <th>Payment Mode</th>
            <th>Payment Date</th>
            <th>Payment Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(installment, index) in bookings" :key="installment.id">
            <td>{{ index + 1 }}</td>
            
            <td>
              <input 
                type="number" 
                v-model="installment.installmentNumber" 
                :disabled="installment.paymentStatus === 'PAID'" 
              />
            </td>
            
            <td>
              <input 
                type="number" 
                v-model="installment.amount" 
                @input="calculateTotalAmount" 
                :disabled="installment.paymentStatus === 'PAID'" 
              />
            </td>

            <td>{{ installment.transactionId }}</td>
            
            <td>
              <select v-model="installment.paymentMode" class="form-control">
                <option value="CASH">Cash</option>
                <option value="CREDIT">Credit/Debit Card</option>
                <option value="UPI">UPI (Phonepe, Gpay)</option>
                <option value="WALLET">Mobile Wallets</option>
                <option value="TRANSFER">Bank Transfer</option>
              </select>
            </td>
            
            <td>
              <input 
                type="date" 
                v-model="installment.paymentDate" 
                :disabled="installment.paymentStatus === 'PAID'" 
              />
            </td>
            
            <td>
              <select v-model="installment.paymentStatus" class="form-control">
                <option value="PAID">PAID</option>
                <option value="PENDING">PENDING</option>
              </select>
            </td>
          </tr>
          
          <tr>
          
          
  <td colspan="7" class="text-end">
    <strong style="margin-right: 30px;">Total Installment Amount: </strong><span class="bold-amount" style="margin-right: 30px;">{{ totalAmountFormatted }}</span>
  </td>




          </tr>
        </tbody>
      </table>
    </div>

    <div class="modal-footer">
      <button @click="cancelChanges" type="button" class="btn" style="margin-right: 7px !important;">
        <font-awesome-icon :icon="['fas', 'xmark']" class="me-2" /> Cancel
      </button>
      <button @click="saveChanges" type="button" class="btn">
        <font-awesome-icon :icon="['fas', 'floppy-disk']" class="me-2" /> Save Changes
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { API_CONFIG } from '@/apiConfig';
import { useRoute } from 'vue-router';

export default {
  data() {
    return {
      id: undefined,
    userInfo: undefined,
    packageInfo: undefined,
    passengersInfo: [],
    bookings: [],
    totalAmount: 0,
    originalBookings: [],
    loading: false,
    isFormVisible: false,
    };
  },
  methods: {
    async fetchInstallment(id) {
  this.loading = true;
  try {
    const response = await axios.get(`${API_CONFIG.getEndpoint("fetchInstallment")}/${id}`);
    console.log(response.data);  

    if (response.data) {
      this.id = response.data.id;
      this.userInfo = response.data.userInfo; 
      this.packageInfo = response.data.packageInfo;  
      this.passengersInfo = response.data.passengersInfo;
      this.bookings = response.data.bookingInstallmentsInfo;
      this.totalAmount = response.data.totalAmount;

      this.originalBookings = JSON.parse(JSON.stringify(this.bookings));
      this.calculateTotalAmount();
    }
  } catch (error) {
    console.error("Error fetching installments:", error);
  } finally {
    this.loading = false;
  }
},


    addRow() {
      this.bookings.push({
        id: null,
        amount: '',
        installmentNumber: '',
        transactionId: '',
        paymentMode: '',
        paymentDate: '',
        paymentStatus: 'PENDING',  
      });
      this.calculateTotalAmount(); 
    },

    calculateTotalAmount() {
  this.totalAmount = this.bookings.reduce((sum, installment) => {
    return sum + (parseFloat(installment.amount) || 0);  
  }, 0);
  
  this.totalAmountFormatted = this.totalAmount.toLocaleString();
},

async saveChanges() {
  const changes = this.bookings.map((installment, index) => {
    const originalInstallment = this.originalBookings[index];
    return {
      id: installment.id || null,
      amount: installment.amount,
      installmentNumber: installment.installmentNumber,
      transactionId: installment.transactionId,
      paymentMode: installment.paymentMode,
      paymentDate: installment.paymentDate,
      paymentStatus: installment.paymentStatus,
      isUpdated: JSON.stringify(installment) !== JSON.stringify(originalInstallment),
    };
  });

  const modifiedInstallments = changes.filter(change => change.isUpdated);

  if (modifiedInstallments.length > 0) {
    const payload = {
      id: this.id, 
      userId: this.userInfo.id,
      packageCode: this.packageInfo.packageCode,
      passengersInfo: this.passengersInfo, 
      bookingInstallmentsInfo: this.bookings, 
      totalAmount: this.totalAmount,
    };

    console.log('Payload:', payload); 

    try {
      const response = await axios.put(`${API_CONFIG.getEndpoint("updateInstallmentPayment")}`, payload);
      console.log(response.data);
      this.originalBookings = JSON.parse(JSON.stringify(this.bookings)); 
    } catch (error) {
      console.error("Error updating installments:", error);
    }
  } else {
    console.log('No changes made.');
  }
},

    cancelChanges() {
      this.bookings = JSON.parse(JSON.stringify(this.originalBookings)); 
      this.calculateTotalAmount(); 
    },

    goToBookings() {
      this.$router.push('/bookings');
    },
  },
  mounted() {
    const route = useRoute();
    const id = route.params.id;
    
    if (id) {
      this.fetchInstallment(id);
    }
  },
};
</script>
<style scoped>
.bold-amount {
  font-weight: bold;
}

</style>