<template>
  <div class="container mb-5">
    <h4>Dashboard</h4>
    <p class="text-muted pt-2">Welcome to the <span style="color:#4B49AC">Admin Panel</span> dashboard!</p>

    <div class="row">
            <div class="col-md-7 grid-margin stretch-card">
              <div class="card">
                <div class="card-people mt-auto">
                  <img src="../assets/people.svg" alt="people" height="100%"/>
                </div>
              </div>
            </div>
            <div class="col-md-5 grid-margin transparent">
              <div class="row">
                <div class="col-md-6 stretch-card transparent">
                  <div class="card" @click="goToPath('/viewpackages')" style="background-color: #7DA0FA; cursor: pointer;">
                    <div class="card-body">
                      <h6 class="mb-3"><font-awesome-icon :icon="['fas', 'cubes']" class="me-2"/> View Packages </h6>
                      <span class="fs-30 mb-2">Explore all available packages</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 stretch-card transparent">
                  <div class="card" @click="goToPath('/destinations')" style="background-color: #5050B2; cursor: pointer;">
                    <div class="card-body">
                      <h6 class="mb-3"><font-awesome-icon :icon="['fas', 'location-dot']" class="me-2"/> Destinations </h6>
                      <span class="fs-30 mb-2">Browse available destinations</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-lg-0 stretch-card transparent">
                  <div class="card" @click="goToPath('/cities')" style="background-color: #7978E9; cursor: pointer;">
                    <div class="card-body">
                      <h6 class="mb-3"> <font-awesome-icon :icon="['fas', 'city']" class="me-2"/> Cities </h6>
                      <span class="fs-30 mb-2">Manage cities in the system</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 stretch-card transparent">
                  <div class="card"  @click="goToPath('/airports')" style="background-color: #F3797E; cursor: pointer;">
                    <div class="card-body">
                      <h6 class="mb-3"> <font-awesome-icon :icon="['fas', 'plane-departure']" class="me-2" /> Airports</h6>
                      <span class="fs-20 mb-2">Manage available airports</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  </div>
</template>

<script>

export default {
  name: 'TheDashboard',
  methods: {
    goToPath(path) {
      this.$router.push(path); 
    }
  }
}
</script>

<style scoped>
.card {
  border: none;
  margin-bottom: 20px;
  color: #ffffff;
}

.text-muted {
  font-size: 15px;
}

.card-body{
  height: 125px;
}
.card-people img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
</style>
