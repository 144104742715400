<template>
  <div class="container my-5">    
     <h5> Create Custom Website Package </h5>
     <hr>
    <div> 

      <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>   


      <div class="form-group row mb-3">
        <label for="packageTitle" class="col-sm-3 col-form-label">Package Title <span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <input type="text" class="form-control" id="packageTitle" v-model="packageInfo.packageTitle" placeholder="Package Title">
          <span class="text-danger" v-if="v$.packageInfo.packageTitle.$error">This value is required </span>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="description" class="col-sm-3 col-form-label">Description <span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <textarea rows="5" class="form-control" id="description" v-model="packageInfo.description" placeholder="Description"></textarea>
          <span class="text-danger" v-if="v$.packageInfo.description.$error">This value is required </span>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="description" class="col-sm-3 col-form-label">Short Description</label>
        <div class="col-sm-6">
          <textarea class="form-control" id="description" v-model="packageInfo.shortDescription" placeholder="Short Description"></textarea>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="uploadImage" class="col-sm-3 col-form-label">Select Main Image <span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <input type="file" class="form-control" id="uploadImage" @change="handleFileUpload" accept="image/jpeg, image/png" />
          <div v-if="packageInfo.packageMainImage">
            <img :src="packageInfo.packageMainImage" alt="Package Image" style="width: 256px; margin-bottom: 10px;"/>
          </div>             
        </div>
        <div class="col-sm-2 d-flex" style="height: 38px !important" >  
          <button type="button" class="btn btn-success me-2" @click="saveImage">
            <font-awesome-icon :icon="['fas', 'floppy-disk']" /> Upload
          </button>
          <button type="button" class="btn btn-danger" @click="cancelUpload">
            <font-awesome-icon :icon="['fas', 'xmark']" /> Cancel
          </button>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="packageType" class="col-sm-3 col-form-label">Package Type <span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <select class="form-control" id="packageType" v-model="packageInfo.packageType">
            <option disabled value="">--Please select a package type--</option>
            <option v-for="type in packageTypes" :key="type.packageTypeName" :value="type.packageTypeName">{{ type.packageTypeName }}</option>
          </select>
          <span class="text-danger" v-if="v$.packageInfo.packageType.$error">This value is required </span>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="packageTheme" class="col-sm-3 col-form-label">Package Theme <span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <select class="form-control" id="packageTheme" v-model="packageInfo.packageTheme">
        <option disabled value="">--Please select a package theme--</option>
        <option v-for="theme in packageThemes" :key="theme.packageThemeName" :value="theme.packageThemeName">{{ theme.packageThemeName }}</option>
          </select>
          <span class="text-danger" v-if="v$.packageInfo.packageTheme.$error">This value is required </span>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="other-tags" class="col-sm-3 col-form-label">Other Tags</label>
        <div class="col-sm-6">
          <textarea class="form-control" id="other-tags" v-model="packageInfo.otherTags" placeholder="Other Tags"></textarea>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="destinationId" class="col-sm-3 col-form-label">Select Destination<span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <select v-model="packageInfo.destinationId" class="form-control" id="destination" required>
              <option disabled value="">--Please select a destination--</option>
              <option v-for="destination in destinations" :key="destination.id" :value="destination.id">
                  {{ destination.destinationName }}
              </option>
          </select>   
          <span class="text-danger" v-if="v$.packageInfo.destinationId.$error">This value is required </span>
        </div>
      </div>

      <div class="form-group row mb-3">
        <label for="nestedOptions" class="col-sm-3 col-form-label">Select Location</label>
        <div class="col-sm-6">
          <NestedOptions :data="destinationsTreeMap" v-model:selectedData="selectedNestedData" />
        </div>
      </div>

      <div class="form-group row mb-3 d-flex align-items-center">
        <label for="status" class="col-sm-3 col-form-label">Best Deal</label>  
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.bestDeal" class="form-check-input m-0 me-2" id="customerCanCustomize-yes" :value="true" >
          <label for="bestDeal-yes" class="form-check-label m-0">Yes</label>
        </div>
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.bestDeal" class="form-check-input m-0 me-2" id="customerCanCustomize-no" :value="false">
          <label for="bestDeal-no" class="form-check-label m-0">No</label>
        </div>
      </div> 

      <div class="form-group row mb-3 d-flex align-items-center">
        <label for="status" class="col-sm-3 col-form-label">Featured Tour</label>  
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.featuredTour" class="form-check-input m-0 me-2" id="featuredTour-yes" :value="true" >
          <label for="featuredTour-yes" class="form-check-label m-0">Yes</label>
        </div>
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.featuredTour" class="form-check-input m-0 me-2" id="featuredTour-no" :value="false">
          <label for="featuredTour-no" class="form-check-label m-0">No</label>
        </div>
      </div> 

      <div class="form-group row mb-3 d-flex align-items-center">
        <label for="status" class="col-sm-3 col-form-label">Is Flight Included in this package?</label>  
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.flightIncluded" class="form-check-input m-0 me-2" id="flightIncluded-yes" :value="true" >
          <label for="flightIncluded-yes" class="form-check-label m-0">Yes</label>
        </div>
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.flightIncluded" class="form-check-input m-0 me-2" id="flightIncluded-no" :value="false">
          <label for="flightIncluded-no" class="form-check-label m-0">No</label>
        </div>
      </div> 

      <div class="form-group row mb-3 d-flex align-items-center">
        <label for="status" class="col-sm-3 col-form-label">Is Hotel Included in this package?</label>  
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.hotelIncluded" class="form-check-input m-0 me-2" id="hotelIncluded-yes" :value="true" >
          <label for="hotelIncluded-yes" class="form-check-label m-0">Yes</label>
        </div>
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.hotelIncluded" class="form-check-input m-0 me-2" id="hotelIncluded-no" :value="false">
          <label for="hotelIncluded-no" class="form-check-label m-0">No</label>
        </div>
      </div> 

      <div class="form-group row mb-3 d-flex align-items-center">
        <label for="status" class="col-sm-3 col-form-label">Is Activity Included in this package?</label>  
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.activityIncluded" class="form-check-input m-0 me-2" id="activityIncluded-yes" :value="true" >
          <label for="activityIncluded-yes" class="form-check-label m-0">Yes</label>
        </div>
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.activityIncluded" class="form-check-input m-0 me-2" id="activityIncluded-no" :value="false">
          <label for="activityIncluded-no" class="form-check-label m-0">No</label>
        </div>
      </div> 

      <div class="form-group row mb-3 d-flex align-items-center">
        <label for="status" class="col-sm-3 col-form-label">Is Transfer Included in this package?</label>  
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.transferIncluded" class="form-check-input m-0 me-2" id="transferIncluded-yes" :value="true" >
          <label for="transferIncluded-yes" class="form-check-label m-0">Yes</label>
        </div>
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.transferIncluded" class="form-check-input m-0 me-2" id="transferIncluded-no" :value="false">
          <label for="transferIncluded-no" class="form-check-label m-0">No</label>
        </div>
      </div> 

      
      
      <div class="form-group row mb-3 d-flex align-items-center">
        <label for="built-from" class="col-sm-3 col-form-label">Build From</label>                
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.builtFrom" class="form-check-input m-0 me-2" id="built-from-custom" value="custom">
          <label for="built-from-custom" class="form-check-label m-0">Custom Trip</label>
        </div>
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.builtFrom" class="form-check-input m-0 me-2" id="built-from-template" value="template">
          <label for="built-from-template" class="form-check-label m-0">Template Trip</label>
        </div>
      </div>      

      <div class="form-group row mb-3 d-flex align-items-center">
        <label for="status" class="col-sm-3 col-form-label">Customer can Customize this package</label>  
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.customerCanCustomize" class="form-check-input m-0 me-2" id="customerCanCustomize-yes" :value="true" >
          <label for="customerCanCustomize-yes" class="form-check-label m-0">Yes</label>
        </div>
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.customerCanCustomize" class="form-check-input m-0 me-2" id="customerCanCustomize-no" :value="false">
          <label for="customerCanCustomize-no" class="form-check-label m-0">No</label>
        </div>
      </div>   

      <div class="form-group row mb-3 d-flex align-items-center">
        <label for="customized-tour" class="col-sm-3 col-form-label">Publish for customized tours</label>                
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.publishForCustomizedTours" class="form-check-input m-0 me-2" id="customized-tour-active" value="ACTIVE">
          <label for="customized-tour-active" class="form-check-label m-0">Active</label>
        </div>
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.publishForCustomizedTours" class="form-check-input m-0 me-2" id="customized-tour-inactive" value="INACTIVE">
          <label for="customized-tour-inactive" class="form-check-label m-0">Inactive</label>
        </div>
      </div>
      
      
      <div class="form-group row mb-3">
        <label for="noOfDays" class="col-sm-3 col-form-label">No. of Days<span style="color:red !important">*</span></label>
        <div class="col-sm-6">
          <input type="number" step="1" min="1" max="365" class="form-control" id="noOfDays" v-model="packageInfo.noOfDays" placeholder="No. of Days">
          <span class="text-danger" v-if="v$.packageInfo.noOfDays.$error">This value is required </span>
        </div>
      </div>
      <div class="form-group row mb-3">
        <label for="noOfNights" class="col-sm-3 col-form-label">No. of Nights</label>
        <div class="col-sm-6">
          <input type="number" class="form-control" id="noOfNights" v-model="packageInfo.noOfNights" placeholder="No. of Nights" min="0">
        </div>
      </div>

      <div class="form-group row mb-3 d-flex align-items-center">
        <label for="status" class="col-sm-3 col-form-label">Status</label>                
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.status" class="form-check-input m-0 me-2" id="statusActive" value="ACTIVE">
          <label for="statusActive" class="form-check-label m-0">Active</label>
        </div>
        <div class="col-sm-2 d-flex align-items-center mt-1">
          <input type="radio" v-model="packageInfo.status" class="form-check-input m-0 me-2" id="statusInactive" value="INACTIVE">
          <label for="statusInactive" class="form-check-label m-0">Inactive</label>
        </div>
      </div>
      <hr>
      <div class="form-actions">
        <button type="submit" class="btn btn-success" @click="saveWebsitePackage" v-if="!isEdit" :disabled="isSaveDisabled">
          <font-awesome-icon :icon="['fas', 'fa-save']" class="me-2" /> Save </button>
        <button type="submit" class="btn btn-success" @click="updatePackages" v-if="isEdit" :disabled="isSaveDisabled">
          <font-awesome-icon :icon="['fas', 'fa-save']" class="me-2" /> Update </button>
        <button type="button" class="ml-3 btn btn-light" @click="cancel"> <font-awesome-icon :icon="['fas', 'times']" /> Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>  
import { API_CONFIG } from '@/apiConfig';
import axios from 'axios';
import NestedOptions from './NestedOptions.vue';
import { required,  numeric } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core'

export default {
  components: {
    NestedOptions
  },
  data() {
    return {
      packageInfo: {
        packageCode : '',
        packageTitle : '',
        shortDescription : '',
        description : '',

        packageMainImage : '',
        packageType : '',
        packageTheme : '',
        otherTags : '',
        
        destinationId : '',
        
        continent : '',                
        region : '',
        country : '',
        state : '',
        city : '',

        bestDeal : true,
        featuredTour : true,
        flightIncluded : true,
        hotelIncluded : true,
        activityIncluded : true,
        transferIncluded : true,
        
        builtFrom : 'custom',
        customerCanCustomize : true,        
        publishForCustomizedTours : 'ACTIVE',
        noOfDays : 1,
        noOfNights : 0,
        parentPackageCode : '',
        status: 'ACTIVE',

        selectedContinent : [],
        selectedRegion : [],
        selectedCountry : [],
        selectedState : [],
        selectedCity : []
        
      },      
      destinationsTreeMap : {},
      selectedNestedData: {},
      selectedFile: null,
      errorMessage: '',
      destinations : [],      
      isEdit : false,
      defaultPackageDetails: false,
      disableStitchAble: false,
      packageTypes: [],
      packageThemes: []
    };
  },
  created(){
    const pkgCode = this.$route.params.packageCode;    
    console.log('pkgCode ' + pkgCode);
    if(pkgCode) {
      if (pkgCode.startsWith('parent-')) {
        this.isEdit = false;
        this.packageInfo.parentPackageCode = pkgCode.split('parent-')[1];
      } else {
        this.isEdit = true;
        this.packageInfo.packageCode = pkgCode; 
        this.fetchDestinationsTreeMap();
        this.fetchWebPackageByCode();
      }      
    }
  },
  validations() {
    return {
      packageInfo: {        
        destinationId : { required },        
        packageTitle :{ required },        
        noOfDays : { required, numeric },
        description: { required },
        packageType: { required },
        packageTheme: { required }
      }
    }
  },

  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },

  computed: {
    noOfNights() {
      return this.packageInfo.noOfDays > 0 ? this.packageInfo.noOfDays - 1 : 0;
    },

    isSaveDisabled() {
      return false;
    }
  },
  watch: {
    'packageInfo.noOfDays'(newVal) {
      this.packageInfo.noOfNights = newVal > 0 ? newVal - 1 : 0;
    }
  },
  methods: {      

    // Cancel file upload
    cancelUpload() {
      this.selectedFile = null;
      document.getElementById('uploadImage').value = ''; // Reset file input
    },

    cancel() {      
      this.$router.push('/web-packages/view');
    },

    // Handle image selection
    handleFileUpload(event) {
      this.errorMessage = ''; // Reset error message
      const file = event.target.files[0];
      if (!file) {
    return;
  }
      const validTypes = ['image/jpeg', 'image/png'];
      const maxSize = 2 * 1024 * 1024; // 2MB limit

      if (!validTypes.includes(file.type)) {
        alert('Only JPEG and PNG files are allowed.');
        event.target.value = ''; // Clear the input
        return;
      }

      if (file.size > maxSize) {
        alert('File size should not exceed 2MB.');
        event.target.value = ''; 
        return;
      }
      this.selectedFile = file;
    },

    async fetchWebPackageByCode() {
      this.loading = true;
      try {
        const response = await axios.get(API_CONFIG.getEndpoint('websitePackages') + `/` + this.packageInfo.packageCode);
        if (response.data && typeof response.data === 'object') {
          this.packageInfo = { ...this.packageInfo, ...response.data };
          this.selectedNestedData = {}
          this.selectedNestedData['Continent'] = this.packageInfo.continent;
          this.selectedNestedData['Region'] = this.packageInfo.region;
          this.selectedNestedData['Country'] = this.packageInfo.country;
          this.selectedNestedData['State'] = this.packageInfo.state;
          this.selectedNestedData['City'] = this.packageInfo.city;
        } else {
          console.error('Invalid response data:', response.data);
          this.errorMessage = 'Failed to fetch package details. Invalid data received.';
        }
        this.findFilteredComponents();  
        console.log('packageInfo', this.packageInfo);
      } catch (error) {
        console.log('Error fetching package details', error);
        this.error = 'Failed to fetch package details. Please try again.';
      } finally {
        this.loading = false;
      }
    },

    async fetchPackageTypes() {
      this.loading = true;
      try {
        const response = await axios.get(API_CONFIG.getEndpoint("fetchAllActivePackageTypes") );
        this.packageTypes = response.data;
        console.log("packageThemes ", this.packageThemes);
      } catch (error) {
        console.log("Error fetching packagetype list", error);
      } finally {
        this.loading = false;
      }
    },

    async fetchPackageThemes() {
      this.loading = true;
      try {        
        const response = await axios.get(API_CONFIG.getEndpoint("fetchAllActivePackageThemes"));
        this.packageThemes = response.data;
        console.log("packageTypes ", this.packageTypes);
      } catch (error) {
        console.log("Error fetching packagetype list", error);
      } finally {
        this.loading = false;
      }
    },

    async saveImage() {
      if (!this.selectedFile) {
        alert('Please select an image to save.');
        return;
      }      
      const imageData = new FormData();
      imageData.append('images', this.selectedFile); // Append file
      try {
        const response = await axios.post(API_CONFIG.getEndpoint('uploadImages') + `/`+'web-package', imageData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log("S3 bucket path:", response.data);
        this.packageInfo.packageMainImage = response.data[0];
      } catch (error) {
        console.log("Error uploading images", error);
      }
      this.selectedFile = null;
      document.getElementById('uploadImage').value = ''; // Reset file input
    },

    async fetchDestinationsTreeMap() {      
      this.loading = true;
      try {
          const resp = await axios.get(API_CONFIG.getEndpoint('destinationTreeMap'));                
          if (resp.data.continents && Object.keys(resp.data.continents).length > 0) {
            this.destinationsTreeMap = resp.data;
          } else {
            this.errorMessage = 'No continents data available.';
          }
      } catch (error) {
          console.log('Error fetching destinations list', error);
          this.error = 'Failed to fetch destinations list. Please try again.';
      } finally {
          this.loading = false;
      }
    },

    async fetchDestinations() {      
      this.loading = true;
      try {
          const resp = await axios.get(API_CONFIG.getEndpoint('fetchDestinations'));                
          this.destinations = resp.data;
      } catch (error) {
          console.log('Error fetching destinations list', error);
          this.error = 'Failed to fetch destinations list. Please try again.';
      } finally {
          this.loading = false;
      }
    },
    
    async updatePackages() {
     
      this.v$.$touch();
      if (this.v$.$invalid) {
          const invalidFields = [];
          for (const field in this.v$.packageInfo) {
              if (this.v$.packageInfo[field].$invalid) {
                  invalidFields.push(field);
              }
          }
          alert(`Please fill all the required fields: ${invalidFields.join(', ')}`);
          return;
      }
      
      try {

        this.packageInfo.continent = this.selectedNestedData['Continent'];
        this.packageInfo.region = this.selectedNestedData['Region'];
        this.packageInfo.country = this.selectedNestedData['Country'];
        this.packageInfo.state = this.selectedNestedData['State'];
        this.packageInfo.city = this.selectedNestedData['City'];

        console.log(this.selectedNestedData);
        console.log(this.packageInfo);
        const response = await axios.post(  API_CONFIG.getEndpoint('websitePackages') , this.packageInfo );
        console.log('Success:', response.data);

        this.$router.push({ name: 'ViewWebPackages'});
      }catch(error) {
        console.log('Error updating packages ', error);
        this.error = 'Failed to updating packages. Please try again.';
      }
    },
  
    async saveWebsitePackage() {
      
      this.v$.$touch();
      if (this.v$.$invalid) {
          const invalidFields = [];
          for (const field in this.v$.packageInfo) {
              if (this.v$.packageInfo[field].$invalid) {
                  invalidFields.push(field);
              }
          }
          alert(`Please fill all the required fields: ${invalidFields.join(', ')}`);
          return;
      }
      try {
        this.packageInfo.continent = this.selectedNestedData['Continent'];
        this.packageInfo.region = this.selectedNestedData['Region'];
        this.packageInfo.country = this.selectedNestedData['Country'];
        this.packageInfo.state = this.selectedNestedData['State'];
        this.packageInfo.city = this.selectedNestedData['City'];

        console.log(this.selectedNestedData);
        console.log(this.packageInfo);
        const response = await axios.post(  API_CONFIG.getEndpoint('websitePackages') , this.packageInfo );
        console.log('Success:', response.data);

        this.$router.push({ name: 'ViewWebPackages'});

      } catch(error) {
        console.error('Error:', error);
        this.errorMessage="Failed, please try again.";
      }
    },

    clear() {
      this.packageInfo =  {        
        packageTitle: '',
        description: '',        
        destinationId: '',        
        packageType: '',
        noOfDays: 1,
        noOfNights: 0,
        status: 'ACTIVE'
      }
    },
    
  },

  mounted() {      
    this.fetchDestinations();    
    this.fetchDestinationsTreeMap();
    this.fetchPackageTypes();
    this.fetchPackageThemes();
  }

};
</script>

<style scoped>
  .ml_0_5_rem  {
    margin-left: 0.5rem !important;        
  }
  .ml-3  {
      margin-left: 1rem !important;        
  }
  .ml-6  {
      margin-left: 2rem !important;        
  }
  .ml-9  {
      margin-left: 3rem !important;        
  }
  .mr-3 {        
      margin-right: 1rem !important;        
  }
  .mr-6 {
      margin-right: 2rem !important;
  }
  .form-group {
      margin-bottom: 15px;
  }
  .text-danger {
      color: red;
      font-size: 0.875em;
  }
</style>
