<template>
    <div class="container">
      <div class="card-header" v-if="!isFormVisible">
        <div class="d-flex justify-content-between align-items-center">
          <h4>Exchange Rates</h4>
          <button class="btn" @click="showForm">
            <font-awesome-icon :icon="['fas', 'arrows-rotate']" /> Update Rates
          </button>
        </div>
      </div>
  
      <div v-if="loading">Loading...</div>
      <div v-if="error" class="text-danger">{{ error }}</div>
  
      <div class="table-container" v-if="!isFormVisible">
        <table class="table table-bordered table-hover table-responsive" style="table-layout: auto;">
          <thead>
            <tr>
              <th>SI.No</th>
              <th>Date</th>
              <th>Currency Code</th>
              <th>Exchange Rate</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rate, index) in exchangeRates" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ rate.date }}</td>
              <td>{{ rate.currencyCode }}</td>
              <td>{{ rate.exchangeRate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <!-- Form Section -->
      <div v-if="isFormVisible">
        <h5>Update Exchange Rates</h5>
        <hr />
        <form @submit.prevent="submitForm">
          <div class="form-group row mb-3">
            <label for="date" class="col-sm-3 col-form-label">Date <span class="text-danger">*</span></label>
            <div class="col-sm-6">
              <input type="date" v-model="form.date" class="form-control" id="date" required />
            </div>
          </div>
  
          <div class="form-group row mb-3">
            <label for="currencyCode" class="col-sm-3 col-form-label">Currency Code <span class="text-danger">*</span></label>
            <div class="col-sm-6">
              <input type="text" v-model="form.currencyCode" class="form-control" id="currencyCode" required placeholder="Enter Currency Code" />
            </div>
          </div>
  
          <div class="form-group row mb-3">
            <label for="exchangeRate" class="col-sm-3 col-form-label">Exchange Rate <span class="text-danger">*</span></label>
            <div class="col-sm-6">
              <input type="number" v-model="form.exchangeRate" class="form-control" id="exchangeRate" required placeholder="Enter Exchange Rate" />
            </div>
          </div>
  
          <hr />
          <div class="d-flex justify-content-end">
            <button type="submit" class="btn btn-success me-2">
              <font-awesome-icon :icon="['fas', 'save']" class="me-2" /> Submit
            </button>
            <button type="button" class="btn btn-light" @click="cancelForm">
              <font-awesome-icon :icon="['fas', 'times']" /> Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        exchangeRates: [],
        isFormVisible: false,
        form: {
          date: "",
          currencyCode: "",
          exchangeRate: ""
        },
        loading: false,
        error: null
      };
    },
    methods: {
      showForm() {
        this.isFormVisible = true;
      },
      cancelForm() {
        this.isFormVisible = false;
      },
      submitForm() {
        console.log("Submitting form:", this.form);
        this.isFormVisible = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .table-container {
    max-width: 100%;
    overflow-x: auto;
  }
  .container {
    margin-top: 20px;
  }
  td {
    font-weight: bold;
  }
  </style>
  