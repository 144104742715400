<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-9"><h5> Manage Package Other Details of <span style="color:#4B49AC"> {{ packageCode }}</span></h5></div>
            <div class="col-sm-3">                
                <button type="button" class="btn btn-success me-2 text-right float-right" @click="back_to_packages"> <font-awesome-icon :icon="['fas', 'fa-arrow-left']" class="me-2" />Back to Packages</button> 
            </div> 
        </div> 
        <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>            
        
        <div class="clickable-div container border p-3">
            <table class="border table table-bordered">                
                <tbody>
                    <template v-for="(day, dIndex) in packageInfo.days" :key="dIndex">
                        <tr>
                            <td colspan="2" class="table-merged-row text-center">{{ formatDayHeader(dIndex, day.itineraryDate) }}</td>
                        </tr>
                        <template v-for="(city, cIndex) in day.cities" :key="cIndex">
                            <tr v-for="(itinerary, iIndex) in city.itineraries" :key="iIndex">                            
                                <td>{{ itinerary.excursionType }}</td>
                                <td>{{ itinerary.subCodeDescriptor }}</td>
                            </tr>
                        </template>
                    </template>                    
                </tbody>
            </table>
            <hr />                        
        </div>    
    </div>
</template>
<script>
    
    import { ref, reactive,  onMounted } from 'vue';    
    import { useRoute, useRouter } from 'vue-router';
    import axios from 'axios';
    import { API_CONFIG } from '@/apiConfig';

    export default {
        
        name: 'InclusionsAndExclusionsList',
        setup() {
            const router = useRouter(); // Get the router instance
            const route = useRoute(); // Access current route's properties
            const packageCode = ref(route.params.id);
            const packageInfo = reactive({ 
                days: [
                    {
                        day_number: '',
                        itineraryDate: '',
                        cities: [
                            {
                                cityName: '',
                                itineraries: [
                                    {
                                        excursionType: '',
                                        subCode: '',
                                        price: 0
                                    }
                                ]
                            }
                        ]
                    }
                ]
            });

            
            
            const errorMessage = ref("");
            const itineraries = reactive([]);

            onMounted(() => {
                if (packageCode.value) {
                    fetchPackageByCode();
                }
            });
           
            const cancel = () => {
                console.log('Cancel button clicked');                
            };

            const formatDayHeader = (dIndex, itineraryDate) => {
                const date = new Date(itineraryDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                return `Day ${dIndex + 1} - ${day}-${month}-${year}`;
            };

            const fetchPackageByCode = async () => {
                try {
                    const response = await axios.get(API_CONFIG.getEndpoint('fetchPackages') + `/` + packageCode.value);
                    Object.assign(packageInfo, response.data);                    
                    console.log("fetchPackageByCode ::: fetched successfully");                    
                } catch (error) {
                    errorMessage.value = "Error fetching package details..";
                    console.error('Error fetching package details..' + error);                    
                }
            };
                       

            const back_to_packages = () => {
                if( packageInfo.stitchAble) {
                    router.push({ path: `/template/view` });
                } else if ( packageInfo.packageType == 'website_parent') {                    
                    router.push({ path: '/web-packages/view'});  
                } else {
                    router.push({path: `/viewpackages`});
                }
            }

            onMounted ( () => {                    
                if (packageCode.value) {    
                    fetchPackageByCode();                    
                }
            });

            return {
                packageInfo,
                fetchPackageByCode, 
                back_to_packages,
                packageCode,
                errorMessage,
                cancel,
                itineraries,                
                formatDayHeader                
            };
        }
    };
</script>
<style scoped>

.table-merged-row {
  font-size: 1.2em;
  background: aliceblue;
}
</style>