<template>
  <nav class="navbar navbar-light">
    <!-- Add your top bar content here -->
    <h1
      class="headingtitle"
    >
 <img src="https://wise-yatra-ui.vercel.app/assets/logo.png" alt="..." height="40" width="350">
    </h1>
    <h2 class="heading">Admin Panel
 <span class="current-date">{{ currentDay }}, {{ currentDate }}</span></h2>
    <!-- Conditional rendering of the login button or user greeting -->
    <div>
      <button v-if="!isLoggedIn" @click="openLoginModal" class="button-header">
      <font-awesome-icon :icon="['fas', 'user']" class="login-icon" />
  Login
</button>
      <p v-else class="text-primary mb-0">Hi, {{ userEmail }}</p>
    </div>

    <!-- Login Modal -->
    <div
        v-if="showLoginModal"
        class="modal fade show d-block"
        tabindex="-1"
        role="dialog"
    >
     <div class="login-box">
      <p>Login</p>
               <button
                type="button"
                class="close"
                @click="closeLoginModal"
               >
       <font-awesome-icon :icon="['fas', 'times']" class="icon-close"></font-awesome-icon>          
        </button>
  <form @submit.prevent="handleLogin">
    <div class="user-box">
      <input required="" name="" type="email" v-model="email" id="email">
      <label>Email</label>
    </div>
    <div class="user-box">
      <input required="" name="" type="password" v-model="password" id="password">
      <label>Password</label>
    </div>
    <a href="#" class="submit-login" type="submit">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      Login
    </a>
  </form>
</div>
    </div>
    <!-- Backdrop -->
    <div v-if="showLoginModal" class="modal-backdrop fade show"></div>
  </nav>
</template>

<script>
import axios from "axios";
import {API_CONFIG} from "@/apiConfig";

export default {
  name: "AdminHeader",
  data() {
    return {
      showLoginModal: false,
      email: "",
      password: "",
      isLoggedIn: false, // Track login state
      userEmail: "", // Store logged-in user's email
       currentDate: "", 
      currentDay: "", 
    };
  },
  methods: {
    openLoginModal() {
      this.showLoginModal = true;
    },
    closeLoginModal() {
      this.showLoginModal = false;
      this.email = '';
      this.password = '';
    },
    async handleLogin() {
      try {
        const response = await axios.post(API_CONFIG.getEndpoint('signIn'), {
          email: this.email,
          password: this.password,
        });
        // Simulate successful login response
        this.isLoggedIn = true; // User is now logged in
        this.userEmail = this.email; // Store user's email for greeting
        this.closeLoginModal(); // Close the modal
        alert("Login successful!");
        console.log("Login Response:", response.data);
        // Handle additional logic like saving tokens, etc.
      } catch (error) {
        alert("Login failed. Please check your credentials.");
        console.error(error);
      }
    },
    setDateAndDay() {
      const today = new Date();
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const monthsOfYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      this.currentDay = daysOfWeek[today.getDay()];
      this.currentDate = `${today.getDate()} ${monthsOfYear[today.getMonth()]} ${today.getFullYear()}`;
    },
  },
   mounted() {
    this.setDateAndDay(); 
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

body {
  font-family: 'Poppins', sans-serif; 
}

.navbar {
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  border: 1px solid #ddd; 
}

.navbar h1 {
  border-radius: 0.7rem;
  text-align: center;
  padding: 0 !important;
}

.navbar h1 img {
  height: 40px;
  width: 130px;
}

.label-style{
  color:#4B49AC;
}
.button-header{
background-color: #4B49AC; 
color: white; 
border: none; 
border-radius: 10px; 
padding: 10px 20px; 
font-size: 16px; 
cursor: pointer; 
margin-right:5px;
display: flex;
align-items: center;
gap: 6px;
}

.button-header:hover{
  background-color: #4B49AC;
  color: white;
  border: none;
}

.modal-header{
justify-content:center;
}

.headingtitle{
    color:#000000;
    border-radius: 0.7rem;
    text-align: center;
    margin:0px;
}

.modal-title {
  font-family: 'Poppins', sans-serif; 
  font-weight: 800; 
  font-size: 30px; 
  color: #4B49AC; 
  letter-spacing: 1px; 
  text-transform: uppercase; 
  text-align: center; 
  margin-bottom: 0px; 
}
.logo {
  background-color: #4B49AC;
  padding: 2px;
  margin: 5px;
}

.heading {
  color: #444444;
  margin: 5px;
  font-size: 18px;
  font-weight: 600; 
  font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.close {
  color: #4B49AC;
  border: none;
  border-radius: 30%;
  width: 30px; 
  height: 30px; 
  font-size: 18px; 
  font-weight: bold;
  line-height: 0;
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: auto; 
  justify-content: center;
}

.close:hover {
  background-color: transparent;
  color: #4B49AC;
  border: 2px solid #4B49AC;
  box-shadow: 0 0 8px rgba(75, 73, 172, 0.5);
  transform: scale(1.1);
}

.close:active {
  background-color: rgba(75, 73, 172, 0.1);
  box-shadow: none;
  transform: scale(0.95);
}
.current-date {
  font-size: 12px;
  color: #999999;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  display: block;
}
button.btn-button.btn-primary {
  background-color: #4B49AC!important; 
  color: white!important;
  font-size: 20px; 
  font-weight: 500; 
  text-align:center;
  align-items:center;
  margin-left:25%!important;
  border-radius: 10px!important; 
  width: 45%!important; 
  transition: background-color 0.3s ease, transform 0.2s ease!important;
}

button.btn-button.btn-primary:hover {
  background-color: transparent!important; 
  color: #4B49AC!important;
  border: 2px solid #4B49AC!important; 
}

button.btn-button.btn-primary:active {
  background-color: rgba(75, 73, 172, 0.1)!important; 
  border: 2px solid #4B49AC !important;
}

input.form-control {
  font-size: 16px; 
  margin-bottom: 15px; 
  border: 2px solid #ddd; 
  border-radius: 10px; 
  transition: border-color 0.3s ease;
}

input.form-control:focus {
  border-color: #4B49AC; 
  outline: none; 
  box-shadow: none;
}

input.form-control:focus-visible {
  outline: none;
  box-shadow: none; 
}

label {
  font-size: 16px; 
  font-weight: 500; 
  margin-bottom: 8px; 
  color: #333; 
}

button:hover {
    background-color: transparent;
    color: #4B49AC; /* Text color changes to match the background */
    border: 2px solid #4B49AC; /* Adds a border that matches the button's color */
}
.text-primary {
  font-weight: bold;
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 40px;
  margin: 20px auto;
  transform: translate(-50%, -55%);
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  border-radius: 10px;
}

.login-box p:first-child {
  margin: 0 0 30px;
  padding: 0;
  color: #4B49AC;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.login-box .user-box {
  position: relative;
  margin-bottom: 30px;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #4B49AC;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #4B49AC;
  outline: none;
  background: transparent;
  margin-bottom: 5px;
  box-sizing: border-box;
}

.login-box .user-box label {
  top: 10px;
  z-index: 1;
  position: absolute;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #4B49AC;
  pointer-events: none;
  transition: .5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #4B49AC;
  font-size: 12px;
}

.login-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-weight: bold;
  color: #4B49AC;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 3px
}

.login-box a:hover {
  background-color: transparent !important; 
  color:#4B49AC !important ; 
  border: 1px solid #4B49AC; 
}


.login-box a span {
  position: absolute;
  display: block;
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #4B49AC);
  animation: btn-anim1 1.5s linear infinite;
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #4B49AC);
  animation: btn-anim2 1.5s linear infinite;
  animation-delay: .375s
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #4B49AC);
  animation: btn-anim3 1.5s linear infinite;
  animation-delay: .75s
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #4B49AC);
  animation: btn-anim4 1.5s linear infinite;
  animation-delay: 1.125s
}

.login-box p:last-child {
  color: #aaa;
  font-size: 14px;
}

.login-box a.a2 {
  color: #4B49AC;
  text-decoration: none;
}

.login-box a.a2:hover {
  background: transparent;
  color: #aaa;
  border-radius: 5px;
}

.icon-close{
  font-size: 22px;
}

.submit-login{
  background-color: #4B49AC !important; 
  color: #ffffff !important; 
  justify-content: center !important;
  display: flex !important;
}
</style>