<template>
  <!--<aside class="scrollable-aside">-->
    <nav class="navbar navbar-light flex-column">
      <!--<h4>Menu </h4>-->
      <ul class="nav flex-column px-3" style="width: 100%;margin-top:13px;">
        <li class=" list-changes" >
  <button class="d-flex align-items-center rounded px-3 py-2 w-100" @click="toggleDashboardDropdown" :class="{ 'active-bg': isDashboardOpen }">
    <font-awesome-icon :icon="['fas', 'tachometer-alt']" class="me-2" />
    <span class="flex-grow-1">Dashboard</span>
<font-awesome-icon :icon="['fas', isDashboardOpen ? 'chevron-down' : 'chevron-right']" style="height:12px;" />
  </button>
  <div v-show="isDashboardOpen" :class="{ 'dropdown-open': isDashboardOpen }" >
    <ul class=" list-unstyled fw-normal small">
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/') }">
        <router-link to="/" class="nav-link" @click="closeAllDropdownsOnNavigation">Dashboard</router-link>
      </li>
    </ul>
  </div>
</li>

<li class="list-changes" >
  <button class=" d-flex align-items-center rounded px-3 py-2   w-100 nav-item" @click="toggleUsersDropdown" :class="{ 'active-bg': isUsersOpen }">
    <font-awesome-icon :icon="['fas', 'user']" class="me-2" />
    <span class="flex-grow-1">Users</span>
    <font-awesome-icon :icon="['fas', isUsersOpen ? 'chevron-down' : 'chevron-right']" style="height:12px;"/>
  </button>
  <div v-show="isUsersOpen" :class="{ 'dropdown-open': isUsersOpen }" >
    <ul class=" list-unstyled fw-normal small">
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/users') }">
        <router-link to="/users" class="nav-link" @click="closeAllDropdownsOnNavigation">Users</router-link>
      </li>
    </ul>
  </div>
</li>

<li class="list-changes" >
  <button class=" d-flex align-items-center rounded px-3 py-2   w-100 nav-item" @click="toggleSettingDropdown" :class="{ 'active-bg': isSettingsOpen }">
    <font-awesome-icon :icon="['fas', 'cog']" class="me-2" />
    <span class="flex-grow-1">Settings</span>
    <font-awesome-icon :icon="['fas', isSettingsOpen ? 'chevron-down' : 'chevron-right']" style="height:12px;"/>
  </button> 
  <div v-show="isSettingsOpen" :class="{ 'dropdown-open': isSettingsOpen }">
    <ul class=" list-unstyled fw-normal small">
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/settings') }">
        <router-link to="/settings" class="nav-link" @click="closeAllDropdownsOnNavigation">Settings</router-link>
      </li>
    </ul>
  </div>
</li>

<li class="list-changes">
  <button class="d-flex align-items-center rounded px-3 py-2   w-100" @click="togglePackageDropdown" :class="{ 'active-bg': isPackageOpen }">
    <font-awesome-icon :icon="['fas', 'box']" class="me-2" />  <!-- Package Icon -->
    <span class="flex-grow-1">Packages</span>
    <font-awesome-icon :icon="['fas', isPackageOpen ? 'chevron-down' : 'chevron-right']" style="height:12px;"/>
  </button>
  <div v-show="isPackageOpen" :class="{ 'dropdown-open': isPackageOpen }">
    <ul class=" list-unstyled fw-normal small">
      <li class="rounded-bullet align-items-center" :class="{ active: isActive('/viewpackages') }">
        <router-link to="/viewpackages" class="nav-link" @click="closeAllDropdownsOnNavigation">View Packages</router-link>
      </li>
      <li class="rounded-bullet" :class="{ active: isActive('/packages') }">
        <router-link to="/packages" class="nav-link" @click="closeAllDropdownsOnNavigation">Create Package</router-link>
      </li>
    </ul>
  </div>
</li>

<li class="list-changes">
  <button class="d-flex align-items-center rounded px-3 py-2   w-100" @click="toggleWebPackageDropdown" :class="{ 'active-bg': isWebPackageOpen }">
    <font-awesome-icon :icon="['fas', 'box']" class="me-2" />  <!-- Package Icon -->
    <span class="flex-grow-1">Website Packages</span>
    <font-awesome-icon :icon="['fas', isWebPackageOpen ? 'chevron-down' : 'chevron-right']" style="height:12px;"/>
  </button>
  <div v-show="isWebPackageOpen" :class="{ 'dropdown-open': isWebPackageOpen }">
    <ul class=" list-unstyled fw-normal small">
      <li class="rounded-bullet align-items-center" :class="{ active: isActive('/web-packages/view') }">
        <router-link to="/web-packages/view" class="nav-link" @click="closeAllDropdownsOnNavigation">View Web Packages</router-link>
      </li>
      <li class="rounded-bullet" :class="{ active: isActive('/web-packages/create') }">
        <router-link to="/web-packages/create" class="nav-link" @click="closeAllDropdownsOnNavigation">Create Web Package</router-link>
      </li>
      <li class="rounded-bullet align-items-center" :class="{ active: isActive('/web-packages/packagetype') }">
        <router-link to="/web-packages/packagetype" class="nav-link" @click="closeAllDropdownsOnNavigation">Package Type</router-link>
      </li>
      <li class="rounded-bullet align-items-center" :class="{ active: isActive('/web-packages/packagetheme') }">
        <router-link to="/web-packages/packagetheme" class="nav-link" @click="closeAllDropdownsOnNavigation">Package Theme</router-link>
      </li>
    </ul>
  </div>
</li>

        
<li class="list-changes">
  <button class="d-flex align-items-center rounded px-3 py-2   w-100" @click="toggleTemplateDropdown" :class="{ 'active-bg': isTemplateOpen }">
    <font-awesome-icon :icon="['fas', 'film']" class="me-2" />
    <span class="flex-grow-1">Template</span>
    <font-awesome-icon :icon="['fas', isTemplateOpen ? 'chevron-down' : 'chevron-right']" style="height:12px;" />
  </button>
  <div v-show="isTemplateOpen" :class="{ 'dropdown-open': isTemplateOpen }">
    <ul class=" list-unstyled fw-normal small">
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/template/view') }">
        <router-link to="/template/view" class="nav-link" @click="closeAllDropdownsOnNavigation">List of Templates</router-link>
      </li>
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/template/create') }">
        <router-link to="/template/create" class="nav-link" @click="closeAllDropdownsOnNavigation">Stitch Templates</router-link>
      </li>
    </ul>
  </div>
</li>

<li class="list-changes">
  <button class="d-flex align-items-center rounded px-3 py-2   w-100" @click="toggleAdminControl" :class="{ 'active-bg': isAdminOpen }">
    <font-awesome-icon :icon="['fas', 'tools']" class="me-2" />
    <span class="flex-grow-1" style="margin-left: 10px;">Admin Control </span>
    <font-awesome-icon :icon="['fas', isAdminOpen ? 'chevron-down' : 'chevron-right']" style="height:12px;" />
  </button>
  <div v-show="isAdminOpen" :class="{ 'dropdown-open': isAdminOpen }">
    <ul class=" list-unstyled fw-normal small">
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/destinations') }">
        <router-link to="/destinations" class="nav-link" @click="closeAllDropdownsOnNavigation">Destination</router-link>
      </li> 
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/partner') }">
        <router-link to="/partner" class="nav-link" @click="closeAllDropdownsOnNavigation">Sourcing Partner</router-link>
      </li>
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/cities') }">
        <router-link to="/cities" class="nav-link" @click="closeAllDropdownsOnNavigation">City</router-link>
      </li>
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/airports') }">
        <router-link to="/airports" class="nav-link" @click="closeAllDropdownsOnNavigation" >Airports</router-link>
      </li>
      <!-- <li class="rounded-bullet align-items-center " :class="{ active: isActive('/exchangerates') }">
        <router-link to="/exchangerates" class="nav-link" @click="closeAllDropdownsOnNavigation" >Exchange Rates</router-link>
      </li> -->
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/populardestinations') }">
        <router-link to="/populardestinations" class="nav-link" @click="closeAllDropdownsOnNavigation" >Popular Destination</router-link>
      </li>
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/leads') }">
        <router-link to="/leads" class="nav-link" @click="closeAllDropdownsOnNavigation" >Leads</router-link>
      </li>
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/coupons') }">
        <router-link to="/coupons" class="nav-link" @click="closeAllDropdownsOnNavigation" >Generate Coupons</router-link>
      </li>
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/webcards') }">
        <router-link to="/webcards" class="nav-link" @click="closeAllDropdownsOnNavigation" >Web Cards</router-link>
      </li>
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/installmentsettings') }">
        <router-link to="/installmentsettings" class="nav-link" @click="closeAllDropdownsOnNavigation" >Installment Settings</router-link>
      </li>
    </ul>
  </div>
</li>

<li class="list-changes">
  <button class="d-flex align-items-center rounded px-3 py-2   w-100" @click="toggleExcursion" :class="{ 'active-bg': isExcursionOpen }">
    <font-awesome-icon :icon="['fas', 'map-marked-alt']" class="me-2" />
    <span class="flex-grow-1" style="margin-left: 10px;">Excursion</span>
    <font-awesome-icon :icon="['fas', isExcursionOpen ? 'chevron-down' : 'chevron-right']" style="height:12px;" />
  </button>
  <div v-show="isExcursionOpen" :class="{ 'dropdown-open': isExcursionOpen }">
    <ul class=" list-unstyled fw-normal small">
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/hotelregistration') }">
        <router-link to="/hotelregistration" class="nav-link" @click="closeAllDropdownsOnNavigation">Hotel Registration</router-link>
      </li>
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/airport-transfers') }">
        <router-link to="/airport-transfers" class="nav-link" @click="closeAllDropdownsOnNavigation">Airport Transfers</router-link>
      </li>
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/intercity-transfer') }">
        <router-link to="/intercity-transfer" class="nav-link" @click="closeAllDropdownsOnNavigation">Inter City Transfers</router-link>
      </li>
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/sightseeing') }">
        <router-link to="/sightseeing" class="nav-link" @click="closeAllDropdownsOnNavigation">Sightseeing</router-link>
      </li>
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/information') }">
        <router-link to="/information" class="nav-link" @click="closeAllDropdownsOnNavigation">New Information</router-link>
      </li>
    </ul>
  </div>
</li>


<li class="list-changes">
  <button class="d-flex align-items-center rounded px-3 py-2   w-100" @click="toggleBookingsDropdown" :class="{ 'active-bg': isBookingsOpen }">
    <font-awesome-icon :icon="['fas', 'clipboard-list']" class="me-2" />
    <span class="flex-grow-1" style="margin-left: 14px;">Bookings</span>
    <font-awesome-icon :icon="['fas', isBookingsOpen ? 'chevron-down' : 'chevron-right']" style="height:12px;"/>
  </button>
  <div v-show="isBookingsOpen" :class="{ 'dropdown-open': isBookingsOpen }">
    <ul class=" list-unstyled fw-normal small">
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/bookings') }">
        <router-link to="/bookings" class="nav-link" @click="closeAllDropdownsOnNavigation">Bookings</router-link>
      </li>
    </ul>
  </div>
</li>

 <!--  <li class="list-changes">
  <button class="d-flex align-items-center rounded px-3 py-2   w-100" @click="toggleHotelFormDropdown" :class="{ 'active-bg': isHotelFormOpen }">
    <font-awesome-icon :icon="['fas', 'hotel']" class="me-2" />
    <span class="flex-grow-1" style="margin-left: 10px;">Hotel Form</span>
    <font-awesome-icon :icon="['fas', isHotelFormOpen ? 'chevron-down' : 'chevron-right']" style="height:12px;" />
  </button>
  <div v-show="isHotelFormOpen" :class="{ 'dropdown-open': isHotelFormOpen }">
    <ul class=" list-unstyled fw-normal small">
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/hotel-form') }">
        <router-link to="/hotel-form" class="nav-link" @click="closeAllDropdownsOnNavigation">Hotel Form</router-link>
      </li>
    </ul>
  </div>
</li>

<li class="list-changes">
  <button class="d-flex align-items-center rounded px-3 py-2 w-100" @click="toggleRoomFormDropdown" :class="{ 'active-bg': isRoomFormOpen }">
    <font-awesome-icon :icon="['fas', 'bed']" class="me-2" />
    <span class="flex-grow-1" style="margin-left: 7px;">Room Form</span>
    <font-awesome-icon :icon="['fas', isRoomFormOpen ? 'chevron-down' : 'chevron-right']" style="height:12px;" />
  </button>
  <div v-show="isRoomFormOpen" :class="{ 'dropdown-open': isRoomFormOpen }">
    <ul class=" list-unstyled fw-normal small">
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/room-form/:hotelCode') }">
        <router-link to="/room-form/:hotelCode" class="nav-link" @click="closeAllDropdownsOnNavigation">Room Form</router-link>
      </li>
    </ul>
  </div>
</li>-->

<li class="list-changes">
  <button class="d-flex align-items-center rounded px-3 py-2   w-100" @click="toggleWebManagementDropdown" :class="{ 'active-bg': isWebManagementOpen }">
    <font-awesome-icon :icon="['fas', 'film']" class="me-2" />
    <span class="flex-grow-1">Web Management</span>
    <font-awesome-icon :icon="['fas', isWebManagementOpen ? 'chevron-down' : 'chevron-right']" style="height:12px;" />
  </button>
  <div v-show="isWebManagementOpen" :class="{ 'dropdown-open': isWebManagementOpen }">
    <ul class=" list-unstyled fw-normal small">
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/testimonials') }">
        <router-link to="/testimonials" class="nav-link" @click="closeAllDropdownsOnNavigation">Testimonials</router-link>
      </li>
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/webimage') }">
        <router-link to="/webimage" class="nav-link" @click="closeAllDropdownsOnNavigation">Web Image</router-link>
      </li>
      <li class="rounded-bullet align-items-center " :class="{ active: isActive('/faq') }">
        <router-link to="/faq" class="nav-link" @click="closeAllDropdownsOnNavigation">FAQ</router-link>
      </li>
    </ul>
  </div>
</li>
      </ul>
    </nav>

    <ParentComponent v-if="selectedForm === 'hotel-form' || selectedForm === 'room-form'" :selectedForm="selectedForm" /> 
    
 <!-- </aside> -->
</template>

<script>
import { ref } from 'vue'; 
import ParentComponent from './ParentComponent.vue';

export default {
  name: 'LeftPanel',
  components: { ParentComponent }, 
  setup() { 
  const selectedForm = ref('');
  
  // States for dropdowns
  const isDashboardOpen = ref(false);
  const isUsersOpen = ref(false);
  const isSettingsOpen = ref(false);
  const isPackageOpen = ref(false);
  const isTemplateOpen = ref(false);
  const isAdminOpen = ref(false);  // 🔹 Add this line
  const isExcursionOpen = ref(false);
  const isBookingsOpen = ref(false);
  const isHotelFormOpen = ref(false);
  const isRoomFormOpen = ref(false);
  const isWebPackageOpen = ref(false);
  const isWebManagementOpen = ref(false);


    const closeAllDropdowns = () => {
    isDashboardOpen.value = false;
    isUsersOpen.value = false;
    isSettingsOpen.value = false;
    isPackageOpen.value = false;
    isWebPackageOpen.value = false;
    isTemplateOpen.value = false;
    isAdminOpen.value = false;
    isExcursionOpen.value = false;
    isBookingsOpen.value = false;
    isHotelFormOpen.value = false;
    isRoomFormOpen.value = false;
    isWebManagementOpen.value = false;
  };

  const toggleDropdown = (menu) => {
  if (menu.value) {
    menu.value = false;
  } else {
    closeAllDropdowns();
    menu.value = true;
  }
};

const toggleDashboardDropdown = () => toggleDropdown(isDashboardOpen);
const toggleUsersDropdown = () => toggleDropdown(isUsersOpen);
const toggleSettingDropdown = () => toggleDropdown(isSettingsOpen);
const togglePackageDropdown = () => toggleDropdown(isPackageOpen);
const toggleWebPackageDropdown = () => toggleDropdown(isWebPackageOpen);
const toggleTemplateDropdown = () => toggleDropdown(isTemplateOpen);
const toggleAdminControl = () => toggleDropdown(isAdminOpen);
const toggleExcursion = () => toggleDropdown(isExcursionOpen);
const toggleBookingsDropdown = () => toggleDropdown(isBookingsOpen);
const toggleHotelFormDropdown = () => toggleDropdown(isHotelFormOpen);
const toggleRoomFormDropdown = () => toggleDropdown(isRoomFormOpen);
const toggleWebManagementDropdown = () => toggleDropdown(isWebManagementOpen);

const closeAllDropdownsOnNavigation = () => {
  closeAllDropdowns();
};
  const isActive = (route) => {
    return window.location.pathname === route; // Adjust if using Vue Router
  };

  return { 
    selectedForm,
    isDashboardOpen,
    isUsersOpen,
    isSettingsOpen, 
    isPackageOpen, 
    isWebPackageOpen,
    isTemplateOpen,
    isBookingsOpen,
    isAdminOpen,   // 🔹 Return this
    isHotelFormOpen,
    isRoomFormOpen,
    toggleDashboardDropdown,
    toggleUsersDropdown,
    toggleSettingDropdown,
    togglePackageDropdown, 
    toggleWebPackageDropdown,
    toggleTemplateDropdown,
    toggleAdminControl, // 🔹 Return this
    isActive,
    isExcursionOpen,
    toggleBookingsDropdown,
    toggleHotelFormDropdown,
    toggleRoomFormDropdown,
    toggleExcursion,
    closeAllDropdownsOnNavigation,
    toggleWebManagementDropdown,
    isWebManagementOpen
  }; 
}
};
</script>



<style>
  .scrollable-aside {
    max-height: 100vh;
    overflow-y: auto;
    padding-right: 15px; /* Adjust to prevent horizontal scroll */
    color: #007bff;
    background-color: #d9f1ff; 
  }
 
  .nav-item.active .nav-link {
    color: black;
  }

.nav-link.active{
  color: grey;
}

  .navbar-nav .nav-item {
    width: 100%;
  }

  .navbar-nav .nav-item .nav-link {
    width: 100%;
    text-align: left;
  }

  .navbar-light .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-light .navbar-nav .nav-item {
    padding-right: 0;
    padding-left: 0;
  }
  button {
  border: none;
  outline: none;
  background: none;
  padding: 5px 10px;
}

button:focus, button:active {
  outline: none;
  box-shadow: none;
  border: none;
}

.flex-grow-1{
  text-align:left;
  margin-left:8px;
  font-size: 14px;
}

.list-changes {
  margin-bottom: 0.39rem !important;
  position: relative; 
}

.active-bg {
  background-color: #7978E9 !important;
  color: #ffffff !important;
  transition: background-color 2s ease-in-out, color 2s ease-in-out;
}


.list-changes button {
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  color: #4b49ac;
  transition: background-color 0.3s, color 0.3s;
}

.dropdown-open {
  display: block;
  position: absolute;
  top: 0;
  left: 105%;
  background-color: #ffffff; 
  width: max-content;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: background-color 2s, color 3s;
  padding: 5px;
  z-index: 999;
}

.rounded-bullet{
  border-radius: 5px;
}

.rounded-bullet:hover{
  background-color: #7978E9;
  color: #ffffff!important;
}

.nav-link:hover{
  color: #ffffff !important;
}

.nav-link{
  color: #7978E9 !important;
}
</style>
