<template>
  <div class="container">
    <div class="card-header" v-if="!isFormVisible">
      <div class="d-flex justify-content-between align-items-center">
        <h4>Manage Web Cards</h4>
        <div style="display: flex !important; gap: 20px !important">
          <div v-if="!isFormVisible">
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div> 
  <button class="btn" @click="showForm('create')">
          <font-awesome-icon :icon="['fas', 'plus']" class="me-2" /> New Web
          Card
        </button>
        </div>
      </div>
    </div>
    <div v-if="loading">Loading...</div>
    <div v-if="error" class="text-danger">{{ error }}</div>

    <div class="table-container" v-if="!isFormVisible">
      <table
        class="table table-bordered table-hover table-responsive"
        style="table-layout: auto"
      >
        <thead>
          <tr>
            <th>SI.No</th>
            <th>Image</th>
            <th>Card Title</th>
            <th>Card Description</th>
            <th>Card Link</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pkg, index) in paginatedUsers" :key="index">
            <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>
            <td>
              <img :src="pkg.imageLink" alt="Website Image" width="100" />
            </td>
            <td>{{ pkg.title }}</td>
            <td>{{ pkg.description }}</td>
            <td>{{ pkg.cardLink }}</td>
            <td>{{ pkg.status }}</td>
            <td>
              <div style="display: flex;">
              <a
                href="#"
                class="text-success mr-3 faq-button"
                role="button"
                @click="showForm('edit', pkg)"
              >
                <font-awesome-icon
                  :icon="['fas', 'edit']"
                  class="edit"
                />
                <span class="tooltip">Edit</span>
              </a>

              <a
                href="#"
                class="text-success mr-3 faq-button"
                role="button"
                @click="deleteWebCardDialog(pkg)"
              >
                <font-awesome-icon
                  :icon="['fas', 'fa-trash']"
                  class="delete"
                />
                <span class="tooltip">Delete</span>
              </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Form Section -->
    <div v-if="isFormVisible">
      <h5>Create Web Card</h5>
      <hr />
      <form @submit.prevent="submitForm">
        <div class="form-group row mb-3">
          <label for="title" class="col-sm-3 col-form-label"
            >Card Title <span class="text-danger">*</span></label
          >
          <div class="col-sm-6">
            <input
              type="text"
              v-model="form.title"
              class="form-control"
              id="title"
              required
              placeholder="Enter Card Title"
            />
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="description" class="col-sm-3 col-form-label"
            >Card Description <span class="text-danger">*</span></label
          >
          <div class="col-sm-6">
            <textarea
              type="text"
              v-model="form.description"
              class="form-control"
              id="description"
              required
              placeholder="Enter Card Description"
            ></textarea>
          </div>
        </div>

        <div class="form-group row mb-3">
          <label class="col-sm-3">
            Image
            <span
              v-if="!form.imageFile && !form.imageLink"
              style="color: red !important"
              >*</span
            >
          </label>
          <div class="col-sm-6">
            <div v-if="form.imageLink">
              <img
                :src="form.imageLink"
                alt="WebCards Image"
                style="width: 300px; margin-bottom: 10px"
              />
            </div>
            <input
              type="file"
              @change="handleFileUpload('imageFile', $event)"
              class="form-control"
              id="imageFile"
              :required="!form.imageLink"
            />
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="cardLink" class="col-sm-3 col-form-label"
            >Card Link <span class="text-danger">*</span></label
          >
          <div class="col-sm-6">
            <input
              type="url"
              v-model="form.cardLink"
              class="form-control"
              id="cardLink"
              required
              placeholder="Enter Card Link"
            />
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="status" class="col-sm-3 col-form-label">Status</label>
          <div class="col-sm-6 d-flex align-items-center">
            <div class="form-check me-3">
              <input
                type="radio"
                v-model="form.status"
                class="form-check-input"
                id="statusActive"
                value="ACTIVE"
              />
              <label for="statusActive" class="form-check-label">Active</label>
            </div>
            <div class="form-check">
              <input
                type="radio"
                v-model="form.status"
                class="form-check-input"
                id="statusInactive"
                value="INACTIVE"
              />
              <label for="statusInactive" class="form-check-label"
                >Inactive</label
              >
            </div>
          </div>
        </div>

        <hr />
        <div class="d-flex justify-content-end">
          <button type="submit" class="btn btn-success me-2">
            <font-awesome-icon :icon="['fas', 'save']" class="me-2" /> Submit
          </button>
          <button type="button" class="btn btn-light" @click="cancelForm">
            <font-awesome-icon :icon="['fas', 'times']" /> Cancel
          </button>
        </div>
      </form>
    </div>
    <DeleteModal
      :visible="showDeleteModal"
      @confirmDelete="deleteWebCards"
      @cancelDelete="showDeleteModal = false"
    />
    <div class="d-flex justify-content-between mt-3" v-if="!isFormVisible">
      <div class="d-flex align-items-center">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredWedCards.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination mb-0" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>     
    </div>   
  </div>
</template>

<script>
import axios from "axios";
import { API_CONFIG } from "@/apiConfig";
import DeleteModal from "./DeleteModal.vue";



export default {
  components: {
    DeleteModal,
  },
  data() {
    return {
      webcards: [],
      isFormVisible: false,
      form: {
        title: "",
        description: "",
        imageFile: null,
        imageLink: "",
        cardLink: "",
        status: "ACTIVE",
      },
      loading: false,
      error: null,
      showDeleteModal: false,
      webCardToDelete: null,
      searchQuery: "", 
      currentPage: 1, 
      itemsPerPage: 10,  
    };
  },
  computed: {
        filteredWedCards() {
    return this.webcards.filter(pkg => {
      const searchQueryLower = this.searchQuery.toLowerCase();
      return (
        pkg.title.toLowerCase().includes(searchQueryLower) 
      );
    });
  },
  paginatedUsers() {
    const pageSize = Number(this.itemsPerPage) || 10;
    const currentPage = Number(this.currentPage) || 1;
    
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    
    if (!this.filteredWedCards.length) return [];
    
    return this.filteredWedCards.slice(startIndex, endIndex);
  },
  
  totalPages() {
    const pageSize = Number(this.itemsPerPage) || 10;
    return Math.max(1, Math.ceil(this.filteredWedCards.length / pageSize));
  },
},
watch: {
  itemsPerPage(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.resetPagination();
    }
  },
  searchQuery() {
    this.resetPagination();
  },
  filteredWedCards: {
    handler() {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages || 1;
      }
    },
    immediate: true
  }
},
  methods: {
    resetPagination() {
    this.currentPage = 1;
    
    this.$forceUpdate();
  },
  
  goToPage(page) {
    const pageNum = Number(page);
    if (pageNum >= 1 && pageNum <= this.totalPages) {
      this.currentPage = pageNum;
    }
  },

getPageRange() {
    const range = [];
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    const delta = 2;

    if (currentPage > delta + 1) {
      range.push(1);
      if (currentPage > delta + 2) range.push('...');
    }

    for (let i = Math.max(1, currentPage - delta); i <= Math.min(totalPages, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage < totalPages - delta - 1) {
      if (currentPage < totalPages - delta - 2) range.push('...');
      range.push(totalPages);
    }

    return range;
  },
    clearSearch() {
    this.searchQuery = ""; 
  },
    handleFileUpload(field, event) {
      const file = event.target.files[0];
      const validTypes = ["image/jpeg", "image/png"];
      const maxSize = 2 * 1024 * 1024;

      if (!validTypes.includes(file.type)) {
        alert("Only JPEG and PNG files are allowed.");
        event.target.value = "";
        return;
      }

      if (file.size > maxSize) {
        alert("File size should not exceed 2MB.");
        event.target.value = "";
        return;
      }

      this.form[field] = file;
      this.form.imageLink = URL.createObjectURL(file);
    },

    showForm(action, pkg = null) {
      if (action === "create") {
        this.resetForm();
      } else if (action === "edit" && pkg) {
        this.form = { ...pkg, imageFile: null, imageLink: pkg.imageLink || "" };
      }
      this.isFormVisible = true;
    },

    cancelForm() {
      this.isFormVisible = false;
      this.resetForm();
    },
    async submitForm() {
      if (this.form.id) {
        await this.updateWebCards();
      } else {
        await this.createWebCards();
      }
    },
    resetForm() {
      this.form = {
        id: null,
        title: "",
        cardLink: "",
        imageFile: null,
        description: "",
        status: "ACTIVE",
      };
    },

    async fetchWebCards() {
      this.loading = true;
      try {
        const response = await axios.get(
          API_CONFIG.getEndpoint("fetchWebCards")
        );
        this.webcards = response.data;
      } catch (error) {
        console.log("Error fetching webcards", error);
      } finally {
        this.loading = false;
      }
    },

    async createWebCards() {
      this.loading = true;
      try {
        const formData = new FormData();
        const webCardDTO = {
          id: this.form.id,
          title: this.form.title,
          cardLink: this.form.cardLink,
          description: this.form.description,
          status: this.form.status,
          imageFile: this.form.imageFile,
          imageLink: this.form.imageLink,
        };

        formData.append(
          "webCardDTO",
          new Blob([JSON.stringify(webCardDTO)], {
            type: "application/json",
          })
        );
        formData.append("imageFile", this.form.imageFile);

        let response = await axios.post(
          API_CONFIG.getEndpoint("createWebCards"),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Webcards created successfully:", response.data);

        this.fetchWebCards();
        this.isFormVisible = false;
        this.resetForm();
      } catch (error) {
        console.error("Error creating webcards:", error);
      } finally {
        this.loading = false;
      }
    },

    async updateWebCards() {
      this.loading = true;
      try {
        const formData = new FormData();
        const webCardDTO = {
          id: this.form.id,
          title: this.form.title,
          cardLink: this.form.cardLink,
          description: this.form.description,
          status: this.form.status,
          imageFile: this.form.imageFile,
          imageLink: this.form.imageLink,
        };

        formData.append(
          "webCardDTO",
          new Blob([JSON.stringify(webCardDTO)], {
            type: "application/json",
          })
        );

        if (this.form.imageFile) {
          formData.append("imageFile", this.form.imageFile);
        }

        let response = await axios.post(
          `${API_CONFIG.getEndpoint("updateWebCards")}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("webcards updated successfully:", response.data);

        this.fetchWebCards();
        this.isFormVisible = false;
        this.resetForm();
      } catch (error) {
        console.error("Error updating webcards:", error);
      } finally {
        this.loading = false;
      }
    },

    async deleteWebCards() {
      if (this.webCardToDelete) {
        this.loading = true;
        try {
          await axios.delete(
            `${API_CONFIG.getEndpoint("fetchWebCards")}/${
              this.webCardToDelete.id
            }`
          );
          console.log("Coupon deleted successfully");

          this.fetchWebCards();
        } catch (error) {
          console.error("Error deleting coupon:", error);
        } finally {
          this.loading = false;
        }

        this.showDeleteModal = false;
        this.webCardToDelete = null;
      }
    },

    deleteWebCardDialog(webCardDTO) {
      this.showDeleteModal = true;
      this.webCardToDelete = webCardDTO;
    },

  },

  mounted() {
    this.fetchWebCards();
  },
};
</script>

<style scoped>
.table-container {
  max-width: 100%;
  overflow-x: auto;
}
.container {
  margin-top: 20px;
}
th {
  font-weight: bold;
}
</style>
