<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-9"><h5> Inclusion & Exclusion for <span style="color:#4B49AC"> {{ packageCode }}</span></h5></div>
            <div class="col-sm-3">                
                <button type="button" class="btn btn-success me-2 text-right float-right" @click="back_to_packages"><font-awesome-icon :icon="['fas', 'fa-arrow-left']" class="me-2" /> Back to Packages</button> 
            </div> 
        </div> 
        <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>            
        
        <div class="clickable-div container border p-3">
            <table class="border table table-bordered">                
                <tbody>
                    <template v-for="(day, dIndex) in packageInfo.days" :key="dIndex">
                        <tr>
                            <td colspan="3" class="table-merged-row text-center">{{ formatDayHeader(dIndex, day.itineraryDate) }}</td>
                        </tr>
                        <template v-for="(city, cIndex) in day.cities" :key="cIndex">
                            <tr v-for="(itinerary, iIndex) in city.itineraries" :key="iIndex">                            
                                <td>{{ itinerary.excursionType }}</td>
                                <td>{{ itinerary.subCodeDescriptor }}</td>
                                <td>
                                    <input 
                                        type="number" 
                                        :value="calculateItineraryPrice(itinerary)" 
                                        disabled
                                        class="form-control"
                                    />
                                </td>
                            </tr>
                        </template>
                    </template>
                    <tr>
                        <td colspan="2" class="table-merged-row text-center"><strong>Total Price:</strong></td>                        
                        <td><input type="number" :value="totalItineraryPrice" class="form-control" disabled/></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="table-merged-row text-center"><strong>Actual Price:</strong></td>
                        <td><input type="number" v-model="priceSummary.actualPrice" class="form-control" disabled/></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="table-merged-row text-center"><strong>Miscellaneous Cost :</strong></td>
                        <td><input type="number" v-model="priceSummary.miscCost" class="form-control"/></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="table-merged-row text-center"><strong>Agent Markup in %:</strong></td>
                        <td><input type="number" v-model="priceSummary.agentMarkup" class="form-control"/></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="table-merged-row text-center"><strong>Agent Discount in %:</strong></td>
                        <td><input type="number" v-model ="priceSummary.agentDiscount" class="form-control"/></td>
                    </tr>
                    
                    <tr>
                        <td colspan="2" class="table-merged-row text-center"><strong>Margin (for agent's reference):</strong></td>
                        <td><input type="number" v-model ="priceSummary.agentDiscount" class="form-control" disabled/></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="table-merged-row text-center"><strong>Gross Selling Price:</strong></td>
                        <td><input type="number" v-model ="priceSummary.grossSellingPrice" class="form-control" disabled/></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="table-merged-row text-center"><strong>Tax GST:</strong></td>
                        <td><input type="number" v-model ="priceSummary.taxGst" class="form-control" disabled/></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="table-merged-row text-center"><strong>Net Selling Price:</strong></td>
                        <td><input type="number" v-model ="priceSummary.netSellingPrice" class="form-control" disabled/></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="table-merged-row text-center"><strong>Net Commission:</strong></td>
                        <td><input type="number" v-model ="priceSummary.netCommission" class="form-control" disabled/></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="table-merged-row text-center"><strong>Net Markup:</strong></td>
                        <td><input type="number" v-model ="priceSummary.netMarkup" class="form-control" disabled/></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="table-merged-row text-center"><strong>Net Cost:</strong></td>
                        <td><input type="number" v-model ="priceSummary.netCost" class="form-control" disabled/></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="table-merged-row text-center"><strong>Gross Margin:</strong></td>
                        <td><input type="number" v-model = "priceSummary.grossMargin" class="form-control" disabled/></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="table-merged-row text-center"><strong>Tax GST Margin:</strong></td>
                        <td><input type="number" v-model ="priceSummary.taxGstMargin" class="form-control" disabled/></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="table-merged-row text-center"><strong>Tax GST Paid:</strong></td>
                        <td><input type="number" v-model ="priceSummary.taxGstPaid" class="form-control" disabled/></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="table-merged-row text-center"><strong>Net Margin:</strong></td>
                        <td><input type="number" v-model ="priceSummary.netMargin" class="form-control" disabled/></td>
                    </tr>
                </tbody>
            </table>
            <hr />
            <div class="form-actions d-flex justify-content-end">
                <button type="submit" class="btn btn-success" @click="updatePackages" style="margin-right: 10px;"><font-awesome-icon :icon="['fas', 'floppy-disk']" /> Save and Next page</button>                    
                <button type="button" class="btn btn-light" @click="move_to_nextpage"> <font-awesome-icon :icon="['fas', 'arrow-right']" /> Go to Next Page</button>
            </div>
            <br>
        </div>    
    </div>
</template>
<script>
    
    import { ref, reactive, computed, onMounted } from 'vue';    
    import { useRoute, useRouter } from 'vue-router';
    import axios from 'axios';
    import { API_CONFIG } from '@/apiConfig';

    export default {
        
        name: 'InclusionsAndExclusionsList',
        setup() {
            const router = useRouter(); // Get the router instance
            const route = useRoute(); // Access current route's properties
            const packageCode = ref(route.params.id);
            const packageInfo = reactive({ 
                days: [
                    {
                        day_number: '',
                        itineraryDate: '',
                        cities: [
                            {
                                cityName: '',
                                itineraries: [
                                    {
                                        excursionType: '',
                                        subCode: '',
                                        price: 0
                                    }
                                ]
                            }
                        ]
                    }
                ],
                priceSummary: {                    
                    actualPrice : 0.0,
                    miscCost : 0.0,
                    agentMarkup : 0.0,
                    agentDiscount : 0.0,
                    grossSellingPrice : 0.0,
                    taxGst : 0.0,
                    netSellingPrice : 0.0,
                    netCommission : 0.0,
                    netMarkup : 0.0,
                    netCost : 0.0,
                    grossMargin : 0.0,
                    taxGstMargin : 0.0,
                    taxGstPaid : 0.0,
                    netMargin : 0.0
                }
            });

            const priceSummary =  reactive({
                actualPrice : 10000.0,
                miscCost : 0.0,
                agentMarkup : 0.0,
                agentDiscount : 0.0,
                grossSellingPrice : 0.0,
                taxGst : 0.0,
                netSellingPrice : 0.0,
                netCommission : 0.0,
                netMarkup : 0.0,
                netCost : 0.0,
                grossMargin : 0.0,
                taxGstMargin : 0.0,
                taxGstPaid : 0.0,
                netMargin : 0.0
            });
            
            const errorMessage = ref("");
            const itineraries = reactive([]);

            const totalItineraryPrice = computed(() => {
                const total_price = packageInfo.days.reduce((total, day) => {
                    return total + day.cities.reduce((cityTotal, city) => {
                        return cityTotal + city.itineraries.reduce((itineraryTotal, itinerary) => {
                            return itineraryTotal + (parseFloat(calculateItineraryPrice(itinerary)) || 0);
                        }, 0);
                    }, 0);
                }, 0);
                console.log('Total Itinerary Price:', total_price);
                calculatePriceSummary(total_price); // Call calculatePriceSummary after computing totalItineraryPrice
                return total_price;
            });

            const calculateItineraryPrice = (itinerary) => {
                let price = itinerary.price * packageInfo.noOfAdults;
                if (itinerary.excursionType === 'hotel') {
                    price = price / 2;
                }
                return price;
            };

            const calculatePriceSummary = (total_price) => {
                
                priceSummary.actualPrice = total_price.toFixed(2);
                                
                let agentMarkup = ((priceSummary.agentMarkup / 100.0) * priceSummary.actualPrice).toFixed(2);
                let agentDiscount = ((priceSummary.agentDiscount / 100.0) * priceSummary.actualPrice).toFixed(2);
                
                priceSummary.grossSellingPrice = (parseFloat(priceSummary.actualPrice) + parseFloat(agentMarkup) + parseFloat(priceSummary.miscCost) - parseFloat(agentDiscount)).toFixed(2);

                priceSummary.taxGst = (priceSummary.grossSellingPrice * 0.18).toFixed(2);
                
                priceSummary.netSellingPrice = (parseFloat(priceSummary.grossSellingPrice) + parseFloat(priceSummary.taxGst)).toFixed(2);

                //TODO: Calculate netCommission and netMarkup based on Partner's input
                //priceSummary.netCommission = priceSummary.netSellingPrice - priceSummary.netCost;
                //priceSummary.netMarkup = priceSummary.netSellingPrice - priceSummary.netCost;
                
                priceSummary.netCost = (parseFloat(priceSummary.netSellingPrice) - parseFloat(priceSummary.netCommission) - parseFloat(priceSummary.netMarkup)).toFixed(2);  
                priceSummary.grossMargin = (parseFloat(priceSummary.netSellingPrice) - parseFloat(priceSummary.netCost)).toFixed(2);
                priceSummary.taxGstMargin = (priceSummary.grossMargin * 0.18).toFixed(2);
                priceSummary.taxGstPaid = priceSummary.taxGstMargin;
                priceSummary.netMargin = (parseFloat(priceSummary.grossMargin) - parseFloat(priceSummary.taxGstPaid)).toFixed(2);

            };
            
            onMounted(() => {
                if (packageCode.value) {
                    fetchPackageByCode();
                }
            });
           
            const cancel = () => {
                console.log('Cancel button clicked');                
            };

            const formatDayHeader = (dIndex, itineraryDate) => {
                const date = new Date(itineraryDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                return `Day ${dIndex + 1} - ${day}-${month}-${year}`;
            };

            const fetchPackageByCode = async () => {
                try {
                    const response = await axios.get(API_CONFIG.getEndpoint('fetchPackages') + `/` + packageCode.value);
                    Object.assign(packageInfo, response.data);
                    //INFO: it is important to initialize priceSummary object with default values if not initialized we get NaN error
                    if (packageInfo.priceSummary) {
                        Object.keys(packageInfo.priceSummary).forEach(key => {
                            packageInfo.priceSummary[key] = packageInfo.priceSummary[key] || 0;
                        });
                    }
                    if( packageInfo.priceSummary != null) {
                        Object.assign(priceSummary, packageInfo.priceSummary);
                    }                    
                    console.log("fetchPackageByCode ::: fetched successfully");
                    console.log("fetchPackageByCode ::: fetched successfully priceSummary=" + JSON.stringify(packageInfo.priceSummary));
                } catch (error) {
                    console.error('Error fetching package details..' + error);
                    errorMessage.value = "Error fetching package details..";
                }
            };
            
            const move_to_nextpage = () => {
                router.push({ name: 'PackageSummary', params: { id: packageCode.value } });
            }

            const back_to_packages = () => {
                if( packageInfo.stitchAble) { 
                    router.push({ path: `/template/view` });
                } else if ( packageInfo.packageType == 'website_parent') {                    
                    router.push({ path: '/web-packages/view'});  
                } else {
                    router.push({path: `/viewpackages`});
                }
            }

            const savePriceSummary = async () => {                
                try {
                    const response = await axios.post(API_CONFIG.getEndpoint('fetchPackages') + `/${packageCode.value}/price/summary`, priceSummary);
                    console.log('Itineraries saved successfully:', response.data);                    
                    //back_to_packages();
                    move_to_nextpage();
                } catch(error) {
                    console.error('Error saving itineraries: ' + error);
                }
            };

            const updatePackages = async ()  => {
                Object.assign( packageInfo.priceSummary, priceSummary);
                console.log('updatePackages' + JSON.stringify(packageInfo.priceSummary));
                try {        
                    const response = await axios.put(  API_CONFIG.getEndpoint('updatePackages') +`/` + packageInfo.packageCode, packageInfo );
                    console.log('Price Summary saved successfully:', response.data);                    
                    move_to_nextpage();
                }catch(error) {
                    console.log('Error updating inclusions and exclusions ', error);
                    errorMessage.value = "Unable to save the itineraries.. Please try again later.";
                }
            };

            onMounted ( () => {                    
                if (packageCode.value) {    
                    fetchPackageByCode();                    
                }
            });

            return {
                packageInfo,
                fetchPackageByCode, 
                back_to_packages,
                packageCode,
                errorMessage,
                cancel,
                itineraries,
                savePriceSummary,
                formatDayHeader,
                priceSummary,
                totalItineraryPrice,calculatePriceSummary,
                move_to_nextpage,
                updatePackages,
                calculateItineraryPrice
            };
        }
    };
</script>
<style scoped>

.table-merged-row {
  font-size: 1.2em;
  background: aliceblue;
}
</style>