
<template>
  <head><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons/font/bootstrap-icons.css"></head>
  <div class="container">
     <div class="card-header" v-if="showHotelListView">
    <div class="d-flex justify-content-between align-items-center" style="margin-top: 0px">
        <h4 >Manage Hotels</h4>  
        <div style="display: flex !important; gap: 20px !important">
          <div v-if="showHotelListView">
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div> 
  <button type="button" class="btn1 me-2" @click="showForm('create')"><font-awesome-icon :icon="['fas', 'fa-plus']" class="me-2" />New Hotel</button>
        </div>        
        </div>
      </div>
      <div v-if="showHotelListView" class="table-container" >
     <!--    <h4> Hotels List</h4>   -->     
          <table class="table table-bordered mt-3">
            <thead>
              <tr>    
                  <th>SI.No</th>              
                  <th>Hotel Name</th>                    
                  <th>Hotel Code</th>
                  <th>Destination Name</th>
                  <th>City Name</th>
                  <th>Status</th>
                  <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(excursion, index) in paginatedUsers" :key = "excursion.id"> 
                <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>
                <td class="text-nowrap">{{ excursion.hotelName }}</td>
                <td class="text-nowrap">{{ excursion.hotelCode  }}</td>
                <td>{{ excursion.destinationName }}</td>
                <td>{{ excursion.cityName }}</td>
                <td>{{ excursion.status }}</td>
                <td class="d-flex justify-content-evenly m-0">
                    <button @click="showForm('upload-picture',excursion)" class=" btn-sm faq-button"><font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']"  class="upload"/><span class="tooltip">Picture</span>
                    </button>
                    <button @click="showForm('add-rooms',excursion)" class="btn-sm faq-button"><font-awesome-icon :icon="['fas', 'fa-plus']" class="plus"  /><span class="tooltip">Add</span></button>
                    <button @click="showForm('edit',excursion)" class="btn-sm faq-button"><font-awesome-icon :icon="['fas', 'fa-edit']" class="edit" /><span class="tooltip">Edit</span></button>                        
                    <button @click="confirmDeleteHotel(excursion)" class="btn-sm faq-button">
                      <font-awesome-icon :icon="['fas', 'fa-trash']"  class="delete" /> <span class="tooltip">Delete</span>           </button>  
                </td>

              </tr>
            </tbody>
          </table>
      </div>
      <div v-if= showCreateHotel >
        <div class="form-section">                      
          <h5>{{ formTitle }}</h5>
          <hr/>
          <div class="form-group row mb-3">
            <label for="destination" class="col-sm-3 col-form-label">Select Destination <span style="color:red !important">*</span></label>                    
            <div class="col-sm-6">
                <select v-model="excursionInfo.destinationId"  @change="onChangeByDestination" class="form-control" id="destination" required>
                    <option disabled value="">--Please select a destination--</option>
                    <option v-for="destination in destinations" :key="destination.id" :value="destination.id">
                        {{ destination.destinationName }}
                    </option>
                </select>                
                <div class="text-danger" v-for="(error, index) in v$.excursionInfo.destinationId.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="city" class="col-sm-3 col-form-label">Select City <span style="color:red !important">*</span></label>                    
            <div class="col-sm-6">
              <select v-model="excursionInfo.cityId" class="form-control" id="city">
                  <option disabled value="">-- Please select a city --</option>
                  <option v-for="city in cities" :key="city.id" :value="city.id">
                      {{ city.cityName }}
                  </option>
              </select>              
              <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span>
              <div class="text-danger" v-for="(error, index) in v$.excursionInfo.cityId.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>                    
          </div>
        </div>
        <div class="form-section">

          <h5>Hotel Information</h5>
          <hr/>

          <div class="form-group row mb-3">
            <label for="hotelLocation" class="col-sm-3 col-form-label">Hotel Name <span style="color:red !important">*</span></label>
            <div class="col-sm-6">
              <input type="text" class="form-control" id="hotelLocation" v-model="excursionInfo.hotelName" placeholder="Enter Hotel name">  
              <div class="text-danger" v-for="(error, index) in v$.excursionInfo.hotelName.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>            
          </div>

          <div class="form-group row mb-3">
            <label for="hotelLocation" class="col-sm-3 col-form-label">Hotel Code <span style="color:red !important">*</span></label>
            <div class="col-sm-6">
              <input type="text" class="form-control" id="hotelLocation" v-model="excursionInfo.hotelCode" placeholder="Enter Hotel Code to uniquely identify">              
              <div class="text-danger" v-for="(error, index) in v$.excursionInfo.hotelCode.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            
          </div>

          <div class="form-group row mb-3">
            <label for="hotelLocation" class="col-sm-3 col-form-label">Description </label>
            <div class="col-sm-6">
              <textarea type="text" class="form-control" id="hotelLocation" v-model="excursionInfo.hotelDesc" placeholder="Enter description"></textarea>              
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="hotelLocation" class="col-sm-3 col-form-label">Location/Address</label>
            <div class="col-sm-6">
              <input type="text" class="form-control" id="hotelLocation" v-model="excursionInfo.hotelAddress" placeholder="Hotel location or address details..">
            </div>
          </div>
          
          <div class="form-group row mb-3">
            <label for="hotelLatLng" class="col-sm-3 col-form-label">Latitude & Longitude</label>
            <div class="col-sm-3">
              <input type="number" step="0.000001" class="form-control" id="hotelLatLng" v-model="excursionInfo.hotelLocationLatitude" placeholder="Latitude - 0.000000">
              
              <div class="text-danger" v-for="(error, index) in v$.excursionInfo.hotelLocationLatitude.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="col-sm-3">
              <input type="number" step="0.000001" class="form-control" id="hotelLatLng" v-model="excursionInfo.hotelLocationLongitude" placeholder="Longitute - 0.000000">
              <div class="text-danger" v-for="(error, index) in v$.excursionInfo.hotelLocationLongitude.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>              
            </div>            
          </div>
        </div> <!-- hotel information -->
        
        <div class="form-section">

          <h5>Public Transport Details</h5>    
          <hr/>  
          
          <div class="form-group row mb-3">
            <label for="hotelLocation" class="col-sm-3 col-form-label">Transport nearby the hotel</label>
            <div class="col-sm-6">
              <input type="text" class="form-control" id="hotelLocation" v-model="excursionInfo.publicTransportNearBy" placeholder="Public transport near the hotel">
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="public-transport-show-in" class="col-sm-3 col-form-label">Transport Display In</label>            
            <div class="col-sm-6 mt-2">
              <div class="form-group-inline-flex mr-3" v-for="showIn in showInOptions" :key="showIn.value"> 
                <input type="checkbox" v-model="excursionInfo.publicTransportDisplayIn" :id="showIn.value" :value="showIn.value"> 
                <label class="ml-3" :for="showIn.value"> {{ showIn.label }} </label> 
              </div>
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="hotelLocation" class="col-sm-3 col-form-label">Transport Location</label>
            <div class="col-sm-3">
              <input type="number" step="0.000001" class="form-control" id="hotelLocation" v-model="excursionInfo.publicTransportLocationLatitude" placeholder="Latitude">
              <div class="text-danger" v-for="(error, index) in v$.excursionInfo.publicTransportLocationLatitude.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="col-sm-3">
              <input type="number" step="0.000001" class="form-control" id="hotelLocation" v-model="excursionInfo.publicTransportLocationLongitude" placeholder="Longitude">
              <div class="text-danger" v-for="(error, index) in v$.excursionInfo.publicTransportLocationLongitude.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>  
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="public-transport-show-in" class="col-sm-3 col-form-label">Transport Location Display In</label>              
            <div class="col-sm-6 mt-2">
              <div class="form-group-inline-flex mr-3" v-for="showIn in showInOptions" :key="showIn.value"> 
                <input type="checkbox" v-model="excursionInfo.publicTransportLocationDisplayIn" :id="showIn.value" :value="showIn.value"> 
                <label class="ml-3" :for="showIn.value"> {{ showIn.label }} </label> 
              </div>
            </div>
          </div>

        </div> <!-- location details -->

        <div class="form-section">
          <h5>Ratings & Links</h5> 
          <hr/>             
          <div class="form-group row mb-3">
            <label for="regionName" class="col-sm-3 col-form-label">Star Rating</label>
            <div class="col-sm-6">
                <input type="number" class="form-control" id="regionName" v-model="excursionInfo.starRating" placeholder="Enter star rating out of 10"   :min="0" :max="10" step="0.1"
                @input="validateStarRating">
                <div v-if="starRatingError" class="text-danger mt-2">Star rating must be between 0 and 10.</div>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="regionName" class="col-sm-3 col-form-label">Location Rating</label>
            <div class="col-sm-6">
                <input type="number" step="0.1" min="0" max="10" class="form-control" id="regionName" v-model="excursionInfo.locationRating" placeholder="Enter location rating out of 10"    @input="validateLocationRating">
                <div v-if="locationRatingError" class="text-danger mt-2">Location rating must be between 0 and 10.</div>
            </div>
          </div>
          <h5>External Site's rating</h5>
          <hr/>
          <div class="form-group row mb-3">
            <label for="regionName" class="col-sm-3 col-form-label"></label>
            <div class="col-sm-6">
              <div v-for="(external, index) in this.excursionInfo.externalRatings" :key="index" class="mb-2">
                <input type="text" id="external_name" class="form-control mb-1" v-model="external.name" placeholder="Enter Site's name">
                <input type="text" class="form-control mb-1" v-model="external.link" placeholder="Enter Site's link">                
                <input type="number" step="0.1" min="0" max="10" class="form-control mb-1" v-model="external.rank" placeholder="Enter the rating out of 10"   @input="validateExternalRating(index)">
                <div v-if="external.rankError" class="text-danger mt-2">External rating must be between 0 and 10.</div>
              </div>
              <a href="#" v-if="excursionInfo.externalRatings.length > 1" @click.prevent="confirmRemoveExternal(index)" class="btn mb-3 text-decoration-none" style="background-color: #dc3545 !important; padding: 8px;
    font-size: 14px;"><font-awesome-icon :icon="['fas', 'minus']" class="me-2" />Remove</a>
            </div>
            <div class="col-sm-3">
              <a href="#" @click.prevent="addexternal" class="btn p-2"> <font-awesome-icon
                    :icon="['fas', 'fa-plus']"
                    class="me-2"
                  />Add More</a>              
            </div>
          </div>  
        </div><!-- ratings -->

        <div class="form-section">
          <h5>Contact Information</h5>   
          <hr/>         
          <div class="form-group row mb-3">
            <label for="contactNumber" class="col-sm-3 col-form-label">Phone Number </label>
            <div class="col-sm-6">                
              <input type="tel" class="form-control" id="contactNumber" v-model="excursionInfo.contactNumber" placeholder="Enter hotel phone number">              
              <div class="text-danger" v-for="(error, index) in v$.excursionInfo.contactNumber.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="public-transport-show-in" class="col-sm-3 col-form-label">Phone Number displayIn</label>              
            <div class="col-sm-6 mt-2">
              <div class="form-group-inline-flex mr-3" v-for="showIn in showInOptions" :key="showIn.value"> 
                <input type="checkbox" v-model="excursionInfo.contactNumberDisplayIn" :id="showIn.value" :value="showIn.value"> 
                <label class="ml-3" :for="showIn.value"> {{ showIn.label }} </label> 
              </div>
            </div>
          </div>          

        </div> <!-- contact info -->
        <div class="form-section">          
          <h5>Additional Information</h5>
          <hr/>
          
          <div class="form-group row mb-3">
            <label for="hotelLocation" class="col-sm-3 col-form-label">Inclusions </label>
            <div class="col-sm-6">
              <textarea type="text" class="form-control" id="hotelLocation" v-model="excursionInfo.inclusions" placeholder="Enter inclusions"></textarea>
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="hotelLocation" class="col-sm-3 col-form-label">Notes for Customer </label>
            <div class="col-sm-6">
              <textarea type="text" class="form-control" id="hotelLocation" v-model="excursionInfo.customerNote" placeholder="Notes to customer "></textarea>
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="hotelLocation" class="col-sm-3 col-form-label">Notes for Agent </label>
            <div class="col-sm-6">
              <textarea type="text" class="form-control" id="hotelLocation" v-model="excursionInfo.agentNote" placeholder="Notes to Agent"></textarea>
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="hotelLocation" class="col-sm-3 col-form-label">Notes for Admin </label>
            <div class="col-sm-6">
              <textarea type="text" class="form-control" id="hotelLocation" v-model="excursionInfo.adminNote" placeholder="Notes to Admin"></textarea>
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="hotelLocation" class="col-sm-3 col-form-label">Notes for Sub-Admin </label>
            <div class="col-sm-6">
              <textarea type="text" class="form-control" id="hotelLocation" v-model="excursionInfo.subAdminNote" placeholder="Notes to sub-admin"></textarea>
            </div>
          </div>  
          
        </div>
        <div class="form-section">
          <h5>Currency & Partners Information</h5>
          <hr/>
          
          <div class="form-group row mb-3">
    <label for="currencies" class="col-sm-3 col-form-label">Select Currency</label>                    
    <div class="col-sm-6">
      <select 
        v-model="excursionInfo.currency"  
        class="form-control" 
        id="currencies"
      >
        <option disabled value="">-- Select currency --</option>
        <option 
          v-for="currency in currencies" 
          :key="currency" 
          :value="currency"
        >
          {{ currency }}
        </option>
      </select>
    </div>
  </div>

          <div class="form-group row mb-3">
            <label for="partners" class="col-sm-3 col-form-label">Select Partner </label>                    
            <div class="col-sm-6">
                <select v-model="excursionInfo.partner"  class="form-control" id="partners" required>
                    <option disabled value="">--Please select a partner--</option>
                    <option v-for="partner in partners " :key="partner.partnerCode" :value="partner.partnerCode">
                        {{ partner.partnerName }}
                    </option>
                </select>
            </div>
          </div>

        </div>
        <div class="form-section">
          <div class="form-group row mb-3 d-flex align-items-center">
            <label for="status" class="col-sm-3 col-form-label">Status</label>                
            <div class="col-sm-2 d-flex g-1 m-0">
                <input type="radio" v-model="excursionInfo.status" class="form-check-input" id="statusActive" value="Active">
                <label for="statusActive" class="form-check-label">Active</label>
            </div>
            <div class="col-sm-2 d-flex g-1 m-0">
                <input type="radio" v-model="excursionInfo.status" class="form-check-input" id="statusInactive" value="Inactive">
                <label for="statusInactive" class="form-check-label">Inactive</label>
            </div>            
        </div>  

        </div>  
        
        <hr>
        <div class="form-actions">
          <button type="submit" class="btn btn-success" @click="saveHotels" v-if="!isEdit" :disabled="!isFormValid"> <font-awesome-icon :icon="['fas', 'fa-save']" class="me-2" />  Save & Next To Add Rooms </button>
          <button type="submit" class="btn btn-success" @click="updateHotels" v-if="isEdit" > <font-awesome-icon :icon="['fas', 'fa-save']" class="me-2" /> Update Hotels </button>
          <button type="button" class="btn btn-light" @click="cancel">  <font-awesome-icon :icon="['fas', 'xmark']" /> Cancel</button>
        </div>
        <br>
      </div>  
      <DeleteModal
      :visible="showDeleteModal"
      @confirmDelete="deleteHotel"
      @cancelDelete="showDeleteModal = false"
    />    
    <DeleteModal
  :visible="showDeleteRemoveModal"
  @confirmDelete="removeexternal"
  :actionType="'Remove'"
  @cancelDelete="showDeleteRemoveModal = false"
  />  
  <div class="d-flex justify-content-between mt-3" v-if="showHotelListView">
      <div class="d-flex align-items-center mt-0">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredRegistration.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination mb-0" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>   
    </div>
  </div> <!-- end of container -->
</template>
<script>
  
  import axios from 'axios';
  import { API_CONFIG } from '@/apiConfig';
  import { required, between, numeric, minLength, maxLength } from '@vuelidate/validators'
  import useVuelidate from '@vuelidate/core'
  import DeleteModal from './DeleteModal.vue';
  import currencies from '@/data/currencies';

  export default {
    components: {
    DeleteModal,
  },
    data()  {
      return {   
        currencies,     
        excursionType : "hotel",
        excursions : [],
        hotelImages : [],
        updateExcursionInfo : {},        
        excursionInfo : {
          destinationId : "",
          //cityName : "",
          cityId : "",
          hotelName: "",
          hotelCode : "",
          hotelDesc : "",
          hotelAddress : "",
          hotelLocationLatitude : "",
          hotelLocationLongitude : "",
          publicTransportNearBy : "",
          publicTransportDisplayIn : [],
          publicTransportLocationLatitude : "",
          publicTransportLocationLongitude : "",
          publicTransportLocationDisplayIn : [],
          starRating : "",
          locationRating : "",
          externalRatings : [{ }],
          contactNumber : "",
          contactNumberDisplayIn : [],
          inclusions : "",
          customerNote : "",
          agentNote : "",
          adminNote : "",
          subAdminNote: "",
          roomDetails : [],
          currency: "",
          partner : "",
          status: "Active",
        }, 
        searchQuery: "",
        currentPage: 1, 
        itemsPerPage: 10,  
        starRatingError: false,
      locationRatingError: false,
      externalRatingErrors: [],       
        destinations: [],
        cities: [],
        note : { text: '' },
        external : { 
          name :"",
          link : "",
          rank : ""
        },        
        partners : [],
        statusOptions : [
          { value: 'active', label : 'Active'},
          { value: 'inactive', label: 'InActive' }
        ],
        showInOptions: [ 
          { value: 'quotation', label: 'Quotation Itinerary' }, 
          { value: 'final', label: 'Final  Itinerary' }, 
          { value: 'website', label: 'Website' }, 
        ],
        errorMessage : "",
        latitudeErrorMessage : "",
        longitudeErrorMessage : "",
        showHotelListView : true,
        showCreateHotel: false,
        isEdit : true,
        showDeleteModal: false,
        hotelToDelete: null,
        showDeleteRemoveModal: false,
      }
    },
    validations() {
      return {
        excursionInfo: {
          destinationId : { required },
          //cityName : { required },
          cityId : { required },
          hotelName : { required },
          hotelCode : { required },          
          contactNumber: { numeric, minLength: minLength(10), maxLength:maxLength(10) },
          hotelLocationLatitude: { between: between(-90, 90) },
          hotelLocationLongitude: { between: between(-180, 180) },
          publicTransportLocationLatitude :  { between: between(-90, 90) },
          publicTransportLocationLongitude : { between: between(-180, 180) },

        }
      }
    },
    setup() {
      const v$ = useVuelidate();
      return { v$ };
    },
    computed: {
      filteredRegistration() {
    return this.excursions.filter(excursion => {
      const searchQueryLower = this.searchQuery.toLowerCase();
      return (
        excursion.hotelName.toLowerCase().includes(searchQueryLower) ||
        excursion.hotelCode.toLowerCase().includes(searchQueryLower) ||
        excursion.destinationName.toLowerCase().includes(searchQueryLower) ||
        excursion.cityName.toLowerCase().includes(searchQueryLower)
      );
    });
  },
  paginatedUsers() {
    const pageSize = Number(this.itemsPerPage) || 10;
    const currentPage = Number(this.currentPage) || 1;
    
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    
    if (!this.filteredRegistration.length) return [];
    
    return this.filteredRegistration.slice(startIndex, endIndex);
  },
  
  totalPages() {
    const pageSize = Number(this.itemsPerPage) || 10;
    return Math.max(1, Math.ceil(this.filteredRegistration.length / pageSize));
  },
      isFormValid() {
        return (
          this.excursionInfo.destinationId &&
          this.excursionInfo.cityId &&
          this.excursionInfo.hotelName &&
          this.excursionInfo.hotelCode 
        );
      },
    },  
    watch: {
  itemsPerPage(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.resetPagination();
    }
  },
  searchQuery() {
    this.resetPagination();
  },
  filteredRegistration: {
    handler() {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages || 1;
      }
    },
    immediate: true
  }
},  
    methods : {
      resetPagination() {
    this.currentPage = 1;
    
    this.$forceUpdate();
  },
  
  goToPage(page) {
    const pageNum = Number(page);
    if (pageNum >= 1 && pageNum <= this.totalPages) {
      this.currentPage = pageNum;
    }
  },

getPageRange() {
    const range = [];
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    const delta = 2;

    if (currentPage > delta + 1) {
      range.push(1);
      if (currentPage > delta + 2) range.push('...');
    }

    for (let i = Math.max(1, currentPage - delta); i <= Math.min(totalPages, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage < totalPages - delta - 1) {
      if (currentPage < totalPages - delta - 2) range.push('...');
      range.push(totalPages);
    }

    return range;
  },
      clearSearch() {
    this.searchQuery = ""; 
  },
      validateStarRating() {
      const value = parseFloat(this.excursionInfo.starRating);
      if (isNaN(value) || value < 0 || value > 10) {
        this.starRatingError = true;
      } else {
        this.starRatingError = false;
      }
    },
    validateLocationRating() {
      const value = parseFloat(this.excursionInfo.locationRating);
      if (isNaN(value) || value < 0 || value > 10) {
        this.locationRatingError = true;
      } else {
        this.locationRatingError = false;
      }
    },
    validateExternalRating(index) {
      const value = parseFloat(this.excursionInfo.externalRatings[index].rank);
      if (isNaN(value) || value < 0 || value > 10) {
        this.excursionInfo.externalRatings[index].rankError = true;
      } else {
        this.excursionInfo.externalRatings[index].rankError = false;
      }
    },
      onFileSelected(field, event) {
        const selectedFiles = event.target.files;
        //to iterate each files selected 
        Array.from(selectedFiles).forEach((file) => { 
          this.hotelImages.push( file.name );          
        });
      },
      removeFile(idx) {
        this.hotelImages.splice(idx,1);
        //const dt = new DataTransfer();
        //this.hotelImages.forEach( _file => dt.items.add(_file) );
        //this.hotelImages = dt.files;
      },
      async fetchDestinations() {
        this.loading = true;
        try {
            const resp = await axios.get(API_CONFIG.getEndpoint('fetchDestinations'));                
            this.destinations = resp.data;
        } catch (error) {
            console.log('Error fetching destinations list', error);
            this.error = 'Failed to fetch destinations list. Please try again.';
        } finally {
            this.loading = false;
        }
      },
      async updateHotels() {
        try {  
          const response = await axios.post(API_CONFIG.getEndpoint('createExcursion') +`/${this.excursionInfo.id}`
            +`?excursionType=${this.excursionType}`
            +`&code=${this.excursionInfo.hotelCode}`
            +`&destinationId=${this.excursionInfo.destinationId}`              
            +`&cityId=${this.excursionInfo.cityId}`,  this.excursionInfo );

          this.excursionInfo = response.data;
          this.showCreateHotel = false;
          this.$router.push({              
            path: `/rooms/${this.excursionInfo.id}`
          });
        } catch(error) {
          console.log("Update hotels failed" + error);
        }
      },
      async saveHotels() {
        //alert("save called.." + this.excursionInfo.destinationId);
        this.v$.$touch();
        if (this.v$.$invalid) {
            alert("Form is invalid");
            return;
        }
        try {    
             const response = await axios.post(API_CONFIG.getEndpoint('createExcursion') 
               +`?excursionType=${this.excursionType}`
               +`&code=${this.excursionInfo.hotelCode}`
               +`&destinationId=${this.excursionInfo.destinationId}`              
               +`&cityId=${this.excursionInfo.cityId}`,  this.excursionInfo );

            this.excursionInfo = response.data;
            console.log("Excursion details",this.excursionInfo);
            this.showCreateHotel = false;
            this.$router.push({              
              path: `/rooms/${this.excursionInfo.id}`
            });

        }catch(error) {
          console.log("Error while saving hotel details",error);
        }
      },
      
      async fetchExcursions() {
        try {
          const response = await axios.get(API_CONFIG.getEndpoint('fetchExcursions') +'/type/' + this.excursionType);          
          this.excursions = response.data;
        }catch(error) {
          console.log("Error ::" , error);
        }
      },

      async onChangeByDestination(){
        this.fetchCitiesByDestination();
        this.fetchPartnersByDestination();
      },

      async fetchCitiesByDestination() {        
        try {
          const response = await axios.get(API_CONFIG.getEndpoint('citiesByDestination')  + `/${this.excursionInfo.destinationId}`); 
          this.cities =  response.data;
          if (this.cities.length === 0) {
              this.errorMessage = "No cities available for the selected destination.";
          } else {
              this.errorMessage = "";
          }
        } catch (error) {
          console.log("Error fetching cities list ", error);
          this.error = "Failed to fetch cities list. Please try again !!";
        }finally {
          //this.loading = false;
        }
      },      
      
      addexternal(){
        this.excursionInfo.externalRatings.push({});
      },
      confirmRemoveExternal(index) {
      this.removeIndex = index;
      this.showDeleteRemoveModal = true;
    },
    removeexternal() {
      if (this.removeIndex !== null) {
        this.excursionInfo.externalRatings.splice(this.removeIndex, 1);
        this.showDeleteRemoveModal = false;
        this.removeIndex = null;
      }
    },
      showForm(action, excursionInfo = null) {
        if( action === 'create') {
            this.formTitle =  'Add/Update Hotel Details';            
            this.showHotelListView = false;
            this.showCreateHotel = true;
            this.isEdit = false;
            this.resetHotelRegistration();
            //this.fetchPartnersByDestination();
        } else if (action === 'edit' && excursionInfo) {
            this.formTitle = 'Edit Airport details';
            this.excursionInfo = {...excursionInfo };
            this.isEdit = true;            
            this.showCreateHotel = true;
            this.showHotelListView = false;   
            this.fetchCitiesByDestination();
            this.fetchPartnersByDestination();         
        } else if ( action === 'add-rooms' && excursionInfo) {
          this.$router.push({
            path: `/rooms/${excursionInfo.id}`
          });
        }
       else if (action === 'upload-picture' && excursionInfo) {
        this.$router.push({
        path: `/upload-picture/${excursionInfo.id}`
          });
      }
                         
      },
      resetHotelRegistration(){
        this.excursionInfo = {
          destinationId : "",          
          cityId : "",
          hotelName: "",
          hotelCode : "",
          hotelDesc : "",
          hotelAddress : "",
          hotelLocationLatitude : "",
          hotelLocationLongitude : "",
          publicTransportNearBy : "",
          publicTransportDisplayIn : [],
          publicTransportLocationLatitude : "",
          publicTransportLocationLongitude : "",
          publicTransportLocationDisplayIn : [],
          starRating : "",
          locationRating : "",
          externalRatings : [{ }],
          contactNumber : "",
          contactNumberDisplayIn : [],
          inclusions : "",
          customerNote : "",
          agentNote : "",
          adminNote : "",
          subAdminNote : "",
          roomDetails : [],
          status: "Active",
        }
      },
      cancel() {          
          this.showHotelListView = true;
          this.showCreateHotel = false;
          this.resetHotelRegistration();
      },
       async fetchPartnersByDestination() {
         try {
             const response = await axios.get(API_CONFIG.getEndpoint('searchPartners') +  `/${this.excursionInfo.destinationId}`);
             this.partners = response.data;
         }catch(error) {
             console.error('Error fetching partners list', error);
         }
       },
       
      // async fetchExcursionById(excursion_id) {
      //   try {
      //     const response = await axios.get(API_CONFIG.getEndpoint('fetchExcursions') +'/' +excursion_id );
      //     this.updateExcursionInfo = response.data;
          
      //     console.log(" fetchExcursionById ::" , this.updateExcursionInfo);

      //   }catch(error) {
      //     console.log("Error ::" , error);
      //   }
      // },

  confirmDeleteHotel(hotel) {
    this.hotelToDelete = hotel.id; 
    this.showDeleteModal = true;
  },
  async deleteHotel() {
    if (!this.hotelToDelete) return;
    try {
      await axios.delete(API_CONFIG.getEndpoint("fetchExcursions") + `/${this.hotelToDelete}`);
      this.excursions = this.excursions.filter(hotel => hotel.id !== this.hotelToDelete);
      this.fetchExcursions();
    } catch (error) {
      console.error("Unable to delete hotel", error);
    } finally {
      this.showDeleteModal = false;
      this.hotelToDelete = null;
    }
  },
  cancelDelete() {
    this.showDeleteModal = false;
    this.hotelToDelete = null;
  },


    },
    mounted() {
      this.fetchExcursions();
      this.fetchDestinations();
      //this.fetchPartnersByDestination();
    }

  };

</script>

<style scoped>
  .button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 5px; /* Adjust the spacing between the icon and text */
  width: 100%; /* Make sure the button scales properly */
  height: 100%;
}
  .ml-3  {
    margin-left: 0.5rem !important;        
  }
  .mr-3 {        
      margin-right: 1rem !important;        
  }
  .container {
      margin-top: 20px;
  }   
  h3 {
      margin-bottom: 20px;
  }
  .d-flex {
      margin-top: 20px;
  }
  .form-section {
    margin-bottom: 20px;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-group label {
    display: block;
  }
  .form-group input,
  .form-group select {
    /* width: 100%; */
    padding: 8px;
    box-sizing: border-box;
  }
  .form-group-inline-flex {
    display: inline-flex;
  }
  .form-actions {
    display: flex;
    justify-content: flex-end;
  }
  .btn {
    padding: 10px 20px;
    margin-left: 10px;
  }
  .text-danger {
    color: red;
    font-size: 0.875em;
  }

  
.btn1{
  color: #ffffff;
  background-color: #7978E9;
  border-radius:6px;
}
.btn1:hover{
  background-color: #4B49AC;
}

</style>