
<template>
  <div class="container">
      <div class="card-header" v-if="showListView">
    <div class="d-flex justify-content-between align-items-center" style="margin-top: 0px">
        <h4>Manage Airport Transfer </h4>   
        <div style="display: flex !important; gap: 20px !important">
          <div v-if="showListView">
    <form class="search-input" style="background-color:#F5F7FF;">
      <button>
          <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
              <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
      </button>
      <input class="input" placeholder="Search" type="text" v-model="searchQuery">
      <button class="reset" type="reset" @click="clearSearch">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
      </button>
  </form>
  </div> 
  <button type="button" class="btn1  me-2" @click="showForm('create')"><font-awesome-icon :icon="['fas', 'fa-plus']" class="me-2" />New Airport Transfer </button>
        </div>     
      </div>
      </div>
      <div class="table-container"> 
      <div v-if="showListView">
      <!--  <h4> Airport Transfers List</h4> -->
          <table class="table table-bordered mt-3 custom-table-width">
            <thead>
              <tr>
                   <th>SI.No</th>
                  <th>Destination Name</th>
                  <th>City Name</th>
                  <th>Airport</th>
                  <th>CarType</th>
                  <th>Transfer Direction</th>
                  <th>TransferMode</th>
                  <th>Partner</th>
                  <th>Currency</th>
                  <th>Actual Price</th>
                  <th>Company Markup In</th>
                  <th>Company Markup</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th style="width: 100px;">Actions</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(excursion, index) in paginatedUsers" :key = "excursion.id">
                <tr v-for="(transportDetail, tIndex) in excursion.transportDetails" :key="tIndex"> 
                  <td>{{ (currentPage - 1) * itemsPerPage + (index + 1) }}</td>
                  <td>{{ excursion.destinationName }}</td>
                    <td>{{ excursion.cityName }}</td>
                    <td>{{ excursion.airportName }}</td>                
                    <td>{{ transportDetail.carType  }}</td>
                    <td>{{ transportDetail.transferFrom  }}</td>
                    <td>{{ transportDetail.transferMode  }}</td>
                    <td>{{ transportDetail.partner  }}</td>
                    <td>{{ transportDetail.currency  }}</td>
                    <td>{{ transportDetail.actualPrice  }}</td>
                    <td>{{ transportDetail.companyMarkupType  }}</td>
                    <td>{{ transportDetail.companyMarkup  }}</td>
                    <td>{{ transportDetail.finalPrice  }}</td>
                    <td>{{ excursion.status  }}</td>                
                    <td>
                        <div style="display: flex;">

                          <a href="#" @click="showForm('edit',excursion)" class="text-warning mr-3 faq-button mt-1" role="button"> <font-awesome-icon :icon="['fas', 'fa-edit']"  class="edit" /><span class="tooltip">Edit</span> </a>
                          <a @click="confirmDeleteExcursion(excursion.id)" class="text-danger faq-button mt-1" role="button"> <font-awesome-icon :icon="['fas', 'fa-trash']"  class="delete" /> <span class="tooltip">Delete</span></a>

                          <!-- <a @click="showForm('create',excursion)" class="text-success mr-3" role="button"> <i class="bi bi-plus"></i></a> 
                          <a @click="showForm('edit',excursion)" class="text-warning mr-3" role="button"> <i class="bi bi-pencil"></i> </a>
                          <a @click="deleteTransport(excursion.id,tIndex)" class="text-danger" role="button"> <i class="bi bi-trash"></i> </a> -->
                          
                          <!-- <button @click="showForm('add-cities',excursion)" class="btn btn-sm btn-success "><i class="bi bi-plus"></i></button>
                          <button @click="showForm('edit',excursion)" class="btn btn-sm btn-warning "><i class="bi bi-pencil"></i></button>                        
                          <button @click="deleteTransport(excursion.id)" class="btn btn-sm btn-danger"><i class="bi bi-trash"></i></button>                         -->
                        </div>
                    </td>                            
                </tr>
              </template>
            </tbody>
          </table>
      </div>
      </div>
      <div v-if="!showListView">
        <div class="form-section">                      
          <!-- <h4>{{ formTitle }}</h4> -->
          <div class="form-group row mb-3">
            <div class="col-sm-1"></div>
            <label for="destination" class="col-sm-3 col-form-label">Select Destination </label>                    
            <div class="col-sm-6">
                <select v-model="excursionInfo.destinationId"  @change="onChangeByDestination" class="form-control" id="destination" required>
                    <option disabled value="">--Please select a destination--</option>
                    <option v-for="destination in destinations.list" :key="destination.id" :value="destination.id">
                        {{ destination.destinationName }}
                    </option>
                </select>                
                <div class="text-danger" v-for="(error, index) in v$.excursionInfo.destinationId.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
          </div>

          <div class="form-group row mb-3">
            <div class="col-sm-1"></div>
            <label for="city" class="col-sm-3 col-form-label">Select City <span style="color:red !important">*</span></label>                    
            <div class="col-sm-6">
              <select v-model="excursionInfo.cityId" class="form-control" id="city">
                  <option disabled value="">-- Please select a city --</option>
                  <option v-for="city in cities.list" :key="city.id" :value="city.id">
                      {{ city.cityName }}
                  </option>
              </select>              
              <div class="text-danger" v-for="(error, index) in v$.excursionInfo.cityId.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>                    
          </div>
          
          <div class="form-group row mb-3">
            <div class="col-sm-1"></div>
            <label for="city" class="col-sm-3 col-form-label">Select Airport <span style="color:red !important">*</span></label>
            <div class="col-sm-6">
              <select v-model="excursionInfo.airportId" class="form-control" id="city">
                  <option disabled value="">-- Please select a airport --</option>
                  <option v-for="airport in airports.list" :key="airport.id" :value="airport.id">
                      {{ airport.airportName }}
                  </option>
              </select>
              <div class="text-danger" v-for="(error, index) in v$.excursionInfo.airportId.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>              
            </div>                    
          </div>         
        </div>
        <div v-for="(transportInfo1, index) in excursionInfo.transportDetails" :key="index" class="mb-3">
          <!-- <div>            
            <h4> 
              <label for="" class="col-form-label"> Car Type {{ transportInfo.carType }}</label>
              <a href="#" @click.prevent="toggleForm(index)" v-if="excursionInfo.transportDetails.length < 0">
                {{ formVisible[index] ? 'Hide' : 'Show' }} Transport Details {{ index + 1 }}
              </a>
              <a href="#" @click.prevent="addMoreTransportDetails(index)" class="ml-3"> Add More </a>
              <a href="#" @click.prevent="removeTransportDetail(index)" class="ml-3 text-danger">Remove</a>
            </h4>
          </div> -->
          <div v-if="formVisible[index] || excursionInfo.transportDetails.length === 1" >
            
            <div class="container border p-3">
              <div class="form-section"> 
                <div class="form-group row mb-3">
                    <label for="carType" class="col-sm-3 col-form-label">Car Type </label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" id="carType" v-model="transportInfo1.carType" placeholder="Enter Car type">
                        <div class="text-danger" v-for="(error, eindex) in v$.excursionInfo.transportDetails.$each.$response.$errors[index].carType" :key="eindex">
                          <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                </div>
                
              </div> 
              <div class="form-section">
                <h5>Price Details </h5>
                <hr/>
                <div class="form-group row mb-3">
                  <label for="partners" class="col-sm-3 col-form-label">Select partner </label>                    
                  <div class="col-sm-3">
                      <select v-model="transportInfo1.partner"  class="form-control" id="partners" >
                          <option disabled value="">--Please select a partner--</option>
                          <option v-for="partner in partners.list " :key="partner.partnerCode" :value="partner.partnerCode">
                              {{ partner.partnerName }}
                          </option>
                      </select>
                  </div>                  
                  <label for="partners" class="col-sm-3 col-form-label">Select Currency </label>                    
                  <div class="col-sm-3">
                    <select 
    v-model="transportInfo1.currency"  
    class="form-control" 
    id="currency"
  >
    <option disabled value="">-- Select currency --</option>
    <option 
      v-for="currency in currencies" 
      :key="currency" 
      :value="currency"
    >
      {{ currency }}
    </option>
  </select>
                  </div>
                </div>

                <div class="form-group row mb-3">
                  <label for="actualPrice" class="col-sm-3 col-form-label">Actual Price </label>
                  <div class="col-sm-3">
                    <input type="number" step="0.01" min="0" class="form-control" id="actualPrice" 
       v-model="transportInfo1.actualPrice" placeholder="Enter actual price"
       @input="limitDecimals(transportInfo1, 'actualPrice')">                  </div>                  
                  <label for="companyMarkupType" class="col-sm-3 col-form-label">Select Company Markup In </label>
                  <div class="col-sm-3">
                      <select v-model="transportInfo1.companyMarkupType"  class="form-control" id="companyMarkupType" >
                          <option disabled value="">-- Please select a markup type --</option>
                          <option value="percentage">Percentage</option>
                          <option value="absolute">Absolute</option>
                      </select>  
                  </div>
                </div>

                <div class="form-group row mb-3">
                    <label for="companyMarkup" class="col-sm-3 col-form-label">Company Markup </label>
                    <div class="col-sm-3">
                      <input type="number" min="0" class="form-control" id="companyMarkup" 
       v-model="transportInfo1.companyMarkup" placeholder="Enter price"
       @input="limitDecimals(transportInfo1, 'companyMarkup')">                    </div>                
                    <label for="finalPrice" class="col-sm-3 col-form-label">Final Price </label>
                    <div class="col-sm-3">
                        <input type="number" step="0.01" class="form-control" id="finalPrice" :value="calculateFinalPrice(transportInfo1)" readonly>
                    </div>
                </div>
                <!-- <div class="form-group row mb-3">                  
                  <input hidden type="text" v-model="excursionInfo.title" :value="computedTitles[index]" class="form-control" id="_title">                  

                </div>               -->
                <div class="form-group row mb-3">         
                  <label for="transferFrom" class="col-sm-3 col-form-label">Transfer From </label>
                  <div class="col-sm-3">
                      <select v-model="transportInfo1.transferFrom"  class="form-control" id="transferFrom" >
                          <option disabled value="">--Transfer from --</option>
                          <option value="Airport to Hotel">Airport to Hotel</option>
                          <option value="Hotel to Airport">Hotel to Airport </option>
                      </select>  
                  </div>

                  <label for="transferMode" class="col-sm-3 col-form-label">Transfer Mode </label>
                  <div class="col-sm-3">
                      <select v-model="transportInfo1.transferMode"  class="form-control" id="transferMode" >
                          <option disabled value="">--Transfer Mode--</option>
                          <option value="Private">Private</option>
                          <option value="Shared">Shared</option>
                      </select>  
                  </div>
                </div>
              </div><!-- End of price details -->

              <div class="form-section">
                <h5>Meeting Details</h5>
                <div class="form-group row mb-3">
                  <label for="finalPrice" class="col-sm-3 col-form-label">Pickup Time </label>
                  <div class="col-sm-6">
                      <input type="text" v-model="transportInfo1.pickupTime" class="form-control" id="finalPrice">
                  </div>       
                </div>
                <div class="form-group row mb-3">
                  <label for="pickupTimeDisplayIn" class="col-sm-3 col-form-label" >Display In</label>
                  <div class="col-sm-9">
                    <div class="form-group-inline-flex mr-3 col-sm-3 d-flex" v-for="showIn in showInOptionsPT" :key="showIn.key">
                      <input type="checkbox" v-model="transportInfo1.pickupTimeDisplayIn" class="mr-3"
                             :id="showIn.key" :value="showIn.value">
                      <label :for="showIn.key" class="text-nowrap"> {{ showIn.label }} </label>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-3">
                  <label for="local-partner-no" class="col-sm-3 col-form-label">Local Partner Number </label>
                  <div class="col-sm-6">
                      <input type="number" v-model="transportInfo1.partnerNumber" class="form-control" id="local-partner-no">
                  </div>       
                </div>
                <div class="form-group row mb-3">
                  <label for="local-partner-number-display-in" class="col-sm-3 col-form-label">Local Partner Number Display In</label>
                  <div class="col-sm-9">
                    <div class="form-group-inline-flex mr-3 col-sm-3 d-flex" v-for="showIn in showInOptionsLP" :key="showIn.key">
                      <input type="checkbox" v-model="transportInfo1.partnerNumberDisplayIn" class="mr-3"
                             :id="showIn.key" :value="showIn.value">
                      <label :for="showIn.key" class="text-nowrap"> {{ showIn.label }} </label>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-3">
                  <label for="meetingPoint" class="col-sm-3 col-form-label">Meeting Point </label>
                  <div class="col-sm-6">
                      <input type="text" v-model="transportInfo1.meetingPoint" class="form-control" id="meetingPoint" >
                  </div>       
                </div>
                <div class="form-group row mb-3">
                  <label for="meeting-point-display-in" class="col-sm-3 col-form-label">Meeting Point Display In</label>
                  <div class="col-sm-9">
                    <div class="form-group-inline-flex mr-3 col-sm-3 d-flex" v-for="showIn in showInOptions" :key="showIn.key">
                      <input type="checkbox" v-model="transportInfo1.meetingPointDisplayIn"
                             :id="showIn.key" :value="showIn.value" class="mr-3">
                      <label :for="showIn.key" class="text-nowrap"> {{ showIn.label }} </label>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-3">
                  <label for="meeting-point-google-link" class="col-sm-3 col-form-label">Meeting Point Google Link </label>
                  <div class="col-sm-6">
                    <textarea type="text" class="form-control" id="description" v-model="transportInfo1.meetingPointGoogleLink" placeholder="Enter Link"></textarea>
                  </div>
                </div>
              </div>  <!-- End of meeting details -->

              <div class="form-section">
                <div class="form-group row mb-3">
                  <label for="description" class="col-sm-3 col-form-label">Description </label>
                  <div class="col-sm-6">
                    <textarea type="text" class="form-control" id="description" v-model="transportInfo1.description" placeholder="Enter description"></textarea>
                  </div>
                </div>                           
              </div>
            </div>
          </div>
        </div> 
        <div class="form-group row mb-3 d-flex align-items-center">
            <label for="status" class="col-sm-3 col-form-label">Status</label>                
            <div class="col-sm-2">
                <input type="radio" v-model="excursionInfo.status" class="form-check-input mr-3" id="statusActive" value="Active">
                <label for="statusActive" class="form-check-label">Active</label>
            </div>
            <div class="col-sm-2">
                <input type="radio" v-model="excursionInfo.status" class="form-check-input mr-3" id="statusInactive" value="Inactive">
                <label for="statusInactive" class="form-check-label">Inactive</label>
            </div>            
        </div> 
        <hr>
        <div class="form-actions">
          <button type="submit" class="btn btn-success"  style="margin-right: 10px;" @click="addTransport" v-if="!isEdit" > <font-awesome-icon :icon="['fas', 'fa-save']" class="me-2" />Save </button>
          <button type="submit" class="btn btn-success me-2" @click="updateTransport" v-if="isEdit" ><font-awesome-icon :icon="['fas', 'file-pen']" /> Update </button>
          <button type="button" class="btn btn-light" @click="cancel"><font-awesome-icon :icon="['fas', 'xmark']" /> Cancel</button>
        </div>
        <br>
      </div>   
  <DeleteModal
  :visible="showDeleteModal"
  @confirmDelete="deleteExcursion"
  @cancelDelete="showDeleteModal = false"
  />   
  <div class="d-flex justify-content-between mt-3" v-if="showListView">
      <div class="d-flex align-items-center  mt-0">
        <label for="itemsPerPage" class="me-2" style="font-size: 14px;">Showing</label>
        <select id="itemsPerPage" class="form-select" v-model="itemsPerPage" @change="resetPagination" style="font-size: 14px;">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        <span class="ms-2 text-nowrap" style="font-size: 14px;">of total <span style="color:#4B49AC">{{ filteredExcursions.length }} entries</span> </span>
      </div>
      <nav aria-label="Page navigation">
        <ul class="pagination mb-0" >
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="goToPage(currentPage - 1)" style="font-size: 14px;">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="(page) in getPageRange()" :key="page" :class="{ disabled: page === currentPage }">
            <a class="page-link" href="#" @click.prevent="page !== '...' && goToPage(page)" style="font-size: 14px;">
                {{ page }}
            </a>
        </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="goToPage(currentPage + 1)" style="font-size: 14px;">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>   
    </div>
  </div> <!-- end of container -->
</template>
<script>
    import axios from 'axios';
    import { reactive,  onMounted, ref, computed, watch} from 'vue';
    import { API_CONFIG } from '@/apiConfig';
    import { required, helpers, numeric, minValue} from '@vuelidate/validators'
    import useVuelidate from '@vuelidate/core'
    import 'bootstrap-icons/font/bootstrap-icons.css'
    import DeleteModal from './DeleteModal.vue';
    import currencies from '@/data/currencies';
    
    export default {
      components: {
        DeleteModal,
        },

        setup() {
            
            const excursionType = ref("airport");
            // const router = useRouter();
            
            const excursions =  reactive({ list: [] });
            const destinations =  reactive({ list: [] });            
            const cities = reactive( { list :[] });            
            const airports = reactive({ list: [] });
            const transportDetails = reactive({ list: [] });
            const partners = reactive({ list: [] });
            const excursionInfo = reactive({ 
              destinationId : '',
              cityId : '',
              airportId : '',
              title :  '',
              status: 'Active',
              transportDetails : [{}]
            });
            const showDeleteModal = ref(false);
            const excursionToDelete = ref(null);
            const searchQuery = ref('');
            const currentPage = ref(1);
            const itemsPerPage = ref(10);
           
            // const excursionInfo = reactive({ ...initialExcursionInfo });            
            const maxValue = 5;
            const items = ref([ { 
              content: 'This is the content for Accordion Item #1' }, 
              { content: 'This is the content for Accordion Item #2' } 
            ]); 
            const activeIndex = ref(null); 
            const toggle = (index) => { activeIndex.value = activeIndex.value === index ? null : index; }; 
            const addItem = () => { 
              { if (items.value.length < maxValue)
                items.value.push({ content: `This is the content for Accordion Item #${items.value.length + 1}` }); 
                activeIndex.value = items.value.length - 1; 
              }
            };           

            const showInOptions = reactive([
              { key: 'quotation', value: 'quotation', label: 'Quotation Itinerary' },
              { key: 'final', value: 'final', label: 'Final  Itinerary' },
              { key: 'website', value: 'website', label: 'Website' },
            ]);

          const showInOptionsPT = reactive([
            { key: 'quotation_pt', value: 'quotation', label: 'Quotation Itinerary' },
            { key: 'final_pt', value: 'final', label: 'Final  Itinerary' },
            { key: 'website_pt', value: 'website', label: 'Website' },
          ]);

          const showInOptionsMT = reactive([
            { key: 'quotation_mt', value: 'quotation', label: 'Quotation Itinerary' },
            { key: 'final_mt', value: 'final', label: 'Final  Itinerary' },
            { key: 'website_mt', value: 'website', label: 'Website' },
          ]);

          const showInOptionsLP = reactive([
            { key: 'quotation_lp', value: 'quotation', label: 'Quotation Itinerary' },
            { key: 'final_lp', value: 'final', label: 'Final  Itinerary' },
            { key: 'website_lp', value: 'website', label: 'Website' },
          ]);

            const showListView = ref(true);
            const isEdit = ref(false);
            const selectedIndex = ref(-1);
            const errorMessage = ref("");

            const rules = { 
              excursionInfo: {
                destinationId: { required }, 
                cityId: { required },
                airportId : { required},
                transportDetails: {
                  $each: helpers.forEach(
                      {
                        carType: {required}
                      }
                  )
                },
                // carType : { required },
                actualPrice : { numeric, minValue:minValue(0.0),
                  maxDecimalPlaces: helpers.withMessage(
            'Maximum 2 decimal places allowed',
            (value) => {
              if (!value) return true;
              const valueStr = value.toString();
              const decimalIndex = valueStr.indexOf('.');
              return decimalIndex === -1 || valueStr.length - decimalIndex <= 3;
            }
          )
                 },
                 companyMarkup: {
          numeric,
          minValue: minValue(0.0),
          maxDecimalPlaces: helpers.withMessage(
            'Maximum 2 decimal places allowed',
            (value) => {
              if (!value) return true;
              const valueStr = value.toString();
              const decimalIndex = valueStr.indexOf('.');
              return decimalIndex === -1 || valueStr.length - decimalIndex <= 3;
            }
          )
        }     
              }
            };

            const filteredExcursions = computed(() => {
  return excursions.list.filter((excursion) => {
    return (
      excursion.destinationName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      excursion.cityName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      excursion.airportName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      excursion.transportDetails.some((transportDetail) =>
        transportDetail.carType.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        transportDetail.transferFrom.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        transportDetail.transferMode.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        transportDetail.partner.toLowerCase().includes(searchQuery.value.toLowerCase()) 
      )
    );
  });
});

const limitDecimals = (transportInfo, field) => {
  if (transportInfo[field] !== undefined && transportInfo[field] !== null) {
    const valueStr = transportInfo[field].toString();
    const decimalIndex = valueStr.indexOf('.');
    
    if (decimalIndex !== -1 && valueStr.length - decimalIndex > 3) {
      transportInfo[field] = parseFloat(valueStr.substring(0, decimalIndex + 3));
    }
  }
};

const getPageRange = () => {
  const range = [];
  const total = totalPages.value;
  const current = currentPage.value;
  const delta = 2;

  if (current > delta + 1) {
    range.push(1);
    if (current > delta + 2) range.push('...');
  }

  for (let i = Math.max(1, current - delta); i <= Math.min(total, current + delta); i++) {
    range.push(i);
  }

  if (current < total - delta - 1) {
    if (current < total - delta - 2) range.push('...');
    range.push(total);
  }

  return range;
};

const resetPagination = () => {
  currentPage.value = 1;
};

const goToPage = (page) => {
  const pageNum = Number(page);
  if (pageNum >= 1 && pageNum <= totalPages.value) {
    currentPage.value = pageNum;
  }
};

const paginatedUsers = computed(() => {
  const pageSize = Number(itemsPerPage.value) || 10;
  const current = Number(currentPage.value) || 1;
  
  const startIndex = (current - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  
  if (!filteredExcursions.value.length) return [];
  
  return filteredExcursions.value.slice(startIndex, endIndex);
});

const totalPages = computed(() => {
  const pageSize = Number(itemsPerPage.value) || 10;
  return Math.max(1, Math.ceil(filteredExcursions.value.length / pageSize));
});

watch(filteredExcursions, () => {
  if (currentPage.value > totalPages.value) {
    currentPage.value = totalPages.value || 1;
  }
});

watch(itemsPerPage, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    resetPagination();
  }
});

const clearSearch = () => {
  searchQuery.value = '';
};

            const v$ = useVuelidate(rules, { excursionInfo });

            const fetchExcursions = async () => {
              
              try {
                const response = await axios.get(API_CONFIG.getEndpoint('fetchExcursions') +'/type/' + excursionType.value);          
                excursions.list = response.data;
              }catch(error) {
                console.log("Error ::" , error);
              }
            };

            const fetchDestinations =  async () => {
              //loading = true;
              try {
                  const resp = await axios.get(API_CONFIG.getEndpoint('fetchDestinations'));                
                  destinations.list = resp.data;
              } catch (error) {
                  console.log('Error fetching destinations list', error);
                  //error = 'Failed to fetch destinations list. Please try again.';
              } finally {
                //loading = false;
              }
            };

            const fetchCitiesByDestination = async () => {        
              try {
                const response = await axios.get(API_CONFIG.getEndpoint('citiesByDestination')  + `/`+ excursionInfo.destinationId );
                cities.list =  response.data;
                if (cities.length === 0) {
                    errorMessage.value = "No cities available for the selected destination.";
                } else {
                    errorMessage.value = "";
                }
              } catch (error) {
                console.log("Error fetching cities list ", error);
                //error = "Failed to fetch cities list. Please try again !!";
              }finally {
                //loading = false;
              }
            };
            
            const fetchPartnersByDestination = async () => {                
                try {
                    const response = await axios.get(API_CONFIG.getEndpoint('searchPartners') + `/`  + excursionInfo.destinationId );
                    partners.list = response.data;
                } catch(error) {
                    console.error('Error fetching partners list', error);
                }
            };

            const fetchAirportsByDestination = async() => {
                try {
                    const resp = await axios.get(API_CONFIG.getEndpoint('searchAirports') + `/` + excursionInfo.destinationId );
                    airports.list = resp.data;
                }catch (error) {
                    console.error("Error fetching airports list",error);
                    //this.error = "Failed to fetch airports list. Please try again";
                }
            }

            const addTransport = async () => {
                //excursionInfo.transportDetails.push({...transportInfo});                
                saveTransportDetails();                
            };

            const formVisible = ref([]);            

            const addMoreTransportDetails = ( index ) => {
              const newTransportDetail = reactive({                
                carType  : '',
                partner: '',
                currency : '',
                actualPrice  : '0',
                companyMarkupType : '', 
                companyMarkup  : 0,
                finalPrice  : '0', 
                transferFrom : 'Airport to Hotel',
                transferMode : 'Private',
                pickupTime : '',
                pickupTimeDisplayIn : [],
                partnerNumber : '',
                partnerNumberDisplayIn : [],
                meetingPoint: '',
                meetingPointDisplayIn : [],
                meetingPointGoogleLink : '',
                description : '',
                status: 'Active',                
              });

              //excursionInfo.transportDetails.push(newTransportDetail); 
              //formVisible.value.push(true); // Show the new form by default 

              excursionInfo.transportDetails.splice(index + 1, 0, newTransportDetail);
              formVisible.value.splice(index + 1, 0, true); // Show the new form by default
            };
            
            const removeTransportDetail = (index) => { 
              if (excursionInfo.transportDetails.length > 1) {
                excursionInfo.transportDetails.splice(index, 1); 
                formVisible.value.splice(index, 1);
              }else{
                console.log("Remove from the list failed since to keep atleast one from the list.");
              }
            };

            const toggleForm = (index) => { 
              formVisible.value[index] = !formVisible.value[index]; 
            };

            const saveTransportDetails = async () => {
                            
              v$.value.$touch(); 
              if (v$.value.$invalid) {
                console.log(v$.excursionInfo);
                showListView.value = false;
                return; 
              }

              excursionInfo.title = excursionInfo.destinationId + '-' + excursionInfo.cityId + '-' + excursionInfo.airportId 
              + '-' +  excursionInfo.transportDetails[0].carType;

              try {
                  const response = await axios.post(API_CONFIG.getEndpoint('createExcursion') 
                    +`?excursionType=${ excursionType.value }`
                    +`&code=${ excursionInfo.airportId }`
                    +`&destinationId=${ excursionInfo.destinationId }`
                    +`&cityId=${ excursionInfo.cityId}`
                    +`&title=${ excursionInfo.title}`,  excursionInfo );
                  Object.assign(excursionInfo, response.data);          
                  console.log("Excursion details",excursionInfo);
                  fetchExcursions();
                  showListView.value = true;
              } catch(error) {
                if (error.response && error.response.status === 409) { 
                  errorMessage.value = 'Duplicate entry detected. The excursion already exists.';alert(errorMessage.value = 'Duplicate entry detected. The excursion already exists.');
                } else { 
                  errorMessage.value = 'An error occurred while saving the excursion details.';
                } 
                console.log("Error while saving excursion details", error);
              }
            };

            const showForm = (action, excursion = null, idx) => {
              selectedIndex.value = idx;
              if( action === 'create') {
                //formTitle =  'Add/Update Hotel Details';
                console.log("Creating a new form")
                reset();
                fetchDestinations();
                showListView.value = false;
                isEdit.value = false;
              } else if (action === 'edit' && excursion ) {                    
                //load excursion object to excursionInfo obj  
                loadExcursionDetails(excursion);
                console.log("This is called => " + JSON.stringify(excursionInfo) );                      
                fetchDestinations();                    
                onChangeByDestination();
                showListView.value = false;
                isEdit.value = true;                    
              }
            };           

            const cancel = () => {
              reset();
              showListView.value  = true;
            };

            const reset = () => {                             
              Object.assign(excursionInfo, {
                destinationId : '',
                cityId : '',
                airportId : '',
                status: 'Active',
                transportDetails : [{
                  carType  : 'test',
                  partner: '',
                  currency : '',
                  actualPrice  : '0',
                  companyMarkupType : '',
                  companyMarkup  : 0,
                  finalPrice  : '0',
                  transferFrom : 'Airport to Hotel',
                  transferMode : 'Private',
                  pickupTime : '',
                  pickupTimeDisplayIn : [],
                  partnerNumber : '',
                  partnerNumberDisplayIn : [],
                  meetingPoint: '',
                  meetingPointDisplayIn : [],
                  meetingPointGoogleLink : '',
                  description : '',
                  status: 'Active',
                }],
                title : ''
              });

              formVisible.value = [];
            };

            const onChangeByDestination = () => {
              fetchCitiesByDestination();
              fetchPartnersByDestination();
              fetchAirportsByDestination();
            }

            const findTitle = (transport ) => {              
              excursionInfo.title = excursionInfo.destinationId + '-' + excursionInfo.cityId + '-' + excursionInfo.airportId + '-' + transport.carType;              
              console.log("findTitle called :: " +  excursionInfo.title );
            };
            const computedTitles = computed(() => {
              console.log("computedTitles called :: ");
              return excursionInfo.transportDetails.map(transportInfo => {
                return `${excursionInfo.destinationId} - ${excursionInfo.cityId} - ${excursionInfo.airportId} - ${transportInfo.carType}`;
              });
            });

            const calculateFinalPrice = ( transport ) => {
              let fPrice = transport.actualPrice;
              if( transport.companyMarkupType === 'percentage' ) {
                fPrice *= (1 + transport.companyMarkup / 100);
              } else if( transport.companyMarkupType === 'absolute' ) {
                fPrice += transport.companyMarkup;
              }
              transport.finalPrice = (Math.round(fPrice * 100) / 100).toFixed(2);
              return transport.finalPrice;   
            };

      const confirmDeleteExcursion = (id) => {
      excursionToDelete.value = id;
      showDeleteModal.value = true;
    };

    const deleteExcursion = async () => {
      if (!excursionToDelete.value) return;
      try {
        await axios.delete(`${API_CONFIG.getEndpoint('fetchExcursions')}/${excursionToDelete.value}`);
        excursions.list = excursions.list.filter(excursion => excursion.id !== excursionToDelete.value);
        fetchExcursions();
      } catch (error) {
        console.error('Error deleting excursion:', error);
      } finally {
        showDeleteModal.value = false;
        excursionToDelete.value = null;
      }
    };

    const cancelDelete = () => {
      showDeleteModal.value = false;
      excursionToDelete.value = null;
    };

            const deleteTransport = ( excursion_id, index ) => {
                //excursionInfo.id = excursion_id;
                if (index > -1 && index < excursionInfo.transportDetails.length) {
                    excursionInfo.transportDetails.splice(index, 1); //removed from rooms list
                    updateTransport();
                }
            };

            const loadExcursionDetails = (excursion) => { 
              // Copy top-level properties 
              Object.assign(excursionInfo, excursion); 
              // Deep copy transportDetails array               
              excursionInfo.transportDetails = excursion.transportDetails.map(detail => ({ ...detail }));

              if (! ('pickupTimeDisplayIn' in excursionInfo.transportDetails[0])) {
                excursionInfo.transportDetails[0]['pickupTimeDisplayIn'] = []
              }
              if (! ('partnerNumberDisplayIn' in excursionInfo.transportDetails[0])) {
                excursionInfo.transportDetails[0]['partnerNumberDisplayIn'] = []
              }
              if (! ('meetingPointDisplayIn' in excursionInfo.transportDetails[0])) {
                excursionInfo.transportDetails[0]['meetingPointDisplayIn'] = []
              }
              console.log(excursionInfo.transportDetails)
              formVisible.value = excursionInfo.transportDetails.map(() => true);
            };

            const updateTransport = async () => {
              v$.value.$touch();
              if (v$.value.$invalid) {
                console.log(v$.value);
                showListView.value = false;
                return;
              }

              excursionInfo.title = excursionInfo.destinationId + '-' + excursionInfo.cityId + '-' + excursionInfo.airportId 
              + '-' +  excursionInfo.transportDetails[0].carType;

              try {  
                
                const response = await axios.post(API_CONFIG.getEndpoint('createExcursion') +`/${excursionInfo.id}`
                    +`?excursionType=${ excursionType.value }`
                    +`&code=${ excursionInfo.airportId }`
                    +`&destinationId=${ excursionInfo.destinationId }`
                    +`&cityId=${ excursionInfo.cityId}`
                    +`&title=${ excursionInfo.title}`,  excursionInfo );

                Object.assign(excursionInfo, response.data);                 
                showListView.value = true;
                fetchExcursions();
                // this.$router.push({              
                //   path: `/rooms/${this.excursionInfo.id}`
                // });
              } catch(error) {
                console.log("Update hotels failed" + error);
              }
            };

            onMounted ( () => {        
              fetchExcursions();
            });

            return {
                //...toRefs(transportInfo),
                v$,
                transportDetails,excursionInfo,
                destinations,partners,airports,excursions,
                fetchDestinations, fetchPartnersByDestination,
                currencies,
                fetchExcursions,deleteTransport,updateTransport,
                showListView,                
                cities,
                showForm,formVisible,addMoreTransportDetails,toggleForm,removeTransportDetail,
                reset,
                errorMessage,
                isEdit,
                cancel,
                onChangeByDestination,
                showInOptions, showInOptionsPT, showInOptionsMT, showInOptionsLP,
              addTransport,saveTransportDetails,
                items,
                activeIndex,
                toggle,
                addItem,                
                maxValue,
                calculateFinalPrice, loadExcursionDetails,
                deleteExcursion,findTitle,computedTitles,
                showDeleteModal,
                cancelDelete, confirmDeleteExcursion, excursionToDelete,
                filteredExcursions, searchQuery, clearSearch,
                paginatedUsers, goToPage, resetPagination, totalPages, getPageRange, currentPage, itemsPerPage, limitDecimals

            };
        }
    };
</script>

<style scoped>
/* Layout */
.layout {
  display: flex;
  height: 100vh;
}

/* Sidebar Styling */
.sidebar {
  width: 250px;
  background: #f8f9fa;
  padding: 20px;
  height: 100vh;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.active {
  color: blue;
}

/* Content Area */
.content {
  flex: 1;
  padding: 20px;
  background: #fff;
}

/* Table Styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

th {
  background: #007bff;
  color: white;
}

.btn1{
  color: #ffffff;
  background-color: #7978E9;
  border-radius:6px;
}
.btn1:hover{
  background-color: #4B49AC;
}

label{
    margin: 0px;
  }
</style>
